import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, FormControl, FormLabel, FormGroup, FormControlLabel, Switch, RadioGroup, Radio } from '@mui/material';
import { QueuePlayNext, DeleteForever, ContentCopy } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Service from '../../services/config.service';
import * as SatisfactionSurveyService from '../../services/satisfactionsurvey.service';
import * as SatisfactionSurveySettingsDetailService from '../../services/satisfactionsurveysettingsdetail.service';
import * as EmailCustomSettingsService from '../../services/emailcustomsettings.service';
import * as componentActions from '../../redux/actions/configs';
import * as shippingservicesettingsActions from '../../redux/actions/shippingservicesettings';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs'
import Autocomplete from '@mui/material/Autocomplete';
import SimpleCheckBox from '../../components/checkbox/check';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import SimpleSelect from "../../components/select/simple";
import { PapiroConsole } from '../../utils/papiroConsole';
import ShippingServiceSettings from './components/shippingservicesettings-tab';
import ConfigPasswordPolicy from './components/configpasswordpolicy';
import Emailcustomsettings from './components/emailcustomsettings';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import WhitelabelconfigTab from './components/whitelabelconfig-tab';
import MessageProviderConfig from './components/messageproviderconfig'
import * as userSessionActions from '../../redux/actions/user-session'
import * as OrganizationService from '../../services/organization.service'
import DialogConfirmationDeleteProviderUser from '../../components/dialog-confirmation-not-button'
import Delete from "@mui/icons-material/Delete";
import EmailconfigTab from './components/emailconfig-tab';
import Configtwofactorauthentication from './components/configtwofactorauthentication';

function getRandomNUmber(){
  const min = 1;
  const max = 10000;
  const rand = min + Math.random() * (max - min);      
  return rand;
}

class ConfigEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,configs } = this.props;
    PapiroConsole.log(configs)
    PapiroConsole.log("configs")
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    if(!configs || (configs && !configs.config)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );
    PapiroConsole.log("=== ConfigEdit ===")
    PapiroConsole.log(this.props)
    console.log(configs)
    console.log("configsconfigs")
    const defaultvalue = {id:0, name:''};
    this.timezoneid =   configs.config.timezone;
    const intl = this.props.intl;
    const ascending = intl.formatMessage({id:"order.ascending"}); const descending = intl.formatMessage({id:"order.descending"});
    let ordeningoptions = [ { id: 1, name: ascending }, { id: 2, name: descending }]
    
    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }

    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    let rTabIndex = 0
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.propTab && this.props.location.state.propTab > 0) {
      rTabIndex = this.props.location.state.propTab
    }
    
    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: configs.config.id,
      hirerid: configs.config.hirerid,
      //timezone :  defaultvalue, 
      timezone :  {id: configs.config.timezone.id, name: configs.config.timezone.id + ' - ' + configs.config.timezone.name}, 
      formatdate : configs.config.formatdate == null ? defaultvalue : { id: getRandomNUmber(), name: configs.config.formatdate}, 
      decimalseparator : configs.config.decimalseparator == null ? '' : configs.config.decimalseparator, 
      thousandseparator : configs.config.thousandseparator == null ? '' : configs.config.thousandseparator, 
      //gasvalueperliter: configs.config.gasvalueperliter == null ? '' : configs.config.gasvalueperliter, 
      //kmperliter: configs.config.kmperliter == null ? '' : configs.config.kmperliter,
      kmvaluerotated: configs.config.kmvaluerotated == null ? '' : configs.config.kmvaluerotated,
      daystoarchivefinishedcalled: configs.config.daystoarchivefinishedcalled == 0 ? 0 : configs.config.daystoarchivefinishedcalled,
      showquickcalled : configs.config.showquickcalled ? true: false,
      attachmentlimitmb : configs.config.attachmentlimitmb == null ? 0 : configs.config.attachmentlimitmb,

      timeintervalforreopeningcalled : configs.config.timeintervalforreopeningcalled == null ? 0 : configs.config.timeintervalforreopeningcalled,

      passwordmail: "",
      mailtest: "",
      cancreateclientusersexternal: configs.config.cancreateclientusersexternal ? configs.config.cancreateclientusersexternal : false,
      showknowledgebaseinlogin: configs.config.showknowledgebaseinlogin ? configs.config.showknowledgebaseinlogin : false,
      canusereditemail: configs.config.canusereditemail ? configs.config.canusereditemail : false,
      removecallswithfinishedstatus:
        configs.config.removecallswithfinishedstatus == 1
          ? true
          : false,
      removecallswithcanceledstatus: configs.config.removecallswithcanceledstatus ? true: false,
      calledaccompanimentordertype: configs.config.calledaccompanimentordertype,
      satisfactionsurveyid : configs.config.satisfactionsurvey ? configs.config.satisfactionsurvey : { id: 0, name: "Nenhuma" } ,
      validationofexistingcalleds: configs.config.validationofexistingcalleds,
      validationofequalcalledsatcreation : configs.config.validationofequalcalledsatcreation ? configs.config.validationofequalcalledsatcreation : false,
      emailvalidationofexistingcalled: configs.config.emailvalidationofexistingcalled,
      documentvalidationofexistingcalled: configs.config.documentvalidationofexistingcalled,
      allcategoryvalidationofexistingcalled: configs.config.allcategoryvalidationofexistingcalled,
      samecategoryvalidationofexistingcalled: configs.config.samecategoryvalidationofexistingcalled,
      validationofexistingcalledmessage: configs.config.validationofexistingcalledmessage,
      twoFactorAuthenticationEnable: configs.config.twofactorauthenticationenable,
      twoFactorAuthenticationAppEnable: configs.config.twofactorauthenticationappenable,
      configpasswordpolicy: configs.config.configpasswordpolicy,
      canexternaluserclientcreatecalled: configs.config.canexternaluserclientcreatecalled ? configs.config.canexternaluserclientcreatecalled : false,
      canclientshowhelpfloatingbutton: configs.config.canclientshowhelpfloatingbutton ? configs.config.canclientshowhelpfloatingbutton : false,
      isunityobrigatory: configs.config.isunityobrigatory ? configs.config.isunityobrigatory : false,
      iscostcontrolobrigatory: configs.config.iscostcontrolobrigatory ? configs.config.iscostcontrolobrigatory : false,
      automaticallycreatecalledafterendingchat : configs.config.automaticallycreatecalledafterendingchat ?  configs.config.automaticallycreatecalledafterendingchat : false,
      satisfactionsurveysettingsid: configs.config.satisfactionsurveysettingsid,
      enablewebchatinside2do: configs.config.enablewebchatinside2do,
     };         
    this.state = {
      config: this.initialState,
      emailconfig: {
        id: configs.config.id,
        frommail: configs.config.frommail == null ? '' : configs.config.frommail,
        hostmail: configs.config.hostmail == null ? '' : configs.config.hostmail,
        portmail: configs.config.portmail == null ? '' : configs.config.portmail,
        usermail: configs.config.usermail == null ? '' : configs.config.usermail,
        enablessl: configs.config.enablessl == null ? false : configs.config.enablessl,
        usemexxdefaultemailprovider: configs.config.usemexxdefaultemailprovider ? configs.config.usemexxdefaultemailprovider : false,
        frommailreceive: configs.config.frommailreceive,
        emailorganizationid: { id: 0, name: "" },
        accompanimentcalledforresponseemail: configs.config.accompanimentcalledforresponseemail,
        responseemailisnewcalled: configs.config.responseemailisnewcalled
      },
      satisfactionSurveyList : [],
      OrgsList : [],
      satisfactionSurveySettingsDetailList : [],
      emailCustomSettings: {},
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      TimeZoneList: [],
      FormatDateList: [],
      ordeningoptions: ordeningoptions,
      rTabIndex: rTabIndex,
      openDeleteProviderUserDialog: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.newchangeValues = this.newchangeValues.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.setRTabIndex = this.setRTabIndex.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.handleChangeEditorAction = this.handleChangeEditorAction.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
    this.updateList = this.updateList.bind(this)
    this.updateEmailCustomSettings = this.updateEmailCustomSettings.bind(this)
  }

  setLoading = value => this.setState({ loading: value })
  setMessage = (open, variant, message) => this.setState({ openNotification: open, notificationVariant: variant, notificationMessage: message })

  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

  async componentDidMount() {
    await this.getTimeZones();
    //console.log(this.state.config.timezoneid);
    // await this.getTimeZone(this.timezoneid);
    await this.getFormatDates();
    await this.getSatisfactionSurveys();
    await this.getSatisfactionSurveySettingsDetails()
    await this.getEmailCustomSettings()
    await this.getOrganizations()
  };

  setRTabIndex = idx => this.setState({ rTabIndex: idx })

  async getTimeZone(id){
    this.setState({ loading: true });
    var result = await Service.getTimeZone(id);
      if (result.success) {
        //console.log(result.data);
        //this.setState({ loading: false, timezone: result.data });

        this.setState(prevState => ({
          config: {
            ...prevState.config,
            ['timezone']: result.data
          }
        }));        
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
  };

  async getTimeZones(){
      this.setState({ loading: true });
      var result = await Service.getTimeZones();
        if (result.success) {
          //console.log(result.data);
          if(result.data && result.data.length > 0) {
            var formattedList = result.data.map(item => {

              let itemOldName = item.name
              item.name = item.id + ' - ' + item.name

              return {
                  ...item,
                  oldName: itemOldName
              }
            })
            this.setState({ loading: false, TimeZoneList: formattedList });
          }
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
  };

  async getFormatDates(){
        this.setState({ loading: true });
        var result = await Service.getFormatDates();
        //console.log(result.data);
          if (result.success) {
            this.setState({ loading: false, FormatDateList: result.data });
                          
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
          }
  };

  async getSatisfactionSurveys(){
    this.setState({ loading: true });
    var result = await SatisfactionSurveyService.getAll();
    //console.log(result.data);
      if (result.success) {
        const NoneItem = { id: 0, name: "Padrão" };
        let satisfactionSurveyList = result.data; 
        if (satisfactionSurveyList.length > 0) {
        
          satisfactionSurveyList.unshift(NoneItem);
          this.setState({ loading: false, satisfactionSurveyList: result.data });
        }

        this.setState({ loading: false });

        
                      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
};
  async getSatisfactionSurveySettingsDetails() {
    this.setState({ loading: true })
    var result = await SatisfactionSurveySettingsDetailService.getAll(this.state.config.id)
    if (result.success) {
      this.setState({ satisfactionSurveySettingsDetailList: result.data, loading: false })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }
  async getEmailCustomSettings() {
    this.setState({ loading: true })
    var result = await EmailCustomSettingsService.getByConfigId(this.state.config.id)
    if (result.success) {
      this.setState({ emailCustomSettings: result.data, loading: false })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }
  
  async getOrganizations() {
    this.setState({ loading: true })
    var result = await OrganizationService.getOrganizationsNewSimple()
    if (result.success) {
      this.setState((prevState) =>
        ({
          ...prevState,
          emailconfig: {
            ...prevState.emailconfig,
            emailorganizationid: result.data.find(org => org.id == this.props.configs.config.emailorganizationid)
          },
          OrgsList: result.data,
          loading: false 
        }))
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }


  NumberFormatCustom(props) {

    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  NumberFormatCustomDecimal(props) {

    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator="."
        isNumericString
        decimalScale={2}
       
      />
    );
  }

  async newchangeValues(stateName, value) {
    this.setState({ loading: true });

    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'kmvaluerotated' || value.length == 0) {
      const re = /^[0-9\b]+$/;
      if (re.test(value)) {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            [name]: value
          }
        }));
      }else{
        this.setState(prevState => ({
          config: {
            ...prevState.config,
            [name]: ""
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          [name]: value
        }
      }));
    }
  }

  handleClick = async (e) => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    if(this.state.config.mailtest.length > 0) {
        let result = await Service.testMail(this.state.config.mailtest);
        if(result && result.data && result.data.success == true){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({id:"email.sent"})
            });
        }else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
    }else {
        this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"missing.destination.email"})
        })
    }
  };

  checkProviderDeletion = (e) => {
    e.preventDefault();
    if (this.props.configs.config.usemexxdefaultemailprovider != this.state.config.usemexxdefaultemailprovider && this.state.config.usemexxdefaultemailprovider == false) {
      this.setState({
        openDeleteProviderUserDialog: true
      })
    } else {
      this.handleSubmit(e)
    }
  }

  handleSubmit = async e => {
    const intl = this.props.intl;

    this.setState({ loading: true });

    if (this.state.config.calledaccompanimentordertype == null || this.state.config.calledaccompanimentordertype == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"config.edit.notification.invalid.called.accompaniment.order"})
      })
    } else {
    

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
          }
        }));
      } else {
        this.setState(prevState => ({
          config: {
            ...prevState.config,
          }
        }));
      }


      for (let key in this.state.config) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.config[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('timezone') > -1) { 
          data.append(key, this.state.config[key].id);
        } else if (key.toString().toLowerCase().indexOf('formatdate') > -1) { 
          data.append(key, this.state.config[key].name);
        } else if(key.toString().toLowerCase().indexOf("gasvalueperliter") > -1) {
            if(this.state.config[key].length > 0) {
              data.append(key, this.state.config[key])
            } else
              data.append(key, 0)
        } else if (key.toString().toLowerCase().indexOf('removecallswithfinishedstatus') > -1) {
          data.append(key, this.state.config[key]);
        }
        else if (key.toString().toLowerCase().indexOf('showquickcalled') > -1) {
          data.append(key, this.state.config[key]);
        }
        else if (key.toString().toLowerCase().indexOf('cancreateclientusersexternal') > -1) {
          data.append(key, this.state.config[key]);
        } else if (key.toString().toLowerCase().indexOf('showknowledgebaseinlogin') > -1) {
          data.append(key, this.state.config[key]);
        } else if (key.toString().toLowerCase().indexOf('canusereditemail') > -1) {
          data.append(key, this.state.config[key]);
        } else if (key.toString().toLowerCase().indexOf('calledaccompanimentordertype') > -1) {
          data.append(key, this.state.config[key]);
        }else if (key.toString().toLowerCase().indexOf('satisfactionsurveyid') > -1) {
          if(this.state.config[key] && this.state.config[key].id){
              data.append(key, this.state.config[key].id);
          }
          else{
            data.append(key, 0);
          }
          
        } else if (key.toString().toLowerCase().indexOf('validationofexistingcalledmessage') > -1) {
          data.append(key, this.state.config[key] != null ? this.state.config[key] : "");
        } else if (key.toString().toLowerCase().indexOf('validationofexistingcalleds') > -1 || 
        key.toString().toLowerCase().indexOf('emailvalidationofexistingcalled') > -1 || 
        key.toString().toLowerCase().indexOf('documentvalidationofexistingcalled') > -1 || 
        key.toString().toLowerCase().indexOf('allcategoryvalidationofexistingcalled') > -1 || 
        key.toString().toLowerCase().indexOf('samecategoryvalidationofexistingcalled') > -1 || 
        key.toString().toLowerCase().indexOf('canexternaluserclientcreatecalled') > -1 || 
        key.toString().toLowerCase().indexOf('canclientshowhelpfloatingbutton') > -1) {
          data.append(key, this.state.config[key] == null || typeof this.state.config[key] === 'undefined' ? false : this.state.config[key]);
        } else {
          data.append(key, this.state.config[key]);
        }
      }

      PapiroConsole.log(this.state.config.attachmentlimitmb)
      PapiroConsole.log("this.state.config.attachmentlimitmb")

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/configs', data, config)

        .then(result => {
          if (result.data.success) {
        const formattedList = result.data.data;
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
            if (this.props.userSession && this.props.userSession.user) {
              let user = this.props.userSession.user
              user.isunityobrigatory = result.data.data.isunityobrigatory
              user.iscostcontrolobrigatory = result.data.data.iscostcontrolobrigatory
              this.props.addUserSession(user)
            }
        this.props.addCurrent(formattedList);
        this.timezoneid=formattedList.timezone;
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
    }
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });
    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [stateName]: value
      }
    }));
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  handleChangeEditorAction = value => {
    this.setState(prevState => ({
      config: {
        ...prevState.config,
        validationofexistingcalledmessage: value
      }
    }))
  }

  handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    if (name === 'emailvalidationofexistingcalled') {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          documentvalidationofexistingcalled: checked ? false : true
        }
      }))
    } else if (name === 'documentvalidationofexistingcalled') {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          emailvalidationofexistingcalled: checked ? false : true
        }
      }))
    } else if (name === 'allcategoryvalidationofexistingcalled') {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          samecategoryvalidationofexistingcalled: checked ? false : true
        }
      }))
    } else if (name === 'samecategoryvalidationofexistingcalled') {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          allcategoryvalidationofexistingcalled: checked ? false : true
        }
      }))
    }
    else if (name === 'validationofequalcalledsatcreation') {
      this.setState(prevState => ({
        config: {
          ...prevState.config,
          validationofequalcalledsatcreation: checked ? false : true
        }
      }))
    }
    
    this.setState(prevState => ({
      config: {
        ...prevState.config,
        [name]: checked
      }
    }))
  }

  updateList = (list) => {
    this.setState({ satisfactionSurveySettingsDetailList: list})
  }

  updateEmailCustomSettings = (emailCustomSettings) => {
    this.setState({ emailCustomSettings: emailCustomSettings })
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, configs, shippingservicesettings, whitelabelconfigs, userSession } = this.props;
    const { config, emailconfig } = this.state;
    const intl = this.props.intl;
    PapiroConsole.log(this.state.config)
    PapiroConsole.log("config aqui")

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
                <Grid item xs={12} sm={12}>
                  <RTabs selectedIndex={this.state.rTabIndex} onSelect={ index => this.setRTabIndex(index) } >
                    <RTabList style={{ borderColor: '#BB8FCE' }}>
                      <RTab>{intl.formatMessage({id:"general.settings"})}</RTab>
                      <RTab>{intl.formatMessage({id:"mail.box.config"})}</RTab>
                      <RTab>{intl.formatMessage({id:"whitelabelconfig.header"})}</RTab>
                      <RTab>{intl.formatMessage({id:"email.appeearance"})}</RTab>
                      <RTab>{intl.formatMessage({id:"shippingservicesettings"})}</RTab>
                      <RTab>{intl.formatMessage({id:"password.policies"})}</RTab>
                      <RTab>{intl.formatMessage({id:"two-factor.authentication.policy"})}</RTab>
                    </RTabList>
                    <RTabPanel style={{ padding: 10 }}>
                      <form name='myForm' className={classes.form} onSubmit={this.checkProviderDeletion} encType='multipart/form-data'>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <SimpleAutoComplete label={intl.formatMessage({id:"timezone"})} options={this.state.TimeZoneList} stateName='timezone' changeSelect={this.changeValues} selected={config.timezone} />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleAutoComplete label={intl.formatMessage({id:"date.format"})} options={this.state.FormatDateList} stateName='formatdate' changeSelect={this.changeValues} selected={config.formatdate} />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField fullWidth label={intl.formatMessage({id:"decimal.separator"})} autoComplete='fname' variant='outlined' name='decimalseparator' onChange={this.handleInputChange} value={config.decimalseparator} required 
                            onInput = {(e) =>{
                              e.target.value = e.target.value.toString().slice(0,1)
                            }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField fullWidth label={intl.formatMessage({id:"thousand.separator"})} autoComplete='fname' variant='outlined' name='thousandseparator' onChange={this.handleInputChange} value={config.thousandseparator} required 
                            onInput = {(e) =>{
                              e.target.value = e.target.value.toString().slice(0,1)
                            }}
                            />
                          </Grid>
                          <Grid item xs={3} sm={3}>
                            <TextField
                              label={intl.formatMessage({id:"called.distance.value"})}
                              variant="outlined"
                              value={config.kmvaluerotated}
                              onChange={this.handleInputChange}
                              name="kmvaluerotated"
                              id="formatted-numberformat-input"
                              InputProps={{
                                inputComponent: this.NumberFormatCustom,
                                
                              }}
                              autoComplete="fname"
                              required
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleSelect
                            label={intl.formatMessage({id:"calledaccompanimentordertype"})}
                            options={this.state.ordeningoptions}
                            stateName='calledaccompanimentordertype'
                            changeSelect={this.changeValues}
                            selected={config.calledaccompanimentordertype}
                            required={true}
                            />
                          </Grid>

                          <Grid item xs={11.5} >
                            <SimpleAutoComplete
                            label={intl.formatMessage({id:"select.satisfaction.survey"})}
                            options={this.state.satisfactionSurveyList}
                            stateName='satisfactionsurveyid'
                            changeSelect={this.changeValues}
                            selected={config.satisfactionsurveyid}
                            
                            />
                            
                            
                          </Grid>
                          <Grid item xs={0.5} sm={0.5}
                              style={{ textAlign: "middle", alignSelf: "stretch",  marginTop:'13px' }}
                            >
                              <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'select.satisfaction.survey.info' })  }>
                                <HelpIcon />
                              </Tooltip>
                            </Grid>
                          <Grid item xs={11.5} sm={11.5}>
                            <TextField
                              name="daystoarchivefinishedcalled"
                              variant="outlined"
                              type="number"
                              fullWidth
                              id="daystoarchivefinishedcalled"
                              label={intl.formatMessage({id:"daysToArchive"})}
                              value={config.daystoarchivefinishedcalled}
                              onChange={this.handleInputChange}
                              InputProps={{
                                inputProps: { min: 0 }
                              }} />
                          </Grid>
                          <Grid item xs={0.5} sm={0.5}
                              style={{ textAlign: "middle", alignSelf: "stretch",  marginTop:'13px' }}
                            >
                              <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'tooltip.daysToArchive' })  }>
                                <HelpIcon />
                              </Tooltip>
                            </Grid>

                            <Grid item xs={11.5} sm={11.5}>
                              <TextField
                                label={intl.formatMessage({id:"attachmentlimitmb"})}
                                variant="outlined"
                                value={config.attachmentlimitmb}
                                onChange={this.handleInputChange}
                                name="attachmentlimitmb"
                                id="formatted-numberformat-input"
                                InputProps={{
                                  inputComponent: this.NumberFormatCustomDecimal,
                                  
                                }}
                                helperText="Caso esse valor seja alterado, será necessário efetuar login novamente"
                                autoComplete="fname"
                            
                                InputLabelProps={{ shrink: true }}
                              />
                          </Grid>
                          <Grid item xs={0.5} sm={0.5}
                              style={{ textAlign: "middle", alignSelf: "stretch",  marginTop:'13px' }}
                            >
                              <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'tooltip.attachmentlimitmb' })  }>
                                  <HelpIcon />
                              </Tooltip>
                          </Grid>
                          <Grid item xs={11.5} sm={11.5}>
                              <TextField
                                label={intl.formatMessage({id:"time.interval.for.reopening.called"})}
                                variant="outlined"
                                value={config.timeintervalforreopeningcalled}
                                onChange={this.handleInputChange}
                                name="timeintervalforreopeningcalled"
                                id="formatted-numberformat-input"
                                InputProps={{
                                  inputComponent: this.NumberFormatCustomDecimal,
                                  
                                }}
                                helperText="Caso esse valor seja alterado, será necessário efetuar login novamente"
                                autoComplete="fname"
                            
                                InputLabelProps={{ shrink: true }}
                              />
                          </Grid>
                          <Grid item xs={0.5} sm={0.5}
                              style={{ textAlign: "middle", alignSelf: "stretch",  marginTop:'13px' }}
                            >
                              <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'tooltip.time.interval.for.reopening.called' })  }>
                                  <HelpIcon />
                              </Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.removecallswithfinishedstatus"})} 
                              name='removecallswithfinishedstatus' 
                              stateName='removecallswithfinishedstatus' 
                              changeSelect={this.changeValues} 
                              selected={config.removecallswithfinishedstatus} />                       
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.removecallswithcanceledstatus"})} 
                              name='removecallswithcanceledstatus' 
                              stateName='removecallswithcanceledstatus' 
                              changeSelect={this.changeValues} 
                              selected={config.removecallswithcanceledstatus} />                       
                          </Grid>

                          
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.cancreateclientusersexternal"})} 
                              name='cancreateclientusersexternal' 
                              stateName='cancreateclientusersexternal' 
                              changeSelect={this.changeValues} 
                              selected={config.cancreateclientusersexternal} />                       
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.showknowledgebaseinlogin"})} 
                              name='showknowledgebaseinlogin' 
                              stateName='showknowledgebaseinlogin' 
                              changeSelect={this.changeValues} 
                              selected={config.showknowledgebaseinlogin} />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.canusereditemail"})} 
                              name='canusereditemail' 
                              stateName='canusereditemail' 
                              changeSelect={this.changeValues} 
                              selected={config.canusereditemail} />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.isunityobrigatory"})} 
                              name='isunityobrigatory' 
                              stateName='isunityobrigatory' 
                              changeSelect={this.changeValues} 
                              selected={config.isunityobrigatory} />
                          </Grid>
                          <Grid item xs={11.5}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.showquickcalled"})} 
                              name='showquickcalled' 
                              stateName='showquickcalled' 
                              changeSelect={this.changeValues} 
                              selected={config.showquickcalled} />
                          </Grid>
                          <Grid item xs={0.5} 
                              style={{ textAlign: "middle", alignSelf: "stretch",  marginTop:'13px' }}
                            >
                              <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'config.showquickcalled.info' })  }>
                                  <HelpIcon />
                              </Tooltip>
                          </Grid>
                          
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"config.iscostcontrolobrigatory"})} 
                              name='iscostcontrolobrigatory' 
                              stateName='iscostcontrolobrigatory' 
                              changeSelect={this.changeValues} 
                              selected={config.iscostcontrolobrigatory} />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"automatically.create.called.after.ending.chat"})} 
                              name='automaticallycreatecalledafterendingchat' 
                              stateName='automaticallycreatecalledafterendingchat' 
                              changeSelect={this.changeValues} 
                              selected={config.automaticallycreatecalledafterendingchat} />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox 
                              label={intl.formatMessage({id:"enable.webchat.inside.2do"})} 
                              name='enablewebchatinside2do' 
                              stateName='enablewebchatinside2do' 
                              changeSelect={this.changeValues} 
                              selected={config.enablewebchatinside2do} />
                          </Grid>
                          {/* <Grid item xs={12} sm={12}>
                            <SimpleCheckBox
                              label={intl.formatMessage({ id: "twoFactorAuth.enableEmail" })}
                              name='twoFactorAuthenticationEnable'
                              stateName='twoFactorAuthenticationEnable'
                              changeSelect={this.newchangeValues}
                              selected={config.twoFactorAuthenticationEnable} />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SimpleCheckBox
                              label={intl.formatMessage({ id: "enable.two-factor.authentication.via.app" })}
                              name='twoFactorAuthenticationAppEnable'
                              stateName='twoFactorAuthenticationAppEnable'
                              changeSelect={this.newchangeValues}
                              selected={config.twoFactorAuthenticationAppEnable} />
                          </Grid> */}
                          <Grid item xs={12} sm={12}>
                            <Typography component="h1" variant="h5" style={{color: "#263238", fontSize: "16px" }}>
                              {intl.formatMessage({id:"mail.reading"})}
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset" variant="standard">
                              <FormLabel component="legend">
                                <FormattedMessage id="called.service.settings.validation" />
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch 
                                      checked={config.validationofexistingcalleds}
                                      onChange={this.handleChangeSwitch}
                                      name="validationofexistingcalleds" />
                                  }
                                  label={intl.formatMessage({ id:"called.service.settings.existing.called.validation" })} />
                              </FormGroup>
                            </FormControl>

                            
                    


                            
                          </Grid>
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={11} style={{ paddingTop: 0 }}>
                            <Typography component="span" variant="body1">
                              {intl.formatMessage({id:"called.service.settings.existing.called.user.validation"})}
                            </Typography>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={config.emailvalidationofexistingcalled}
                                    onChange={this.handleChangeSwitch}
                                    name="emailvalidationofexistingcalled" />
                                }
                                label={intl.formatMessage({ id:"called.service.settings.mail.existing.called.validation" })} />
                            </FormGroup>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.mail.existing.called.validation.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={config.documentvalidationofexistingcalled}
                                    onChange={this.handleChangeSwitch}
                                    name="documentvalidationofexistingcalled" />
                                }
                                label={intl.formatMessage({ id:"called.service.settings.document.existing.called.validation" })} />
                            </FormGroup>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.document.existing.called.validation.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={11} style={{ paddingTop: 0 }}>
                            <Typography component="span" variant="body1">
                              {intl.formatMessage({id:"called.service.settings.existing.called.category.validation"})}
                            </Typography>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={config.allcategoryvalidationofexistingcalled}
                                    onChange={this.handleChangeSwitch}
                                    name="allcategoryvalidationofexistingcalled" />
                                }
                                label={intl.formatMessage({ id:"called.service.settings.all.category.existing.called.validation" })} />
                            </FormGroup>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.all.category.existing.called.validation.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={1} style={{ paddingTop: 0 }} />}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={6} style={{ paddingTop: 0 }} >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch 
                                    checked={config.samecategoryvalidationofexistingcalled}
                                    onChange={this.handleChangeSwitch}
                                    name="samecategoryvalidationofexistingcalled" />
                                }
                                label={intl.formatMessage({ id:"called.service.settings.same.category.existing.called.validation" })} />
                            </FormGroup>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={1} sm={5} style={{ textAlign: 'left', alignSelf: 'stretch', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.same.category.existing.called.validation"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={10} sm={10}>
                            <Typography>
                              <FormattedMessage id="called.service.settings.existing.called.validation.message" />
                            </Typography>
                            <ReactQuill
                              id="validationofexistingcalledmessage"
                              name="validationofexistingcalledmessage"
                              modules={this.reactQuillModules}
                              formats={this.reactQuillFormats}
                              value={config.validationofexistingcalledmessage}
                              style={{ height: 200 }}
                              fullWidth
                              required
                              InputLabelProps={{ shrink: config.validationofexistingcalledmessage && config.validationofexistingcalledmessage.length > 0 ? true : false }}
                              variant="outlined"
                              onChange={this.handleChangeEditorAction} />
                          </Grid>}
                          {config.validationofexistingcalleds == true && <Grid item xs={2} sm={2} style={{ display: 'flex', alignItems: 'center', paddingTop: 0 }}>
                            <Tooltip title={<h5>{intl.formatMessage({id:"called.service.settings.existing.called.validation.message.helper"})}</h5>}>
                              <HelpIcon />
                            </Tooltip>
                          </Grid>}
                          <Grid item xs={11.5} sm={11.5} style={{marginTop: config.validationofexistingcalleds ? '50px' : '0px'}}>
                            <FormControl component="fieldset" variant="standard">
                            
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch 
                                      checked={config.validationofequalcalledsatcreation}
                                      onChange={this.handleChangeSwitch}
                                      name="validationofequalcalledsatcreation" />
                                  }
                                  label={intl.formatMessage({ id:"validation.of.equal.calleds.at.creation" })}/>
                              
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={0.5} sm={0.5}
                              style={{ textAlign: "middle", alignSelf: "stretch",  marginTop:'10px' }}
                            >
                              <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'validation.of.equal.calleds.at.creation.tooltip' })  }>
                                <HelpIcon />
                              </Tooltip>
                            </Grid>

                          
                           
                         
                          <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset" variant="standard">
                              <FormLabel component="legend">
                                Outras configurações
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch 
                                      checked={config.canexternaluserclientcreatecalled}
                                      onChange={this.handleChangeSwitch}
                                      name="canexternaluserclientcreatecalled" />
                                  }
                                  label={"Mostrar botão flutuante 'Ajuda' para usuários perfil cliente (internos e externos)?"} />
                                <FormControlLabel
                                  control={
                                    <Switch 
                                      checked={config.canclientshowhelpfloatingbutton}
                                      onChange={this.handleChangeSwitch}
                                      name="canclientshowhelpfloatingbutton" />
                                  }
                                  label={"Mostrar botão flutuante 'Criar chamado', e botão de criação de chamado na tela de listagem de chamados para usuários externos, perfil cliente?"} />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ marginTop: 50, display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                              <Grid item xs={12} sm={4}    >
                                <Button 
                                  type='submit'
                                  fullWidth
                                  variant='contained'
                                  color='primary'
                                  className={classes.submit}
                                >
                                  {<FormattedMessage id="save" />}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </RTabPanel>
                    <RTabPanel style={{ padding: 10 }}>
                      <EmailconfigTab 
                        classes={classes}
                        config={emailconfig}
                        OrgsList={this.state.OrgsList}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage}
                        lang={this.props.match.params.lang} 
                        history={this.props.history}
                      />
                    </RTabPanel>
                    <RTabPanel style={{ padding: 10 }}>
                      <WhitelabelconfigTab
                        userId={ userSession && userSession.user && userSession.user.id ? userSession.user.id : 0 }
                        hirerId={ configs && configs.config && configs.config.hirerid ? configs.config.hirerid : 0 }
                        classes={classes}
                        //whitelabelconfigs={{ whitelabelconfigs: whitelabelconfigs && whitelabelconfigs.whitelabelconfig ? whitelabelconfigs.whitelabelconfig : {} }}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage}
                      />
                    </RTabPanel>
                    <RTabPanel style={{ padding: 10 }}>
                      <Emailcustomsettings
                        hirerId={ configs && configs.config && configs.config.hirerid ? configs.config.hirerid : 0 }
                        classes={classes}
                        emailcustomsettings={this.state.emailCustomSettings}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage} 
                        configId={this.state.config.id} 
                        updateEmailCustomSettings={this.updateEmailCustomSettings}
                      />
                    </RTabPanel>
                    <RTabPanel style={{ padding: 10 }}>
                      <ShippingServiceSettings
                        userId={ userSession && userSession.user && userSession.user.id ? userSession.user.id : 0 }
                        hirerId={ configs && configs.config && configs.config.hirerid ? configs.config.hirerid : 0 }
                        classes={classes}
                        satisfactionSurveySettingsDetailList={this.state.satisfactionSurveySettingsDetailList}
                        //shippingservicesettings={{ shippingservicesettings: shippingservicesettings && shippingservicesettings.shippingservicesettings ? shippingservicesettings.shippingservicesettings : {} }}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage}
                        configId={this.state.config.id} 
                        satisfactionsurveysettingsid={this.state.config.satisfactionsurveysettingsid}
                        updateList={this.updateList} />
                    </RTabPanel>

                    <RTabPanel style={{ padding: 10 }}>
                      <ConfigPasswordPolicy
                        userId={ userSession && userSession.user && userSession.user.id ? userSession.user.id : 0 }
                        hirerId={ configs && configs.config && configs.config.hirerid ? configs.config.hirerid : 0 }
                        classes={classes}
                        configpasswordpolicy={{ configpasswordpolicy: configs.config.configpasswordpolicy }}
                        intl={intl}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage} 
                      />
                    </RTabPanel>
                    <RTabPanel style={{ padding: 10 }}>
                      <Configtwofactorauthentication
                        intl={intl}
                        id = {configs.config.id}
                        setLoading={this.setLoading}
                        setMessage={this.setMessage} 
                      />
                    </RTabPanel>

                  </RTabs>
                </Grid>
              </Grid>
              {this.state.openDeleteProviderUserDialog && (
                    <DialogConfirmationDeleteProviderUser
                        msgButton={intl.formatMessage({ id: "confirm" })}
                        msg={intl.formatMessage({ id: "delete.provider.usermail" })}
                        msgDisagree={intl.formatMessage({ id: "cancel" })}
                        msgAgree={intl.formatMessage({ id: "yes" })}
                        handleConfirmation={_ => this.handleSubmit()}
                        handleClose={_ => this.setState({ openDeleteProviderUserDialog: false })}
                        classes={classes}
                        disabled={false}
                        icon={<Delete />}
                    />
                )}
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
ConfigEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                  actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    configs: state.configs,
                    shippingservicesettings: state.shippingservicesettings,
                    whitelabelconfigs: state.whitelabelconfigs
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(Object.assign({}, componentActions, userSessionActions), dispatch, );

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(ConfigEdit)));
