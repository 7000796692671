import { Button, CircularProgress, Container, CssBaseline, Grid } from "@mui/material";
import Rating from '@mui/material/Rating';
import { withStyles } from "@mui/styles";
import clsx from 'clsx';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars";
import { Overlay } from "../../styles/global";
import { Styles } from "../../styles/material-styles";
//import MyMaterialTable from '../../components/table/index-report';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import fileDownload from 'js-file-download';
import 'jspdf-autotable';
import moment from "moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import Footer from '../../components/footer';
import MyMaterialTable from '../../components/table';
import { AbilityContext, Can } from '../../config/ability-context';
import LogoMarca from '../../images/new-logo-cmtech.png';
import * as calledsActions from '../../redux/actions/calleds';
import Api from "../../services/api";
import * as CalledService from '../../services/called.service';

import CallMade from '@mui/icons-material/CallMade';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import RatingElement from "../../components/rating-element";
import ApiReports from "../../services/apireport";
import { PapiroConsole } from "../../utils/papiroConsole";
import * as Service from '../../services/techinicalteamorganizationcategory.service';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

class CalledClassificationReports extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.Reload();

        const { userSession } = this.props;
        const intl = this.props.intl;

        this.isOnlyTechinical = false
        this.isOnlyClient = true

        if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        var alreadyViewAnotherRole = false;
        userSession.user.userrole.map((item, key) => {

            if (item.role.roletype != 3) {
                this.isOnlyClient = false;
            }

            if (item.role.roletype == 2) {
                if (alreadyViewAnotherRole == false) {
                    this.isOnlyTechinical = true;
                }

            }
            else {
                if (item.role.roletype != 3) {
                    alreadyViewAnotherRole = true
                    this.isOnlyTechinical = false;
                }

            }
        });
        this.organizationId = null;

        PapiroConsole.log(this.isOnlyTechinical)
        PapiroConsole.log("this.isOnlyTechinical")
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }

        this.state = {
            dateStart: moment(new Date().setDate(1)).format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            TechinicalTeamFilter: { id: 0, name: '' },
            location: 0,
            loading: false,
            openNotification: false,
            columns: [],
            notificationVariant: "error",
            notificationMessage: "",
            organizationAddressesList: [],
            classificationType: 0,
            classificationTypesList: [
                { id: 2, name: 'NPS (0 à 10)' },
                { id: 3, name: 'NPS (1 á 10 números)' },
                { id: 4, name: 'NPS (1 à 10 estrelas)' },
                { id: 5, name: 'CSAT (1 à 5 números)' },
                { id: 6, name: 'CSAT (1 à 5 estrelas)' }
            ],
            currentModule: 1,
            classificationModule: 1,
            classificationModuleList: [
                { id: 1, name: 'Chamados' },
                { id: 2, name: 'Conversas' }
            ],
            showGrid: false,
            calledsList: [],
            organizationList: [],
            TechinicalTeamList: [],
            userList: [],
            user: { id: 0, name: '' },
            classificationList: [
                { id: 5, name: '5' },
                { id: 4, name: '4' },
                { id: 3, name: '3' },
                { id: 2, name: '2' },
                { id: 1, name: '1' },
            ],
            classification: "",
            showHeader: false,
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            originOfSurveyResponseList : [],
            originOfSurveyResponse : { id: 0, name: '' }
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getExcel = this.getExcel.bind(this)
        this.getUserOptions = this.getUserOptions.bind(this);
        this.renderSatisfactionIcons = this.renderSatisfactionIcons.bind(this);
    }

    setPagination = (count, page) => {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    async changeValues(stateName, value, text = '') {

        PapiroConsole.log("print variaveis aqui")
        PapiroConsole.log(stateName)
        PapiroConsole.log(value)
        this.setState({ loading: true });
        if (stateName === 'location') {
            this.setState({ [stateName]: value.id });
        }
        else if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id });
            if (value.id !== 0) {
                await this.getOrganizationAddresses(value.id);
                await this.getUserOptions(value.id);
                if (!this.state.userList.find(t => t.id == this.state.user.id)) {
                    this.setState({ user: { id: 0, name: '' } })
                }
                await this.getTechinicalTeams(value.id);
                if (!this.state.TechinicalTeamList.find(t => t.id == this.state.TechinicalTeamFilter.id)) {
                    this.setState({ TechinicalTeamFilter: { id: 0, name: '' } })
                }
            }
            else {
                this.setState({ 'organizationAddressesList': [], 'userList': [], user: { id: 0, name: '' }, userList: [] });

            }

        } else if (stateName === 'classification') {
            this.setState({ [stateName]: value.id })
        } else if (stateName === 'classificationType') {
            let classificationList = []
            let i, j
            if (value.id == 2) {
                i = 0
                j = 10
            } else if (value.id <= 4) {
                i = 1
                j = 10
            } else {
                i = 1
                j = 5
            }
            do {
                classificationList.push({ id: i, name: (i).toString() })
                i++
            } while (i <= j)
            classificationList.reverse()
            this.setState({
                [stateName]: value.id,
                classificationList: classificationList
            })
        } else if (stateName === 'classificationModule') {
            this.setState({ [stateName]: value.id });
        } else {
            this.setState({ [stateName]: value });
        }
        this.setState({ loading: false });
    };

    async exportPdf() {
        this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({ id: "routine.in.maintenance" })
        })
    }

    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({ id: "yes" })
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({ id: "no" })
        }
        else {
            writing = this.props.intl.formatMessage({ id: "undefined" })
        }
        return writing
    }

    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1) {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({ id: "called.accompaniment.notification.organization.no.registered.address" })
                    });
                }
            }

            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async getUserOptions(organizationId, techinicalTeamId = 0) {
        const intl = this.props.intl;
        this.setState({ loading: true });

        var result = {}
        if (techinicalTeamId == 0) {
            result = await CalledService.getTechnicalUsersByOrganizationId(organizationId);
        } else {
            result = await CalledService.GetUsersByOrgAndTeam(organizationId, techinicalTeamId)
        }

        if (result.success) {
            if (result.data.length < 1) {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error", userList: result.data,
                    notificationMessage: intl.formatMessage({ id: "called.classification.report.no.technicians" })
                });
                return
            }
            this.setState({ loading: false, userList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };


    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);
        }
        return null;
    }

    async getOrganizations() {
        let data = [];
        const { userSession } = this.props
        if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                });
        }
        this.setState({ organizationList: data })
    };

    async componentDidMount() {
        this.setState({ loading: true })
        await this.getOrganizations();
        await this.getTechinicalTeams();
        await this.getAllOriginOfSurveyResponse();
        this.setState({ loading: false })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async getExcel() {

        this.setState({ loading: true })
        const intl = this.props.intl;
        var columns = [];

        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
        if (dateStart > dateEnd) {

            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
                loading: false
            });
        }

        else {
            const request = {
                dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'), // string
                dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'), // string
                organization: this.state.OrganizationFilter, // int
                techteam: this.state.TechinicalTeamFilter.id, // int
                location: this.state.location, // int
                user: this.state.user.id, // int
                classification: this.state.classification, // string
                classificationType: this.state.classificationType,
                page: this.state.page,
                originofsurveyresponse : this.state.originOfSurveyResponse != null && this.state.originOfSurveyResponse.id != 0 ? this.state.originOfSurveyResponse.id : 0
            }
            let filterParams = '';

            Object.keys(request).forEach(key => {
                filterParams += `&${key}=${request[key]}`
            });

            let url;

            if (this.state.currentModule === 2) {
                url = `/report/chatclassification/excel?`
            } else {
                url = `/report/calledclassification/excel?`
            }
            
            ApiReports.get(`${url}${filterParams}`, {
                responseType: 'blob'
            })
                .then((response) => {

                    let filename = "";

                    if (this.state.currentModule === 2) {
                        filename = `chats_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`;
                    } else {
                        filename = `called_organization_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`;
                    }

                    fileDownload(response.data, filename);

                    this.setState({ loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                })
        }
    }

    returnOriginOfSurveyResponse(value) {

        if(this.state.originOfSurveyResponseList && this.state.originOfSurveyResponseList.length > 0){
    
            var response = this.state.originOfSurveyResponseList.find(c=> c.id == value)

            if(response != null ){
                return response.name;
            }
            else{
                return '';
            }
    
            
        }
        else{
          switch (value) {
            case 1:
                return "Portal";
            case 2:
                return "Criação de chamado";
            case 3:
                return "Notificação";
            case 4:
                return "Email";
            case 5:
                return "WhatsApp";
            default:
                return "";
          }
        }
          
    
      }

    handleSubmit = async e => {
        const intl = this.props.intl;
        e.preventDefault();

        this.setState({ loading: true });

        var columns = [];
        var formattedList = [];

        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD');
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD');

        if (dateStart > dateEnd) {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
                loading: false
            });
        } else {

            if (this.state.classificationModule == 2) { // chats

                const filter = {
                    dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'), 
                    dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'), 
                    organization: this.state.OrganizationFilter, 
                    user: this.state.user.id, 
                    classification: this.state.classification,
                    page: this.state.page
                }
    
                const response = await CalledService.getChatsClassificationByFiltering(filter);
                
                if (response.success) {

                    columns = [
                        { 
                            title: intl.formatMessage({ id: "id" }), 
                            field: 'id' 
                        }, 
                        { 
                            title: intl.formatMessage({ id: "chat" }), 
                            field: 'chatid' 
                        },
                        { 
                            title: "Data da classificação", 
                            field: 'createdat' 
                        },
                        { 
                            title: intl.formatMessage({ id: "organization" }), 
                            field: 'organizationname' 
                        },
                        { 
                            title: "Atendente", 
                            field: 'assignedusername' 
                        },
                        { 
                            title: "CSAT Cliente para o Técnico", 
                            field: 'noteAssigned' 
                        },
                        { 
                            title: "Cliente", 
                            field: 'requestusername' 
                        },
                        { 
                            title: "Observação do cliente para o técnico", 
                            field: 'observationassigned' 
                        },
                        { 
                            title: "Provedor", 
                            field: 'messageprovidername' 
                        },
                    ];
    
                    let count = response.count;
                    this.setState({ count: count });
                    this.setPagination(count, this.state.page);
    
                    formattedList = response.data.map(item => {
                        return {
                            ...item,
                            createdat: item?.createdat ? moment(item?.createdat).format("DD/MM/YYYY HH:mm") : "",
                            organizationname: item.organizationname ? item.organizationname : "",
                            observationassigned: item.observationassigned ?  <span dangerouslySetInnerHTML={{ __html: item.observationassigned }} /> : "N/A",
                            noteAssigned: item.noteassigned ? <Rating name="read-only" value={item.noteassigned} readOnly /> : 'N/A',
                        }
                    });

                    this.setState({
                        calledsList: formattedList,
                        columns: columns,
                        loading: false,
                        showGrid: true,
                        currentModule: 2
                    })

                } else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: response && response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    })
                }

            } else { // calleds

                const request = {
                    dateStart: moment(this.state.dateStart).format('YYYY-MM-DD'), // string
                    dateEnd: moment(this.state.dateEnd).format('YYYY-MM-DD'), // string
                    organization: this.state.OrganizationFilter, // int
                    techteam: this.state.TechinicalTeamFilter.id,
                    location: this.state.location, // int
                    user: this.state.user.id, // int
                    classification: this.state.classification, // string
                    classificationType: this.state.classificationType,
                    classificationModule: this.state.classificationModule,
                    page: this.state.page,
                    originofsurveyresponse : this.state.originOfSurveyResponse != null && this.state.originOfSurveyResponse.id != 0 ? this.state.originOfSurveyResponse.id : 0
                }
    
                const response = await CalledService.getCalledsByFiltering(request);
                PapiroConsole.log("response aqui no relatorio")
                PapiroConsole.log(response)

                if (response.success) {
                    columns = [
                        { title: intl.formatMessage({ id: "id" }), field: 'id' }, { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                        { title: intl.formatMessage({ id: "title" }), field: 'title' }, { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
                        { title: intl.formatMessage({ id: "menu.organizationpatrimonies" }), field: 'patrimonies' }, { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' },
                        { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' }, { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                        { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' }, { title: intl.formatMessage({ id: "location" }), field: 'addressname' },
                        { title: intl.formatMessage({ id: "open.date" }), field: 'opening' }, { title: intl.formatMessage({ id: "solution.date" }), field: 'closing' }, 
                        { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' }, { title: intl.formatMessage({ id: "score.assigned.to.the.tech" }), field: 'classificationToTech' }, 
                        { title: intl.formatMessage({ id: "score.assigned.to.the.client" }), field: 'classificationToClient' }, { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' },
                        { title: intl.formatMessage({ id: "comment" }), field: 'observationAssigned' },
                        { title: intl.formatMessage({ id: "origin.of.survey.response" }), field: 'originOfSurveyResponse' }
                    ];
    
                    let count = response.count;
                    this.setState({ count: count });
                    this.setPagination(count, this.state.page);
    
                    formattedList = response.data.map(item => {
                        let patrimonies = '';
                        let times = "";
    
                        let writing = ""
    
                        writing = this.getMessageBadUseFlag(item.baduse)
    
                        const rating = <RatingElement
                            type={item.satisfaction_survey_questions_type_id}
                            value={item.note_assigned}
                        />
    
                        return {
                            ...item,
                            baduse: writing,
                            statusName: item.status,
                            patrimonies: item.patrimonies,
                            OrganizationCategory: item.category,
                            Organization: item.organization,
                            slaAtendimento: item.sla_service + "%",
                            slaSolucao: item.sla_solution + "%",
                            assignedUser: item.responsible,
                            requestingUser: item.requesting_user_name,
                            time: times,
                            addressname: item.address,
                            opening: item.opening_date,
                            closing: item.closing_date,
                            classificationToTech: rating,
                            originOfSurveyResponse : this.returnOriginOfSurveyResponse(item.origin_of_survey_response),
                            classificationToClient: item.note_request ? <Rating name="read-only" value={item.note_request} readOnly /> : 'N/A',
                            classificationPdf: item.note_assigned ? item.note_assigned : 'N/A',
                            observationAssigned: item.observation_assigned ? item.observation_assigned : 'N/A'
                        }
                    });

                    this.setState({
                        calledsList: formattedList,
                        columns: columns,
                        loading: false,
                        showGrid: true,
                        currentModule: 1
                    })

                } else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: response && response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    })
                }
            }

        }
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    renderSatisfactionIcons(option) {
        const intl = this.props.intl;
        switch (option) {
            case intl.formatMessage({ id: "dashboard.report.components.pizza.detractor" }):
                return <SentimentVeryDissatisfiedIcon style={{ color: '#E74C3C', textAlign: 'center' }} />
            case intl.formatMessage({ id: "dashboard.report.components.pizza.neutral" }):
                return <SentimentSatisfiedAltIcon style={{ color: '#00C49F', textAlign: 'center' }} />
            case intl.formatMessage({ id: "dashboard.report.components.pizza.promoter" }):
                return <SentimentSatisfiedIcon style={{ color: '#FFBB28', textAlign: 'center' }} />
        }
    }

    onChangePaginate = async (e, page) => {
        await this.setState({ page: page });
        this.handleSubmit(e);
    }

    getById = async (id, newTab = false) => {
        let url = `/calleds/calledInfoPaginate/${id}`;
        let data = {}

        await this.setState({ loading: true });

        Api.get(url)
            .then(async result => {
                if (result.data.success) {
                    let item = result.data.data
                    let times = "";
                
                    data = {
                        ...item,
                        typeName: item.type.name,
                        originRequestName: item.originrequest.name,
                        requestingUser: item.requestinguser ? item.requestinguser.name : "",
                        time: times,
                        opening: moment(item.openingdate).format("DD/MM/YYYY"),
                    }

                    await this.props.addCurrentCalled(data);
                    await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null);

                    await this.props.addCalled(data);
                    await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id);

                    let urlRedirect = "/" + this.props.match.params.lang + `/called-accompaniment/${data.id}`;

                    await setTimeout(() => {
                        window.open(urlRedirect, "_blank");
                        this.setState({ loading: false })
                    }, 3000);
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });

                    Api.kickoff(result);
                }
            })
            .catch((err) => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response &&
                            err.response.data &&
                            err.response.data.errors &&
                            err.response.data.errors[0]
                            ? err.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(err);
            });
    };

    async getTechinicalTeams(OrganizationId = 0) {
        var result = null
        if (OrganizationId > 0) {
          result = await Service.getTechinicalTeamsByOrganization(OrganizationId);
        } else {
          result = await Service.getTechinicalTeams()
        }
        if (result.success) {
          this.setState({ TechinicalTeamList: result.data });
    
        } else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      }

      async getAllOriginOfSurveyResponse(){

        let url = `/calledclassifications/survey-response-origins`;
        await Api.get(url)
          .then((result) => {
            if (result.data.success) {
    
              this.setState({
                originOfSurveyResponseList : result.data.data
              })
    
    
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  result.data &&
                    result.data.response &&
                    result.data.response.data &&
                    result.data.response.data.errors &&
                    result.data.response.data.errors[0]
                    ? result.data.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
              Api.kickoff(result);
            }
          })
          .catch((err) => {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response &&
                  err.response.data &&
                  err.response.data.errors &&
                  err.response.data.errors[0]
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(err);
          });
      }

    render() {
        const { classes, userSession, headerMenu, actionsHeaderMenu } = this.props;

        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const tableList = () => {
            return (
                <div>
                    {this.state.showHeader &&
                        <Grid container style={{ zIndex: -2000, position: "absolute" }}>
                            <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }}>
                                <Card>
                                    <CardContent>
                                        <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="calledClassification.report.header" />}</span>
                                        <Divider />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    }
                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 10 }} spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6} md={4} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "user" })} options={this.state.userList} selected={this.state.user} stateName='user' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "classification.type" })} options={this.state.classificationTypesList} stateName='classificationType' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleAutoComplete
                                            label={intl.formatMessage({ id: "classification" })}
                                            options={this.state.classificationList}
                                            stateName='classification'
                                            changeSelect={this.changeValues}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleAutoComplete
                                            label="Módulo de classificação"
                                            options={this.state.classificationModuleList}
                                            defaultValueProp={this.state.classificationModuleList[0]}
                                            stateName='classificationModule'
                                            changeSelect={this.changeValues}
                                        />
                                    </Grid>

                                    <Grid item xs={11}  sm={5} md={3.5}>
                                        <SimpleAutoComplete
                                             label={intl.formatMessage({ id: "origin.of.survey.response" })}
                                            options={this.state.originOfSurveyResponseList}
                                            stateName='originOfSurveyResponse'
                                            changeSelect={this.changeValues}
                                        />
                                    </Grid>
                                    <Grid item xs={1}  sm={1} md={0.5} marginTop={2}>
                                        <Tooltip title={intl.formatMessage({ id: "origin.of.survey.response.filter.tooltip" })}>
                                            <HelpIcon />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>
                            {this.state.showGrid && this.state.calledsList.length > 0 && <Grid item xs={12} sm={5} md={2} style={{ marginTop: 10, marginRight: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={this.getExcel}
                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>
                            }
                            {5 == 2 && this.state.showGrid && this.state.calledsList.length > 0 && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.exportPdf}
                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>
                            }
                        </Grid>
                    </form>
                    {
                        this.state.showGrid &&
                        <>
                            {
                                this.state.currentModule === 2 // chat
                                ? (
                                    <MyMaterialTable
                                        title=""
                                        columns={this.state.columns}
                                        data={this.state.calledsList}
                                    />
                                )
                                : (
                                    <MyMaterialTable
                                        title=""
                                        columns={this.state.columns}
                                        data={this.state.calledsList}
                                        action={[
                                            {
                                                icon: () => <CallMade />,
                                                tooltip: intl.formatMessage({ id: "new.tab" }),
                                                onClick: (event, rowData) => this.getById(rowData.id, true),
                                            },
                                        ]}
                                    />
                                )
                            }
                        
                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>
                        </>
                    }
                </div>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={0}>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>
                                <Card >
                                    <CardContent>
                                        <Typography variant="h5">
                                            {<FormattedMessage id="calledClassification.report.header" />}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

CalledClassificationReports.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledClassificationReports)));
CalledClassificationReports.contextType = AbilityContext;