import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";

import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as componentActions from "../../redux/actions/calledclassifications";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import React, { Component } from "react";
import "./modalRating.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from '@mui/icons-material/Close';
import Rating from "@mui/material/Rating";
import Api from "../../services/api";
import { Overlay } from "../../styles/global";
import SnackbarsModal from "../snackbarsModal";
import * as CalledService from "../../services/called.service";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ReactHtmlParser from 'react-html-parser';
import { getSlaAtendimento, getSlaSolucao } from '../../pages/called-list/called.functions';
import * as calledsActions from '../../redux/actions/calleds';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import { PapiroConsole } from "../../utils/papiroConsole";
import * as SatisfactionSurveyService from '../../services/satisfactionsurvey.service';
import * as OrganizationSettingsService from '../../services/organizationsetting.service'
import MEUnlikeIcon from "../me-feedback-images/unlike-icon";
import ThumbsUpOrDown from "../../pages/feedback/components/thumbs-up-or-down";
import RatingScale from "../../pages/feedback/components/rating-scale";
import StarScale from "../../pages/feedback/components/star-scale";
import MELikeIcon from "../me-feedback-images/like-icon";
import IconButton from '@mui/material/IconButton';

class ModalRating extends Component {
  constructor(props) {
    super(props);
    PapiroConsole.log(props)
    PapiroConsole.log("props ModalRating")
    PapiroConsole.log(props.originOfSurveyResponse)
    this.listItems = null

    this.childRefs = [];

    this.state = {
      loadingCompleted: false,
      isDisabled : false,
      starRating: null,
      Observations: "",
      calledclassification: this.initialState,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      CalledList: [],
      AssignedUserList: [],
      observationCalled: undefined,
      lastAccompaniment: null,
      selectedCalled: {
        id: 0,
        Rating: 0,
        Observations: ""
      },
      technicalName: null,
      requestingUserName : null,
      lastAccompaniments: null,
      title: null,
      idCalled: null,
      selectedIndex: 0,
      createdUser: this.props.createdUser,
      page: 1,
      adm: this.props.adm,
      hirerId: this.props.userSession.user.hirerid,
      satisfactionSurveyId: 0,
      satisfactionSurveyQuestions: [],
      ratingVisible: true,
      disabledCallRejectionByRequester: false,
      originOfSurveyResponse : props.originOfSurveyResponse,
      colorsZeroToTen: [
        '#EC475C',
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
        '#0eae9c'

      ],
      colorsOneToTen: [
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
        '#0eae9c'

      ],
      colorsOneToFive: [
        '#EC475C',
        '#FB8A31',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
      ],
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.getById = this.getById.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.getTechnicalUsers = this.getTechnicalUsers.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.FirstLoading = this.FirstLoading.bind(this)
    this.selectCalled = this.selectCalled.bind(this)
    this.getAccompaniment = this.getAccompaniment.bind(this)
    this.showMore = this.showMore.bind(this)
    this.changeValuesSurveyQuestions = this.changeValuesSurveyQuestions.bind(this)
    this.getSatisfactionSurveyByHirerIdAndCalledId = this.getSatisfactionSurveyByHirerIdAndCalledId.bind(this)
    this.GetDisabledCallRejectionByRequesterByOrganization = this.GetDisabledCallRejectionByRequesterByOrganization.bind(this)
  }

  async componentDidMount() {

    try {
      this.setState({ loadingCompleted: false })
      this.props.setLoading(true)

      await this.FirstLoading(this.props.data)
      await this.GetDisabledCallRejectionByRequesterByOrganization()
      this.setState({ loadingCompleted: true })
      this.props.setLoading(false)


    } catch (error) {

      this.props.setLoading(false)
      this.setState({ loadingCompleted: true })

    }

  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async getSatisfactionSurveyByHirerIdAndCalledId(calledId) {
    const hirerId = this.state.hirerId

    PapiroConsole.log("calledId")
    PapiroConsole.log(calledId)

    if (hirerId && calledId) {

      // this.setState({ loading: true });
      var result = await SatisfactionSurveyService.getByHirerIdAndCalledId(hirerId, calledId);
      
      if (result.success) {
        
        PapiroConsole.log("result.data.satisfactionsurveyquestions")
        PapiroConsole.log(result.data.satisfactionsurveyquestions)
        let satisfactionsurveyquestions = result.data.satisfactionsurveyquestions

        if (satisfactionsurveyquestions && satisfactionsurveyquestions.length > 0) {
          //ordenação por id 
          satisfactionsurveyquestions = satisfactionsurveyquestions.sort((a, b) => a.id - b.id)

          const satisfactionSurveyQuestionsList = satisfactionsurveyquestions.map((item) => ({
            id: item.id,
            name: item.name,
            indcsatisfiedserviceindex: item.indcsatisfiedserviceindex,
            satisfactionsurveyquestionstypeid: item.satisfactionsurveyquestionstypeid,
            response: item.indcsatisfiedserviceindex && this.state.starRating ? this.state.starRating : null

          }));

          this.setState({ satisfactionSurveyId: result.data.id, satisfactionSurveyQuestions: satisfactionSurveyQuestionsList });
          PapiroConsole.log("entrei no loading 4 ")
          
         
        }
        //não encontrou pesquisa de satisfação
        else{
          this.setState({satisfactionSurveyQuestions: []})
          

        }
        


      } else {
        PapiroConsole.log("entrei no loading 5 ")
        const intl = this.props.intl;
        this.setState({
          openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
       
        Api.kickoff(result);
      }
    }
  }

  changeValuesSurveyQuestions(index, value, indcsatisfiedserviceindex) {

    PapiroConsole.log("index")
    PapiroConsole.log(index)
    const values = [...this.state.satisfactionSurveyQuestions];
    values[index]['response'] = value;
    this.setState(prevState => ({
      satisfactionSurveyQuestions: values,
      starRating: indcsatisfiedserviceindex ? value : prevState.starRating,
    }));
    PapiroConsole.log("selectCalled")
    PapiroConsole.log(this.state.selectedCalled)
  }

  validate() {

    var check = true;

    if ((typeof this.state.starRating === 'undefined' || this.state.starRating == null) && this.state.ratingVisible) {
      this.props.setLoading(false)
      this.props.setMessage(true, "error", "Nota inválida")
      check = false;
    }

    this.state.satisfactionSurveyQuestions.forEach(element => {
      if (typeof element.response === 'undefined' || element.response == null) {
        this.props.setLoading(false)
        this.props.setMessage(true, "error", "É necessário responder todas as questões da pesquisa de satisfação  ")
        check = false;
      }
    });




    return check;

  }

  handleClick = async () => {
    const intl = this.props.intl;
    PapiroConsole.log("=== this.state.selectedCalled.Rating ===")
    PapiroConsole.log(this.state.selectedCalled.Rating)

    PapiroConsole.log("this.state.starRating")
    PapiroConsole.log(this.state.starRating)


    PapiroConsole.log("this.state.satisfactionSurveyQuestions")
    PapiroConsole.log(this.state.satisfactionSurveyQuestions)

    var validateAnswers = this.validate()
    if (!validateAnswers) {
      return false
    }


    this.props.setLoading(true)

    var feedbackBoValue

    if (this.state.ratingVisible == true) {
      feedbackBoValue = "1"
    }
    else {
      feedbackBoValue = "2"
    }
    const description = this.state.selectedCalled.Observations;

    if (feedbackBoValue == '2' && description == "") {
      this.props.setLoading(false)
      this.props.setMessage(true, "error", intl.formatMessage({ id: "feedback.assigned.called.reopen.justification" }))
    } else {
      let data = {
        ObservationAssigned: description,
        CalledId: this.state.selectedCalled.id,
        NoteAssigned: this.state.starRating,
        CreatedUser: this.state.createdUser,
        RequestingUser: this.state.selectedCalled.requestinguser.id,
        AssignedUser: this.state.selectedCalled.assigneduser.id,
        Adm: this.state.adm,
        Page: this.state.page,
        SatisfactionSurveyQuestions: this.state.satisfactionSurveyQuestions,
        SatisfactionSurveyId: this.state.satisfactionSurveyId,
        ApplicationOriginId: 2,
        FeedbackBo: feedbackBoValue,
        OriginOfSurveyResponse : this.state.originOfSurveyResponse
      };
      await Api.post("/calledclassifications/withsurvey", data)
        .then((result) => {
          if (result.data.success) {
            let newArray = this.props.data
            newArray.forEach((element, index) => {
              if (this.state.selectedCalled.id === element.id)
                newArray.splice(index, 1)
            });

            if (newArray.length === 0) {
              if (this.props.showMore) {
                this.setState({ page: 1 })
                this.props.getFinishedCalleds(1)
              } else {
                this.props.onClose()
              }
            }
            else
              this.props.updateList(newArray)

            this.props.setLoading(false)
            this.props.setMessage(true, "success", this.props.intl.formatMessage({ id: "modalRating.called.rating.success" }))
            this.props.updateList(newArray)

            PapiroConsole.log("=== newArray ===");
            PapiroConsole.log(newArray);
            this.FirstLoading(newArray)
          } else {
            const error = result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            this.props.setLoading(false)
            this.props.setMessage(true, "error", this.props.intl.formatMessage({ id: "process.error" }))
          }

        })
        .catch((err) => {
          const error = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          const intl = this.props.intl;
          this.props.setLoading(false)
          this.props.setMessage(true, "error", error)
        });
    }
  };

  async getTechnicalUsers(organizationId) {
    this.props.setLoading(true)

    const result = await CalledService.getTechnicalUsers(organizationId);

    if (result.success) {
      this.props.setLoading(false)
      this.setState({ technicalUsersList: result.data });
    } else {
      const intl = this.props.intl;
      this.props.setLoading(false)
      this.props.setMessage(true, "error", result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  handleInputChange(e) {
    const value = e.target.value;
    this.setState({ observationCalled: value });
  }

  changeValues(event, newValue, name) {
    var selectedCalledCopy = null
    var unratedCalledsCopy = null
    const index = this.props.data.findIndex((i) => {
      return i.id === this.state.selectedCalled.id;
    })
    if (event.target.name === "rating") {
      selectedCalledCopy = this.state.selectedCalled
      selectedCalledCopy.Rating = newValue
      unratedCalledsCopy = this.props.data
      unratedCalledsCopy[index].Rating = newValue

      this.setState(
        {
          selectedCalled: selectedCalledCopy,
          starRating: newValue
        }
      )
      this.props.updateList(unratedCalledsCopy)
    }
    if (event.target.name === "observation") {
      selectedCalledCopy = this.state.selectedCalled
      selectedCalledCopy.Observations = event.target.value
      unratedCalledsCopy = this.props.data
      unratedCalledsCopy[index].Observations = event.target.value

      this.setState(
        {
          selectedCalled: selectedCalledCopy,
          Observations: event.target.value

        }
      )
      this.props.updateList(unratedCalledsCopy)
    }

  }

  loading(choice) {
    this.props.setLoading(choice)
  }

  async getById(id) {

    let data = {}
    this.props.setLoading(true)

    await Api.get(`/calleds/calledInfoPaginate/${id}`)
      .then(async result => {
        if (result.data.success) {

          let item = result.data.data

          let times = "";
          if (item.organizationcategory.techinicalteamorganizationcategories != null) {
            item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
              times = times + item2.techinicalteam.name + ","
            });
            times = times.substring(0, times.length - 1);
          }
          data = {
            ...item,
            statusName: item.status.name,
            OrganizationCategory: item.organizationcategory.parentname,
            Organization: item.organization.name,
            slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
            slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
            assignedUser: item.assigneduser ? item.assigneduser.name : "",
            requestingUser: item.requestinguser ? item.requestinguser.name : "",
            time: times,
            addressname: item.address.name,
            opening: moment(item.openingdate).format("DD/MM/YYYY"),
            classification: item.calledclassification
          }

          await this.props.addCurrentCalled(data);
          await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null);

          await this.props.addCalled(data)
          await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id)

          const urlRedirect = `/pt/called-accompaniment/${data.id}`;

          await setTimeout(() => {
            window.open(urlRedirect, "_blank");
            this.props.setLoading(false)
          }, 3000);
        } else {
          this.props.setLoading(false)
          this.props.setMessage(true, "error", result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "modalRating.send.rating" }))
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.props.setLoading(false)
        this.props.setMessage(true, "error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }))
        Api.kickoff(err);

      });
  };

  GetDisabledCallRejectionByRequesterByOrganization = async () => {
    await OrganizationSettingsService.GetDisabledCallRejectionByRequesterByOrganization(this.props.organizationId)
      .then(result => {
        if (result.success) {

          
          let disabledCallRejectionByRequester = result.data === "true" || result.data === "True"  ? true : false;
          this.setState({ disabledCallRejectionByRequester: disabledCallRejectionByRequester })
        } else {
          //this.props.setLoading(false)
          this.props.setMessage(true, "error", result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "modalRating.send.rating" }))
          Api.kickoff(result);
        }
      })
      .catch(err => {
        //this.props.setLoading(false)
        this.props.setMessage(true, "error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }))
        Api.kickoff(err);

      })
  }

  async FirstLoading(data) {
    PapiroConsole.log("=== FirstLoading ===")
    PapiroConsole.log(this.props.selectedCalled)
    PapiroConsole.log(data)
    if (this.props.selectedCalled && data) {

      const index = data.findIndex(element => element.id === this.props.selectedCalled);
      //elemento encontrado
      if (index !== -1) {
        await this.selectCalled(this.props.selectedCalled, index)
      }
      //elemento não encontrado
      else {
        if (data[0] !== null && data[0] !== undefined) {
          if (data[0] && data[0].id)
            await this.selectCalled(data[0].id, 0)
        }
      }

    }
    else {
      if (data[0] !== null && data[0] !== undefined) {
        if (data[0] && data[0].id)
          await this.selectCalled(data[0].id, 0)
      }
    }

    //console.log("não entrei")
  }

  updateAllChildrenState = (newState) => {
    this.childRefs.forEach(ref => {
      if (ref && ref.updateChildState) {
        ref.updateChildState(newState);
      }
    });
  }

  setChildRef = (ref) => {
    PapiroConsole.log("entrei aqui x vezes")
    PapiroConsole.log(ref)
    PapiroConsole.log(this.childRefs)
    if (ref && !this.childRefs.includes(ref)) {
      this.childRefs.push(ref);
    }
  };


  async selectCalled(id, indexList, setLoadingOnChangeConversation = false) {

    PapiroConsole.log("=== selectCalled ===")
    PapiroConsole.log(id)

    if (id == null) {
      return false;
    }
    PapiroConsole.log("this.state.isDisabled")
    PapiroConsole.log(this.state.isDisabled)
    if (this.state.isDisabled) return;

    if (setLoadingOnChangeConversation) {
      this.props.setLoading(true)
      this.setState({ isDisabled: true });
    }

    PapiroConsole.log("this.state.satisfactionSurveyQuestions")
    PapiroConsole.log(this.state.satisfactionSurveyQuestions)

    const updatedList = this.state.satisfactionSurveyQuestions.map(item => ({
      ...item,
      response: null,
    }));

    PapiroConsole.log(updatedList)
    PapiroConsole.log("updatedList")

    this.updateAllChildrenState('Updated State for All Children')



    this.setState({ satisfactionSurveyQuestions: updatedList });

    var lastAccompaniment = null

    await Api.get(`performance/accompaniments/getLastAccompaniment/${id}`)
      .then(async result => {

        if (result.data.success && result.data.data) {
          lastAccompaniment = result.data.data;
          const index = this.props.data.findIndex((i) => {
            return i.id === id;
          })

          let selectedCalledCopy = this.props.data[index]
          if (selectedCalledCopy.Observations === undefined) {
            selectedCalledCopy.Observations = ""
          }
          

          const technicalName = this.props.data ? (this.props.data[index].assigneduser ? this.props.data[index].assigneduser.name : "") : ""
          const requestingUserName = this.props.data ? (this.props.data[index].requestinguser ? this.props.data[index].requestinguser.name : "") : ""
          const teamName = this.props.data ? (this.props.data[index].techinicalteam ? this.props.data[index].techinicalteam.name : "") : ""

          this.setState({
            selectedCalled: selectedCalledCopy,
            selectedIndex: indexList,
            technicalName: this.props.data ? (this.props.data[index].displayassignedinfomode != 4 ? technicalName : teamName) : "",
            requestingUserName : requestingUserName,
            lastAccompaniments: lastAccompaniment,
            title: this.props.data[index].title,
            idCalled: id,
            starRating: null,
            Observations: ""
          })

          await this.getSatisfactionSurveyByHirerIdAndCalledId(id)

          if (setLoadingOnChangeConversation) {
            this.props.setLoading(false)
            this.setState({ isDisabled: false });
          }
          // this.props.setLoading(false)

        } else {
          const intl = this.props.intl;
          this.setState({
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.action.success" }),
            files: [],
            action: {
              description: "",
              privacy: "false"
            }
          });
          if (setLoadingOnChangeConversation) {
            this.props.setLoading(false)
            this.setState({ isDisabled: false });
          }
          //this.props.setLoading(false)
        }
      }
      )

    //this.props.setLoading(false)

  }

  async getAccompaniment() {
    if (this.state.idCalled == null) {
      return false;
    }
    Api.get(`accompaniments/getAllByCalled/${this.state.idCalled && this.state.idCalled.id && this.state.idCalled.id > 0 ? this.state.idCalled.id : this.state.idCalled}`)
      .then(result => {

        if (result.data.success) {
          //this.lastAccompaniment = result.data.data[0].description
          //console.log(this.lastAccompaniment)
          this.setState({ lastAccompaniment: result.data.data[0].description });

        } else {
          this.props.setLoading(false)
          this.props.setMessage(true, "success", intl.formatMessage({ id: "add.action.success" }))
          const intl = this.props.intl;
          this.setState({
            files: [],
            action: {
              description: "",
              privacy: "false"
            }
          });
        }
      }
      )
  }

  showMore = async () => {
    PapiroConsole.log("SHOW MORE")
    const page = this.state.page + 1
    this.setState({ page: page })
    await this.props.getFinishedCalleds(page)
  }

  render() {

    PapiroConsole.log("this.state.satisfactionSurveyQuestions")
    PapiroConsole.log(this.state.satisfactionSurveyQuestions)
    PapiroConsole.log(this.state.disabledCallRejectionByRequester)
    PapiroConsole.log("this.state.originOfSurveyResponse")
    PapiroConsole.log(this.state.originOfSurveyResponse)
    //PapiroConsole.log(this.state.satisfactionSurveyQuestions)
    //PapiroConsole.log()
    return (
      <div className="modalRating">
        <div className="containerModal" align="center">

          <h3 className="inlineDisplay" align="center">Avalie seus chamados</h3>
          {this.props.requiredratingcalleds === false ? (
            <button className="closeButton" onClick={this.props.onClose}>
              <IconButton size="small">
                <CloseIcon />
              </IconButton>
            </button>)
            : null}

          <Divider />
          <div className="flexDisplay">
            <Divider />
            <div className="listCalleds" >
              {this.props.data.length !== 1 ? (
                <div align="center">
                  <h4 >{this.props.intl.formatMessage({ id: "called.header" })}</h4>
                  <Divider />
                </div>
              ) : null}

              <div style={{ overflowY: 'scroll', height: '95%', maxHeight: '650px' }}>
                {this.props.data.map((element, index) => (
                  <List component="nav" key={index} >
                    <ListItem disabled={this.state.isDisabled} button selected={this.state.selectedIndex === index} onClick={() => this.selectCalled(element.id, index, true)} id={element.id}>
                      <ListItemIcon>
                        <CallEndIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Chamado: ${element.id}`} />
                    </ListItem>
                    <Divider />
                  </List>
                ))}
                {this.props.showMore &&
                  <Button
                    variant="contained"
                    onClick={this.showMore}
                  >
                    Mostrar mais
                  </Button>}
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <Container component="main" maxWidth="xl">
              {this.state.loadingCompleted &&
                <div className="content">

                  <div className="attributes">
                    <div className="title">
                      <h3 className="boldClass">{this.state.title}</h3>
                    </div>
                    <div className="technician">
                      <h4 className="boldClass"><span className="spantitle">{this.props.intl.formatMessage({ id: "modalRating.called.user" })}</span><p>{this.state.technicalName}</p></h4>
                    </div>
                    <div className="technician">
                      <h4 className="boldClass"><span className="spantitle">{this.props.intl.formatMessage({ id: "modalRating.called.requesting.user" })}</span><p>{this.state.requestingUserName}</p></h4>
                    </div>
                    <div>
                      <div className="boldClass"><span className="spantitle">{this.props.intl.formatMessage({ id: "modalRating.last.accompaniment" })}</span> <p style={{ wordBreak: 'break-all', maxHeight: '15vh', overflowY: 'scroll' }}>{ReactHtmlParser(this.state.lastAccompaniments)}</p></div>
                    </div>
                    <Grid container spacing={4} alignItems="flex-start"
                      justify="center" style={{ justifyContent: 'center' }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        container
                        alignItems="flex-start"
                        justify="center"
                        direction="row"
                        style={{ justifyContent: 'center' }}
                      >
                        <Box component="fieldset" mt={3} mb={3} borderColor="transparent">
                          {!this.state.disabledCallRejectionByRequester && !(this.state.satisfactionSurveyQuestions.length && this.state.satisfactionSurveyQuestions.length > 0) &&
                            <>
                              <Grid item xs={12} sm={12} md={6} lg={5} xl={4} >
                                <p style={{ color: '#09316F' }}><strong>01.</strong>Sua solicitação foi resolvida?</p>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={7} xl={8} >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                  <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === true ? '#CCC' : '#EEE', borderRight: '1px solid #CCC', cursor: 'pointer' }}
                                    onClick={() => {
                                      this.setState({ ratingVisible: true })
                                    }}>
                                    <p style={{ color: '#595959', paddingRight: '10%' }}><strong>SIM</strong></p>
                                    <MELikeIcon style={{ width: 30 }} />
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === false ? '#CCC' : '#EEE', borderLeft: '1px solid #CCC', cursor: 'pointer' }}
                                    onClick={() => {
                                      this.setState({ ratingVisible: false })
                                    }}>
                                    <p style={{ color: '#595959', paddingRight: '10%' }}><strong>NÃO</strong></p>
                                    <MEUnlikeIcon style={{ width: 30 }} />
                                  </div>
                                </div>
                              </Grid>
                            </>
                          }
                          {this.state.satisfactionSurveyQuestions.length && this.state.satisfactionSurveyQuestions.length > 0 ?

                            <>
                              {this.state.satisfactionSurveyQuestions.map((item, index) => (
                                <>
                                  {item.satisfactionsurveyquestionstypeid == 1 &&
                                    <ThumbsUpOrDown ref={this.setChildRef} fqa={this.state.firstQuestionValue} newlayout={false} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} index={index} title={item.name} disabledCallRejectionFlag={this.state.disabledCallRejectionByRequester} />
                                  }
                                  {item.satisfactionsurveyquestionstypeid == 2 &&
                                    <RatingScale ref={this.setChildRef} starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={0} end={10} colors={this.state.colorsZeroToTen} title={item.name} index={index} disabledCallRejectionFlag={this.state.disabledCallRejectionByRequester} />
                                  }
                                  {item.satisfactionsurveyquestionstypeid == 3 &&
                                    <RatingScale ref={this.setChildRef} starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={1} end={10} colors={this.state.colorsOneToTen} title={item.name} index={index} disabledCallRejectionFlag={this.state.disabledCallRejectionByRequester} />
                                  }
                                  {item.satisfactionsurveyquestionstypeid == 4 &&
                                    <StarScale ref={this.setChildRef} starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} end={9} title={item.name} index={index} disabledCallRejectionFlag={this.state.disabledCallRejectionByRequester} />
                                  }
                                  {item.satisfactionsurveyquestionstypeid == 5 &&
                                    <RatingScale ref={this.setChildRef} starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={1} end={5} title={item.name} colors={this.state.colorsOneToFive} index={index} disabledCallRejectionFlag={this.state.disabledCallRejectionByRequester} />
                                  }
                                  {item.satisfactionsurveyquestionstypeid == 6 &&
                                    <StarScale ref={this.setChildRef} starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} end={5} title={item.name} index={index} disabledCallRejectionFlag={this.state.disabledCallRejectionByRequester} />
                                  }
                                </>

                              ))}
                            </>
                            :
                            <>
                              {this.state.ratingVisible &&
                                <>
                                  <Grid style={{marginTop:'10px'}} item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <p style={{ color: '#09316F' }}><strong>{this.state.disabledCallRejectionByRequester ? '01.' : '02.'}</strong> Numa escala de <strong>1 a 5</strong>, onde 1 é muito insatisfeito e 5 é muito satisfeito, <strong>como você avalia a sua experiência com a nossa Central de Atendimento?</strong></p>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <div className="vote-gradient">
                                      <div
                                        className={"vote-number" + (this.state.starRating == 1 ? ' vote-number-selected' : '')}
                                        style={{ marginRight: 2, backgroundColor: '#EC475C' }}
                                        onClick={() => {
                                          this.setState({ starRating: 1 })
                                        }} >1</div>
                                      <div
                                        className={"vote-number" + (this.state.starRating == 2 ? ' vote-number-selected' : '')}
                                        style={{ marginRight: 2, background: '#FB8A31' }}
                                        onClick={() => {
                                          this.setState({ starRating: 2 })
                                        }} >2</div>
                                      <div
                                        className={"vote-number" + (this.state.starRating == 3 ? ' vote-number-selected' : '')}
                                        style={{ marginRight: 2, backgroundColor: '#FFE44B' }}
                                        onClick={() => {
                                          this.setState({ starRating: 3 })
                                        }} >3</div>
                                      <div
                                        className={"vote-number" + (this.state.starRating == 4 ? ' vote-number-selected' : '')}
                                        style={{ marginRight: 2, background: 'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)' }}
                                        onClick={() => {
                                          this.setState({ starRating: 4 })
                                        }} >4</div>
                                      <div
                                        className={"vote-number" + (this.state.starRating == 5 ? ' vote-number-selected' : '')}
                                        style={{ marginRight: 2, background: '#0eae9c' }}
                                        onClick={() => {
                                          this.setState({ starRating: 5 })
                                        }} >5</div>
                                    </div>
                                  </Grid>
                                </>
                              }
                            </>
                          }
                        </Box>
                      </Grid>
                    </Grid>
                  </div>


                  <div className="classification">
                    {!this.state.ratingVisible && (
                      <div style={{ marginTop: "15px" }}>
                        <div>
                          <Typography> Explique um pouco sobre o que aconteceu </Typography>
                        </div>
                      </div>
                    )}
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          label={this.props.intl.formatMessage({ id: "observation" })}
                          variant="outlined"
                          name="observation"
                          value={this.state.selectedCalled.Observations}
                          //onChange={this.handleInputChange}
                          multiline
                          rows="8"
                          inputProps={{ maxLength: 800 }}
                          onInput={(e, newValue, name) =>
                            //this.setState({ observationCalled: e.target.value })
                            this.changeValues(e, newValue)
                          }
                        />
                      </Grid>



                      <Grid item xs={12} sm={12}>
                        <Grid container justifyContent="flex-end" spacing={2} style={{ marginBottom: '10px', marginTop: '10px' }}  >
                          {this.props.requiredratingcalleds === false ? (
                            <Grid item xs={4} >
                              <Button
                                color="primary"
                                onClick={this.props.onClose}

                              >
                                {this.props.intl.formatMessage({ id: "cancel" })}
                              </Button>
                            </Grid>
                          )
                            : null}
                          <Grid item xs={4} >
                            <Button

                              className="buttonSpacing"
                              variant="contained"
                              color="primary"
                              //onClick={this.getById.bind(this,this.state.selectedCalled.id,true)}
                              onClick={() => this.getById(this.state.selectedCalled.id, true)}
                            >
                              {this.props.intl.formatMessage({ id: "modalRating.open.called" })}
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button

                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleClick(this, this.state.selectedCalled)}
                            >
                              {this.props.intl.formatMessage({ id: "modalRating.send.rating" })}
                            </Button>
                          </Grid>


                        </Grid>
                      </Grid>



                    </Grid>
                  </div>
                </div>
              }

            </Container>

          </div>
        </div>
      </div>
    );
  }
}

ModalRating.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calleds: state.calleds,
  relationshipcalled: state.relationshipcalled.relationshipcalled
});


const mapDispatchToProps = dispatch =>
  //bindActionCreators({ addCurrentCalled, addAccompanimentsList }, dispatch);
  bindActionCreators(calledsActions, dispatch);
//export default ModalRating;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ModalRating)));