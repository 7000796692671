import { QueuePlayNext } from "@mui/icons-material";
import { Avatar, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import HeaderMenu from "src/components/header-menu";
import { Styles } from "src/styles/material-styles";
import Api from "src/services/api";
import moment from "moment";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { browserHistory } from '../../helpers/history';
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs';
import MailSending from "./components/mailsending";
import MailReceiving from "./components/mailreceiving";
import CustomizedSnackbars from "src/components/material-snackbars";
import ProviderSelection from "./components/providerselection";
import { PapiroConsole } from "src/utils/papiroConsole";
import * as OrganizationService from "../../services/organization.service"

class ConfigMailBoxRegistration extends Component {
    constructor(props) {
        super(props)

        const { userSession, configs, intl } = this.props

        if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
            Api.logoff()
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true
            }
        })

        this.initialState = {
            configid: configs.config.id,
            hirerid: userSession.user.hirerid,
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            server: "",
            fromMail: "",
            port: "",
            cryptotype: "",
            user: "",
            password: "",
            emailtest: "",
            authenticationRequired: false,
            authenticationMethodTypeId: 0,
            cloudType: 0,
            authType: 0,
            clientId: "",
            clientSecret: "",
            redirectUris: "",
            tenantId: "",
            projectId: "",
            tokenUri: "",
            authUri: "",
            authProviderX509CertUrl: "",
            responseemailisnewcalled: false,
            accompanimentcalledforresponseemail: false,
            serverReceive: "",
            fromMailReceive: "",
            portReceive: "",
            cryptotypeReceive: "",
            userReceive: "",
            passwordReceive: "",
            authenticationRequiredReceive: false,
            authenticationMethodTypeIdReceive: 0,
            cloudTypeReceive: 0,
            authTypeReceive: 0,
            clientIdReceive: "",
            clientSecretReceive: "",
            redirectUrisReceive: "",
            tenantIdReceive: "",
            projectIdReceive: "",
            tokenUriReceive: "",
            authUriReceive: "",
            authProviderX509CertUrlReceive: "",
            organization: { id: 0, name: "" },
        }

        this.state = {
            item: this.initialState,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            CryptoList: [],
            CloudTypeList: [],
            AuthTypeList: [],
            rTabIndex: 0,
            customProvider: 0,
            OrgsList: [],
        }
        this.changeValues = this.changeValues.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.testSendMail = this.testSendMail.bind(this)
        this.testReceivedMail = this.testReceivedMail.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeNotification = this.closeNotification.bind(this)
        this.setCustomProvider = this.setCustomProvider.bind(this)
    }

    componentDidMount() {
        this.getCrypto()
        this.getCloudType()
        this.getAuthType()
        this.getOrganizations()
    }

    async getCrypto() {
        var result = []
        result.push({ id: '1', name: 'SSL' })
        this.setState({ CryptoList: result })
    }

    async getCloudType() {
        var result = []
        result.push({ id: '1', name: 'Custom' })
        result.push({ id: '2', name: 'GoogleMail' })
        result.push({ id: '3', name: 'MicrosoftOutlook' })
        result.push({ id: '4', name: 'MicrosoftHotmail' })
        result.push({ id: '5', name: 'Yahoo' })
        this.setState({ CloudTypeList: result })
    }

    async getAuthType() {
        var result = []
        result.push({ id: '1', name: 'Default' })
        result.push({ id: '2', name: 'Oauth2' })
        this.setState({ AuthTypeList: result })
    }

    async getOrganizations() {
        this.setState({ loading: true })
        var result = await OrganizationService.getOrganizationsNewSimple()
        if (result.success) {
            this.setState({
                OrgsList: result.data,
                loading: false
            })
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    changeValues = (stateName, value) => {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }))
    }

    handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }))
    }

    setCustomProvider = async (value) => {
        const { intl } = this.props
        this.setState({ customProvider: value })

        let client_id = ''
        if (value == 1) {
            await Api.get('/token/oauth/gmail')
                .then(result => {
                    if (result && result.data && result.data.success == true) {
                        client_id = result.data.data
                    } else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    console.log("err")
                    const intl = this.props.intl
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    })
                    Api.kickoff(err)
                })
        }
        if (client_id.length > 0) {
            //let client_id = "457475788861-49q3pp471r06rpv07i9liv73f4tgsnjr.apps.googleusercontent.com";
            let redirect_uri = `https%3A%2F%2F${document.location.host.replace(':', '%3A')}%2Fpt%2Fauthorize`;
            let scope = "https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify";
            //state = tela a ser redirecionada, id da org, tab da tela de edição da config
            let state = `config-edit ${this.state.item.configid} 1`;
            var url = `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${client_id}&prompt=select_account+consent&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}`;
            PapiroConsole.log(redirect_uri)
            PapiroConsole.log("redirect_uri")
            document.location.href = url;
        }
    }

    closeNotification = () => {
        this.setState({ openNotification: false })
    }

    testSendMail = async () => {
        const intl = this.props.intl
        this.setState({ loading: true })

        if (this.state.item.password != '' && this.state.item.server != '' && this.state.item.port != '' && this.state.item.user != '' && this.state.item.emailtest != '' && this.state.item.fromMail != '') {
            var data = {
                idSend: null,
                serverSend: this.state.item.server,
                fromMailSend: this.state.item.fromMail,
                portSend: this.state.item.port,
                passwordSend: this.state.item.password != null ? this.state.item.password : null,
                userSend: this.state.item.user,
                addressemail: this.state.item.emailtest,
            }

            await Api.post('/emails/testSend', data)
                .then(result => {
                    if (result && result.data && result.data.success == true) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "email.sent" })
                        })
                    } else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "error.verify.sending.info" })
                        })
                    }
                })
                .catch(err => {
                    const intl = this.props.intl
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: intl.formatMessage({ id: "error.verify.sending.info" })
                    })
                    Api.kickoff(err)
                })

        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "insert.sending.info" }),
            })
        }
    }

    testReceivedMail = async () => {
        const intl = this.props.intl
        this.setState({ loading: true })

        if (this.state.item.passwordReceive != '' && this.state.item.serverReceive != '' && this.state.item.portReceive != '' && this.state.item.userReceive != '' && this.state.item.fromMailReceive != '') {
            var data = {
                idReceived: null,
                serverReceived: this.state.item.serverReceive,
                portReceived: this.state.item.portReceive,
                passwordReceived: this.state.item.passwordReceive != null ? this.state.item.passwordReceive : null,
                fromMailReceived: this.state.item.fromMailReceive,
                userReceived: this.state.item.userReceive,
            }

            await Api.post('/emails/testReceived', data)
                .then(result => {
                    if (result && result.data && result.data.success == true) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "mailbox.read.success.first.half" }) + result.data.data + intl.formatMessage({ id: "mailbox.read.success.second.half" })
                        })
                    } else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "error.verify.receiving.info" })
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    console.log("err")
                    const intl = this.props.intl
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: intl.formatMessage({ id: "error.verify.receiving.info" })
                    })
                    Api.kickoff(err)
                })

        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "insert.receiving.info" }),
            })
        }
    }

    setRTabIndex = idx => this.setState({ rTabIndex: idx })

    handleSubmit = async e => {
        const intl = this.props.intl
        e.preventDefault()

        if ((this.state.item.server == null || this.state.item.server.length == 0) && this.state.item.cloudType == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.server.field" })
            })
            return false
        }
        if (this.state.item.fromMail == null || this.state.item.fromMail.length == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: `O campo 'E-mail' em 'Informações de Envio' é obrigatório`
            })
            return false
        }
        if ((this.state.item.port == null || this.state.item.port.length == 0) && this.state.item.cloudType == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.port.field" })
            })
            return false
        }
        if ((this.state.item.cryptotype == null || this.state.item.cryptotype.length == 0) && this.state.item.cloudType == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.crypto.field" })
            })
            return false
        }
        if (this.state.item.user == null || this.state.item.user.length == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.user.field" })
            })
            return false
        }
        if ((this.state.item.password == null || this.state.item.password.length == 0) && this.state.item.cloudType == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.password.field" })
            })
            return false
        }

        if ((this.state.item.serverReceive == null || this.state.item.serverReceive.length == 0) && this.state.item.cloudTypeReceive == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.server.field.info" })
            })
            return false
        }
        if (this.state.item.fromMailReceive == null || this.state.item.fromMailReceive.length == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: `O campo 'E-mail' em 'Informações de Recebimento' é obrigatório`
            })
            return false
        }
        if ((this.state.item.portReceive == null || this.state.item.portReceive.length == 0) && this.state.item.cloudTypeReceive == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.port.field.info" })
            })
            return false
        }
        if ((this.state.item.cryptotypeReceive == null || this.state.item.cryptotypeReceive.length == 0) && this.state.item.cloudTypeReceive == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.crypto.field.info" })
            })
            return false
        }
        if (this.state.item.userReceive == null || this.state.item.userReceive.length == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.user.field.info" })
            })
            return false
        }
        if ((this.state.item.passwordReceive == null || this.state.item.passwordReceive.length == 0) && this.state.item.cloudTypeReceive == 1) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.missing.password.field.info" })
            })
            return false
        }
        if (this.state.item.organization.id == null || this.state.item.organization.id == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.calendar.registration.missing.organization.field" })
            })
            return false
        }

        this.setState({ loading: true })

        if (this.state.item.responseemailisnewcalled === true && this.state.item.accompanimentcalledforresponseemail === true) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "organization.account.email.registration.email.answer.is.new.called" })

            })
        } else {
            let data = new FormData()
            for (let key in this.state.item) {
                if (key.toString().toLowerCase().indexOf('organization') > -1) {
                    data.append("organizationid", this.state.item[key].id)
                }
                data.append(key, this.state.item[key])
            }

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            Api.post('/configmailbox', data, config)

                .then(result => {
                    if (result.data.success) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "add.success" }),
                            item: this.initialState,
                            files: []
                        })
                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

                        })
                        Api.kickoff(result)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                        })
                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                        })
                    }
                    Api.kickoff(err)
                })
        }
    }

    render() {

        const { item, AuthTypeList, CryptoList, CloudTypeList, OrgsList } = this.state
        const { classes, headerMenu, actionsHeaderMenu, intl } = this.props
        
        PapiroConsole.log(item)
        PapiroConsole.log("item")
        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={_ => this.props.history.push(`/${this.props.match.params.lang}/config-edit`, { propTab: 1 })}>{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="add.item" />} {<FormattedMessage id="mail.box.config" />}
                            </Typography>
                            <ProviderSelection setCustomProvider={this.setCustomProvider} selected={this.state.customProvider} />
                            {this.state.customProvider == 3 &&
                                <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                    <RTabs selectedIndex={this.state.rTabIndex} onSelect={index => this.setRTabIndex(index)} >
                                        <RTabList style={{ borderColor: '#BB8FCE' }}>
                                            <RTab>{intl.formatMessage({ id: "sending.info" })}</RTab>
                                            <RTab>{intl.formatMessage({ id: "reveiving.info" })}</RTab>
                                        </RTabList>
                                        <RTabPanel>
                                            <MailSending
                                                intl={intl}
                                                item={item}
                                                handleInputChange={this.handleInputChange}
                                                changeValues={this.changeValues}
                                                testSendMail={this.testSendMail}
                                                AuthTypeList={AuthTypeList}
                                                CryptoList={CryptoList}
                                                CloudTypeList={CloudTypeList}
                                            />
                                        </RTabPanel>
                                        <RTabPanel>
                                            <MailReceiving
                                                intl={intl}
                                                item={item}
                                                handleInputChange={this.handleInputChange}
                                                changeValues={this.changeValues}
                                                handelSelect={this.handelSelect}
                                                testReceivedMail={this.testReceivedMail}
                                                AuthTypeList={AuthTypeList}
                                                CryptoList={CryptoList}
                                                CloudTypeList={CloudTypeList}
                                                OrgsList={OrgsList}
                                            />
                                        </RTabPanel>
                                    </RTabs>
                                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                        <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                                            <Button
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                            >
                                                {this.props.intl.formatMessage({ id: "save" })}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>}
                        </div>
                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        )
    }
}

ConfigMailBoxRegistration.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    configs: state.configs,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(ConfigMailBoxRegistration)))
