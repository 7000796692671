import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Tooltip, CssBaseline, CircularProgress, Container, Button, Grid, Avatar, Typography, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleSelect from "../../components/select/simple";
import * as componentActions from '../../redux/actions/rulesattendance';
import Api2Talk from "../../services/api2talk";
import RulesAttendanceComponent from "../../components/rules-attendance";
import Api from '../../services/api';
import * as ServiceTech from '../../services/techinicalteamorganizationcategory.service';
import * as ServiceTeam from '../../services/techinicalteam.service';
import * as ServiceOrganization from '../../services/organization.service';
import * as ServiceChannel from '../../services/2talk/channel';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import { QueuePlayNext } from '@mui/icons-material';
import * as ServiceWorkHour from '../../services/organizationsla.service';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple';
import HelpIcon from '@mui/icons-material/Help'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PapiroConsole } from 'src/utils/papiroConsole';
import userList from '../user-list';

class RulesAttendance extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            organizationList: [],
            channelList: [],
            techinicalTeamList: [],
            usersList: [],
            conditionOptions: [{ id: 1, name: 'OU' }, { id: 2, name: 'E' }],
            workHourList: [{ id: 0, name: 'Qualquer um' }],
            item: {
                organizationid: null,
                channelid: null,
                techinicalTeamId: null,
                workhourId: null,
                condition: 1,
                userId: null,
                name: ""
            },
            rules: [{ index: 0, disable: false }],
            howIndexEnable: 1
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {
        this.setState({ searchValue: event.target.value, page: 1 });
    }

    callSearch() {
        if (this.state.searchValue != '') {
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ searchValue: '', page: 1 })
        this.getItens(1);
    }

    componentDidMount() {
        this.getOrganization();
        this.getChannels()
        this.getTechinicalTeams();
    }

    getOrganization = async () => {
        var result = await ServiceOrganization.getOrganizationsNewSimple();
        if (result.success) {

            if (result.data && result.data.length) {
                var organizationList = result.data;
                organizationList.unshift({ id: 0, name: 'Qualquer uma' })
                this.setState({ loading: false, organizationList: result.data });
            }


        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    getChannels = async () => {
        var result = await ServiceChannel.getChannelList();
        if (result.success) {

            if (result.data && result.data.length) {
                var channelList = result.data;
                this.setState({ loading: false, channelList: result.data });
            }


        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    getUsers = async (techinicalTeamId) => {
        PapiroConsole.log("entrei aqui 123")
        PapiroConsole.log(techinicalTeamId)
        await Api.get('/users/get-by-techinicalteamid/' + techinicalTeamId)
            .then(result => {
                if (result.data.success && result.data.data) {
                    this.setState({ usersList: result.data.data });
                } else {

                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);

                }
            }).catch(err => {

                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

                    });
                }
            });
    }

    getUsersByTeams = async (teamid) => {
        const users = new Array();
        await Api.get('/techinicalteamusers/technicalteam/' + teamid)
            .then(result => {
                if (result.data.success && result.data.data) {

                    result.data.data.map(item => {
                        users.push(item.user.id)
                    });

                } else {

                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    return null;

                }
            }).catch(err => {

                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    return null;
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

                    });
                    return null;
                }
            });
        return users;
    }

    changeValues = (stateName, value) => {
        console.log(stateName)
        console.log(value)
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));

        if (stateName == 'organizationid') {
            if (value.id) {

                this.getWorkHours(value.id);
                // this.getUsers(value.id);
            }
            else{
                if(value.id == 0 && value.name == "")
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                        workhourId : null
                    },
                    workHourList : [{ id: 0, name: 'Qualquer uma' }]
                }));
                else if(value.id == 0 && value.name == "Qualquer uma"){
                    this.getWorkHours(0);
                }
                
            }
        }

        if (stateName == 'techinicalTeamId') {
            if (value.id) {
                this.getUsers(value.id);
            }
            else{
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                        userId : null
                    },
                    usersList : []
                }));
                
            }
        }

    };

    changeValuesText = (event) => {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                ['name']: event.target.value
            }
        }));

    };

    getTechinicalTeams = async () => {
        var result = await ServiceTeam.getAllSelectionModel();
        if (result.success) {
            this.setState({ techinicalTeamList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    getWorkHours = async (orgId) => {
        this.setState({ loading: true });
        var result = await ServiceWorkHour.getWorkHours(orgId);
        if (result.success) {
            if (result.data && result.data.length) {
                var workHourList = result.data;
                workHourList.unshift({ id: 0, name: 'Qualquer um' })
                this.setState({ loading: false, workHourList: workHourList });
            }


        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    addNewRules = () => {
        const actualRules = this.state.rules;
        actualRules.push({ index: actualRules.length, disable: false });

        this.setState({ rules: actualRules });
    }

    removeItem = (value) => {
        const actualRules = this.state.rules;
        const objIndex = actualRules.findIndex((obj => obj.index == value.index));
        actualRules[objIndex].disable = true;
        if (objIndex == this.state.howIndexEnable) {
            this.setState({ howIndexEnable: objIndex + 1 });
        }

        const lengthRulesEnable = actualRules.filter(item => item.disable == false)
        if (lengthRulesEnable.length == 1) {
            this.setState({ rules: [{ index: 0, disable: false }], howIndexEnable: 1 });
        } else {
            this.setState({ rules: actualRules });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (Api2Talk == null) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return;
        }

        if (this.state.item['organizationid'] == null || (this.state.item['organizationid']?.id == 0 && this.state.item['organizationid']?.name == "")) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'É necessário selecionar a organização'
            });
            return;
        }

        if (this.state.item['workhourId'] == null || (this.state.item['workhourId']?.id == 0 && this.state.item['workhourId']?.name == "")) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'É necessário selecionar o expediente'
            });
            return;
        }

        if (this.state.item['techinicalTeamId'] == null || (this.state.item['techinicalTeamId']?.id == 0)) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'É necessário selecionar um time de atendimento'
            });
            return;
        }
        this.setState({ loading: true });

        const intl = this.props.intl;
        const count = event?.target?.elements?.valueRule.length;
        const definitionRules = new Array();

        if (count && count > 1) {
            for (let index = 0; index < count; index++) {
                definitionRules.push({
                    priority: index,
                    property: event?.target?.elements?.propertyRule[index]?.value,
                    relation: event?.target?.elements?.relationRule[index]?.value,
                    value: event?.target?.elements?.valueRule[index]?.value
                })
            }

        } else {

            if (event?.target?.elements?.propertyRule?.value == null) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: 'É necessário selecionar propriedade'
                });
            }

            if (event?.target?.elements?.relationRule?.value == null) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: 'É necessário selecionar a condição'
                });
            }

            definitionRules.push({
                priority: 0,
                property: event?.target?.elements?.propertyRule?.value,
                relation: event?.target?.elements?.relationRule?.value,
                value: event?.target?.elements?.valueRule?.value
            })
        }

        // const users = this.state.item.userId?.map(item => {
        //     return item.id;
        // });

        const users = [];
        for (let key in this.state.item.userId) {
            if (key == "id") {
                users.push(this.state.item.userId[key]);
            }
        }



        const data = {
            organizationid: this.state.item['organizationid']?.id,
            workhourid: this.state.item['workhourId']?.id,
            techinicalteamid: this.state.item['techinicalTeamId']?.id,
            operator: this.state.item['condition'],
            servicedefinitionrules: definitionRules,
            users: users,
            name: this.state.item['name'],
            channelId :  this.state.item['channelid']?.id
        };

        PapiroConsole.log("data this.state.item['workhourId']?.id")
        PapiroConsole.log(this.state.item['workhourId']?.id)
        PapiroConsole.log(this.state.item['workhourId'])

        Api2Talk.post('/servicerules', data)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "save.success" })
                    });

                    window.location.reload(true);

                } else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        PapiroConsole.log("this.state.item.workhourId")
        PapiroConsole.log(this.state.item.workhourId)

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />

                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={browserHistory.goBack}
                                    >
                                        {<FormattedMessage id="back" />}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="add.item" />} Regras de Atendimento<Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2} style={{ marginTop: 15 }}>

                                    <Grid item xs={12} sm={12}>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{
                                                borderLeft: '5px solid #3f51b4',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                padding: '10px 20px',
                                                backgroundColor: '#f9f9f9',
                                            }}>
                                                <Typography variant="h6">Dados da regra de atendimento</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ marginTop: '20px' }}>
                                                <Grid container spacing={2} >

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="nameRules"
                                                            fullWidth
                                                            label="Nome"
                                                            variant='outlined'
                                                            value={this.state.item.name}
                                                            required
                                                            onChange={this.changeValuesText}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <SimpleAutoComplete
                                                            label={intl.formatMessage({ id: "channel" })}
                                                            options={this.state.channelList}
                                                            stateName='channelid'
                                                            changeSelect={this.changeValues}
                                                            selected={this.state.item.channelid}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <SimpleAutoComplete
                                                            label={intl.formatMessage({ id: "organization" })}
                                                            options={this.state.organizationList}
                                                            stateName='organizationid'
                                                            changeSelect={this.changeValues}
                                                            selected={this.state.item.organizationid}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <SimpleAutoComplete
                                                            label="Expediente"
                                                            options={this.state.workHourList}
                                                            stateName='workhourId'
                                                            changeSelect={this.changeValues}
                                                            selected={this.state.item.workhourId}
                                                            required
                                                        />
                                                    </Grid>


                                                </Grid>


                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{
                                                borderLeft: '5px solid #3f51b4',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                padding: '10px 20px',
                                                backgroundColor: '#f9f9f9',
                                            }}>
                                                <Typography variant="h6">Condições da regra de atendimento
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ marginTop: '20px' }}>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} >
                                                        <Grid item xs={12}>
                                                            <RulesAttendanceComponent
                                                                id="rules-attendance-component"
                                                                removeItemCallback={this.removeItem}
                                                                index={0}
                                                                deleteButton={false}
                                                            />
                                                        </Grid>
                                                        <Stepper orientation="vertical">
                                                            {this.state.rules.map(item => {
                                                                return item.disable == false && item.index != 0 ? (
                                                                    <Step active={true} >
                                                                        <StepLabel>
                                                                            <Grid item xs={3}>
                                                                                <SimpleSelect
                                                                                    label="Condição"
                                                                                    options={this.state.conditionOptions}
                                                                                    stateName='condition'
                                                                                    changeSelect={this.changeValues}
                                                                                    selected={this.state.item.condition}
                                                                                    disabled={!(this.state.howIndexEnable == item.index)}
                                                                                    isRequired={true}
                                                                                />
                                                                                {item.index == this.state.howIndexEnable
                                                                                    && "Qualquer uma das condições abaixo"}
                                                                            </Grid>
                                                                        </StepLabel>
                                                                        <StepContent>
                                                                            <Grid style={{ marginLeft: item.index != 0 ? 30 : 0 }} item xs={12}>
                                                                                <RulesAttendanceComponent
                                                                                    id="rules-attendance-component"
                                                                                    removeItemCallback={this.removeItem}
                                                                                    index={item}
                                                                                    deleteButton={item.index != 0}
                                                                                />
                                                                            </Grid>
                                                                        </StepContent>
                                                                    </Step>
                                                                ) : ""
                                                            })}
                                                        </Stepper>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Grid container >
                                                            <Grid item sm={4} />
                                                            <Grid item sm={4}>
                                                                <Button
                                                                    onClick={this.addNewRules}
                                                                    fullWidth
                                                                    variant='contained'
                                                                    color='primary'
                                                                >
                                                                    Adicionar nova condição
                                                                </Button>
                                                            </Grid>
                                                            <Grid item sm={4} />
                                                        </Grid>
                                                    </Grid>


                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{
                                                borderLeft: '5px solid #3f51b4',
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                padding: '10px 20px',
                                                backgroundColor: '#f9f9f9',
                                            }}>
                                                <Typography variant="h6">Ações da distribuição
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ marginTop: '20px' }}>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={11}>
                                                        <SimpleAutoComplete
                                                            label="Time"
                                                            options={this.state.techinicalTeamList}
                                                            stateName='techinicalTeamId'
                                                            changeSelect={this.changeValues}
                                                            selected={this.state.item.techinicalTeamId}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} style={{ marginTop: 15 }}>
                                                        <Tooltip title="É possível selecionar um time ou usuários">
                                                            <HelpIcon />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <SimpleAutoComplete
                                                            label="Usuários"
                                                            options={this.state.usersList}
                                                            stateName="userId"
                                                            changeSelect={this.changeValues}
                                                            selected={this.state.item.userId}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} style={{ marginTop: 15 }}>
                                                        <Tooltip title="É possível selecionar um time ou usuários">
                                                            <HelpIcon />
                                                        </Tooltip>
                                                    </Grid>


                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>


                                    <Grid item xs={12} >
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    type='submit'
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.submit}
                                                >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </form>
                        </div>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
RulesAttendance.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(RulesAttendance)));
RulesAttendance.contextType = AbilityContext;
