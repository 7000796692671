import { PapiroConsole } from "src/utils/papiroConsole";

export default function userSession(state = [], action) {
    switch (action.type) {
        case 'ADD_USER':
            return {
                user: action.user,
            }
        case 'REMOVE_USER':
            return null;

        case 'UPDATE_ALERT_ALREADY_DISPLAYED':

        PapiroConsole.log("UPDATE_ALERT_ALREADY_DISPLAYED")
        PapiroConsole.log(action)
        PapiroConsole.log(state)
            return {
                ...state,
                user: {
                    ...state.user,
                    alertalreadydisplayed: action.alertAlreadyDisplayed
                }
                
            }
        default:
            return state;
    }
}
