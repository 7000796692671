import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, LensTwoTone } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Service from '../../services/segment.service';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import SimpleAutoComplete from "../../components/auto-complete/autocomplete.js";
import Api2Talk from "../../services/api2talk";
import ReactQuill from 'react-quill' // ES6
import { styled } from "@mui/material/styles";
import { PapiroConsole } from 'src/utils/papiroConsole';
import { Autocomplete } from '@mui/material';
import * as componentActions from '../../redux/actions/templatemessages';
import { bindActionCreators } from 'redux';

import {
  Box,

} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddIcon from "@mui/icons-material/Add";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer,
  Paper
} from "@mui/material";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // Cor de fundo branca
    color: "#000000", // Cor do texto preta
    fontSize: "14px", // Tamanho do texto
    padding: "20px", // Espaçamento interno
    border: "1px solid #ddd", // Borda discreta
    borderRadius: "4px", // Bordas arredondadas
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra leve
    whiteSpace: "pre-line", // Permite quebra de linha
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#ffffff", // Cor da seta
  },
}));

class TemplateMessageEdit extends Component {

  constructor(props) {
    super(props);

    PapiroConsole.log("props aqui na edição")
    PapiroConsole.log(props)

    const { userSession, templatemessages } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    this.dynamicText = " Olá {{1}}, seu pedido #{{2}} está pronto para retirada no endereço {{3}}. Horário: {{4}}."


    this.initialState = {
      id: templatemessages.templatemessage.id,
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name: templatemessages.templatemessage.name,
      label: templatemessages.templatemessage.label,
      status: templatemessages.templatemessage.status,
      rejectedReason: templatemessages.templatemessage.rejectedreason ? templatemessages.templatemessage.rejectedreason : null,
      languageCode: "pt_BR",
      headerText: templatemessages.templatemessage.headertext,
      bodyText: templatemessages.templatemessage.bodytext,
      footerText: templatemessages.templatemessage.footertext,
      templateReferenceId: templatemessages.templatemessage.templatereferenceid,
      messageProviderId: null,
      registrationnumberrequestid: null,
      system: templatemessages.templatemessage.system,
      templatetype: null,
      satisfactionsurveyid: null

    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
      exampleText:
        <>
          Esse é o texto especificado na API que será personalizado para o cliente. <br /><br />Para adição de variáveis é necessário adicionar números consecutivos entre chaves duplas como o exemplo a seguir : <br /><br /> <strong>{this.dynamicText}</strong>
        </>,
      buttons: templatemessages.templatemessage.templatemessagebuttons != null && templatemessages.templatemessage.templatemessagebuttons.length > 0 ? templatemessages.templatemessage.templatemessagebuttons : [],
      showForm: false,
      buttonText: "",
      buttonUrl: "",
      error: "",
      registrationNumberRequestList: [],
      templateTypeList: [
        { id: 1, name: "Padrão" },
        { id: 2, name: "Opt-in" },
        { id: 3, name: "Pesquisa de satisfação" }
      ],
      satisfactionSurveyList: []



    };


    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],

      ],
    }

    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',

    ]
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    // this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

    this.fetchOptionsMessageProvider();
    this.fetchOptionsRegistrationsNumbersActives();
    this.getSelectedTemplateTypeSatisfactionSurveyOptions()

  };

  fetchOptionsMessageProvider = async () => {
    this.setState({ loading: true });
    Api2Talk.get(`/messageprovider`)
      .then(result => {
        this.setState({ loading: false });
        if (result.data.success) {

          if (result.data.data && result.data.data.length > 0) {
            const messageProviderOptions = result.data.data;
            const { templatemessages } = this.props;
            let templateMessageId = templatemessages.templatemessage.messageprovider.id;
            var templateMessageSelected = messageProviderOptions.find(item => item.id == templateMessageId);
            this.setState(prevState => ({
              item:
              {
                ...prevState.item,
                messageProviderId: templateMessageSelected
              },
              optionsMessageProviders: messageProviderOptions
            }))
          }

        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  }

  async fetchOptionsRegistrationsNumbersActives() {

    this.setState({ loading: true });
    Api.get(`/twotalkregistrationnumberrequests/all`)
      .then(result => {
        this.setState({ loading: false });
        if (result.data.success) {
          if (result.data.data && result.data.data.length > 0) {
            const registrationNumberRequestList = result.data.data;
            const { templatemessages } = this.props;
            let registrationNumberRequestId = templatemessages.templatemessage.registrationnumberrequestid;
            PapiroConsole.log("registrationNumberRequestId")
            PapiroConsole.log(registrationNumberRequestId)
            var registrationNumberRequestSelected = registrationNumberRequestList.find(item => item.id == registrationNumberRequestId);
            PapiroConsole.log("registrationNumberRequestSelected")
            PapiroConsole.log(registrationNumberRequestSelected)
            this.setState(prevState => ({
              item:
              {
                ...prevState.item,
                registrationnumberrequestid: registrationNumberRequestSelected
              },
              registrationNumberRequestList: result.data.data
            }))
          }




        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  };

  async getSelectedTemplateTypeSatisfactionSurveyOptions() {

    const { templatemessages } = this.props;



    var satisfactionsurveyid = templatemessages.templatemessage.satisfactionsurveyid ? templatemessages.templatemessage.satisfactionsurveyid : null
    var templateType = templatemessages.templatemessage.templatetype ? templatemessages.templatemessage.templatetype : null

    PapiroConsole.log("templatemessages aqui getSelectedTemplateTypeSatisfactionSurveyOptions")
    PapiroConsole.log(templatemessages)
    PapiroConsole.log(satisfactionsurveyid)
    PapiroConsole.log(templateType)


    this.setState({ loading: true });
    Api.get(`/satisfactionsurvey/simple/all`)
      .then(result => {

        if (result.data.success) {

          const templateTypeinitialOption = this.state.templateTypeList.find(
            option => option.id === templateType,
          );
          PapiroConsole.log("templateTypeinitialOption")
          PapiroConsole.log(templateTypeinitialOption)
          let initialOption = null
          if (result.data.data.length > 0) {
            initialOption = result.data.data.find(
              option => option.id === satisfactionsurveyid,
            );

          }
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              satisfactionsurveyid: initialOption ? initialOption : null,
              templatetype: templateTypeinitialOption ? templateTypeinitialOption : null
            },
            loading: false,
            satisfactionSurveyList: result.data.data ? result.data.data : [],
          }));



        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  };

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Evita a inserção padrão do espaço
      this.setState((prevState) => ({

        item: {
          ...prevState.item,
          ["name"]: prevState.item.name.length < 512 ? prevState.item.name + "_" : prevState.item.name
        },
      }));
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    var value = e.target.value;

    if (name == "name") {
      value = value.toLowerCase()
        .replace(/[^a-zA-Z0-9_]/g, "") // Permite apenas letras, números e "_"
        .slice(0, 512); // Limita a 512 caracteres
    }
    if (name == "label") {
      value = value
        .slice(0, 255); // Limita a 512 caracteres
    }
    if (name == "headerText" || name == "footerText") {
      value = value
        .slice(0, 60); // Limita a 512 caracteres
    }

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));



  };

  handleChangeAutoComplete = async (stateName, value) => {
    let data = value && value.id > 0 ? value : null;


    if (stateName == "templatetype") {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value || "",
          satisfactionsurveyid: null
        }
      }));

    }
    else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: data
        }
      }));
    }

  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value || ""
      }
    }));
  }




  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });


    const { name, bodyText, headerText, footerText, id, templateReferenceId, messageProviderId, registrationnumberrequestid, templatetype, satisfactionsurveyid, system } = this.state.item;

    let data = {
      name,
      bodyText,
      headerText,
      footerText,
      id,
      templatetype: templatetype.id,
      satisfactionsurveyid: satisfactionsurveyid && satisfactionsurveyid.id ? satisfactionsurveyid.id : null,
      messageProviderId: messageProviderId.id,
      registrationnumberrequestid: registrationnumberrequestid.id,
      templateReferenceId: templateReferenceId,
      system

    }

    Api2Talk.put('/templatemessage', data)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "edit.success" }),

          });
        }
        else {

          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleAddButtonClick = () => {
    this.setState({ showForm: true });
  };

  updateStatusTemplate = () => {

    const { id, name, registrationnumberrequestid } = this.state.item;

    this.setState({
      loading: true,

    });

    Api2Talk.get(`/templatemessage/current-status/${registrationnumberrequestid ? registrationnumberrequestid.id : null}/${id}/${name}`)
      .then(result => {
        if (result.data && result.data.success && result.data.data && result.data.data.status) {

          var status = result.data.data.status;
          var rejectedReason = result.data.data.rejectedreason ? result.data.data.rejectedreason : null;
          var notificationMessage = `Status do modelo atualizado com sucesso (${status}) `;
          if (rejectedReason) {
            notificationMessage += ` Motivo da rejeição (${rejectedReason}) `
          }
          const intl = this.props.intl;
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              status: status,
              rejectedReason: rejectedReason
            },
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: notificationMessage,
          }));

        }
        else {

          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
        }
      });
  }

  handleInputChangeTable = (field, value) => {
    this.setState({ [field]: value, error: "" });
  };

  handleAdd = () => {
    const { buttons, buttonText, buttonUrl } = this.state;
    if (buttons.length >= 2) {
      this.setState({ error: "Apenas dois botões podem ser adicionados." });
      return;
    }
    if (!buttonText || !buttonUrl) {
      this.setState({ error: "Preencha ambos os campos." });
      return;
    }

    this.setState({
      buttons: [...buttons, { text: buttonText, url: buttonUrl }],
      buttonText: "",
      buttonUrl: "",
      showForm: false,
    });
  };

  handleRemove = (index) => {
    this.setState((prevState) => ({
      buttons: prevState.buttons.filter((_, i) => i !== index),
    }));
  };

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    const { name, languageCode, bodyText, headerText, footerText, registrationnumberrequestid, templatetype, satisfactionsurveyid } = this.state.item;
    const { buttons, showForm, buttonText, buttonUrl, error } = this.state;
    PapiroConsole.log("this.state.registrationNumberRequestList")
    PapiroConsole.log(this.state.registrationNumberRequestList)

    PapiroConsole.log("this.state.item.system")
    PapiroConsole.log(this.state.item.system)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#303f9f', color: 'white' }} className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />} {<FormattedMessage id="templates" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2} marginTop={1}>

                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>

                      <Grid item xs={11} sm={3} mb={2}   >
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                          onClick={this.updateStatusTemplate}
                        >
                          {<FormattedMessage id="Sincronizar template" />}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField disabled fullWidth label={intl.formatMessage({ id: "name" })} onKeyDown={this.handleKeyDown} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} helperText={`${item.name && item.name.length ? item.name.length : 0}/512`} required />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Nome do seu modelo" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField disabled fullWidth label={intl.formatMessage({ id: "label" })} autoComplete='fname' variant='outlined' name='label' onChange={this.handleInputChange} value={item.label} helperText={`${item.label && item.label.length ? item.label.length : 0}/255`} required />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Label do seu modelo" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11}>
                    <TextField disabled fullWidth label={intl.formatMessage({ id: "status" })} autoComplete='fname' variant='outlined' name='status' value={item.status} />
                  </Grid>
                  {item.status != null && item.status == 'REJECTED' &&
                    <Grid item xs={11} marginBottom={1}>
                      <TextField fullWidth multiline rows={5} disabled  style={{ height: "100%" }} label="Motivo da rejeição" autoComplete='fname' variant='outlined' name='rejectedReason' value={item.rejectedReason ? item.rejectedReason : ""} />
                    </Grid>
                  }

                  <Grid item xs={11} marginBottom={1}>
                    <Autocomplete
                      disabled
                      options={this.state.registrationNumberRequestList}
                      getOptionLabel={(option) => option.phonenumber}
                      onChange={(event, value) => this.handleChangeAutoComplete('registrationnumberrequestid', value)}
                      renderInput={(params) => <TextField {...params} label="Selecione o telefone" variant="outlined" required />}
                      value={registrationnumberrequestid}

                    />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Número de telefone que vai fazer envio dos modelos" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11} marginBottom={1}>
                    <SimpleAutoComplete
                      disabled
                      name="nessageProviderId"
                      label={intl.formatMessage({ id: "select.message_provider" })}
                      options={this.state.optionsMessageProviders}
                      stateName='messageProviderId'
                      changeSelect={(stateName, value) => {
                        this.handleChangeAutoComplete(stateName, value)
                      }}
                      selected={item.messageProviderId}
                      htmlOptions={true}
                      required
                    />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Provedor da mensagem escolhido" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11} marginBottom={1}>
                    <Autocomplete
                      options={this.state.templateTypeList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => this.handleChangeAutoComplete('templatetype', value)}
                      renderInput={(params) => <TextField required {...params} label="Selecione o tipo do template" variant="outlined" />}
                      value={templatetype}
                      disabled={this.state.item.system}

                    />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="tipo do template escolhido" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  {templatetype != null && templatetype.id == 3 &&

                    <Grid item xs={11} marginBottom={1}>
                      <Autocomplete
                        options={this.state.satisfactionSurveyList}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => this.handleChangeAutoComplete('satisfactionsurveyid', value)}
                        renderInput={(params) => <TextField required={templatetype != null && templatetype.id == 3 ? true : false} {...params} label="Selecione a pesquisa de satisfação" variant="outlined" />}
                        value={satisfactionsurveyid}
                        required={templatetype != null && templatetype.id == 3 != null ? true : false}
                      />
                    </Grid>
                  }

                  {templatetype != null && templatetype.id == 3 &&

                    <Grid item xs={1} marginTop={1}>
                      <StyledTooltip title="Pesquisa de satisfação ativa no sistema" placement="right" arrow>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </StyledTooltip>
                    </Grid>
                  }
                  <Grid item xs={11}>
                    <TextField disabled={this.state.item.system} fullWidth label="Cabeçalho" autoComplete='fname' variant='outlined' name='headerText' onChange={this.handleInputChange} value={headerText} helperText={`${item.headerText && item.headerText.length ? item.headerText.length : 0} /60`} />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title={this.state.exampleText

                    } placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11} marginTop={2}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginLeft: '3px', marginBottom: '12px' }} gutterBottom>
                      <FormattedMessage id="Corpo da mensagem" />
                    </Typography>
                    <TextField
                      label="Corpo"
                      name="bodyText"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={5}
                      style={{ height: "100%" }}
                      value={bodyText}
                      onChange={this.handleInputChange}
                      required
                      disabled={this.state.item.system}
                    />

                  </Grid>
                  <Grid item xs={1} marginTop={5}>
                    <StyledTooltip title={this.state.exampleText

                    } placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11} marginTop={2} >
                    <TextField disabled={this.state.item.system} fullWidth label={intl.formatMessage({ id: "whitelabelconfig.footer.text" })} autoComplete='fname' variant='outlined' name='footerText' onChange={this.handleInputChange} value={footerText} helperText={`${item.headerText ? item.headerText.length : 0}/60`} />
                  </Grid>
                  <Grid item xs={1} marginTop={2}>
                    <StyledTooltip title="Rodapé do modelo (não é possível adicionar variáveis)" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  {/* <Grid item xs={12}>



                  </Grid> */}

                  {/* Botão para adicionar novos elementos */}
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <Button

                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={this.handleAddButtonClick}
                      disabled
                    >
                      Novo botão
                    </Button>
                    <Tooltip title="Você pode adicionar até dois botões.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  {/* Formulário para adicionar um botão */}
                  {showForm && (
                    <Grid item xs={11}>

                      <Grid item xs={12}>
                        <TextField
                          label="Texto do Botão"
                          value={buttonText}
                          onChange={(e) => this.handleInputChangeTable("buttonText", e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="URL"
                          value={buttonUrl}
                          onChange={(e) => this.handleInputChangeTable("buttonUrl", e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {error && <Typography color="error">{error}</Typography>}
                        <Button variant="contained" color="primary" onClick={this.handleAdd} style={{ marginTop: 10 }}>
                          Adicionar
                        </Button>
                      </Grid>


                    </Grid>
                  )}
                  <Grid item xs={11}>
                    {buttons.length > 0 && (
                      <TableContainer component={Paper} style={{ marginTop: 20 }}>
                        <Table sx={{ minWidth: 650, borderCollapse: "collapse" }}>
                          <TableHead>
                            <TableRow sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                              <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>Texto do Botão</TableCell>
                              <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>URL</TableCell>
                              <TableCell>Ações</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {buttons.map((button, index) => (
                              <TableRow key={index} sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                                <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{button.label}</TableCell>
                                <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                  <span>
                                    {button.url != null && button.url != "" ? button.url : "Não possui"}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <IconButton disabled color="error" onClick={() => this.handleRemove(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    {/* Tabela para exibir e gerenciar os botões */}

                  </Grid>













                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>

                      <Grid item xs={11} sm={3}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div >
    );
  }
}

TemplateMessageEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  templatemessages: state.templatemessages
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(TemplateMessageEdit)));
