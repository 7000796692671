import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import CustomizedSnackbars from '../../components/material-snackbars'
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/chats'
import { getChatIndicators, GetChatList as GetChatListService, newConversation, getEnabledUserById, getEnabledUsers, getUserStatusList, getModalOptions } from '../../services/2talk/omnichannel'
import Api2Talk from "../../services/api2talk"
import { Overlay } from '../../styles/global'
import { Styles } from '../../styles/material-styles'
import { PapiroConsole } from '../../utils/papiroConsole'
import AgentList from './components/agent-list/agent-list'
import ChatIndicators from './components/chat-indicators/chat-indicators'
import ItemCard from './components/item-card/item-card'
//import { Tabs, Tab } from '@mui/material';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import ThreePIcon from '@mui/icons-material/ThreeP'

import Badge from '@mui/material/Badge'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import AddIcon from '@mui/icons-material/Add';
import CustomModal from './components/custom-modal/custom-modal'
//import './tab.css'

const StyledPaper = withStyles({
  root: {
    '&::-webkit-scrollbar': {
      width: '9px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(63, 81, 180, 0.6)', /* Cor do thumb (cabeça da barra de rolagem) */
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1', /* Cor de fundo da barra de rolagem */
    },
  },
  expanded: {},
})(Paper);

/*const MyPaper = styled(Paper)({
  backgroundColor: (theme) => theme.palette.background.paper,
});

const MyTabs = styled(Tabs)({
  backgroundColor: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.primary.contrastText,
});*/

class ToTalkChatAttendance extends Component {

  constructor(props) {
    super(props)
    const { userSession } = this.props;

    const intl = this.props.intl;

    this.initialState = {
      filterId: { id: 0, name: '' },
      chatFilterId: { id: 0, name: '' }
    };
    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      userSession: userSession,
      chats: [],
      count: 0,
      page: 1,
      pageStart: 0,
      filterOptions: [
        { id: 1, name: 'Conversas' }
      ],
      chatFilter: [],
      pageEnd: 0,
      statusOptions: [],
      enableUsers: [],
      userStatusId: 6,
      chatsInAttendance: [],
      myChatsInAttendance: [],
      otherChatsInAttendance: [],
      countMyChatsInAttendance: 0,
      countOtherChatsInAttendance: 0,


      chatsWaitingAttendance: [],
      countNewChats: 0,
      countInAttendanceChats: 0,
      countCanceledChats: 0,
      countFinishChats: 0,
      countChatsResolvedInTheFirstService: 0,
      countChatsNotResolvedInTheFirstService: 0,
      tabSelected: 0,
      userId: this.props.userSession && this.props.userSession.user && this.props.userSession.user.id ? this.props.userSession.user.id : 0,
      loadingAgents: false,
      loadingIndicators: false,
      loadingNewConversation: false,
      modalOpen: false,
      messageProvidersList: [
      ],
      registrationNumberRequestList: [
      ],
      templateMessageList : [], 

    };

    PapiroConsole.log(this.props)
    PapiroConsole.log("this.props")
    //this.props.refreshDashboard(false);
    this.closeNotification = this.closeNotification.bind(this);
    this.updateChat = this.updateChat.bind(this);
    this.redirectChat = this.redirectChat.bind(this);

    this.changeStatus = this.changeStatus.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setTemplateMessageList = this.setTemplateMessageList.bind(this);
    this.setTemplateMessageListComplete = this.setTemplateMessageListComplete.bind(this);
    this.setMessage = this.setMessage.bind(this);

  }



  componentWillReceiveProps(nextProps) {
    //alert(JSON.stringify(nextProps.chats.refreshdashboard));
    //console.error(nextProps);
    //console.error(JSON.stringify(nextProps));
    //if (nextProps && nextProps.location && nextProps.location.state === "reload") {
    if (nextProps && nextProps.headerMenu && nextProps.headerMenu.refresh === true) {
      nextProps.headerMenu.refresh = false;
      this.componentDidMount();
    }
    else if (nextProps && nextProps.chats && nextProps.chats.refreshdashboard === true) {

      var refreshdashboard = true;
      nextProps.chats.refreshdashboard = false;
      this.componentDidMount(refreshdashboard);
    }
  }

  onChangePaginate = async (e, page) => {
    this.setState({
      loading: true,
    });



    this.setState({ page: page, countLoading: 0 });


    await this.getChats(page);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabSelected: newValue });
  }


  setPagination(count, page, pageCount) {
    let result = {};
    let totalPage = Math.ceil(count / pageCount);
    //this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < pageCount) {
          //this.setState({ pageStart: 1, pageEnd: count });
          result.pageStart = 1;
          result.pageEnd = count;
        } else {
          //this.setState({ pageStart: 1, pageEnd: 10 });
          result.pageStart = 1;
          result.pageEnd = pageCount;
        }
      } else {
        let pageStart = ((page - 1) * pageCount) + 1;

        result.pageStart = pageStart;


        if (count >= page * pageCount) {
          // this.setState({ pageStart: pageStart, pageEnd: page * pageCount });
          result.pageEnd = page * pageCount;
        } else {
          //this.setState({ pageStart: pageStart, pageEnd: count });
          result.pageEnd = page * pageCount;
        }
      }
      result.totalPages = totalPage;
      result.page = page;
      result.count = count;
      result.pageCount = pageCount;


    } else {
      //this.setState({ pageStart: 0, pageEnd: 0, totalPages: 0, page: 1, count: 0 });
      result.totalPages = 0;
      result.page = 1;
      result.count = 0;
      result.pageStart = 0;
      result.pageEnd = 0;
      result.pageCount = pageCount;
    }
    return result;

  }
  closeNotification() {
    this.setState({ openNotification: false });
  }

  async callingFilters(id, filterId, chatFilterId) {
    await this.getChats(id, filterId, chatFilterId);
    await this.getChatIndicators(id, filterId, chatFilterId)
  }

  async getChats(id, filterId, chatFilterId, refreshdashboard) {
    // PapiroConsole.log("filterId")
    // PapiroConsole.log("chatFilterId")
    // PapiroConsole.log(filterId)
    // PapiroConsole.log(chatFilterId)
    if (refreshdashboard) {

      this.setState({ loadingNewConversation: true })

    }
    else {
      this.setState({ loading: true })
    }

    if (filterId && chatFilterId) {
      filterId = filterId.id
      chatFilterId = chatFilterId.id
    }
    let result = await GetChatListService(null, filterId, chatFilterId)
    if (result && result.success) {
      PapiroConsole.log("result endpoint")
      PapiroConsole.log(result)
      let chatList = result.data.totalchats;
      let chatsInAttendance = result.data.chatsinattendance

      let myChatsInAttendance = result.data.mychatsinattendance;
      let otherChatsInAttendance = result.data.otherchatsinattendance;
      let countOtherChatsInAttendance = result.data.countotherchatsinattendance ? result.data.countotherchatsinattendance : 0
      let countMyChatsInAttendance = result.data.countmychatsinattendance ? result.data.countmychatsinattendance : 0
      let chatsWaitingAttendance = result.data.chatswaitingattendance

      //this.props.addCurrentOmnichannelChatList(chatList);
      //this.props.addTotalChatCount(chatList.length);
      this.setState({ loading: false, loadingNewConversation: false, chats: chatList, countOtherChatsInAttendance: countOtherChatsInAttendance, countMyChatsInAttendance: countMyChatsInAttendance, chatsInAttendance: chatsInAttendance, myChatsInAttendance: myChatsInAttendance, otherChatsInAttendance: otherChatsInAttendance, chatsWaitingAttendance: chatsWaitingAttendance })


      //this.getChatIndicators(id, filterId, chatFilterId)
      //var pagination = this.setPagination(count, page, 10);
      //this.setState({ loading: false, count: count, pageStart: pagination.pageStart, pageEnd: pagination.pageEnd, chats: chatList })
      //setCount(chatList.length);

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loading: false, loadingNewConversation: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api2Talk.kickoff(result)

    }


  }

  scrollToBottom = () => {

    //this.ref.current.scrollIntoView({ behavior: 'smooth' });
    //window.scrollTo(0, document.documentElement.scrollHeight);
    const element = document.documentElement; // ou qualquer outro elemento que você deseja rolar para
    element.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  clearFilters() {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        filterId: { id: 0, name: '' },
        chatFilterId: { id: 0, name: '' }
      },
      chatFilter: []
    }));
  }

  async StatusOptions() {
    //this.setState({ loading: true })
    let result = await getUserStatusList()
    if (result && result.success) {

      let statusOptions = result.data;

      this.setState({ statusOptions: statusOptions })

      //setCount(chatList.length);

    }
    else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });

    }
  }
  async getEnabledUsers() {
    this.setState({ loadingAgents: true })
    var userStatusId = 6;
    let userId = this.props.userSession && this.props.userSession.user ? this.props.userSession.user.id : null
    let result = await getEnabledUsers()
    if (result && result.success) {

      PapiroConsole.log(result)
      PapiroConsole.log("result data")

      let enableUsers = result.data;

      if (userId) {

        var currentUser = enableUsers.find(item => item.userid === userId);
        userStatusId = currentUser ? currentUser.userstatusid : 6

      }

      this.setState({ loadingAgents: false, enableUsers: enableUsers ? enableUsers : [], userStatusId: userStatusId })

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loadingAgents: false,
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });

    }
  }

  async getModalOptions() {
    
    let result = await getModalOptions()
    if (result && result.success) {
      this.setState({
        messageProvidersList : result.data.messageproviderlist != null ? result.data.messageproviderlist : [],
        registrationNumberRequestList : result.data.registrationnumberrequestlist != null ? result.data.registrationnumberrequestlist  : [],
        templateMessageList : result.data.templatemessagelist != null ? result.data.templatemessagelist  : [],
        templateMessageListComplete : result.data.templatemessagelist != null ? result.data.templatemessagelist  : []
        })
    }
    else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });

    }
  }

  async getChatIndicators(id, filterId, chatFilterId) {

    PapiroConsole.log("filterId")
    PapiroConsole.log("chatFilterId")
    PapiroConsole.log(filterId)
    PapiroConsole.log(chatFilterId)

    if (filterId && chatFilterId) {
      filterId = filterId.id
      chatFilterId = chatFilterId.id
    }

    this.setState({ loadingIndicators: true })

    let result = await getChatIndicators(filterId, chatFilterId)
    if (result && result.success) {
      PapiroConsole.log("result endpoint indicadores")
      PapiroConsole.log(result)

      let countNewChats = result.data.countnewchats
      let countChatsResolvedInTheFirstService = result.data.countchatsresolvedinthefirstservice
      let countChatsNotResolvedInTheFirstService = result.data.countchatsnotresolvedinthefirstservice
      let countInAttendanceChats = result.data.countinattendancechats
      let countCanceledChats = result.data.countcanceledchats
      let countFinishChats = result.data.countfinishchats

      this.setState({ loadingIndicators: false, countNewChats: countNewChats, countInAttendanceChats: countInAttendanceChats, countCanceledChats: countCanceledChats, countFinishChats: countFinishChats, countChatsResolvedInTheFirstService: countChatsResolvedInTheFirstService, countChatsNotResolvedInTheFirstService: countChatsNotResolvedInTheFirstService })

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loadingIndicators: false,
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });

    }
  }





  async getEnabledUserById(id) {
    this.setState({ loading: true })
    PapiroConsole.log("id aqui ")
    PapiroConsole.log(id)
    let result = await getEnabledUserById(id)
    if (result && result.success) {



      //caso ele não esteja ativo ou esteja ativo e não tenha status, tomar como offline o status do agente
      this.setState({ loading: false, userStatusId: result.data && result.data.userstatusid ? result.data.userstatusid : 6 })



      //setCount(chatList.length);

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });

    }
  }

  async redirectChat(e, id) {

    const { props } = this.props;

    if (props && props.match) {
      const { lang } = props.match.params

      if (id != null) {
        PapiroConsole.log("entrei no observar")
        props.history.push(`/${lang}/omnichannel-observe/${id}`);

      }

    }

    else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'Ocorreu um erro ao redirecionar para a conversa.'
      });


    }
  }

  async updateChat(e, id, observe = false) {
    e.preventDefault()
    e.stopPropagation();
    this.setState({ loading: true })
    const { props } = this.props;

    PapiroConsole.log("entrei aqui updateChat ")

    let sentData = {
      chatId: id,
      connectionid: this.state.userSession.connectionid
    }

    Api2Talk.put(`/chat/assigneduser`, sentData)
      .then(result => {

        if (result.data.success) {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Atendimento iniciado com sucesso'
          });

          if (props && props.match) {
            const { lang } = props.match.params

            if (observe == false) {
              PapiroConsole.log("primeiro redirecionamento")

              //props.history.push(`/${lang}/omnichannel`);
              props.history.push({
                pathname: `/${lang}/omnichannel`,
                state: {
                  chatId: id
                }
              })
            }
            else {
              if (id != null) {
                PapiroConsole.log("entrei no observar")
                props.history.push(`/${lang}/omnichannel-observe/${id}`);

              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: 'Ocorreu um erro ao redirecionar para a conversa.'
                });

              }


            }

          }


        }
        else {
          const intl = this.props.intl;
          let notificatonMessage = "";
          let refreshDash = false;

          if (result.data && result.data.errors && result.data.errors[0]) {
            notificatonMessage = result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" });
          } else {
            if (result.data?.message) {
              notificatonMessage = result.data?.message;
              refreshDash = true;
            }
          }

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: notificatonMessage
          });

          if (refreshDash) {
            this.componentDidMount(refreshDash);
          }

        }
      })
      .catch(err => {
        PapiroConsole.log(err)
        PapiroConsole.log("err aqui encontrado")
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
        }
      });


  }

  async changeStatus(id) {

    this.setState({ loading: true })
    const { props } = this.props;

    Api2Talk.put(`/enableusers/changestatus/${id}`)
      .then(result => {

        PapiroConsole.log("resultado enable users")
        PapiroConsole.log(result)
        if (result.data.success) {

          let agentList = this.state.enableUsers;

          if (result.data.data && result.data.data.length > 0) {
            agentList = result.data.data;
          }

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Status Alterado com sucesso',
            enableUsers: agentList
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
        }
      });


  }

  async changeValues(stateName, value, text = "") {


    if (stateName == "filterId") {
      let chatFilter = []

      if (value.id == 1) {
        chatFilter = [
          { id: 1, name: 'Todas' },
          { id: 2, name: 'Minhas conversas' },
          { id: 3, name: 'Conversas de outros agentes' },
        ]
      }
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [stateName]: value,
          chatFilterId: { id: 0, name: '' }
        },
        chatFilter: chatFilter
      }));

    }
    else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [stateName]: value,
        }
      }));
    }



  }

  changeIndex(index) {
    PapiroConsole.log(index);
    PapiroConsole.log("index");
    this.setState({ tabSelected: index },
      () => {
        // Esta função será chamada após o estado ter sido atualizado
        this.scrollToBottom();
      }
    )


  }




  async componentDidMount(refreshdashboard = false) {

    PapiroConsole.log("this.props aqui")
    PapiroConsole.log(this.props)

    await this.getChats(null, null, null, refreshdashboard);
    this.getChatIndicators()
    this.getEnabledUsers()
    await this.StatusOptions()
     this.getModalOptions();
    //await this.getEnabledUserById(this.props.userSession && this.props.userSession.user ? this.props.userSession.user.id : null)


  }

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  setLoading = value => this.setState({ loading: value })
  
  
  setTemplateMessageList = list => this.setState({ templateMessageList: list })
  setTemplateMessageListComplete = list => this.setState({ templateMessageList: [...this.state.templateMessageListComplete] })

  setMessage = (open, variant, message) => this.setState({ openNotification: open, notificationVariant: variant, notificationMessage: message })

 




  render() {
    PapiroConsole.log(this.state)
    PapiroConsole.log("this.state")
    PapiroConsole.log(this.props)
    PapiroConsole.log("this.props primeira página ")

    const { classes } = this.props;


    const intl = this.props.intl;

    return (
      <Grid container style={{ paddingRight: '20px', paddingLeft: '20px' }} >
        <Grid container spacing={3}  >
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Card className="background-title-search" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              <CardContent>
                <Grid container spacing={1} justify="space-between" alignItems="center">
                  <Grid item xs={12} sm={12} lg={4}>
                    <Typography component={'span'} variant="h6" color="textSecondary">
                      {<FormattedMessage id="Chats" />}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={8}>
                    <Grid container spacing={1} direction="row" alignItems="center">
                      <Grid item xs={12} sm={12} md={12} lg={4} >
                        <SimpleAutoComplete
                          label="Filtro"
                          stateName="filterId"
                          options={this.state.filterOptions}
                          changeSelect={this.changeValues}
                          selected={this.state.item.filterId}
                        /*
                        stateName="techinicalTeam"
                        changeSelect={changeValues}
                        selected={techinicalTeam}*/
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} >
                        <SimpleAutoComplete
                          label="Complemento"
                          stateName="chatFilterId"
                          options={this.state.chatFilter}
                          changeSelect={this.changeValues}
                          selected={this.state.item.chatFilterId}
                        /*
                        stateName="techinicalTeam"
                        changeSelect={changeValues}
                        selected={techinicalTeam}*/
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          fullWidth
                          variant='contained'
                          color='primary'
                          size="large"
                          onClick={() => this.callingFilters(null, this.state.item.filterId, this.state.item.chatFilterId)}
                        >
                          {intl.formatMessage({ id: "apply" })}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={2}>
                        <Button
                          fullWidth
                          variant='contained'
                          color='secondary'
                          size="large"
                          onClick={() => this.clearFilters()}
                        >
                          {intl.formatMessage({ id: "clear" })}

                        </Button>
                      </Grid>


                    </Grid>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>

          </Grid>
          <Grid item xs={3}  >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={this.handleOpenModal}

            >
              Nova conversa
            </Button>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3} style={{ padding: 20, overflow: 'auto', height: '370px' }} >

              {/* Adicione aqui os indicadores de chat */}
              <ChatIndicators loadingIndicators={this.state.loadingIndicators} countNewChats={this.state.countNewChats} countInAttendanceChats={this.state.countInAttendanceChats} countCanceledChats={this.state.countCanceledChats} countFinishChats={this.state.countFinishChats} countChatsNotResolvedInTheFirstService={this.state.countChatsNotResolvedInTheFirstService} countChatsResolvedInTheFirstService={this.state.countChatsResolvedInTheFirstService} />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3} style={{ padding: 20, overflow: 'auto', height: '370px' }}>

              <AgentList loadingAgents={this.state.loadingAgents} statusOptions={this.state.statusOptions} userStatusId={this.state.userStatusId} enableUsers={this.state.enableUsers} changeStatus={this.changeStatus} />
            </StyledPaper>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ marginTop: '30px' }}>



          <Box display="flex" alignItems="center" flexDirection="row" style={{ marginBottom: '10px' }}>
            <Typography variant="h5" color="primary" >
              Conversas aguardando atendimento
            </Typography>
            {this.state.loadingNewConversation &&

              <CircularProgress size={24} style={{ marginLeft: 13 }} />
            }



          </Box>

          {this.state.chatsWaitingAttendance.length > 0 ? (
            // Se há elementos no array, renderiza os elementos


            <>


              {this.state.chatsWaitingAttendance.map((item) => {
                return <ItemCard
                  chats={item}
                  name="chatsWaitingAttendance"
                  updateChat={this.updateChat}
                  currentUserId={this.state.userId}
                />
              })}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: '#7f8c8d', marginBottom: '10px' }} >
                Não há conversas aguardando atendimento
              </Typography>
            </Grid>

          )}



        </Grid>
        <Grid container spacing={0} style={{ marginTop: '30px' }}>
          <Box>
            <Typography variant="h5" color="primary" style={{ marginBottom: '10px' }}>
              Conversas em andamento
            </Typography>


          </Box>
          <Grid item xs={12} style={{ marginTop: '10px', marginBottom:'60px' }} >
            {this.state.chatsInAttendance.length > 0 ? (
              // Se há elementos no array, renderiza os elementos
              <>

                <Tabs selectedIndex={this.state.tabSelected} onSelect={index => this.changeIndex(index)}>
                  <Grid item xs={12}>
                    <TabList style={{ borderColor: '#BB8FCE' }}>
                      <Tab style={{ fontSize: "14px" }}><SpeakerNotesIcon /> Minhas conversas <Badge sx={{
                        "& .MuiBadge-badge": { color: "white", backgroundColor: "#f47920" }, marginLeft: '10px', marginRight: '5px', marginBottom: '8px'
                      }} badgeContent={this.state.countMyChatsInAttendance} ></Badge></Tab>

                      <Tab style={{ fontSize: "14px" }}> <ThreePIcon /> Outras conversas <Badge sx={{
                        "& .MuiBadge-badge": { color: "white", backgroundColor: "#f47920" }, marginLeft: '10px', marginRight: '5px', marginBottom: '8px'
                      }} badgeContent={this.state.countOtherChatsInAttendance} ></Badge></Tab>
                    </TabList>
                  </Grid>

                  <TabPanel>

                    {/*<TechConversations chatsInAttendance={this.state.chatsInAttendance} updateChat={this.updateChat} currentUserId={this.state.userId} />*/}
                    {this.state.myChatsInAttendance && this.state.myChatsInAttendance.length > 0 ? this.state.myChatsInAttendance.map((item) => {
                      return <ItemCard
                        chats={item}
                        name="myChatsInAttendance"
                        updateChat={this.updateChat}
                        currentUserId={this.state.userId}
                      />

                    }) : <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: '#7f8c8d', marginBottom: '10px' }} >
                        Não há conversas a serem exibidas
                      </Typography>
                    </Grid>

                    }

                  </TabPanel>

                  <TabPanel>
                    {/*<TechConversations chatsInAttendance={this.state.chatsInAttendance} updateChat={this.updateChat} currentUserId={this.state.userId} />*/}
                    {this.state.otherChatsInAttendance && this.state.otherChatsInAttendance.length > 0 ? this.state.otherChatsInAttendance.map((item) => {
                      return <ItemCard
                        chats={item}
                        name="otherChatsInAttendance"
                        redirectChat={this.redirectChat}
                        currentUserId={this.state.userId}
                      />

                    }) : <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: '#7f8c8d', marginBottom: '10px' }} >
                        Não há conversas a serem exibidas
                      </Typography>
                    </Grid>

                    }

                  </TabPanel>
                </Tabs>

                {/*<MyPaper square>
                    <MyTabs
                      value={this.state.tabSelected}
                      onChange={this.handleChange}
                      indicatorColor="secondary"
                      textColor="inherit"
                      centered
                    >
                      <Tab label="Minhas conversas" />
                      <Tab label="Outras conversas" />
                    </MyTabs>
                  </MyPaper>
             
               
                  {this.state.tabSelected === 0 && <MyConversations chatsInAttendance={this.state.chatsInAttendance} updateChat={this.updateChat} currentUserId={this.state.userId} />}
                  {this.state.tabSelected === 1 && <MyConversations chatsInAttendance={this.state.chatsInAttendance} updateChat={this.updateChat} currentUserId={this.state.userId} />}
            */}




                {/*this.state.chatsInAttendance.map((item) => {
                return <ItemCard
                  chats={item}
                  name="chatsInAttendance"
                  updateChat={this.updateChat}
                  currentUserId={this.state.userId}
                />
              })*/}
              </>
            ) : (
              <Grid item xs={12} >
                <Typography variant="body1" style={{ color: '#7f8c8d', marginBottom: '10px' }} >
                  Não há conversas em andamento
                </Typography>
              </Grid>
            )}
          </Grid>





        </Grid>

        <CustomModal
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
          messageProvidersList={this.state.messageProvidersList}
          registrationNumberRequestList={this.state.registrationNumberRequestList}
          templateMessageList = {this.state.templateMessageList}
          onSubmit={this.handleSubmitModal}
          intl={this.props.intl}
          setLoading={this.setLoading}
          setTemplateMessageList={this.setTemplateMessageList}
          setTemplateMessageListComplete = {this.setTemplateMessageListComplete}
          setMessage={this.setMessage} 
          match = {this.props.props.match}
          history ={this.props.props.history}
        />

        {/*<Grid container style={{ marginBottom: 12, marginTop: 8 }}>
          <Grid item xs={12} md={4}>
            <Pagination
              count={this.state.totalPages}
              page={this.state.page}
              onChange={this.onChangePaginate}
              showFirstButton
              showLastButton />
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
            {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
          </Grid>
        </Grid>*/}

        {
          this.state.loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )
        }
        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />

      </Grid >
    )
  }
}

ToTalkChatAttendance.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ToTalkChatAttendance)));
ToTalkChatAttendance.contextType = AbilityContext;