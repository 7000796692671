import React, { Component, Fragment } from "react";
import clsx from 'clsx'
import PropTypes from "prop-types";
import { Button, CssBaseline, TextField, Grid, CircularProgress, Container, Radio, RadioGroup, FormControl, FormControlLabel, Tabs, CardHeader, CardActions, Collapse, TableContainer, TableBody, Table, TableCell, TableRow, InputLabel, Fab, Popper, Grow, ClickAwayListener, MenuList } from "@mui/material";
import { Menu, MenuItem, Dialog, FormGroup, Checkbox } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import { withStyles, useTheme } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";

import * as CalledService from '../../services/called.service';
import * as AccompanimentService from '../../services/accompaniment.service';
import CustomizedSnackbars from "../../components/material-snackbars";
import SimpleAutoCompleteAsynchronousKnowledgebase from '../called-registration/components/auto-complete-async-knowledgebase';
import * as AddressService from '../../services/address.service'
import SimpleSelect from "../../components/select/simple";
import SimpleDatePicker from "../../components/date-pickers/inline";
import { DeleteForever, AttachFile, ArrowDropDown, AccountBalance } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClearIcon from '@mui/icons-material/Clear';
import AccompanimentAction from '../../components/accompaniment-actions';
import AccompanimentActionVideo from '../../components/accompaniment-actions/video';
import * as calledsActions from '../../redux/actions/calleds';
import * as headerMenuActions from '../../redux/actions/header-menu';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as lastCalledsQueueActions from '../../redux/actions/last-calleds-queue'
import * as richTextAreaActions from '../../redux/actions/richtextarea'
import ImageList from '@mui/material/ImageList';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import Add from '@mui/icons-material/Add';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteInt from '../../components/auto-complete/autocomplete-int';
import SimpleAutoCompleteOpenManual from '../../components/auto-complete/autocomplete-open-manual';
import SimpleAutoCompleteByName from '../../components/auto-complete/autocomplete-by-name';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-api';
import SimpleAutoCompleteAsynchronousOrganizationCategory from './components/autocomplete-asynchronous-organization-category'
import ReactHtmlParser from 'react-html-parser';
import SimpleAutoCompleteAsynchronousTechnicalUsers from './components/autocomplete-asynchronous-technical-users'
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import TransitionsModal from '../../components/modal/modal';
import CalledFinish from '../../calledFinish/src/js/CalledFinish';
import DuoIcon from '@mui/icons-material/Duo';
import moment from 'moment';
import { getByOrganizationIdDapper } from '../../services/organizationsetting.service'
import * as Service from "../../services/organizationpatrimony.service";
import * as KnowledgeBaseCalledService from "../../services/knowledgebasecalled.service";
import * as KnowledgeBaseService from '../../services/knowledgebase.service'
import { getAllUsersSelecionModel, getDepartamentByUserId, getAllRequestingUsersSelectionModelByOrganizationId, getAllRequestingUsersSelectionModelByOrganizationIdAndLocationId } from '../../services/user.service'
import { getDepartamentsByOrganizationCategoryId } from '../../services/organizationcategory.service';
import { getOrganizationDepartamentsById, getOrganizationsDepartaments } from '../../services/organizationdepartament.service';
import * as CalledOrganizationChecklistService from '../../services/calledorganizationchecklist.service'
import { addAccompanimentToKnowledgeBase, deleteKnowledgeBaseByAccompanimentId } from "../../services/knowledgebase.service";
import { getByCalledIdAndHistoryCheckType, getByAccompanimentIdAndHistoryCheckType, getAccompanimentsToFinish } from '../../services/called.service'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import MiniMap from '../../components/minimap/external-state';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ModalPatrimonio from "../../components/ModalPatrimonio";
import ModalVideoCall from "../../components/modal-video-call";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SimpleHelp from '../../components/help';
import { IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import InventoryIcon from '@mui/icons-material/Inventory';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PauseIcon from '@mui/icons-material/Pause';
import BusinessIcon from '@mui/icons-material/Business';
import SaveIcon from '@mui/icons-material/Save';
import { PapiroConsole } from '../../utils/papiroConsole';
import If, { Else } from '../../components/conditionals/if'
import NumberFormat from "react-number-format"
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SimpleCheckBox from "../../components/checkbox/check";
import { Cell, Pie, PieChart } from "recharts";
import { Tooltip as PieToolTip } from "recharts";
import { styled } from "@mui/styles";
import Budget from "../dashboard/components/budget";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import './style.css'
import { Can } from '../../config/ability-context';
import DialogConfirmation from '../../components/dialog-confirmation-not-button'
import DialogUnarchivedCalled from '../../components/dialog-confirmation-unarchived-called'
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import * as PriorityService from '../../services/priority.service'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import InfoIcon from '@mui/icons-material/Info';
import SimpleSelectWithColor from "../../components/select/simple-with-color";
import { withRouter } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ChecklistModal from "../../components/checklist-modal";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import RichTextArea from "../../components/rich-text-area/rich-text-area";
import TreeDots from '../../components/tree-dots';
import GroupIcon from '@mui/icons-material/Group';
import * as CalledRequestingUserService from '../../services/calledrequestuser.service'
import SimpleAutoCompleteAsynchronousKnowledgebaseByOrganization from '../../components/auto-complete/autocomplete-asynchronous-knowledgebase-by-organization';
import { ColorfyLinearProgress } from '../called-list/components/itemcard/linear-progress'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import * as FileService from '../../services/attachment.service';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuIcon from '@mui/icons-material/Menu';
import { LastCalledsQueueContext } from "../../contexts/lastCalledsQueueContext";
//import { useChatHubContext, ChatHubContext } from "../../contexts/chatHubContext";
import Link from '@mui/material/Link';





const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));







function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`finish-accompaniment-tabs-${index}`}
            aria-labelledby={`finish-accompaniment-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box variant="fullWidth" p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `finish-accompaniment-tabs-${index}`,
        'aria-controls': `finish-accompaniment-tabpanel-${index}`,
    };
}

class CalledRegistration extends Component {



    constructor(props) {
        super(props);
        const { id } = this.props.match.params
        const { userSession, calleds, intl } = this.props;




        PapiroConsole.log(calleds)
        PapiroConsole.log("calleds iniciados aqui")





        this.quill = React.createRef();

        this.urlId = id

        PapiroConsole.log("=== CalledRegistration -> constructor ===")
        PapiroConsole.log(this.props.match)
        PapiroConsole.log(calleds)

        PapiroConsole.log(id)
        PapiroConsole.log(userSession)
        PapiroConsole.log("userSession aqui")

        this.phone = "";
        this.canstartcallcenter = false;
        this.globalAdm = false;
        this.AdmOrganization = false;
        this.isCoordenator = false;
        this.isTechinical = false;
        this.isClient = true;
        this.OrganizationCategoryId = null;
        this.currentTeamOfCalled = null;
        this.currentStatusOfCalled = null;
        this.currentAssignedOfCalled = null;

        this.valueCostAttendance = null
        this.valueTimeAttendance = null

        if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }
        if (!calleds || (calleds && !calleds[`called-${this.urlId}`])) {
            var l = this.props.match.params.lang;
            //document.location.href=`/${l}/calleds`
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
            if (item.role.roletype == 1) {
                this.AdmOrganization = true;
            }
            if (item.role.roletype == 4) {
                this.isCoordenator = true;
            }
            if (item.role.roletype == 2) {
                this.isTechinical = true;
            }
        });

        this.sametechinical = false;
        this.samePerson = false;
        this.canselectpatrimonyincalled = false;
        this.canselectdepartamentincalled = false
        this.canselectparentcalledincalled = false
        this.canselectdisplacementincalled = false
        this.canselecthourcostorcalledcostincalled = false
        this.canclientselectpatrimonyincalled = false;
        this.canclientselecttypeincalled = false;
        this.caninformzeroincalledtechnicalcost = false;
        this.caninforminitialchecklist = false;
        this.allowchangeoforganizationofcalledbyadministration = false;
        this.redirecttheuseraftercompletingthecalled = false;
        this.caninitialchecklistisrequired = false;
        this.canfinalchecklistisrequired = false;
        this.caninformutilizedmaterialsincalledfinish = false;
        this.isrequiredselectteamincalledfinish = false
        this.canclientfinishcalled = false
        this.canclientreopencalled = false
        this.canclientautomaticchangestatusaftersendaccompaniment = false
        this.infoRequest = [];
        this.otherClientInteractsWithCalled = false;
        this.formRef = React.createRef();

        var dataNPS = null;
        var msgNPS = null;

        const defaultvalue = { id: 0, name: "", parentname: "" };

        this.reactQuillModules = {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ],
        }

        this.reactQuillFormats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ]

        this.levelCategoriesInitialState = {
            rootCategoriesList: [],
        }

        this.calledGridData = calleds[`called-${this.urlId}`]
        

        this.orgCatPriority = calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.priorityid && calleds[`called-${this.urlId}`].organizationcategory.priorityid > 0 ? calleds[`called-${this.urlId}`].organizationcategory.priorityid : 0
        this.calledId = calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].id ? calleds[`called-${this.urlId}`].id : 0;
        this.addressInitialState = {
            updatedUser: userSession.user.id,
            updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            UserText: '',
            template: true,
            template2: false,
            id: 0,
            name: '',
            street: '',
            zipcode: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
            longitude: '',
            latitude: '',
            keepaddress: false,
            iscustomaddress: true,
            saveToAllOrganizationsInGroup: false,
        };

        this.initialKnowledgeBaseItem = {
            id: 0,
            title: '',
            description: '',
            knowledgebasepatrimonies: [],
            knowledgebaseattachments: [],
            knowledgebasekeywords: [],
        }

        this.state = {
            asynchronousClosingCalled : false,
            actionsMenuAnchorEl: null,
            campospersonalizados: "",
            openAutoCompleteCategory: false,
            arrayOptionsValues: [],
            selected: true,
            arrayFieldText: [],
            calledOrganizationFieldsList: [],
            clientClickFinishCalled: false,
            redirectWithNoAccess: false,
            addActionAnchorEl: null,
            loadingRequestingUsers: false,
            addActionOpen: false,
            address: this.addressInitialState,
            levelCategoriesReverse: this.levelCategoriesInitialState,
            categoryChildrensReverse: [defaultvalue],
            categoryListsReverse: [],
            levelCategories: this.levelCategoriesInitialState,
            categoryChildrens: [defaultvalue],
            categoryLists: [],
            search: {
                technicalUsersList: `/users/category/${calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategoryid ? calleds[`called-${this.urlId}`].organizationcategoryid : 0}/organization/${calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationid ? calleds[`called-${this.urlId}`].organizationid : 0}`,
                categoriesList: `/organizationcategories/organization/${calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationid ? calleds[`called-${this.urlId}`].organizationid : 0}`,
                knowledgebase: '/knowledgebases'
            },
            isDisabled: true,
            countLoading: 0,
            userId: userSession.user.id,
            organizationFieldList: [],
            MapCalledsToKnowledgeBase: null,
            savedReason: false,
            priority: null,
            sectableStatus: [],
            called: {
                id: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].id ? calleds[`called-${this.urlId}`].id : 0,
                CreatedUser: userSession.user.id,
                techinicalteam: null,
                requestingUser: 0,
                organization: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.id ? calleds[`called-${this.urlId}`].organization.id : 0,
                organizationkey: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.organizationkey ? calleds[`called-${this.urlId}`].organization.organizationkey : "",
                orgname: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.name ? calleds[`called-${this.urlId}`].organization.name : "",
                organizationsettings: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.organizationsettings ? calleds[`called-${this.urlId}`].organization.organizationsettings : {
                    id: 0,
                    canviewcalledfromothertechnical: false,
                    canclientselecttypeincalled: false,
                    roleclientcanselectcategory: 0,

                },
                archived: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].archived != null ? calleds[`called-${this.urlId}`].archived : false,
                type: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].type && calleds[`called-${this.urlId}`].type.id ? calleds[`called-${this.urlId}`].type.id : 0,
                OrganizationCategoryId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory ? calleds[`called-${this.urlId}`].organizationcategory : null,
                ParentCalledId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].parentcalledid ? calleds[`called-${this.urlId}`].parentcalledid : 0,
                ParentCalledText: '',
                OrganizationCategoryText: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.name ? calleds[`called-${this.urlId}`].organizationcategory.name : "",
                parentCategory: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory.parentorganizationcategory && calleds[`called-${this.urlId}`].organizationcategory.parentorganizationcategory.id ? calleds[`called-${this.urlId}`].organizationcategory.parentorganizationcategory.id : 0,
                dueDate: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].duedate ? calleds[`called-${this.urlId}`].duedate : '',
                openingDate: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].openingdate ? calleds[`called-${this.urlId}`].openingdate : '',
                solutionDate: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].solutiondate ? calleds[`called-${this.urlId}`].solutiondate : '',
                serviceStartDate: null,
                title: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].title ? calleds[`called-${this.urlId}`].title : '',
                addressid: 0,
                addresstype: "1",
                status: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].status && calleds[`called-${this.urlId}`].status.id ? calleds[`called-${this.urlId}`].status.id : 0,
                description: '',
                priorityid: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].priorityid && calleds[`called-${this.urlId}`].priorityid > 0 ? calleds[`called-${this.urlId}`].priorityid : this.orgCatPriority,
                priority: null,
                CallDueDate: null,
                originRequest: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].originrequest && calleds[`called-${this.urlId}`].originrequest.id ? calleds[`called-${this.urlId}`].originrequest.id : 0,
                originRequestName: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].originrequest && calleds[`called-${this.urlId}`].originrequest.name ? calleds[`called-${this.urlId}`].originrequest.name : '',
                providerName : '',
                observantUser: null,
                observantUserId: 0,
                assignedUser: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].assigneduser ? calleds[`called-${this.urlId}`].assigneduser : null,
                updatedUser: userSession.user.id ? userSession.user.id : 0,
                quantityreama4: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].quantityreama4 ? calleds[`called-${this.urlId}`].quantityreama4 : 0,
                securitypassword: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].securitypassword ? calleds[`called-${this.urlId}`].securitypassword : '',
                patrimonyId: "",
                classification: dataNPS,
                departamentIdN: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationdepartamentid ? calleds[`called-${this.urlId}`].organizationdepartamentid : 0,
                departamentId: 0,
                employeehourvalue: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].employeehourvalue && calleds[`called-${this.urlId}`].employeehourvalue > 0) ? calleds[`called-${this.urlId}`].employeehourvalue : (calleds.defaulttechnicalemployeehourvalue && calleds.defaulttechnicalemployeehourvalue > 0) ? calleds.defaulttechnicalemployeehourvalue : 0,
                valueforcalled: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].valueforcalled && calleds[`called-${this.urlId}`].valueforcalled > 0) ? calleds[`called-${this.urlId}`].valueforcalled : (calleds.defaulttechnicalvalueforcalled && calleds.defaulttechnicalvalueforcalled > 0) ? calleds.defaulttechnicalvalueforcalled : 0,
                costparameter: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].costparameter ? calleds[`called-${this.urlId}`].costparameter : 1,
                hasdisplacement: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].hasdisplacement != null ? calleds[`called-${this.urlId}`].hasdisplacement : false,
                kmvaluerotated: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].kmvaluerotated && calleds[`called-${this.urlId}`].kmvaluerotated > 0) ? calleds[`called-${this.urlId}`].kmvaluerotated : (calleds.defaulttechnicalkmvaluerotated && calleds.defaulttechnicalkmvaluerotated > 0) ? calleds.defaulttechnicalkmvaluerotated : (calleds.configdisplacement && calleds.configdisplacement.kmvaluerotated > 0) ? calleds.configdisplacement.kmvaluerotated : 0,
                finishaccompanimentid: calleds.finishaccompanimentid ? calleds.finishaccompanimentid > 0 ? calleds.finishaccompanimentid : null : null,
                previousfinishaccompanimentid: calleds.finishaccompanimentid ? calleds.finishaccompanimentid > 0 ? calleds.finishaccompanimentid : null : null,
                hasdisplacementTrue: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].hasdisplacement == true ? true : false,
                hasdisplacementFalse: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].hasdisplacement == false ? true : false,
                hasParentCalledTrue: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].parentcalledid != null ? true : false,
                hasParentCalledFalse: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].parentcalledid == null ? true : false,
                hasPatrimoniesTrue: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].calledorganizationpatrimonies && calleds[`called-${this.urlId}`].calledorganizationpatrimonies.length > 0 ? true : false,
                hasPatrimoniesFalse: (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].calledorganizationpatrimonies == null) || (calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].calledorganizationpatrimonies && calleds[`called-${this.urlId}`].calledorganizationpatrimonies.length == 0) ? true : false,
                calledTechinicalTeamId: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].techinicalteam ? calleds[`called-${this.urlId}`].techinicalteam.id : null,
                calledstatus: [],
                calledattachments: [],
                knowledgebaseattachments: [],
                calledclosingstatusname: null,
                campoTexto: null,
                createdusername: '',
                createduseremail: '',
                requestingusername: '',
                requestinguserids: 0,
                requestinguseremail: '',
                requestinguserdocument: '',
                quickCalledCreated: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].quickcalledcreated ? true : false,
            },
            action: {
                description: "",
                privacy: ""
            },
            calledcost: null,
            cost: {
                values: {
                    totalValueCostInLoco: 0.0,
                    totalValueCostRemote: 0.0,
                    totalValueCostDisplacement: 0.0,
                    totalValueCostTimeEntries: 0.0,
                    totalValueReplacedMaterials: 0.0,
                    totalValueCost: 0.0,
                },
                hours: {
                    totalHoursDisplacement: 10.0,
                    totalHoursInLoco: 30.0,
                    totalHoursRemote: 10.0,
                },
                totalCalledUsers: 0,
                totalTimeEntries: 0,
                totalAccompaniments: 0,
                totalAttachments: 0,
                totalMB: 0.0,
                totalKilometersDisplacement: 0.0,
                countReplacedMaterials: 0
            },
            costBody: {
                attendanceHours: null,
                attendanceRemote: null,
                attendanceCost: null,
                attendanceAdditionalInfo: null,
                attendanceHoursExpanded: false
            },
            boxDisplay: true,
            files: [],
            loading: true,
            dataLoading: true,
            loadingTwo: false, //Criei pois o loading acima não está mudando o state
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            categoriesList: [],
            parentCalledsList: [],
            categoriesListAll: [],
            technicalUsersList: [],
            techinicalTeamList: [],
            clientUsersList: [],
            userAddressesList: [],
            typesList: [],
            organizationAddressesList: [],
            calledValue: 'R$ 0,00',
            calledTime: '00:00:00',
            statusList: [],
            anchorEl: null,
            FinishCalledId: 0,
            ReopenCalledId: 0,
            samePerson: this.samePerson,
            reloadBtn: true,
            disablebutton: false,
            cloneCalledModalOpen: false,
            CalledModalOpen: false,
            unarchiveModalOpen: false,
            locationModalOpen: false,
            positionCalled: [-8.06224030, -34.87254230],
            addressCalled: '',
            accompanimentLocationModalOpen: false,
            positionAccompCalled: [-8.06224030, -34.87254230],
            addressAccompCalled: '',
            cloneCalledAmount: null,
            calledorganizationmaterial: false,
            calledusers: false,
            calledorganizationFields: false,
            calledtimeentries: false,
            calledadditionalservices: false,
            initialcalledorganizationchecklists: false,
            finalcalledorganizationchecklists: false,
            calledorganizationpatrimonies: false,
            modalopen: false,
            user: userSession.user,
            modalopenFinish: false,
            patrimonyList: [],
            statusisFinish: false,
            modalPatrimonio: false,
            msgClassification: msgNPS,
            requestingUserFromBarraNew: null,
            requestingUser: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].requestinguser ? calleds[`called-${this.urlId}`].requestinguser : null,
            orgSettings: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization.organizationsettings ? calleds[`called-${this.urlId}`].organization.organizationsettings : null,
            organization: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization ? calleds[`called-${this.urlId}`].organization : null,
            organizationkey: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organization && calleds[`called-${this.urlId}`].organization.organizationkey ? calleds[`called-${this.urlId}`].organization.organizationkey : '',
       
            disabledvertex: true,
            hirerid: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`] ? calleds[`called-${this.urlId}`].hirerid : 0,
            slaOverflowContentModalOpen: false,
            reasonsList: [],
            calledReasonId: null,
            reasonOther: '',
            userDepartamentId: 0,
            DepartamentList: [],
            departamentId: 0,
            finishAccompaniment: false,
            finishAccompanimentModalOpen: false,
            defaultActionModalOpen: false,
            defaultActionList: [],
            defaultActionId: 0,
            finishAccompanimentList: [],
            finishAccompanimentId: 0,
            finishAccompanimentValue: 0,
            directSaveCalled: false,
            rTabIndex: 0,
            directFinishCalled: false,
            todayDate: moment().format('YYYY/MM/DD'),
            startCalledAttendance: false,
            openDialogNewDisk: false,
            valueNewDisk: 0.00,
            newDiskAccept: false,
            priorityList: [],
            typeDisabled: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.typeid && calleds[`called-${this.urlId}`].organizationcategory.typeid > 0 ? true : false,
            priorityDisabled: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationcategory && calleds[`called-${this.urlId}`].organizationcategory.priorityid && calleds[`called-${this.urlId}`].organizationcategory.priorityid > 0 ? true : false,
            caneditcalledtitleanddescription: true,
            caneditaccompanimentinactivecalled: false,
            canselectorganizationcategoriesbylevels: false,
            caninforminitialchecklist: false,
            caninitialchecklistisrequired: false,
            canfinalchecklistisrequired: false,
            allowchangeoforganizationofcalledbyadministration: false,
            redirecttheuseraftercompletingthecalled: false,
            requireattachedimage: false,
            actionrequiredwhenstatusorteamorassignedchanges: false,
            calledorganizationid: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].organizationid ? calleds[`called-${this.urlId}`].organizationid : 0,
            knowledgebase: null,
            displayassignedinfomode: 0,
            allowclientinteractiononclosedcalleds: false,
            knowledgeBaseList: [],
            knowledgeBaseDialogOpen: false,
            initialChecklistModalOpen: false,
            confirmReopenCalledClientDialog: false,
            totalInitialChecklists: 0,
            totalCompletedInitialChecklists: 0,
            clientStatus: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].status ? calleds[`called-${this.urlId}`].status : null,
            oldStatus: calleds[`called-${this.urlId}`] && calleds[`called-${this.urlId}`].status ? calleds[`called-${this.urlId}`].status : null,
            handleChangeRequestingUserDialogOpen: false,
            itemsTree: [
                <Button
                    color="secondary"
                    size="large"
                    style={{ marginBottom: "5px" }}
                    onClick={this.openHandleChangeRequestingUserDialog}
                    startIcon={<GroupIcon />}>
                    {intl.formatMessage({ id: "change.request.user" })}
                </Button>
            ],
            requestingUserChange: 0,
            handleRequestingUserReason: '',
            requestingUserChangeList: [],
            knowledgeBaseDetailsDialogOpen: false,
            knowledgeBaseItem: this.initialKnowledgeBaseItem,
            saveCalledDialogOpen: false,
            saveCalledDialogCheck: false,
            saveBasicInformationsCalledDialogCheck: true,
            saveMenuAnchorEl: null,
            calledStatusTypeId: null,
            createUserCalled: {
                nameOfNewUser: null,
                cpfOfNewUser: null,
                emailOfNewUser: null,
                confirmEmailOfNewUser: null
            },
            itemError: {
                document: '',
            },
            newUserBaseDialogOpen: false,
            associateOrganizationOptions: 'associateCalledOrganization',
            organizationsList: [],
            showQuickCalled: userSession.user.showquickcalled,
            optionsBarHeight: null,
            ClosingCalledModalDataForm : {},
            ClosingCalledModalDataForm2 : {}
        };

        //this.theme = useTheme()
        this.handleClickActionsMenu = this.handleClickActionsMenu.bind(this)
        this.handleCloseActionsMenu = this.handleCloseActionsMenu.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCalled = this.getCalled.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.getAccompainmentFile = this.getAccompainmentFile.bind(this);
        this.useStyles = this.useStyles.bind(this);
        this.videoCallClick = this.videoCallClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getOrganizationAddresses = this.getOrganizationAddresses.bind(this)
        this.reopenCalledClient = this.reopenCalledClient.bind(this);
        this.finishCalledClient = this.finishCalledClient.bind(this);
        this.checkFinishCalled = this.checkFinishCalled.bind(this);
        this.getOrganizationFieldsNew = this.getOrganizationFieldsNew.bind(this);
        this.openSaveCalledDialog = this.openSaveCalledDialog.bind(this)
        this.closeSaveCalledDialog = this.closeSaveCalledDialog.bind(this)
        this.handleChangeSaveCalledDialogCheck = this.handleChangeSaveCalledDialogCheck.bind(this)
        this.handleSaveCalledDialogCheck = this.handleSaveCalledDialogCheck.bind(this)
        this.handleChangeRadio = this.handleChangeRadio.bind(this)
        this.changeValuesCheckbox = this.changeValuesCheckbox.bind(this);
        this.openModal = this.openModal.bind(this);
        this.pauseCalled = this.pauseCalled.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModalFinish = this.closeModalFinish.bind(this);
        this.cancellCalled = this.cancellCalled.bind(this);
        this.unarchivedCalled = this.unarchivedCalled.bind(this);
        this.cloneCalled = this.cloneCalled.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkRoleToDisable = this.checkRoleToDisable.bind(this)
        this.closeCloneCalledModal = this.closeCloneCalledModal.bind(this);
        this.closeCalledModal = this.closeCalledModal.bind(this);
        this.closeUnarchiveModal = this.closeUnarchiveModal.bind(this);
        this.cleanCache = this.cleanCache.bind(this);
        this.handleClickSaveMenu = this.handleClickSaveMenu.bind(this)
        this.handleCloseSaveMenu = this.handleCloseSaveMenu.bind(this)
        this.refreshAccompaniments = this.refreshAccompaniments.bind(this);
        this.submitAction = this.submitAction.bind(this);
        this.getPatrimonySubgroups = this.getPatrimonySubgroups.bind(this);
        this.addAccompanimentActionToKnowledgeBase = this.addAccompanimentActionToKnowledgeBase.bind(this);
        this.submitCalled = this.submitCalled.bind(this);
        this.getCategoryFields = this.getCategoryFields.bind(this);

        this.saveCalled = this.saveCalled.bind(this);
        this.finishCalled = this.finishCalled.bind(this)
        this.openLocationModal = this.openLocationModal.bind(this)
        this.closeLocationModal = this.closeLocationModal.bind(this)
        this.openAccompanimentLocationModal = this.openAccompanimentLocationModal.bind(this)
        this.closeAccompanimentLocationModal = this.closeAccompanimentLocationModal.bind(this)
        this.openFinishAccompanimentModal = this.openFinishAccompanimentModal.bind(this)
        this.closeFinisAccompanimentModal = this.closeFinisAccompanimentModal.bind(this)
        this.setCountLoading = this.setCountLoading.bind(this)
        this.openPatrimonioModal = this.openPatrimonioModal.bind(this)
        this.LoadUI = this.LoadUI.bind(this)
        this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
        this.showCalledReport = this.showCalledReport.bind(this)
        this.showCostReport = this.showCostReport.bind(this)
        this.showHoursReport = this.showHoursReport.bind(this)
        this.showInLocoReport = this.showInLocoReport.bind(this)
        this.handleExpandAttendanceHoursCard = this.handleExpandAttendanceHoursCard.bind(this)
        this.getCalledCostToCard = this.getCalledCostToCard.bind(this)
        this.LoadBarraNew = this.LoadBarraNew.bind(this)
        this.LoadBarraCalledCost = this.LoadBarraCalledCost.bind(this)
        this.handleRefreshCalledCostAndSLA = this.handleRefreshCalledCostAndSLA.bind(this)

        this.detailCardsCost = this.detailCardsCost.bind(this)
        this.openDefaultActionDialog = this.openDefaultActionDialog.bind(this)
        this.closeDefaultActionDialog = this.closeDefaultActionDialog.bind(this)
        this.saveDefaultAction = this.saveDefaultAction.bind(this)

        this.getDefaultActions = this.getDefaultActions.bind(this)
        this.getPriorities = this.getPriorities.bind(this)
        this.handleChangeEditor = this.handleChangeEditor.bind(this)
        this.handleChangeEditorAction = this.handleChangeEditorAction.bind(this)
        this.openKnowledgeBaseDialog = this.openKnowledgeBaseDialog.bind(this)
        this.closeKnowledgeBaseDialog = this.closeKnowledgeBaseDialog.bind(this)
        this.convertHtmlOption = this.convertHtmlOption.bind(this)
        this.changeValuesKnowledgebase = this.changeValuesKnowledgebase.bind(this)
        this.insertKnowledgebase = this.insertKnowledgebase.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.setClosingCalledModalDataForm = this.setClosingCalledModalDataForm.bind(this)
        
        this.getClientsByOrganizationId = this.getClientsByOrganizationId.bind(this)
        this.getUserAddresses = this.getUserAddresses.bind(this)
        this.getCep = this.getCep.bind(this)
        this.handleInputChangeAddress = this.handleInputChangeAddress.bind(this)

        this.handleDynamicInputChange = this.handleDynamicInputChange.bind(this)
        this.setCheckConfig = this.setCheckConfig.bind(this)
        this.addActionHandleClick = this.addActionHandleClick.bind(this)
        this.addActionHandleClose = this.addActionHandleClose.bind(this)
        this.getCalledGridById = this.getCalledGridById.bind(this)
        this.convertCalled = this.convertCalled.bind(this)
        this.openConfirmReopenCalledClientDialog = this.openConfirmReopenCalledClientDialog.bind(this)
        this.closeConfirmReopenCalledClientDialog = this.closeConfirmReopenCalledClientDialog.bind(this)
        this.confirmReopenCalledClient = this.confirmReopenCalledClient.bind(this)
        this.getTechnicalUsersByTechnicalTeam = this.getTechnicalUsersByTechnicalTeam.bind(this)
        this.redirectToChangeCalledOrganization = this.redirectToChangeCalledOrganization.bind(this)
        this.openHandleChangeRequestingUserDialog = this.openHandleChangeRequestingUserDialog.bind(this)
        this.closeHandleChangeRequestingUserDialog = this.closeHandleChangeRequestingUserDialog.bind(this)
        this.confirmHandleChangeRequestingUserDialog = this.confirmHandleChangeRequestingUserDialog.bind(this)
        this.validateHandleChangeRequestingUser = this.validateHandleChangeRequestingUser.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.setMessage = this.setMessage.bind(this)
        this.validationsOfFlag = this.validationsOfFlag.bind(this)
        this.closeNewUserBaseDialog = this.closeNewUserBaseDialog.bind(this)
        this.openNewUserBaseDialog = this.openNewUserBaseDialog.bind(this)
        this.handleInputChangeNewUser = this.handleInputChangeNewUser.bind(this);
        this.handleChangeRadioDialog = this.handleChangeRadioDialog.bind(this)
        this.actionRadio = this.actionRadio.bind(this)
    }

    handleClickSaveMenu = (e) => this.setState({ saveMenuAnchorEl: e.currentTarget })

    handleCloseSaveMenu = _ => this.setState({ saveMenuAnchorEl: null })

    setLoading = (isLoading) => this.setState({ loading: isLoading })
    setMessage = (open, variant, message) => this.setState({ openNotification: open, notificationVariant: variant, notificationMessage: message })

    openSaveCalledDialog = _ => this.setState({ saveCalledDialogOpen: true })

    closeSaveCalledDialog = _ => this.setState({
        saveCalledDialogOpen: false,
        saveCalledDialogCheck: true,
        saveBasicInformationsCalledDialogCheck: false
    })




    handleChangeSaveCalledDialogCheck = (event, complete = true) => {
        if (complete)
            this.setState({ saveCalledDialogCheck: true, saveBasicInformationsCalledDialogCheck: false }, () => this.saveCalled(event))
        else
            this.setState({ saveBasicInformationsCalledDialogCheck: true, saveCalledDialogCheck: false }, () => this.saveCalled(event))
    }

    handleSaveCalledDialogCheck = async e => {
        await this.saveCalled(e)
    }

    redirectToChangeCalledOrganization = _ => {
        this.handleCloseActionsMenu()
        const { id, lang } = this.props.match.params
        this.props.history.push(`/${lang}/called-edit-organization/${id}`)
    }

    openHandleChangeRequestingUserDialog = async () => {
        this.handleCloseActionsMenu()
        this.setState({
            handleChangeRequestingUserDialogOpen: true,
        })

        /*
        this.setState({ loading: true })
        // Buscar todos os usuários (apenas cliente, só se pertencer a organização/localização)
        PapiroConsole.log("=== openHandleChangeRequestingUserDialog ===")
        PapiroConsole.log("requestingUserId 1")
        PapiroConsole.log(this.state.called.requestinguserids)
        
        
        let result = this.state.called.addressid > 0 ? await getAllRequestingUsersSelectionModelByOrganizationIdAndLocationId(this.state.called.organization, this.state.called.addressid, this.state.called.requestinguserids) : await getAllRequestingUsersSelectionModelByOrganizationId( this.state.called.organization,this.state.called.requestinguserids);
        if (result && result.success) {
            if (result.data != null) {
                let formmatedList = result.data.map(item => {
                    return {
                        ...item,
                        name: `${item.name} (${item.email})`
                    }
                });
                this.setState({
                    handleChangeRequestingUserDialogOpen: true,
                    requestingUserChangeList: formmatedList
                 })
            }
            this.setState({ loading: false })
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
        */
    }

    closeHandleChangeRequestingUserDialog = () => this.setState({ handleChangeRequestingUserDialogOpen: false, requestingUserChange: 0, handleRequestingUserReason: '' })
    AbrirAnexoChat(id) {
        alert('A função foi acionada!');
        console.log("id")
        console.log(id)
        // var url = UrlApi+'/api/controller/'+id+'&token'+storage.getItem("accesstoken");
        //window.open(url);
    }
    confirmHandleChangeRequestingUserDialog = async () => {
        PapiroConsole.log("=== confirmHandleChangeRequestingUserDialog === ")
        PapiroConsole.log(this.state.requestingUserChange)
        let validate = this.validateHandleChangeRequestingUser()
        if (validate) {
            const { userSession, intl } = this.props
            this.setState({ loading: true, handleChangeRequestingUserDialogOpen: false })

            let data = new FormData()
            data.append('PreviousRequestingUserId', userSession.user.id)
            data.append('CalledId', this.state.called.id)
            data.append('RequestingUserId', this.state.requestingUserChange.id)
            data.append('StatusId', this.state.called.status)
            data.append('Reason', this.state.handleRequestingUserReason)
            data.append('CreatedUser', userSession.user.id)

            let result = await CalledRequestingUserService.addItem(data)
            if (result && result.success) {
                let option = this.state.requestingUserChange
                PapiroConsole.log("option aqui")
                PapiroConsole.log(option)
                PapiroConsole.log(option.id)
                this.setState(prevState => ({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: 'O solicitante do chamado foi alterado',
                    requestingUser: option,
                    /*called: {
                        ...prevState.called,
                        requestinguser: option,
                        requestingusername: option && option.name ? option.name : '',
                        requestinguserdocument: option && option.document ? option.document : '',
                        requestinguseremail: option && option.email ? option.email : '',
                        requestinguserids : option ? option.id : 0
                    },*/

                    handleChangeRequestingUserDialogOpen: false,
                    requestingUserChange: 0,
                    handleRequestingUserReason: ''
                }), () => this.LoadBarraNew(this.state.called.id))
            } else {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }
    }





    validateHandleChangeRequestingUser = () => {
        if (this.state.requestingUserChange == null || (this.state.requestingUserChange && this.state.requestingUserChange.id && this.state.requestingUserChange.id == 0)) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'O campo Solicitante é obrigatório'
            })
            return false
        }
        if (this.state.requestingUserChange.id == this.state.called.requestingUser.id) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'Este usuário já é o solicitante do chamado'
            })
            return false
        }
        if (this.state.handleRequestingUserReason == null || (this.state.handleRequestingUserReason != null && this.state.handleRequestingUserReason.length == 0)) {
            PapiroConsole.log("Entrou no if handleRequestingUserReason")
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'O campo Motivo é obrigatório'
            })
            return false
        }
        if (this.state.called.status == null || this.state.called.status == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: 'O chamado precisa ter um status selecionado'
            })
            return false
        }
        return true
    }

    addActionHandleClick = (event) => {
        this.setState({ addActionAnchorEl: event.currentTarget, addActionOpen: true })
    }

    addActionHandleClose = (id = 0) => {
        this.setState({ addActionAnchorEl: null, addActionOpen: false })

        if (id > 0) {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    status: id
                }
            }), () => {
                this.submitAction()
            })
        }
    }

    setCheckConfig = (totalChecklists, completedChecklists) => {
        if (totalChecklists == this.state.totalInitialChecklists)
            this.setState({ totalCompletedInitialChecklists: completedChecklists })
    }

    handleChangeRadio2 = async (e) => {


        let value = e.target.value
        let name = e.target.name
        PapiroConsole.log(name + ' == ' + value)


        this.setState(prevState => ({
            called: {
                ...prevState.called,
                [name]: value

            },
            campospersonalizados: value
        }))

        /*this.setState(prevState => ({
          radioChoice: {
            [name]: value
          }
        }))*/

    }

    handleChangeRadio = (e, d, idx) => {
        let value = e.target.value
        let name = e.target.name
        PapiroConsole.log(e)
        PapiroConsole.log(name + ' == ' + value)
        PapiroConsole.log(d)
        PapiroConsole.log(idx)


        this.setState(prevState => ({
            called: {
                ...prevState.called,
                [name]: value

            },
        }))

        //const temparr = {...this.state.campospersonalizados}
        //let temparr = this.state.campospersonalizados
        //temparr={ key: idx,value : e.target.value};

        //temparr[idx]= e.target.value;
        var tempval = e.target.value;

        this.setState({ campospersonalizados: tempval })
        PapiroConsole.log(this.state.campospersonalizados)

    }

    handleChangeCustomField = (fieldType, id, value, stateName) => {

    }

    openKnowledgeBaseDialog = async () => {
        if (this.state.knowledgeBaseList == null || (this.state.knowledgeBaseList && this.state.knowledgeBaseList.length == 0)) {
            this.setState({ loading: true })
            let result = await KnowledgeBaseService.getKnowledgeBaseByOrganizaytionIdSelectionModel(this.state.called.organization)
            if (result && result.success) {
                let formmatedList = result.data.map(item => {
                    let toName = item && item.name
                    if ('title' in item)
                        toName = item['title']

                    if (item.knowledgebasekeywords && item.knowledgebasekeywords.length > 0) {
                        toName += ` (`
                        item.knowledgebasekeywords.map((word, ix) => {
                            if (word && word.word && word.word.length > 0) {
                                if (ix == 0)
                                    toName += `${word.word}`
                                else
                                    toName += `, ${word.word}`
                            }
                        })
                        toName += `)`
                    }

                    return {
                        ...item,
                        name: toName
                    }
                })
                this.setState({
                    knowledgeBaseList: formmatedList,
                    loading: false,
                    knowledgeBaseDialogOpen: true
                })
            } else {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(result);
            }
        } else
            this.setState({ knowledgeBaseDialogOpen: true })
    }

    closeKnowledgeBaseDialog = () => this.setState({ knowledgeBaseDialogOpen: false })

    convertHtmlOption = value => {
        PapiroConsole.log("=== convertHtmlOption ===")
        let body = value
        let temp = document.createElement("div")
        temp.innerHTML = body
        let found = null
        for (let i in temp.children) {
            if (temp.children[i].innerHTML && temp.children[i].innerHTML.toString().trim() != '<br>' && temp.children[i].innerHTML.toString().trim().length > 0) {
                found += temp.children[i].innerHTML.toString()
            }
        }
        let sanitized = temp.textContent.trim() || temp.innerText.trim() || found

        PapiroConsole.log(sanitized)
        return sanitized
    }

    changeValuesKnowledgebase = (stateName, value, text = '') => {
        this.setState({ [stateName]: value });
    }

    insertKnowledgebase = async (e) => {
        e.preventDefault()

        if (this.state.knowledgebase != null) {
            if (this.state.knowledgebase.knowledgebaseattachments != null && this.state.knowledgebase.knowledgebaseattachments.length > 0) {
                let attachmentList = []
                this.state.knowledgebase.knowledgebaseattachments.forEach(element => {
                    if (element != null && element.attachment != null) {
                        attachmentList.push(element.attachment)
                    }
                });
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        knowledgebaseattachments: attachmentList
                    }
                }))
            }
            PapiroConsole.log("=== insertKnowledgebase ===")
            PapiroConsole.log(this.state.knowledgebase)
            if (this.state.knowledgebase.description != null) {
                let convKnowldgbs = this.convertHtmlOption(this.state.knowledgebase.description)
                convKnowldgbs = convKnowldgbs.trim()
                if (convKnowldgbs.length > 0) {
                    let accompanimentAction = document.getElementById('accompanimentAction')
                    PapiroConsole.log("=== insertKnowledgebase -> accompanimentAction ===")
                    PapiroConsole.log(accompanimentAction)
                    if (accompanimentAction != null) {
                        let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
                        if (qlEditor != null) {
                            qlEditor.innerHTML = this.state.knowledgebase.description || ""
                        }
                        PapiroConsole.log("=== insertKnowledgebase-> qlEditor ===")
                        PapiroConsole.log(qlEditor)
                    }

                    this.setState(prevState => ({
                        action: {
                            ...prevState.action,
                            description: this.state.knowledgebase.description || ""
                        }
                    }))
                }
            }

            let data = new FormData()
            data.append("CalledId", this.state.called.id);
            data.append("KnowledgeBaseId", this.state.knowledgebase.id);
            var result = await KnowledgeBaseCalledService.addItem(data)
            PapiroConsole.log(result)
            PapiroConsole.log("result aqui do knowledge")
            if (result && result.success) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({ id: "add.success" }),
                })
            } else {
                const intl = this.props.intl
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }

        this.closeKnowledgeBaseDialog()
    }

    deleteItem = (file) => {
        if (file != null && file.id != null) {
            let newListItem = this.state.called.knowledgebaseattachments.filter(item => item.id != file.id)
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    knowledgebaseattachments: newListItem
                }
            }))
        }
    }

    saveDefaultAction = () => {
        if (this.state.defaultActionId && this.state.defaultActionId.text && this.state.defaultActionId.text.length > 0) {
            this.setState(prevState => ({
                action: {
                    ...prevState.action,
                    description: this.state.defaultActionId.text || ""
                },
                defaultActionModalOpen: !this.state.defaultActionModalOpen
            }))
        }
    }

    getCalledOrganizationFieldId(organization_field_id) {


        var calledOrganizationFieldObject = {
            id: 0,
            value: "",
            data: {}
        }
        this.state.calledOrganizationFieldsList.forEach(element => {

            if (organization_field_id == element.organizationfieldid) {
                calledOrganizationFieldObject.id = element.id
                calledOrganizationFieldObject.value = element.value
                calledOrganizationFieldObject.data = element

            }


        });

        return calledOrganizationFieldObject



    }

    async changeValuesCheckbox(stateName, value, text = '') {




        if (stateName.includes("ofield")) {

            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    [stateName]: value
                }
            }));


        }

    };

    returnFields() {
        var campoTexto = []
        var arrayseleção = []
        var fieldType = 0
        var organization_field_id = 0;

        //return false;
        PapiroConsole.log("organizationFieldList")
        PapiroConsole.log(this.state.organizationFieldList)

        if (this.state.organizationFieldList.length > 0) {
            this.state.organizationFieldList.forEach(element => {

                fieldType = element.organizationfieldtype
                organization_field_id = element.id
                var called_organization_field = this.getCalledOrganizationFieldId(organization_field_id)



                if (element.organizationfieldoptionsvalues.length > 0) {

                    if (fieldType == 2) {

                        /*element.organizationfieldoptionsvalues.forEach(element2 => {

                            if(element2.organizationfieldid == called_organization_field.data.organizationfieldid && element2.name == called_organization_field.data.value){
                                this.setState({ selected: element2.name +'\n'+element2.id   })
                            }
                            
                        });*/
                        var tempval = "";
                        if (called_organization_field && called_organization_field.id != 0) {
                            //let temparr = this.state.campospersonalizados
                            //temparr[called_organization_field.data.organizationfieldid]= called_organization_field.id;
                            tempval = called_organization_field.data.value + "\n" + called_organization_field.data.organizationfieldoptionsvaluesid;

                            this.setState({ campospersonalizados: tempval })
                        }


                        campoTexto.push(
                            <Grid item xs={6} sm={6}>
                                <FormControl required={element.isrequired}>
                                    <FormLabel id={"demo-radio-buttons-group-label" + element.id}>{element.name}</FormLabel>
                                    <RadioGroup

                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name={"radio-buttons-group" + element.id}
                                        key={"radio-buttons-group" + element.id}
                                        defaultValue={tempval}
                                        //value={this.state.campospersonalizados}
                                        onChange={(event, value) => this.handleChangeRadio(event, value, element.id)}
                                    >
                                        {element.organizationfieldoptionsvalues.map(item =>
                                            item.organizationfieldid == called_organization_field.data.organizationfieldid && item.name == called_organization_field.data.value
                                                ? <FormControlLabel editable={true} key={"radio-" + item.id} name={'ofield-' + element.id} value={item.name + "\n" + item.id} stateName={'ofield-' + element.name} control={<Radio required={element.isrequired}/>} label={item.name} />
                                                : <FormControlLabel editable={true} key={"radio-" + item.id} name={'ofield-' + element.id} value={item.name + "\n" + item.id} stateName={'ofield-' + element.name} control={<Radio required={element.isrequired}/>} label={item.name} />


                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>


                        )
                        arrayseleção = []
                    }
                    if (fieldType == 4) {

                        var checkSelected

                        PapiroConsole.log(called_organization_field)
                        PapiroConsole.log("called_organization_field")
                        PapiroConsole.log("element")
                        PapiroConsole.log(element)
                        if (called_organization_field != null || called_organization_field != undefined || called_organization_field != "") {
                            element.organizationfieldoptionsvalues.forEach(element2 => {

                                if (element2.organizationfieldid == called_organization_field.data.organizationfieldid && element2.name == called_organization_field.data.value) {
                                    checkSelected = element2
                                }

                            });
                        }


                        campoTexto.push(
                            <Grid item xs={6} sm={6}>
                                <SimpleAutoComplete selected={checkSelected} label={element.name} options={element.organizationfieldoptionsvalues} stateName={'ofield-' + element.id} changeSelect={this.changeValues} required={element.isrequired}/>
                            </Grid>

                        )
                        arrayseleção = []
                    }
                }
                if (fieldType == 1) {
                    PapiroConsole.log("here 2")
                    campoTexto.push(
                        <Grid item xs={6} sm={6}>
                            <TextField

                                name={'ofield-' + element.id}
                                variant="outlined"
                                id={'ofield-' + element.id}
                                label={element.name}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                onChange={this.handleDynamicInputChange}
                                inputProps={{ maxLength: element.maxlength ? element.maxlength : 250 }}
                                required={element.isrequired}
                                defaultValue={this.state.called[`ofield-${element.id}`]}
                            />
                        </Grid>
                    )
                }
                if (fieldType == 3) {

                    var isTrueSet = false
                    campoTexto.push(
                        <Grid item xs={6} sm={6}>
                            <SimpleCheckBox
                                selected={isTrueSet = (this.state.called[`ofield-${element.id}`] === 'true')}
                                label={element.name}
                                name={'ofield-' + element.id}
                                stateName={'ofield-' + element.id}
                                changeSelect={this.changeValuesCheckbox}
                            />
                        </Grid>
                    )
                }

                


            });


        }



        this.setState({ campoTexto: campoTexto });

        PapiroConsole.log(campoTexto)
        PapiroConsole.log("campoTexto")


    }



    openDefaultActionDialog = async () => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        await Api.get(`/defaultaction`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    PapiroConsole.log("printe o resultado aqui")
                    PapiroConsole.log(result.data.data)
                    let formmatedList = result.data.data.map(item => {
                        return {
                            ...item,
                            name: item.title && item.title != "" ? item.title : item.text
                            //name: item.text
                        }
                    })

                    this.setState({
                        defaultActionModalOpen: !this.state.defaultActionModalOpen,
                        defaultActionList: formmatedList && formmatedList.length > 0 ? formmatedList.sort((a, b) => a.name.localeCompare(b.name)) : formmatedList,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    getDefaultActions = async (id) => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        PapiroConsole.log("organização aqui")
        PapiroConsole.log(id)
        await Api.get(`/defaultaction/byorganizationid/${id}`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    let formmatedList = result.data.data.map(item => {
                        PapiroConsole.log("printe o resultado aqui 2")
                        PapiroConsole.log(result.data.data)
                        //let body = item.text
                        let body = item.title && item.title != "" ? item.title : item.text
                        let temp = document.createElement("div")
                        temp.innerHTML = body
                        let sanitized = temp.textContent || temp.innerText

                        return {
                            ...item,
                            name: sanitized,
                            Name: item.text
                        }
                    })

                    this.setState({
                        defaultActionList: formmatedList && formmatedList.length > 0 ? formmatedList.sort((a, b) => a.name.localeCompare(b.name)) : formmatedList,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    getPriorities = async () => {
        let result = await PriorityService.getAllByHirerId()
        if (result && result.success) {
            if (result.data != null) {
                this.setState({ priorityList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data })
                if (this.state.called.priorityid && this.state.called.priorityid > 0 && this.state.called.priority == null) {
                    let priorityFilter = result.data.filter(item => item.id == this.state.called.priorityid)
                    if (priorityFilter && priorityFilter.length > 0)
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                priority: priorityFilter[0]
                            }
                        }))
                }
            }
        } else {
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }

    closeDefaultActionDialog = () => this.setState({ defaultActionModalOpen: !this.state.defaultActionModalOpen })

    closeSLAOverflowModal = () => this.setState({ slaOverflowContentModalOpen: !this.state.slaOverflowContentModalOpen })

    closeSLAOverflowModaButton = () => this.setState({ modalopenFinish: !this.state.modalopenFinish, slaOverflowContentModalOpen: !this.state.slaOverflowContentModalOpen })

    closeCloneCalledModal = () => this.setState({ cloneCalledModalOpen: !this.state.cloneCalledModalOpen })

    closeCalledModal = () => this.setState({ CalledModalOpen: !this.state.CalledModalOpen })

    closeUnarchiveModal = () => this.setState({ unarchiveModalOpen: !this.state.unarchiveModalOpen })


    closeLocationModal = () => this.setState({ locationModalOpen: !this.state.locationModalOpen })

    closeAccompanimentLocationModal = () => this.setState({ accompanimentLocationModalOpen: !this.state.accompanimentLocationModalOpen })

    closeFinisAccompanimentModal = () => {
        let prev = this.state.called.previousfinishaccompanimentid
        this.setState(prevState => ({
            finishAccompanimentModalOpen: !this.state.finishAccompanimentModalOpen,
            called: {
                ...prevState.called,
                finishaccompanimentid: prev
            }
        }))
    }

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    setFinishAccompanimentId = (item) => {
        this.setState({ rTabIndex: 1 },
            () => {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        finishaccompanimentid: item
                    }
                }))
            }
        )
    }

    setActionDescription = (item) => {
        let accompanimentAction = document.getElementById('accompanimentAction')
        PapiroConsole.log("=== accompanimentAction ===")
        PapiroConsole.log(accompanimentAction)
        if (accompanimentAction != null) {
            let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
            if (qlEditor != null) {
                qlEditor.innerHTML = item || ""
            }
            PapiroConsole.log("=== qlEditor ===")
            PapiroConsole.log(qlEditor)
        }
        var p = this.state.action.privacy;
        this.setState({ rTabIndex: 0 },
            () => {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        finishaccompanimentid: null
                    },
                    action: {
                        description: item || "",
                        privacy: p, //"false" //não pode marretar o tipo da ação pra pública só pq tá encerrando o chamado
                    }
                }))
            }
        )

    }

    setDefaultActionAccompaniment = (item) => {
        console.log("print item aqui")
        console.log(item)
        if (item != null) {

            this.setState({ rTabIndex: 0 },
                () => {
                    this.setState(prevState => ({
                        defaultActionId: item
                    }))
                }
            )

        }

    }

    knowledgeBaseDetailsClose = _ => this.setState({ knowledgeBaseDetailsDialogOpen: false, knowledgeBaseItem: this.initialKnowledgeBaseItem, evaluation: this.initialEvaluation })

    knowledgeBaseDetails = async () => {
        this.setState({ loading: true })

        let result = await KnowledgeBaseService.getDetailsById(this.state.knowledgebase.id)
        if (result && result.success) {
            if (result.data != null) {
                this.setState({ loading: false, knowledgeBaseDetailsDialogOpen: true, knowledgeBaseItem: result.data })
            }
        } else {
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }

    getFile = async (attachment) => {
        const intl = this.props.intl;
        this.setState({ loading: true });

        let url = await FileService.getFileDownloadLink(attachment.id);
        if (url && url.data && url.data.data && url.data.success) {
            this.setState({ loading: false });
            window.open(`${url.data.data}`, '_blank');
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "file.not.found" })
            });
        }
    }

    copyFileLink = async (attachment) => {
        const intl = this.props.intl;
        this.setState({ loading: true });

        let url = await FileService.getFileDownloadLink(attachment.id);
        if (url && url.data && url.data.data && url.data.success) {
            this.setState({ loading: false });
            navigator.clipboard.writeText(url.data.data)
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: "Link de download copiado!"
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "file.not.found" })
            });
        }
    }

    handleChangeTab = (event, newValue) => {
        //console.log("=== handleChangeTab ===")
        //console.log(newValue)
        this.setState({ finishAccompanimentValue: newValue })
    }

    handleChangeTabIndex = (index) => {
        //console.log("=== handleChangeTabIndex ===")
        //console.log(index)
        this.setState({ finishAccompanimentValue: index })
    }

    setCountLoading(message = "") {


        this.setState({ countLoading: this.state.countLoading + 1 }, () => {
            if (!this.isClient) {
                // verificar porque não está batendo 11 no contador - Eduardo | Porto
                if (this.state.countLoading >= 11) {
                    this.setState({
                        loading: false,
                    });
                }
            }
            else {
                if (this.state.countLoading >= 5) {
                    this.setState({
                        loading: false,
                    });
                }
            }

        });
    }



    getOrganizationFields = async calledId => {

        var result = await CalledOrganizationChecklistService.getCalledOrganizationFieldsByCalled(calledId);

        if (result.success) {
            this.setState({ organizationFieldList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            /*result.data.map(item => {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        ['ofield-' + item.id]: item.value
                    }
                }))
            })*/
            this.setCountLoading("getOrganizationFields");

            this.returnFields();
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getOrganizationFields");
            Api.kickoff(result);
        }
    };

    async componentDidMount() {


        PapiroConsole.log(this.props.match.params.id)
        var checkifUserCanAcessCalled = await this.checkifUserCanAcessCalled(this.props.match.params.id)
        if (checkifUserCanAcessCalled == true) {
            await this.LoadUI()
            this.checkRoleToDisable()
            this.showCalledReport()
        }
        
    };

    convertTohoursFormat = (minutos) => {
        let calledHourLoc = minutos ? minutos : "00:00:00"
        if (calledHourLoc && calledHourLoc > 0) {
            var splitCalledHoutLoc = [
                (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                calledHourLoc % 1
            ]
            let nDate = new Date(0, 0)
            nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
            if (nDate.getSeconds() >= 58) {
                nDate.setSeconds(0)
                nDate.setMinutes(nDate.getMinutes() + 1)
            }
            let calledHourLocResult = nDate.toTimeString().slice(2, 8)
            calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
            calledHourLoc = calledHourLocResult

            if (calledHourLoc == '0')
                calledHourLoc = "00:00:00"
        } else if (calledHourLoc == '0')
            calledHourLoc = "00:00:00"

        return calledHourLoc
    }

    detailCardsCost = (data) => {
        const intl = this.props.intl;
        let totalCost = data.totalvaluecostprincipalremote + data.totalvaluecostcalleduserremote + data.totalvaluecosttimeentries + data.totalvaluecostinloco + data.totalvaluecostdisplacement + data.totalvaluereplacedmaterials + data.totalvalueadditionalcost

        let totalAttendance = data.totalhoursprincipalremote + data.totalhourscalleduserremote + data.totaltimeentries + data.totalhoursinloco + data.totalhoursdisplacement

        this.valueTimeAttendance = <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer component={Paper} >
                <Table size="small" aria-label="a dense table" >
                    {/*<caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Custo total de atendimentos {TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}  </caption>*/}
                    <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                        <TableRow>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "remote.attendance" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "professionals.involved" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "appointment.hours" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "on.site.attendance" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "displacement" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "replaced.materials" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "called.accompaniment.aditional.costs" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "total.value" })}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostprincipalremote.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostcalleduserremote.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecosttimeentries.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostinloco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluecostdisplacement == intl.formatMessage({ id: "not.applicable" }) ? intl.formatMessage({ id: "not.applicable" }) : data.totalvaluecostdisplacement.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvaluereplacedmaterials.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalvalueadditionalcost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totalCost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

        this.valueCostAttendance = <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer component={Paper} >
                <Table size="small" aria-label="a dense table" >
                    {/*<caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Custo total de atendimentos {TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}  </caption>*/}
                    <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                        <TableRow>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "remote.attendance" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "professionals.involved" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "appointment.hours" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "on.site.attendance" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "displacement" })}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({ id: "total.value" })}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totalhoursprincipalremote)}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totalhourscalleduserremote)}</TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totaltimeentries)} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(data.totalhoursinloco)} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{data.totalhoursdisplacement == intl.formatMessage({ id: "not.applicable" }) ? intl.formatMessage({ id: "not.applicable" }) : this.convertTohoursFormat(data.totalhoursdisplacement)} </TableCell>
                            <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.convertTohoursFormat(totalAttendance)} </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    }

    convertCalled = async (item) => {

        PapiroConsole.log("item aqui")
        PapiroConsole.log(item)
        const { userSession, calleds, intl } = this.props;

        let times = "";
        let cardItem = "";
        let DepartamentName = ""
        if (item && item.organizationdepartament && item.organizationdepartament.name) {
            DepartamentName = item.organizationdepartament.name
        }

        if (item && item.techinicalteam && item.techinicalteam.name && item.techinicalteam.name.length > 0) {
            times = item.techinicalteam.name
        } else if (item && item.organizationcategory && item.organizationcategory.techinicalteamorganizationcategories != null) {
            item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                times = times + item2.techinicalteam.name + ","
            });
            times = times.substring(0, times.length - 1);
        }

        let service = getSlaAtendimento(item, this.props.userSession)
        let solution = getSlaSolucao(item, this.props.userSession)

        let slaSolution = item.slasolution ? item.slasolution.toFixed(2) + "%" : solution + "%"
        let slaService = item.slaservice ? item.slaservice.toFixed(2) + "%" : service + "%"

        let slaSolutionN = item.slasolution ? item.slasolution.toFixed(2) : solution
        let slaServiceN = item.slaservice ? item.slaservice.toFixed(2) : service

        if (item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientviewslametricsincalled && item.organization.organizationsettings.canclientviewslametricsincalled === true) {
            let slaSol = ""
        } else {
            if (this.isClient) {
                slaSolution = "----"
                slaService = "----"
            }
        }

        PapiroConsole.log("item aqui")
        PapiroConsole.log(item)

        let appenCalled = true
        let data = new FormData()
        data.append("UserId", this.props.userSession.user.id)
        PapiroConsole.log("=== this.props.lastCalledsQueue")
        PapiroConsole.log(this.props.lastCalledsQueue)
        if (appenCalled) {
            data.append("Id", item.id)
            data.append("Title", item.title)
        }
        if (this.props.lastCalledsQueue != null && this.props.lastCalledsQueue.items != null) {
            let queue = this.props.lastCalledsQueue.items
            for (const key in queue) {
                if (queue.hasOwnProperty(key)) {
                    const valor = queue[key];
                    if (valor != null) {
                        if (valor.id != item.id) {
                            data.append("Id", valor.id)
                            data.append("Title", valor.title)
                        }
                    }
                }
            }
        }
        CalledService.addLastCalledsQueue(data)

        this.props.equeuelastCalledsQueue({ id: item.id, title: item.title })
        PapiroConsole.log(item.requestinguser)
        PapiroConsole.log("item.requestinguser 1 ")
        let newitem = {
            ...item,
            statusName: item.status.name,
            statusTypeId: item.status.statustypeid,
            OrganizationCategory: item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name,
            Organization: item.organization.name,
            slaAtendimento: slaService,
            slaSolucao: slaSolution,
            service: slaServiceN,
            solution: slaSolutionN,
            assignedUser: item.assigneduser ? item.assigneduser.name : "",
            requestingUser: item.requestinguser ? item.requestinguser.name : "",
            requestinguserids: item.requestinguser ? item.requestinguser.id : 0, //this.state.called && this.state.called.requestinguserids ? this.state.called.requestinguserids : item.requestinguser ? item.requestinguser.name : ""  ,
            requestinguseremail: item.requestinguser ? item.requestinguser.email : "",
            requestingusername: item.requestinguser ? item.requestinguser.name : "",
            time: times,
            addressname: item.address.name,
            opening: moment(item.openingdate).format("DD/MM/YYYY"),
            openingHour: moment(item.openingdate).format("HH:mm"),
            solutionDate: moment(item.solutiondate).format("DD/MM/YYYY"),
            solutionHour: moment(item.solutiondate).format("HH:mm"),
            dueDt: moment(item.duedate).format("DD/MM/YYYY"),
            dueHour: moment(item.duedate).format("HH:mm"),
            DateCreatedAt: moment(item.openingdate).format("DD/MM/YYYY HH:mm"),
            typeName: item.type.name,
            originRequestName: item.originrequest.name,
            cardItem: cardItem,
            DepartamentName: DepartamentName,
        }

        let newassigned = newitem.assigneduser;
        if (newitem.assigneduser) {
            if (newitem.assigneduser.email == null || (newitem.assigneduser.email && (newitem.assigneduser.email.length === 0 || newitem.assigneduser.email.length === 'undefined'))) {
                newassigned.name = `${newitem.assigneduser.name}`
            } else {
                newassigned.name = `${newitem.assigneduser.name} - ${newitem.assigneduser.email}`
            }
        }

        newitem.assigneduser = newassigned
        this.calledGridData = newitem
        await this.props.addCurrentCalled(newitem);
        await this.props.addCalled(newitem);

        var dataNPS = null;

        this.orgCatPriority = newitem.organizationcategory.priorityid


        PapiroConsole.log(newitem.requestingusername)
        PapiroConsole.log("item.requestinguser 2 ")

        await this.setState({
            search: {
                technicalUsersList: newitem.techinicalteamid != null && newitem.techinicalteamid > 0 ? `/techinicalteamusers/getusers/technicalteam/${newitem.techinicalteamid}/roletype/${2}` : `/users/category/${newitem.organizationcategoryid}/organization/${newitem.organizationid}`,
                categoriesList: `/organizationcategories/organization/${newitem.organizationid}`,
                knowledgebase: '/knowledgebases'
            },
            called: {
                id: newitem.id,
                CreatedUser: userSession.user.id,
                requestingUser: 0,
                organization: newitem.organization.id,
                organizationkey: newitem.organization.organizationkey,
                orgname: newitem.organization.name,
                requestinguserids: newitem.requestinguserids,
                requestinguseremail: newitem.requestinguseremail,
                requestingusername: newitem.requestingusername,
                organizationsettings: newitem.organization.organizationsettings,
                type: newitem.type.id,
                OrganizationCategoryId: newitem.organizationcategory,
                ParentCalledId: newitem.parentcalledid,
                ParentCalledText: '',
                OrganizationCategoryText: newitem.organizationcategory.name,
                parentCategory: newitem.organizationcategory && newitem.organizationcategory.parentorganizationcategory && newitem.organizationcategory.parentorganizationcategory.id,
                dueDate: newitem.duedate,
                openingDate: newitem.openingdate,
                solutionDate: newitem.solutiondate,
                title: newitem.title,
                addressid: 0,
                addresstype: "1",
                status: newitem.status.id,
                description: '',
                priorityid: newitem.priorityid && newitem.priorityid > 0 ? newitem.priorityid : this.orgCatPriority,
                priority: null,
                CallDueDate: null,
                originRequest: newitem.originrequest.id,
                originRequestName: newitem.originRequestName,
                observantUser: 0,
                assignedUser: newitem.assigneduser,
                //techinicalTeamId: newitem.techinicalteamid,
                updatedUser: userSession.user.id ? userSession.user.id : 0,
                quantityreama4: newitem.quantityreama4 ? newitem.quantityreama4 : 0,
                securitypassword: newitem.securitypassword,
                patrimonyId: "",
                classification: dataNPS,
                departamentIdN: newitem.organizationdepartamentid,
                departamentId: 0,
                employeehourvalue: (newitem.employeehourvalue && newitem.employeehourvalue > 0) ? newitem.employeehourvalue : (calleds.defaulttechnicalemployeehourvalue && calleds.defaulttechnicalemployeehourvalue > 0) ? calleds.defaulttechnicalemployeehourvalue : 0,
                valueforcalled: (newitem.valueforcalled && newitem.valueforcalled > 0) ? newitem.valueforcalled : (calleds.defaulttechnicalvalueforcalled && calleds.defaulttechnicalvalueforcalled > 0) ? calleds.defaulttechnicalvalueforcalled : 0,
                costparameter: newitem.costparameter ? newitem.costparameter : 1,
                hasdisplacement: newitem.hasdisplacement,
                kmvaluerotated: (newitem.kmvaluerotated && newitem.kmvaluerotated > 0) ? newitem.kmvaluerotated : (calleds.defaulttechnicalkmvaluerotated && calleds.defaulttechnicalkmvaluerotated > 0) ? calleds.defaulttechnicalkmvaluerotated : (calleds.configdisplacement && calleds.configdisplacement.kmvaluerotated > 0) ? calleds.configdisplacement.kmvaluerotated : 0,
                finishaccompanimentid: newitem.finishaccompanimentid ? newitem.finishaccompanimentid > 0 ? newitem.finishaccompanimentid : null : null,
                previousfinishaccompanimentid: newitem.finishaccompanimentid ? newitem.finishaccompanimentid > 0 ? newitem.finishaccompanimentid : null : null,
                hasdisplacementTrue: newitem.hasdisplacement == true ? true : false,
                hasdisplacementFalse: newitem.hasdisplacement == false ? true : false,
                hasParentCalledTrue: newitem.parentcalledid != null ? true : false,
                hasParentCalledFalse: newitem.parentcalledid == null ? true : false,
                hasPatrimoniesTrue: newitem.calledorganizationpatrimonies && newitem.calledorganizationpatrimonies.length > 0 ? true : false,
                hasPatrimoniesFalse: (newitem.calledorganizationpatrimonies == null) || (newitem.calledorganizationpatrimonies && newitem.calledorganizationpatrimonies.length == 0) ? true : false,
                calledstatus: [],
                calledattachments: [],
                knowledgebaseattachments: [],
                calledclosingstatusname: null,
            },
            requestingUser: newitem.requestinguser,
            orgSettings: newitem.organization.organizationsettings,
            organization: newitem.organization,
            organizationkey: newitem.organization && newitem.organization.organizationkey ? newitem.organization.organizationkey : '',
            hirerid: newitem.hirerid,
            typeDisabled: newitem.organizationcategory && newitem.organizationcategory.typeid && newitem.organizationcategory.typeid > 0 ? true : false,
            priorityDisabled: newitem.organizationcategory && newitem.organizationcategory.priorityid && newitem.organizationcategory.priorityid > 0 ? true : false,
            calledorganizationid: newitem.organizationid,
            clientStatus: newitem.status,
            oldStatus: newitem.status,
            calledStatusTypeId: newitem.status.statustypeid,
        })

        PapiroConsole.log("entrei aqui na condição ")
        PapiroConsole.log(item.originrequest.id)
        //Caso o chamado tenha origem 12 (mexx2talk) colocar o nome do provedor nas informações do chamado. (endpoint em dapper feito para não penalizar chamados que não possuam essa origem)
        if(item.originrequest.id == 12){    
            this.GetProviderNameByCalledId(newitem.id);
        }

        return newitem
    }

    getCalledGridById = async (id) => {
        const { userSession, calleds, intl } = this.props;

        let calledData = null

        const result = await CalledService.getCalledGridById(id);
        if (result.success) {
            if (result.data && result.data.id) {
                calledData = await this.convertCalled(result.data)
            } else {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.does.not.exist" })
                });
                this.props.history.push(`/${this.props.match.params.lang}/calleds`)
            }
        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }

        return calledData
    }

    GetProviderNameByCalledId = async (id) => {

        const intl = this.props.intl;
        
        const result = await CalledService.GetProviderNameByCalledId(id);
        PapiroConsole.log(result)
        PapiroConsole.log("result totalmente aqui")
        if (result.success) {
            if (result.data ) {
                PapiroConsole.log("result totalmente aqui 2")

            

                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        providerName: result.data ? result.data : ''
                    }
                }))
                
            } else {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "error.finding.provider.name" })
                });
               
            }
        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }

    }

    LoadBarraNew = async (id) => {
        const { userSession, intl } = this.props;

        await Api.get(`/calleds/new/${id}`)
            .then(result => {
                PapiroConsole.log("result aqui no new ")
                PapiroConsole.log(result)

                /*PapiroConsole.log("Acompanhamento do chamado (this)")
                PapiroConsole.log(this)*/

                if (result && result.data && result.data.success && result.data.data) {


                    this.phone = result.data.data.called.organization && result.data.data.called.organization.phone != null ? result.data.data.called.organization.phone : "";
                    //this.canstartcallcenter = result.data.data.called.organization.organizationsettings.canstartcallcenter;
                    if (this.isTechinical && result.data.data.called.assigneduser && result.data.data.called.assigneduser.id == userSession.user.id) {
                        this.sametechinical = true;
                    }

                    PapiroConsole.log("=== otherClientInteractsWithCalled ===")
                    let userIsClient = userSession.user.userrole.find(userrole => userrole.role.roletype == 3)
                    PapiroConsole.log(userIsClient)
                    PapiroConsole.log(userSession.user.caninteractwithcalledwherearenotrequesterorassigned)
                    if (userIsClient != null && typeof userIsClient !== 'undefined' && userSession.user.caninteractwithcalledwherearenotrequesterorassigned != null && userSession.user.caninteractwithcalledwherearenotrequesterorassigned == true) {
                        userSession.user.userorganizations.map(item => {
                            if (item.id == result.data.data.called.organization.id) {
                                this.otherClientInteractsWithCalled = true
                            }
                        })
                    }

                    PapiroConsole.log("result do settings aqui")

                    if (result.data.data.called.title != null) {
                        let calledTitle = document.getElementById('title')
                        if (calledTitle != null) {
                            calledTitle.value = result.data.data.called.title
                        }
                    }
                    /*
                    alert(result.data.data.called.description);
                    if (result.data.data.called.description != null) {
                        let calledDescription = document.getElementById('calledDescription')
                        alert(calledDescription);
                        if (calledDescription != null) {
                            let qlEditor = calledDescription.querySelector('.ql-container .ql-editor')
                            alert(qlEditor);
                            if (qlEditor != null){
                                qlEditor.innerHTML = result.data.data.called.description;
                            }
                            let qlEditor2 = calledDescription.querySelector('.ql-container .ql-editor .ql-blank')
                            alert(qlEditor2);
                            if (qlEditor2 != null){
                                qlEditor2.innerHTML = result.data.data.called.description
                            }
                        }
                    }
                    */
                    //if (result.data.data.called.description != null) {
                    //alert(result.data.data.called.description);

                    //console.log('QUILL')
                    //console.log(this.quill)
                    //console.log('QUILL')
                    //alert(this.quill.getEditor());

                    //let delta = this.quill.getEditor().getDelta();
                    //alert(this.delta);
                    //this.quill.setContents(result.data.data.called.description);
                    //const delta = this.quill.current.editor.clipboard.convert(result.data.data.called.description);
                    //this.quill.current.editor.updateContents(delta, 'silent')
                    //}

                    PapiroConsole.log(result.data.data.called)
                    PapiroConsole.log("result.data.data.called")

                    /*let itemsTreeData = []
    
                    let itemsTreeDataChangeRequestingUser = <Button
                        color="secondary"
                        size="large"
                        style={{ marginBottom: "5px" }}
                        onClick={this.openHandleChangeRequestingUserDialog}
                        startIcon={<GroupIcon />}>
                        {intl.formatMessage({ id: "change.request.user" })}
                    </Button>
    
                    itemsTreeData.push(itemsTreeDataChangeRequestingUser)
    
                    if ((this.globalAdm || this.AdmOrganization) && result.data.data.called.organization != null && result.data.data.called.organization.organizationsettings != null && result.data.data.called.organization.organizationsettings.allowchangeoforganizationofcalledbyadministration != null && result.data.data.called.organization.organizationsettings.allowchangeoforganizationofcalledbyadministration) {
                        let itemsTreeDataChangeOrganization = <Button
                            color="secondary"
                            size="large"
                            style={{ marginBottom: "5px" }}
                            onClick={this.openHandleChangeRequestingUserDialog}
                            startIcon={<GroupIcon />}>
                            {intl.formatMessage({ id: "change.request.user" })}
                        </Button>
    
                        itemsTreeData.push(itemsTreeDataChangeOrganization)
                    }*/
                    PapiroConsole.log("resultado de asynchronousClosingCalled aqui")
                    PapiroConsole.log(result.data.data.asynchronousclosingcalled)
                    this.setState({
                        title: result.data.data.called.title,
                        organizationkey: result.data.data.called.organization && result.data.data.called.organization.organizationkey,
                        asynchronousClosingCalled : result.data.data.asynchronousclosingcalled,
                        //canselectorganizationcategoriesbylevels: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canselectorganizationcategoriesbylevels ? result.data.data.called.organization.organizationsettings.canselectorganizationcategoriesbylevels : false,
                        //caneditaccompanimentinactivecalled: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caneditaccompanimentinactivecalled ? result.data.data.called.organization.organizationsettings.caneditaccompanimentinactivecalled : false,
                        //caninforminitialchecklist: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninforminitialchecklist ? result.data.data.called.organization.organizationsettings.caninforminitialchecklist : false,
                        //caninitialchecklistisrequired: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired ? result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired : false,
                        //allowchangeoforganizationofcalledbyadministration: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.allowchangeoforganizationofcalledbyadministration ? result.data.data.called.organization.organizationsettings.allowchangeoforganizationofcalledbyadministration : false,
                        //itemsTree: itemsTreeData,
                        //requireattachedimage: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.requireattachedimage ? result.data.data.called.organization.organizationsettings.requireattachedimage : false,
                        //actionrequiredwhenstatusorteamorassignedchanges :result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.actionrequiredwhenstatusorteamorassignedchanges ? result.data.data.called.organization.organizationsettings.actionrequiredwhenstatusorteamorassignedchanges : false,
                        totalInitialChecklists: result.data.data.totalinitialchecklists ? result.data.data.totalinitialchecklists : 0,
                        totalCompletedInitialChecklists: result.data.data.totalcompletedinitialchecklists ? result.data.data.totalcompletedinitialchecklists : 0,
                        hirerid: result.data.data.called.hirerid,
                        //displayassignedinfomode: result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.displayassignedinfomode ? result.data.data.called.organization.organizationsettings.displayassignedinfomode : 1,
                        //allowclientinteractiononclosedcalleds : result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.allowclientinteractiononclosedcalleds ? result.data.data.called.organization.organizationsettings.allowclientinteractiononclosedcalleds : false
                    })

                    if (result.data.data.called && result.data.data.called.requestinguser && result.data.data.called.requestinguser.id == userSession.user.id) {
                        this.samePerson = true;
                        this.setState({ samePerson: true })
                    } else {
                        this.samePerson = false;
                        this.setState({ samePerson: false })
                    }

                    //this.canclientfinishcalled = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canclientfinishcalled ? result.data.data.called.organization.organizationsettings.canclientfinishcalled : false

                    //this.canclientreopencalled = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canclientreopencalled ? result.data.data.called.organization.organizationsettings.canclientreopencalled : false

                    //this.canclientautomaticchangestatusaftersendaccompaniment = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.canclientautomaticchangestatusaftersendaccompaniment ? result.data.data.called.organization.organizationsettings.canclientautomaticchangestatusaftersendaccompaniment : false

                    //this.caninforminitialchecklist = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninforminitialchecklist ? result.data.data.called.organization.organizationsettings.caninforminitialchecklist : false
                    //this.caninitialchecklistisrequired = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired ? result.data.data.called.organization.organizationsettings.caninitialchecklistisrequired : false
                    //this.allowchangeoforganizationofcalledbyadministration = result.data.data.called.organization && result.data.data.called.organization.organizationsettings && result.data.data.called.organization.organizationsettings.allowchangeoforganizationofcalledbyadministration ? result.data.data.called.organization.organizationsettings.allowchangeoforganizationofcalledbyadministration : false
                    //this.canselectpatrimonyincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canselectpatrimonyincalled;
                    //this.canselectdepartamentincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canselectdepartamentincalled
                    //this.canselectparentcalledincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canselectparentcalledincalled
                    //this.canselectdisplacementincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canselectdisplacementincalled
                    //this.canselecthourcostorcalledcostincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canselecthourcostorcalledcostincalled
                    //this.caninformzeroincalledtechnicalcost = result.data.data.called && result.data.data.called.organization.organizationsettings.caninformzeroincalledtechnicalcost
                    //this.canclientselectpatrimonyincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canclientselectpatrimonyincalled;
                    //this.canclientselecttypeincalled = result.data.data.called && result.data.data.called.organization.organizationsettings.canclientselecttypeincalled;
                    //this.caninformutilizedmaterialsincalledfinish = result.data.data.called && result.data.data.called.organization.organizationsettings.caninformutilizedmaterialsincalledfinish;
                    //this.isrequiredselectteamincalledfinish = result.data.data.called && result.data.data.called.organization.organizationsettings.isrequiredselectteamincalledfinish
                    this.infoRequest = result.data.data.called && result.data.data.called.requestinguser;
                    if (this.infoRequest && this.infoRequest.email && this.infoRequest.phone) {
                        localStorage.setItem("infoRequest", `${this.infoRequest.email};${this.infoRequest.phone}`)
                    } else {
                        localStorage.setItem("infoRequest", "")
                    }

                    var dataNPS = null;
                    var msgNPS = null;
                    const intl = this.props.intl;

                    if (result.data.data.called.calledclassification) {
                        if (result.data.data.called.calledclassification.noteassigned == 1 || result.data.data.called.calledclassification.noteassigned == 2) {
                            dataNPS = <SentimentVeryDissatisfiedIcon style={{ color: '#E74C3C', textAlign: 'center' }} />
                            msgNPS = intl.formatMessage({ id: "called.accompaniment.attendance.classified.with" }) + result.data.data.called.calledclassification.noteassigned + intl.formatMessage({ id: "called.accompaniment.attendance.classified.with.stars" });
                        } else if (result.data.data.called.calledclassification.noteassigned == 3) {
                            dataNPS = <SentimentSatisfiedIcon style={{ color: '#FFBB28', textAlign: 'center' }} />
                            msgNPS = intl.formatMessage({ id: "called.accompaniment.attendance.classified.with" }) + result.data.data.called.calledclassification.noteassigned + intl.formatMessage({ id: "called.accompaniment.attendance.classified.with.stars" });
                        } else if (result.data.data.called.calledclassification.noteassigned == 4 || result.data.data.called.calledclassification.noteassigned == 5) {
                            dataNPS = <SentimentSatisfiedAltIcon style={{ color: '#00C49F', textAlign: 'center' }} />
                            msgNPS = intl.formatMessage({ id: "called.accompaniment.attendance.classified.with" }) + result.data.data.called.calledclassification.noteassigned + intl.formatMessage({ id: "called.accompaniment.attendance.classified.with.stars" });
                        }

                        this.setState({ msgClassification: msgNPS })
                    }

                    let materialSelectedObj = [];
                    if (result.data.data.called.calledorganizationpatrimonies) {
                        for (var obj of result.data.data.called.calledorganizationpatrimonies) {
                            materialSelectedObj.push(obj.organizationpatrimony);
                        }
                    }

                    if (result.data.data.called.calledstatus != null) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                calledstatus: result.data.data.called.calledstatus
                            }
                        }))
                    }
                    PapiroConsole.log("===ACOMPANHAMENTOS AQUI===")
                    PapiroConsole.log(result.data.data.called.accompaniments)
                    if (result.data.data.called && result.data.data.called.accompaniments) {

                        this.props.addAccompanimentsList(result.data.data.called.accompaniments);
                        this.props.addAccompanimentsListInCalled(result.data.data.called.accompaniments, result.data.data.called.id);
                    }

                    if (!this.isClient) {

                        if (result.data.data.called.parentcalled) {
                            result.data.data.called.parentcalled.parentname = `${result.data.data.called.parentcalled.id}: ${result.data.data.called.parentcalled.title}`
                        }

                        this.setState({
                            called: {
                                ...this.state.called,
                                ParentCalledId: result.data.data.called.parentcalled,
                                patrimonyId: materialSelectedObj,
                                hasParentCalledTrue: result.data.data.called.parentcalled != null ? true : false,
                                hasParentCalledFalse: result.data.data.called.parentcalled == null ? true : false,
                                hasPatrimoniesTrue: materialSelectedObj && materialSelectedObj.length > 0 ? true : false,
                                hasPatrimoniesFalse: materialSelectedObj == null || (materialSelectedObj && materialSelectedObj.length == 0) ? true : false,
                                status: result.data.data.called.status ? result.data.data.called.status.id : null,
                                calledclosingstatusname: result.data.data.called.calledclosingstatus && result.data.data.called.calledclosingstatus.name && result.data.data.called.calledclosingstatus.name.length > 0 ? result.data.data.called.calledclosingstatus.name : null,
                                archived: result.data.data.called.archived,
                                quickCalledCreated: result.data.data.called.quickcalledcreated

                            },
                            clientStatus: result.data.data.called.status,
                            oldStatus: result.data.data.called.status,
                            calledStatusTypeId: result.data.data.called && result.data.data.called.status ? result.data.data.called.status.statustypeid : null
                        });





                        this.getCategoryFields(this.state.called.OrganizationCategoryId.id, result);
                        PapiroConsole.log(result.data.data.statuslist)
                        PapiroConsole.log("result.data.data.statuslist new 1")

                        let statuses = result.data.data.statuslist.map(item => {
                            return {
                                ...item,
                                enable: item.system == false ? true : (this.getAvaliableStatus().indexOf(item.name.toLowerCase().trim()) > -1)
                            }
                        })
                        PapiroConsole.log(statuses)
                        PapiroConsole.log("statuses")
                        let validStatuses = statuses.filter(item => item.enable == true)
                        PapiroConsole.log(validStatuses)
                        PapiroConsole.log("validStatuses")
                        let disabledCurrentStatus = statuses.find(item => item.enable == false && item.id == this.state.called.status)
                        if (disabledCurrentStatus != null && typeof disabledCurrentStatus !== 'undefined')
                            validStatuses = [...validStatuses, disabledCurrentStatus]

                        PapiroConsole.log("=== result.data.data.statuslist ===")
                        let sectableStatus = result.data.data.statuslist.map(item => {

                            PapiroConsole.log(item)
                            if (item.system == false || (item.id == 1 || item.id == 2 || item.id == 4 || item.id == 9))
                                return item
                        })
                        let getStatusById = statuses.filter(item => item.id == this.state.called.status)
                        if (getStatusById != null) {
                            if (getStatusById.length > 0) {
                                if (getStatusById[0].statustypeid == 5 || getStatusById[0].finished == true)
                                    this.setState({ finishAccompaniment: true })
                                else
                                    this.setState({ finishAccompaniment: false })
                            } else
                                this.setState({ finishAccompaniment: false })
                        } else
                            this.setState({ finishAccompaniment: false })

                        let newarrayuserteclist = result.data.data.userstechnicallist;

                        if (result.data.data.userstechnicallist) {
                            if (Array.isArray(result.data.data.userstechnicallist)) {
                                if (result.data.data.userstechnicallist.length == 0) { // porto
                                    if (result.data.data.called && result.data.data.called.assigneduser == null) {
                                        /*this.setState({
                                            openNotification: true, notificationVariant: "error",
                                            notificationMessage: intl.formatMessage({id:"called.accompaniment.error.technician.not.found"})
                                        });*/
                                    } else {
                                        newarrayuserteclist.push(result.data.data.called.assigneduser)
                                    }

                                }
                            }
                        }

                        if (result.data.data.called && result.data.data.called.assigneduser && result.data.data.userstechnicallist) {
                            let verifyTecExist = false;
                            result.data.data.userstechnicallist.map(item => {
                                if (item.id == result.data.data.called.assigneduser.id) {
                                    verifyTecExist = true;
                                }
                            })
                            if (!verifyTecExist) {
                                newarrayuserteclist.push(result.data.data.called.assigneduser);
                            }
                        }




                        let calledValueLoc = `R$0,00`
                        if (result.data.data.calledvaue != null && result.data.data.calledvaue > 0) {
                            calledValueLoc = `R$${result.data.data.calledvaue.toFixed(2)}`
                            calledValueLoc = calledValueLoc.replaceAll(".", ",")
                        }

                        let calledHourLoc = result.data.data.calledtime && !isNaN(result.data.data.calledtime) ? result.data.data.calledtime : "00:00:00"
                        if (calledHourLoc && calledHourLoc > 0) {
                            var splitCalledHoutLoc = [
                                (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                                calledHourLoc % 1
                            ]
                            let nDate = new Date(0, 0)
                            nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
                            if (nDate.getSeconds() >= 58) {
                                nDate.setSeconds(0)
                                nDate.setMinutes(nDate.getMinutes() + 1)
                            }
                            let calledHourLocResult = nDate.toTimeString().slice(2, 8)
                            calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
                            calledHourLoc = calledHourLocResult

                            if (calledHourLoc == '0')
                                calledHourLoc = "00:00:00"
                        } else if (calledHourLoc == '0')
                            calledHourLoc = "00:00:00"

                        let reordernewarrayuserteclist = new Array();
                        if (newarrayuserteclist != null) {
                            newarrayuserteclist.map(item => {
                                item.name = item.name + " (" + item.email + ")";
                                reordernewarrayuserteclist.push(item);
                            })
                        }
                        reordernewarrayuserteclist = reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name))

                        if (result.data.data.called.priorityid && result.data.data.called.priorityid > 0 && result.data.data.called.priorityid == null) {
                            let priorityFilter = result.data.data.prioritylist.filter(item => item.id == result.data.data.called.priorityid)
                            if (priorityFilter && priorityFilter.length > 0)
                                this.setState(prevState => ({
                                    called: {
                                        ...prevState.called,
                                        priority: priorityFilter[0]
                                    }
                                }))
                        }
                        PapiroConsole.log(result.data.data)
                        PapiroConsole.log("result.data.data aqui")
                        this.setState(prevState => ({
                            organizationAddressesList: result.data.data.organizationaddresseslist && result.data.data.organizationaddresseslist.length > 0 ? result.data.data.organizationaddresseslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationaddresseslist,
                            userAddressesList: result.data.data.useraddresseslist && result.data.data.useraddresseslist.length > 0 ? result.data.data.useraddresseslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.useraddresseslist,
                            sectableStatus: sectableStatus && sectableStatus.length > 1 ? sectableStatus.sort((a, b) => a.name.localeCompare(b.name)) : sectableStatus,
                            address: {
                                ...prevState.address,
                                id: result.data.data.customaddress && result.data.data.customaddress.id != null ? result.data.data.customaddress.id : 0,
                                userid: result.data.data.customaddress && result.data.data.customaddress.userid != null ? result.data.data.customaddress.userid : result.data.data.called.requestinguser.id,
                                name: result.data.data.customaddress && result.data.data.customaddress.name != null ? result.data.data.customaddress.name : '',
                                street: result.data.data.customaddress && result.data.data.customaddress.street != null ? result.data.data.customaddress.street : '',
                                zipcode: result.data.data.customaddress && result.data.data.customaddress.zipcode != null ? result.data.data.customaddress.zipcode : '',
                                number: result.data.data.customaddress && result.data.data.customaddress.number != null ? result.data.data.customaddress.number : '',
                                complement: result.data.data.customaddress && result.data.data.customaddress.complement != null ? result.data.data.customaddress.complement : '',
                                neighborhood: result.data.data.customaddress && result.data.data.customaddress.neighborhood != null ? result.data.data.customaddress.neighborhood : '',
                                city: result.data.data.customaddress && result.data.data.customaddress.city != null ? result.data.data.customaddress.city : '',
                                state: result.data.data.customaddress && result.data.data.customaddress.state != null ? result.data.data.customaddress.state : '',
                                country: result.data.data.customaddress && result.data.data.customaddress.country != null ? result.data.data.customaddress.country : '',
                                longitude: result.data.data.customaddress && result.data.data.customaddress.longitude != null ? result.data.data.customaddress.longitude : '',
                                latitude: result.data.data.customaddress && result.data.data.customaddress.latitude != null ? result.data.data.customaddress.latitude : '',
                                iscustomaddress: result.data.data.customaddress && result.data.data.customaddress.iscustomaddress != null ? result.data.data.customaddress.iscustomaddress : false
                            },
                            categoriesList: result.data.data.organizationcategorylist && result.data.data.organizationcategorylist.length > 0 ? result.data.data.organizationcategorylist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationcategorylist,
                            typesList: result.data.data.typeslist && result.data.data.typeslist.length > 0 ? result.data.data.typeslist.sort((a, b) => a.name.localeCompare(b.name)) : [],
                            technicalUsersList: reordernewarrayuserteclist,
                            techinicalTeamList: result.data.data.techinicalteamlist && result.data.data.techinicalteamlist.length > 0 ? result.data.data.techinicalteamlist.sort((a, b) => a.name.localeCompare(b.name)) : [],
                            DepartamentList: result.data.data.organizationdepartamentlist && result.data.data.organizationdepartamentlist.length > 0 ? result.data.data.organizationdepartamentlist.sort((a, b) => a.name.localeCompare(b.name)) : [],
                            statusList: validStatuses && validStatuses.length > 0 ? validStatuses.sort((a, b) => a.name.localeCompare(b.name)) : validStatuses,
                            departamentId: result.data.data.called.organizationdepartament,
                            priorityList: result.data.data.prioritylist && result.data.data.prioritylist.length > 0 ? result.data.data.prioritylist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.prioritylist,
                            loading: false,
                            //calledValue: calledValueLoc,
                            //calledTime: calledHourLoc,
                            //calledcost:result.data.data.calledcost,
                            requestingUserFromBarraNew: result.data.data.called ? result.data.data.called.requestinguser : null,
                            search: {
                                ...prevState.search,
                                technicalUsersList: (result.data.data.called != null && result.data.data.called.techinicalteamid != null && result.data.data.called.techinicalteamid > 0) ? `/techinicalteamusers/getusers/technicalteam/${result.data.data.called.techinicalteamid}/roletype/${2}` : `/users/category/${this.state.called.organizationcategoryid}/organization/${this.state.called.organizationid}`
                            }
                        }));

                        if (result.data.data.calledcost)
                            this.detailCardsCost(result.data.data.calledcost)
                    } else {

                        let statuses = result.data.data.statuslist.map(item => {
                            return {
                                ...item,
                                enable: item.system == false ? true : (this.getAvaliableStatus().indexOf(item.name.toLowerCase().trim()) > -1)
                            }
                        })

                        PapiroConsole.log(result.data.data.statuslist)
                        PapiroConsole.log("result.data.data.statuslist new")

                        let validStatuses = statuses.filter(item => item.enable == true)
                        let disabledCurrentStatus = statuses.find(item => item.enable == false && item.id == this.state.called.status)
                        if (disabledCurrentStatus != null && typeof disabledCurrentStatus !== 'undefined')
                            validStatuses = [...validStatuses, disabledCurrentStatus]

                        this.setState({
                            called: {
                                ...this.state.called,
                                status: result.data.data.called.status ? result.data.data.called.status.id : null,
                                quickCalledCreated: result.data.data.called.quickcalledcreated,
                                patrimonyId: materialSelectedObj,
                                hasPatrimoniesTrue: materialSelectedObj && materialSelectedObj.length > 0 ? true : false,
                                hasPatrimoniesFalse: materialSelectedObj == null || (materialSelectedObj && materialSelectedObj.length == 0) ? true : false,
                            },
                            clientStatus: result.data.data.called.status,
                            oldStatus: result.data.data.called.status,
                            statusList: validStatuses && validStatuses.length > 0 ? validStatuses.sort((a, b) => a.name.localeCompare(b.name)) : validStatuses,
                            categoriesList: result.data.data.organizationcategorylist && result.data.data.organizationcategorylist.length > 0 ? result.data.data.organizationcategorylist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.organizationcategorylist,
                            typesList: result.data.data.typeslist && result.data.data.typeslist.length > 0 ? result.data.data.typeslist.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data.typeslist,
                            departamentId: result.data.data.called.organizationdepartament
                        });

                        PapiroConsole.log("estou aquiiiiiii")
                        this.getCategoryFields(this.state.called.OrganizationCategoryId.id, result);


                        result.data.data.statuslist.map((item, key) => {
                            if (item.statustype.id == 5) {
                                this.setState({ FinishCalledId: item.id });
                            }
                            if (item.id == 2) { // em andamento
                                this.setState({ ReopenCalledId: item.id });
                            }

                            if (item.id == this.state.called.status && item.statustype.id == 5) {
                                this.setState({ FinishCalled: true });
                            }
                            if (item.id == this.state.called.status && item.statustype.id == 2) {
                                this.setState({ FinishCalled: false });
                            }
                        });

                        this.setState({
                            loading: false,
                        });
                    }

                    PapiroConsole.log("result data do user")
                    PapiroConsole.log(result.data.data)

                    PapiroConsole.log(result.data.data.called.requestingusername)
                    PapiroConsole.log("item.requestinguser 3 ")


                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            description: result.data.data.called.description || "",
                            'hasdisplacement': result.data.data.hasdisplacement,
                            priorityid: result.data.data.called.priorityid && result.data.data.called.priorityid > 0 ? result.data.data.called.priorityid : this.orgCatPriority,
                            priority: result.data.data.called.priority,
                            createdusername: result.data.data.called.createdusername && result.data.data.called.createdusername.length > 0 ? result.data.data.called.createdusername : '',
                            createduseremail: result.data.data.called.createduseremail && result.data.data.called.createduseremail.length > 0 ? result.data.data.called.createduseremail : '',
                            iscreateduserclient: result.data.data.called.iscreateduserclient,
                            requestingusername: result.data.data.called.requestingusername && result.data.data.called.requestingusername.length > 0 ? result.data.data.called.requestingusername : '',
                            requestinguserids: result.data.data.called.requestinguser && result.data.data.called.requestinguser.id ? result.data.data.called.requestinguser.id : 0,
                            requestinguseremail: result.data.data.called.requestinguseremail && result.data.data.called.requestinguseremail.length > 0 ? result.data.data.called.requestinguseremail : '',
                            requestinguserdocument: result.data.data.called.requestinguserdocument && result.data.data.called.requestinguserdocument.length > 0 ? result.data.data.called.requestinguserdocument : '',
                            serviceStartDate: result.data.data.called.servicestartdate
                        }
                    }))

                    let newassigneduser = result.data.data.called.assigneduser;
                    if (newassigneduser != null) {
                        newassigneduser.name = newassigneduser.name + " (" + newassigneduser.email + ")"
                    }

                    let editTitleAndDescriptionCalled = true
                    if (result.data.data.called.organizationcategory && result.data.data.called.organizationcategory.caneditcalledtitleanddescription != null) {
                        if (this.state.isDisabled == true && result.data.data.called.organizationcategory.caneditcalledtitleanddescription == true)
                            editTitleAndDescriptionCalled = false
                        else
                            editTitleAndDescriptionCalled = result.data.data.called.organizationcategory.caneditcalledtitleanddescription
                    }

                    PapiroConsole.log("=== result.data.data.called")
                    PapiroConsole.log(result.data.data.observantuser)

                    var observantArray = []

                    if (result.data.data.called.observantuser != null) {
                        observantArray.push(result.data.data.called.observantuser)
                    }

                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            description: result.data.data.called.description || "",
                            classification: dataNPS,
                            requestingUser: result.data.data.called.requestinguser,
                            //organizationsettings: result.data.data.called.organization.organizationsettings,
                            calledattachments: result.data.data.called.calledattachments && result.data.data.called.calledattachments.length > 0 ? result.data.data.called.calledattachments : [],
                            addressid: result.data.data.called.address ? result.data.data.called.address.id : 0,
                            addresstype: result.data.data.called.addresstype ? `${result.data.data.called.addresstype}` : "1",
                            status: result.data.data.called.status ? result.data.data.called.status.id : this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].status && this.props.calleds[`called-${this.urlId}`].status.id,
                            observantUser: result.data.data.called.observantuser ? result.data.data.called.observantuser : null,
                            clientUsersList: observantArray,
                            observantUserId: result.data.data.called.observantuser ? result.data.data.called.observantuser.id : 0,
                            assignedUser: newassigneduser,
                            quantityreama4: result.data.data.called.quantityreama4 ? result.data.data.called.quantityreama4 : 0,
                            departamentIdN: result.data.data.called.organizationdepartamentid ? result.data.data.called.organizationdepartamentid : 0,
                            employeehourvalue: (result.data.data.called.employeehourvalue && result.data.data.called.employeehourvalue > 0) ? result.data.data.called.employeehourvalue : (result.data.data.defaulttechnicalemployeehourvalue && result.data.data.defaulttechnicalemployeehourvalue > 0) ? result.data.data.defaulttechnicalemployeehourvalue : 0,
                            valueforcalled: (result.data.data.called.valueforcalled && result.data.data.called.valueforcalled > 0) ? result.data.data.called.valueforcalled : (result.data.data.defaulttechnicalvalueforcalled && result.data.data.defaulttechnicalvalueforcalled > 0) ? result.data.data.defaulttechnicalvalueforcalled : 0,
                            costparameter: result.data.data.costparameter,
                            hasdisplacement: result.data.data.called.hasdisplacement,
                            kmvaluerotated: result.data.data.called.kmvaluerotated > 0 ? result.data.data.called.kmvaluerotated : result.data.data.configdisplacement ? result.data.data.configdisplacement.kmvaluerotated : 0,
                            //finishaccompanimentid: result.data.data.finishaccompanimentid ? result.data.data.finishaccompanimentid > 0 ? result.data.data.finishaccompanimentid : null : null,
                            previousfinishaccompanimentid: result.data.data.finishaccompanimentid ? result.data.data.finishaccompanimentid > 0 ? result.data.data.finishaccompanimentid : null : null,
                            hasdisplacementTrue: result.data.data.called.hasdisplacement == true ? true : false,
                            hasdisplacementFalse: result.data.data.called.hasdisplacement == false ? true : false,
                        },
                        clientStatus: result.data.data.called.status ? result.data.data.called.status : this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].status && this.props.calleds[`called-${this.urlId}`].status,
                        oldStatus: result.data.data.called.status ? result.data.data.called.status : this.props.calleds[`called-${this.urlId}`] && this.props.calleds[`called-${this.urlId}`].status && this.props.calleds[`called-${this.urlId}`].status,
                        caneditcalledtitleanddescription: editTitleAndDescriptionCalled,
                        calledcost: result.data.data.calledcost,
                        requestingUserFromBarraNew: result.data.data.called ? result.data.data.called.requestinguser : null,
                    }))

                    PapiroConsole.log(result.data.data.called.techinicalteam)
                    PapiroConsole.log("result.data.data.called.techinicalteam")
                    if (result.data.data.called.techinicalteam && result.data.data.called.techinicalteam.id) {
                        this.currentTeamOfCalled = result.data.data.called.techinicalteam
                    }

                    if (result.data.data.called.status && result.data.data.called.status.id) {
                        this.currentStatusOfCalled = result.data.data.called.status
                    }

                    if (result.data.data.called.assigneduser && result.data.data.called.assigneduser.id) {
                        this.currentAssignedOfCalled = result.data.data.called.assigneduser
                    }




                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            techinicalteam: result.data.data.called.techinicalteam,
                            'securitypassword': result.data.data.called.securitypassword,
                            'hasdisplacementTrue': result.data.data.called.hasdisplacement == true ? true : false,
                            'hasdisplacementFalse': result.data.data.called.hasdisplacement == false ? true : false,
                            'kmvaluerotated': result.data.data.called.kmvaluerotated > 0 ? result.data.data.called.kmvaluerotated : result.data.data.defaulttechnicalkmvaluerotated > 0 ? result.data.data.defaulttechnicalkmvaluerotated : result.data.data.configdisplacement && result.data.data.configdisplacement.kmvaluerotated > 0 ? result.data.data.configdisplacement.kmvaluerotated : 0,
                            'employeehourvalue': (result.data.data.called.employeehourvalue && result.data.data.called.employeehourvalue > 0) ? result.data.data.called.employeehourvalue : (result.data.data.defaulttechnicalemployeehourvalue && result.data.data.defaulttechnicalemployeehourvalue > 0) ? result.data.data.defaulttechnicalemployeehourvalue : 0,
                            'valueforcalled': (result.data.data.called.valueforcalled && result.data.data.called.valueforcalled > 0) ? result.data.data.called.valueforcalled : (result.data.data.defaulttechnicalvalueforcalled && result.data.data.defaulttechnicalvalueforcalled > 0) ? result.data.data.defaulttechnicalvalueforcalled : 0,
                            'costparameter': (result.data.data.called.costparameter) ? result.data.data.called.costparameter : (result.data.data.costparameter) ? result.data.data.costparameter : 1
                        }
                    }))

                } else {
                    this.setState({
                        loading: false,
                    });
                }

                PapiroConsole.log("=== this.getDefaultActions ===")
                PapiroConsole.log(this.state.called.organization)
                PapiroConsole.log(this.state.calledorganizationid)
                if (this.state.called.organization && this.state.called.organization > 0)
                    this.setState({ calledorganizationid: this.state.called.organization })
                this.getDefaultActions(this.state.called.organization && this.state.called.organization > 0 ? this.state.called.organization : this.state.calledorganizationid);
                //this.getPriorities()  

                this.setState({ dataLoading: false })
                this.refreshAccompaniments();
            })
            .catch(err => {

                PapiroConsole.log("entrei no catch do /new")
                PapiroConsole.log(err)
                console.error(err)
                const intl = this.props.intl;
                const { history, match } = this.props;
                if (err.response && err.response.data && err.response.data.errors) {
                    if(err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].includes("called_access_error")){
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "user.cannot.access.this.functionality" }),
                            redirectClientWithNoAccess: true,
                        });
        
                        setTimeout(function () {
        
                            history.push({
                                pathname: `/${match.params.lang}/calleds`,
        
                            })
        
                        }, 5000);

                    }else{

                        
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });

                    }
                    

                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });
    }

    getOrganizationSettingsByOrganizationId = async (organizationId) => {
        const { userSession, intl } = this.props;
        PapiroConsole.log("ENTREI AQUI NO ORGANIZATION SETTINGS")
        let result = await getByOrganizationIdDapper(organizationId)
        if (result != null && result.success != null && result.success) {
            if (result.data != null) {
                this.canstartcallcenter = result.data.canstartcallcenter
                this.canclientfinishcalled = result.data.canclientfinishcalled ? result.data.canclientfinishcalled : false
                this.canclientreopencalled = result.data.canclientreopencalled ? result.data.canclientreopencalled : false
                this.canclientautomaticchangestatusaftersendaccompaniment = result.data.canclientautomaticchangestatusaftersendaccompaniment ? result.data.canclientautomaticchangestatusaftersendaccompaniment : false
                this.caninforminitialchecklist = result.data.caninforminitialchecklist ? result.data.caninforminitialchecklist : false
                this.caninitialchecklistisrequired = result.data.caninitialchecklistisrequired ? result.data.caninitialchecklistisrequired : false
                this.canfinalchecklistisrequired = result.data.canfinalchecklistisrequired ? result.data.canfinalchecklistisrequired : false
                this.allowchangeoforganizationofcalledbyadministration = result.data.allowchangeoforganizationofcalledbyadministration ? result.data.allowchangeoforganizationofcalledbyadministration : false
                this.redirecttheuseraftercompletingthecalled = result.data.redirecttheuseraftercompletingthecalled ? result.data.redirecttheuseraftercompletingthecalled : false
                this.canselectpatrimonyincalled = result.data.canselectpatrimonyincalled;
                this.canselectdepartamentincalled = result.data.canselectdepartamentincalled
                this.canselectparentcalledincalled = result.data.canselectparentcalledincalled
                this.canselectdisplacementincalled = result.data.canselectdisplacementincalled
                this.canselecthourcostorcalledcostincalled = result.data.canselecthourcostorcalledcostincalled
                this.caninformzeroincalledtechnicalcost = result.data.caninformzeroincalledtechnicalcost
                this.canclientselectpatrimonyincalled = result.data.canclientselectpatrimonyincalled;
                this.canclientselecttypeincalled = result.data.canclientselecttypeincalled;
                this.caninformutilizedmaterialsincalledfinish = result.data.caninformutilizedmaterialsincalledfinish;
                this.isrequiredselectteamincalledfinish = result.data.isrequiredselectteamincalledfinish

                this.setState(prevState => ({
                    canselectorganizationcategoriesbylevels: result.data.canselectorganizationcategoriesbylevels ? result.data.canselectorganizationcategoriesbylevels : false,
                    caneditaccompanimentinactivecalled: result.data.caneditaccompanimentinactivecalled ? result.data.caneditaccompanimentinactivecalled : false,
                    caninforminitialchecklist: result.data.caninforminitialchecklist ? result.data.caninforminitialchecklist : false,
                    caninitialchecklistisrequired: result.data.caninitialchecklistisrequired ? result.data.caninitialchecklistisrequired : false,
                    canfinalchecklistisrequired: result.data.canfinalchecklistisrequired ? result.data.canfinalchecklistisrequired : false,
                    allowchangeoforganizationofcalledbyadministration: result.data.allowchangeoforganizationofcalledbyadministration ? result.data.allowchangeoforganizationofcalledbyadministration : false,
                    redirecttheuseraftercompletingthecalled: result.data.redirecttheuseraftercompletingthecalled ? result.data.redirecttheuseraftercompletingthecalled : false,
                    requireattachedimage: result.datarequireattachedimage ? result.data.requireattachedimage : false,
                    actionrequiredwhenstatusorteamorassignedchanges: result.data.actionrequiredwhenstatusorteamorassignedchanges ? result.data.actionrequiredwhenstatusorteamorassignedchanges : false,
                    displayassignedinfomode: result.data.displayassignedinfomode ? result.data.displayassignedinfomode : 1,
                    allowclientinteractiononclosedcalleds: result.data.allowclientinteractiononclosedcalleds ? result.data.allowclientinteractiononclosedcalleds : false,
                    called: {
                        ...prevState.called,
                        organizationsettings: result.data,

                    }
                }))
            }
        } else {

        }
    }

    handleRefreshCalledCostAndSLA = async () => {
        const { id } = this.props.match.params
        if (id != null) {
            await this.setState({ loading: true })
            await this.LoadBarraCalledCost(id)
            await this.setState({ loading: false })
        }
    }

    LoadBarraCalledCost = async (id) => {
        PapiroConsole.log("=== LoadBarraCalledCost ===")
        await Api.get(`/calleds/new/calledcost/${id}`)
            .then(result => {
                PapiroConsole.log("=== LoadBarraCalledCost -> 2 ===")
                PapiroConsole.log(result)
                if (result && result.data && result.data.success && result.data.data) {
                    const intl = this.props.intl;

                    /*if (this.state.called.description != null) {
                        let calledDescription = document.getElementById('calledDescription')
                        if (calledDescription != null) {
                            let qlEditor = calledDescription.querySelector('.ql-container .ql-editor')
                            if (qlEditor != null)
                                qlEditor.innerHTML = this.state.called.description
                        }
                    }*/
                    /*let quillRef = this.myQuillRef
                    PapiroConsole.log("quillRef")
                    PapiroConsole.log(quillRef)
                    if (quillRef != null) {
                        let quillRefEditor = quillRef.getEditor()
                        PapiroConsole.log("quillRefEditor")
                        PapiroConsole.log(quillRefEditor)
                    }*/

                    let calledValueLoc = `R$0,00`

                    PapiroConsole.log("=== LoadBarraCalledCost -> 3 ===")
                    PapiroConsole.log(result.data)
                    if (result.data.data.calledvaue != null && result.data.data.calledvaue > 0) {
                        calledValueLoc = `R$${result.data.data.calledvaue.toFixed(2)}`
                        calledValueLoc = calledValueLoc.replaceAll(".", ",")
                    }

                    let calledHourLoc = result.data.data.calledtime && !isNaN(result.data.data.calledtime) ? result.data.data.calledtime : 0
                    if (calledHourLoc && calledHourLoc > 0) {
                        var splitCalledHoutLoc = [
                            (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                            calledHourLoc % 1
                        ]
                        let nDate = new Date(0, 0)
                        nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
                        if (nDate.getSeconds() >= 58) {
                            nDate.setSeconds(0)
                            nDate.setMinutes(nDate.getMinutes() + 1)
                        }
                        let calledHourLocResult = nDate.toTimeString().slice(2, 8)
                        calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
                        calledHourLoc = calledHourLocResult

                        if (calledHourLoc == '0')
                            calledHourLoc = "00:00:00"
                    } else if (calledHourLoc == '0')
                        calledHourLoc = "00:00:00"

                    this.setState(prevState => ({
                        //loading: false,
                        calledcost: result.data.data.calledcost,
                        calledValue: calledValueLoc,
                        calledTime: calledHourLoc,
                    }));

                    if (result.data.data.calledcost)
                        this.detailCardsCost(result.data.data.calledcost)
                } else {
                    this.setState({
                        //loading: false,
                    });
                }

                this.setState({ dataLoading: false })

                if (this.state.called.title != null) {
                    let calledTitle = document.getElementById('title')
                    if (calledTitle != null) {
                        calledTitle.value = this.state.called.title
                    }
                }
            })
            .catch(err => {
                console.error(err)
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        //loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });

                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        // loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });
    }
    cleanCache = async () => {
        let id = this.state.called.id;
        this.setState({
            loading: true,
        });
        Api.get(`/calleds/cleancache/${id}`)
            .then(async result => {
                if (result && result.data && result.data.success && result.data.data) {
                    await this.LoadBarraNew(id);

                    ///this.setState(prevState => ({ loading: false, }));

                }

            })
            .catch(err => {
                console.error(err)
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });

                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        // loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });

    }
    LoadUI = async () => {
        const { userSession, calleds } = this.props;
        const intl = this.props.intl;
        const { id } = this.props.match.params
        this.urlId = id
        PapiroConsole.log("entrei no load ui")

        await this.setState(prevState => ({
            called: {
                ...prevState.called,
                id: id
            }
        }))

        let calledGridData = null

        if (isNaN(id)) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.does.not.exist" })
            });
            this.props.history.push(`/${this.props.match.params.lang}/calleds`)
        }

        calledGridData = await this.getCalledGridById(id) // Tinha um IF aqui pra chamar somente quando o reduz viesse vazio; Tiramos para rescrever o Redux sempre, para garantir os dados;

        this.VertexIsAvaliable();

        let orgCat = (calleds[`called-${id}`] && calleds[`called-${id}`].organizationcategory && calleds[`called-${id}`].organizationcategory.id) ? calleds[`called-${id}`].organizationcategory.id : (calledGridData && calledGridData.organizationcategory && calledGridData.organizationcategory.id) ? calledGridData.organizationcategory.id : 0
        PapiroConsole.log("=== LoadUI -> organizationcategory.id ===")
        PapiroConsole.log(calleds)
        PapiroConsole.log(id)
        PapiroConsole.log(calleds[`called-${id}`])
        PapiroConsole.log(orgCat)
        PapiroConsole.log(this.state.called)
        if (orgCat && orgCat > 0) {
            await this.getCategoryParentByChildrenOrganizationCategoryId(orgCat, '', true)
        }

        let CallDueDate = null

        let service = getSlaAtendimento((calleds[`called-${id}`] != null && typeof calleds[`called-${id}`] !== 'undefined') ? calleds[`called-${id}`] : calledGridData, this.props.userSession)

        if (service && service >= 100)
            CallDueDate = <Chip style={{ color: 'red', borderColor: 'red', fontWeight: 600 }} label={intl.formatMessage({ id: "expired" })} color="error" variant="outlined" />


        this.setState(prevState => ({
            called: {
                ...prevState.called, CallDueDate: CallDueDate
            }
        }))
        this.getOrganizationSettingsByOrganizationId(calledGridData.organizationid)
        this.LoadBarraNew(id);
        this.LoadBarraCalledCost(id);
    }
    async VertexIsAvaliable() {
        var result = await Api.VertexIsAvaliable();

        this.setState({ disabledvertex: !result });
    }

    async addAccompanimentActionToKnowledgeBase(accompanimentId) {
        this.setState({ loading: true })
        const intl = this.props.intl;

        const response = await addAccompanimentToKnowledgeBase(accompanimentId);
        if (response && response.success && response.data) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "add.knowledgebase" })
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: response && response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }

        this.setState({ loading: false })
    }

    async getCategoryFields(organizationCategoryId, resultField) {


        var result
        if (this.isClient) {
            result = await CalledOrganizationChecklistService.GetByOrganizationCategoryIdAndActiveCustomerEdit(organizationCategoryId);
        }
        else {
            result = await CalledOrganizationChecklistService.GetByOrganizationCategoryIdAndActive(organizationCategoryId);
        }

        PapiroConsole.log("result aqui 2036")
        PapiroConsole.log(result)
        PapiroConsole.log("result field")
        PapiroConsole.log(resultField)

        if (result.success) {
            PapiroConsole.log(result.data.length)
            if (result.data.length > 0) {

                this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                this.setState({ calledOrganizationFieldsList: resultField.data.data.called.calledorganizationfields });
                result.data.map(item => {

                    var foundItem = false
                    var checkLoop = false
                    if (resultField.data.data.called.calledorganizationfields != undefined && resultField.data.data.called.calledorganizationfields.length) {
                        checkLoop = true
                        resultField.data.data.called.calledorganizationfields.map(item2 => {

                            if (item.id == item2.organizationfieldid) {
                                PapiroConsole.log("entrei aqui x vezes")
                                foundItem = true
                                if (item.organizationfieldtype == 2 || item.organizationfieldtype == 4) {
                                    this.setState(prevState => ({
                                        called: {
                                            ...prevState.called,
                                            ['ofield-' + item.id]: item2.value + '\n' + item2.organizationfieldoptionsvaluesid

                                        }
                                    }))

                                }
                                else {
                                    this.setState(prevState => ({
                                        called: {
                                            ...prevState.called,
                                            ['ofield-' + item.id]: item2.value

                                        }
                                    }))
                                }
                            }
                            if (foundItem == false) {

                                this.setState(prevState => ({
                                    called: {
                                        ...prevState.called,
                                        ['ofield-' + item.id]: ""

                                    }
                                }))

                            }

                        })
                    }

                    if (checkLoop == false) {

                        if (item.organizationfieldtype == 3) {

                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + item.id]: true

                                }
                            }))

                        }
                        else {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + item.id]: ""

                                }
                            }))

                        }


                    }

                })

                this.returnFields();

            }
            else {

                this.getOrganizationFieldsNew(this.state.called.organization, resultField)

            }

        }

        else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async getOrganizationDepartaments(id) {
        var result = await getOrganizationsDepartaments(id);
        //console.log(result)
        //console.log("getOrganizationDepartaments")
        if (result.success) {
            if (result.data != null && result.data.length > 0) {
                this.setState({ DepartamentList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                this.getOrganizationDepartamentsById(this.state.called.departamentIdN)
            }
            this.setCountLoading("getOrganizationDepartaments");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getOrganizationDepartaments");
            Api.kickoff(result);
        }
    }


    async getOrganizationFieldsNew(organizationId, resultField) {


        var result
        if (!this.isClient) {
            var result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganizationAndCategories(organizationId);
        }
        else {
            result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganizationCustomerEdit(organizationId);
        }


        if (result.success) {

            //this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            if (result.data.length > 0) {

                this.setState({ organizationFieldList: result.data });
                this.setState({ calledOrganizationFieldsList: resultField.data.data.called.calledorganizationfields });
                result.data.map(item => {

                    var foundItem = false
                    var checkLoop = false


                    if (resultField.data.data.called.calledorganizationfields != undefined && resultField.data.data.called.calledorganizationfields.length) {
                        checkLoop = true
                        resultField.data.data.called.calledorganizationfields.map(item2 => {

                            if (item.id == item2.organizationfieldid) {
                                PapiroConsole.log("entrei aqui x vezes")
                                foundItem = true
                                if (item.organizationfieldtype == 2 || item.organizationfieldtype == 4) {
                                    this.setState(prevState => ({
                                        called: {
                                            ...prevState.called,
                                            ['ofield-' + item.id]: item2.value + '\n' + item2.organizationfieldoptionsvaluesid

                                        }
                                    }))

                                }
                                else {
                                    this.setState(prevState => ({
                                        called: {
                                            ...prevState.called,
                                            ['ofield-' + item.id]: item2.value

                                        }
                                    }))
                                }
                            }
                            if (foundItem == false) {

                                this.setState(prevState => ({
                                    called: {
                                        ...prevState.called,
                                        ['ofield-' + item.id]: ""

                                    }
                                }))

                            }

                        })
                    }

                    if (checkLoop == false) {

                        if (item.organizationfieldtype == 3) {

                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + item.id]: true

                                }
                            }))

                        }
                        else {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + item.id]: ""

                                }
                            }))

                        }


                    }

                })

                this.returnFields();

            }

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    checkRoleToDisable() {



        if (this.AdmOrganization || this.isCoordenator || this.isClient || this.globalAdm) {



            this.setState({
                isDisabled: false

            })


        }
        else {

            this.setState({
                isDisabled: true
            });
        }



    }

    async deleteKnowledgeBase(accompanimentId) {
        this.setState({ loading: true })
        const intl = this.props.intl;

        const response = await deleteKnowledgeBaseByAccompanimentId(accompanimentId);
        if (response && response.success) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "delete.knowledgebase" })
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
            })
        }

        this.setState({ loading: false })
    }

    getTechnicalPublicAccompaniment = async _ => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getAccompanimentsToFinish(this.state.called.id, this.state.called.requestingUser.id)
        if (result && result.data && result.data.data) {
            let finishAccompaniments = result.data.data.map(item => {
                let body = item.description
                let temp = document.createElement("div")
                temp.innerHTML = body
                let sanitized = temp.textContent || temp.innerText

                return {
                    ...item,
                    name: sanitized,
                    Name: item.description
                }
            })

            this.setState({
                finishAccompanimentList: finishAccompaniments && finishAccompaniments ? finishAccompaniments.sort((a, b) => a.name.localeCompare(b.name)) : finishAccompaniments,
                loading: false,
                finishAccompanimentModalOpen: !this.state.finishAccompanimentModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    async getCalledCostToCard(id) {
        // this.setState({ loading: true })
        Api.get(`/calledCost/called/${id}`)
            .then(result => {
                if (result && result.data && result.data.success && result.data.data) {
                    const intl = this.props.intl;

                    let calledValueLoc = `R$0,00`

                    if (result.data.data.calledvalue != null && result.data.data.calledvalue > 0) {
                        calledValueLoc = `R$${result.data.data.calledvalue.toFixed(2)}`
                        calledValueLoc = calledValueLoc.replaceAll(".", ",")
                    }

                    let calledHourLoc = result.data.data.calledtime && !isNaN(result.data.data.calledtime) ? result.data.data.calledtime : 0
                    if (calledHourLoc && calledHourLoc > 0) {
                        var splitCalledHoutLoc = [
                            (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
                            calledHourLoc % 1
                        ]
                        let nDate = new Date(0, 0)
                        nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
                        if (nDate.getSeconds() >= 58) {
                            nDate.setSeconds(0)
                            nDate.setMinutes(nDate.getMinutes() + 1)
                        }
                        let calledHourLocResult = nDate.toTimeString().slice(2, 8)
                        calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
                        calledHourLoc = calledHourLocResult

                        if (calledHourLoc == '0')
                            calledHourLoc = "00:00:00"
                    } else if (calledHourLoc == '0')
                        calledHourLoc = "00:00:00"

                    this.setState({
                        calledValue: calledValueLoc,
                        calledTime: calledHourLoc,

                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({

                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({

                    });

                }
                else {
                    const intl = this.props.intl;
                    this.setState({

                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
            });
    }

    async refreshAccompaniments() {
        this.setState({
            loading: true,
        });
        Api.get(`/accompaniments/called/${this.state.called.id}`)
            .then(result => {
                if (result && result.data && result.data.success && result.data.data) {
                    //alert('ATUALIZAR CHAMADOS');
                    PapiroConsole.log("entrei aqui no sexto")
                    this.props.addAccompanimentsList(result.data.data);
                    this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id);
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
            });

    }

    async componentWillReceiveProps(nextProps) {


        PapiroConsole.log("=== componentWillReceiveProps ===")
        PapiroConsole.log(nextProps)
        const { id } = this.props.match.params
        if (nextProps.headerMenu.refresh === true) {
            this.props.reload(false)
            PapiroConsole.log("nextProps.headerMenu.refresh === true")
            PapiroConsole.log("entrei aqui no refresh true")
            await this.setState({ loading: true, countLoading: 0, });
            await this.LoadUI();
            PapiroConsole.log("entrei aqui no refresh true 2")
        } else if (id != null && !isNaN(id) && this.state.called != null && this.state.called.id != null && id != this.state.called.id) {
            await this.setState({ loading: true, countLoading: 0, });

            await this.LoadUI();
        }
    }

    async getPatrimonySubgroups(patrimonyGroupId) {
        var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
        if (result.success) {
            return result.data;
        } else {
            return [];
        }
    }




    handleClick = (event, value) => {

        this.setState({ anchorEl: event.currentTarget });
    };

    async getParentCalledsById() {


        var result = await CalledService.getParentCalledById(this.state.called.ParentCalledId);

        if (result.success) {
            result.data.parentname = `${result.data.id}: ${result.data.title}`
            await this.setState({ called: { ...this.state.called, ParentCalledId: result.data } });
            this.setCountLoading("getParentCalledsById");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            this.setCountLoading("getParentCalledsById");

        }
    };

    async getOrganizationDepartamentsById(id) {
        var result = await getOrganizationDepartamentsById(id);

        if (result.success) {
            if (result.data) {
                this.setState({ departamentId: result.data });
            }
            this.setCountLoading("getOrganizationDepartamentsById");
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getOrganizationDepartamentsById");
            Api.kickoff(result);

        }
    }

    async getDepartamentsByOrganizationCategoryId(id) {
        this.setState({ loading: true });
        var result = await getDepartamentsByOrganizationCategoryId(id);
        if (result.success) {
            if (result.data && result.data.length > 0) {
                if (this.isClient) {
                    if (this.state.called.departamentId && this.state.called.departamentId.id) {
                        var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)
                        if (updatedList)
                            updatedList = updatedList[0]

                        if (result.data.length == 1) {
                            this.setState({ departamentId: result.data[0] })
                        }

                        this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
                    } else {
                        this.setState({ loading: false });
                    }
                } else if (this.isTec) {
                    if (this.state.called.departamentId && this.state.called.departamentId.id) {
                        var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)

                        if (result.data.length == 1) {
                            this.setState({ departamentId: result.data[0] })
                        }

                        this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
                    } else {

                        this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                    }
                } else {

                    this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                }
            } else {
                this.setState({ loading: false, DepartamentList: [] });
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    async getDepartamentByUserId() {
        const { userSession } = this.props;
        this.setState({ loading: true });

        var result = await getDepartamentByUserId(userSession.user.id);
        if (result.success) {
            this.setState({ loading: false, userDepartamentId: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    }

    openPatrimonioModal() {
        this.setState({
            modalPatrimonio: true,
        });
    }

    fecharModalPatrimonio() {
        this.setState({
            modalPatrimonio: false,
        });
    }

    openInitialChecklistModal = () => {
        this.setState({ loading: false, initialChecklistModalOpen: true })
    }

    closeInitialChecklistModal = () => {
        this.setState({ initialChecklistModalOpen: false })
    }

    openConfirmReopenCalledClientDialog = () => {
        const { intl } = this.props
        this.setState({ loading: true });

        /*let sanitized = this.convertHtmlOption(this.state.action.description)
        if (sanitized != null && sanitized.length > 0)
            sanitized = sanitized.trim()
            
        if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0) {

            PapiroConsole.log("entrei aqui nesse papiro ")
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.reopen.error.missing.action.client"})
            });
            return false;
        }*/

        this.setState({ loading: false, loadingTwo: false, confirmReopenCalledClientDialog: true })
    }

    closeConfirmReopenCalledClientDialog = () => {
        this.setState({ confirmReopenCalledClientDialog: false })
    }

    confirmReopenCalledClient = async () => {

        const { intl } = this.props
        // 

        let inputValue = ''
        let dialogAccompanimentAction = document.getElementById('dialogAccompanimentAction')
        PapiroConsole.log("=== dialogAccompanimentAction ===")
        PapiroConsole.log(dialogAccompanimentAction)
        if (dialogAccompanimentAction != null) {
            let qlEditor = dialogAccompanimentAction.querySelector('.ql-container .ql-editor')
            if (qlEditor != null) {
                PapiroConsole.log("=== qlEditor ===")
                PapiroConsole.log(qlEditor)
                inputValue = `${qlEditor.innerHTML}`

                let accompanimentAction = document.getElementById('accompanimentAction')
                if (accompanimentAction != null) {
                    let qlEditorAccompaniment = accompanimentAction.querySelector('.ql-container .ql-editor')
                    if (qlEditorAccompaniment != null) {
                        qlEditorAccompaniment.innerHTML = inputValue
                    }
                }
            }
        }

        await this.setState(prevState => ({ action: { ...prevState.action, description: inputValue } }))

        let sanitized = this.convertHtmlOption(this.state.action.description)
        if (sanitized != null && sanitized.length > 0)
            sanitized = sanitized.trim()

        if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0) {

            PapiroConsole.log("entrei aqui nesse papiro ")
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.reopen.error.missing.action.client" })
            });
            return false;
        }
        else {
            let accompanimentAction = document.getElementById('dialogAccompanimentAction')
            PapiroConsole.log("=== dialogAccompanimentAction 2 ===")
            PapiroConsole.log(accompanimentAction)
            if (accompanimentAction != null) {
                let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
                if (qlEditor != null) {
                    PapiroConsole.log("=== qlEditor 2 ===")
                    PapiroConsole.log(qlEditor)
                    qlEditor.innerHTML = ''
                }
            }
            this.setState({ confirmReopenCalledClientDialog: false })
            await this.submitAction(true, false, true)
        }

    }

    async reopenCalledClient() {
        this.setState({ loading: true });

        const { id } = this.props.match.params
        let updatedUser = this.state.called.updatedUser;

        let data = new FormData();
        data.append("id", parseInt(id))
        data.append("updateduser", updatedUser)

        await Api.put("/calleds/reopen/client", data)
            .then(result => {
                if (result.data.success) {
                    this.setState({ FinishCalled: true });
                    this.props.addCurrentCalled(result.data.data);
                    this.props.addCalled(result.data.data)
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "edit.called.success" }),
                        files: [],
                        action: {
                            description: "",
                            privacy: ""
                        },
                        clientStatus: result.data.data.status,
                        oldStatus: result.data.data.status,
                    });
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
            });
    }

    async getOrganizationAddresses(organizationId) {

        if (organizationId == null) {
            this.setCountLoading("getOrganizationAddresses");
            return false;
        }
        const result = await CalledService.getOrganizationAddresses(organizationId);
        const intl = this.props.intl;

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.organization.no.registered.address" }),
                            notificationLinkMove: "organizations"
                        });
                    } else {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.organization.no.registered.address" })
                        });
                    }
                }
            }

            this.setState({ organizationAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            this.setCountLoading("getOrganizationAddresses");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            this.setCountLoading("getOrganizationAddresses");


        }
    };

    async getPatrimonios(id) {
        if (this.state.patrimonyList && this.state.patrimonyList.length > 0)
            return false

        this.setState({ loading: true })
        if (id == null) {
            //this.setCountLoading("getPatrimonios");
            return false;
        }

        const result = await CalledService.getPatrimonyByOrg(id);
        //console.log(result);
        if (result.success) {
            this.setState({ loading: false, patrimonyList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
            //this.setCountLoading("getPatrimonios");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            //this.setCountLoading("getPatrimonios");


        }
        this.setState({ loading: false })
    };

    async getCalledPatrimonios(id) {
        if (id == null) {
            this.setCountLoading("getCalledPatrimonios");
            return false;
        }
        const result = await CalledService.getOrgPatrimony(id);
        //console.log(result);
        if (result.success) {

            let materialSelectedObj = [];

            for (var obj of result.data) {
                materialSelectedObj.push(obj.organizationpatrimony);
            }

            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    "patrimonyId": materialSelectedObj
                }
            }));
            this.setCountLoading("getCalledPatrimonios");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            this.setCountLoading("getCalledPatrimonios");

        }
    };

    async getCep(cepNumber) {
        return new Promise(function (resolve, reject) {

            fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
                return response.json();
            }).then(function (data) {
                // console.log(data);
                resolve(data);
            })["catch"](function (err) {
                // console.log(err);
                reject(err);
            })["finally"](function (data) {
                return data;
            });

        });
    }

    async handleInputChangeAddress(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (name == 'zipcode') {
            if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                this.setState(prevState => ({
                    address: {
                        ...prevState.address,
                        [name]: value
                    }
                }));
                if (value.length == 8) {
                    this.setState({ loading: true });
                    var data = await this.getCep(value);
                    this.setState(prevState => ({
                        address: {
                            ...prevState.address,
                            'street': data.logradouro,
                            'complement': data.complemento,
                            'neighborhood': data.bairro,
                            'city': data.localidade,
                            'state': data.uf,
                            'country': 'BRASIL',
                        }
                    }));
                    this.setState({ loading: false });
                }
            } else {
                return false;
            }
        } else {
            this.setState(prevState => ({
                address: {
                    ...prevState.address,
                    [name]: value
                }
            }));
        }
    }

    NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={false}
                decimalSeparator=","
                isNumericString
                decimalScale={2}
                prefix={'R$'}
            />
        );
    }




    /*  async addAccompanimentNotification(accompaniment){
  
          PapiroConsole.log(accompaniment)
          PapiroConsole.log("accompaniment novo")
  
  
          let accompanimentId = accompaniment[accompaniment.length - 1].id;
  
          const { userSession } = this.props;
          
          PapiroConsole.log("entrei na função addAccompanimentNotification")
  
          PapiroConsole.log(this.context)
          PapiroConsole.log("this.context")
          PapiroConsole.log(accompanimentId)
          PapiroConsole.log(this.state.called.assignedUser)
          PapiroConsole.log(userSession.user.id)
          PapiroConsole.log(this.state.called.id)
          PapiroConsole.log(this.state.called.organization)
  
          
          
          await this.context.chatHub.invoke("SendAccompanimentNotification", this.state.called.assignedUser.id,userSession.user.id, this.state.called.id, accompanimentId, this.state.called.organization).then(result => {
              
  
              if(result == true){
                  PapiroConsole.log(result)
                  PapiroConsole.log("result notification")    
  
              }
              else{
                  
              }
              
              
            }) .catch(err => {
  
              PapiroConsole.log(err)
              PapiroConsole.log("result notification error")
              
            });
  
      }*/

    checkAccompaniment(itsGoTheCalled = false, closeFinishModal = false, directFinish = false, content = null) {
        PapiroConsole.log("=== checkAccompaniment -> 1 ===")
        this.setState({
            loading: true,
            loadingTwo: true
        });

        const intl = this.props.intl;

        let statusData = this.state.statusList.find(item => item.id == this.state.called.status)
        if (!this.isClient && ((content !== null && content.length > 0) || this.state.files.length > 0) && this.state.action.privacy !== 'true' && this.state.action.privacy !== 'false') {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.select.action.privacy" })
            });
            return false
        }
        PapiroConsole.log("=== CheckStatus ===")
        PapiroConsole.log(this.state.called.status)
        PapiroConsole.log(statusData)
        PapiroConsole.log(this.state.directFinishCalled)
        PapiroConsole.log(this.state.called.calledstatus)
        PapiroConsole.log((((this.state.called.status && this.state.called.status == 4) || (statusData && statusData.statustypeid == 5)) || this.state.directFinishCalled) && this.state.called.calledstatus != null)
        PapiroConsole.log(this.state.called.status && this.state.called.status == 4)
        PapiroConsole.log(statusData && statusData.statustypeid == 5)
        PapiroConsole.log((this.state.called.status && this.state.called.status == 4) || (statusData && statusData.statustypeid == 5))
        PapiroConsole.log((this.state.called.status && this.state.called.status == 4) || (statusData && statusData.statustypeid == 5) || this.state.directFinishCalled)
        if ((((this.state.called.status && this.state.called.status == 4) || (statusData && statusData.statustypeid == 5)) || this.state.directFinishCalled) && this.state.called.calledstatus != null) {
            PapiroConsole.log("entrei na condição primeira vez")
            if (!this.isClient) {
                let calledIsRun = this.state.called.calledstatus.filter(item => item.nextstatus.statustypeid == 2)
                PapiroConsole.log("=== calledIsRun ===")
                PapiroConsole.log(this.state.called.calledstatus)
                PapiroConsole.log(calledIsRun)
                if ((calledIsRun && calledIsRun.length == 0)) {
                    let runningStatusName = "Em andamento"
                    let runningStatusNameFilter = this.state.statusList.filter(item => item.statustypeid == 2)
                    PapiroConsole.log("=== runningStatusNameFilter ===")
                    PapiroConsole.log(runningStatusNameFilter)
                    if (runningStatusNameFilter && runningStatusNameFilter.length > 0) {
                        runningStatusName = runningStatusNameFilter[0].name
                    }
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.status" })
                    });
                    return false
                }
                PapiroConsole.log("=== totalCompletedInitialChecklists -> 1")
                PapiroConsole.log(this.state.totalInitialChecklists)
                PapiroConsole.log(this.state.totalCompletedInitialChecklists)
                PapiroConsole.log("variaveis checklist iniciais aqui")
                PapiroConsole.log(this.caninforminitialchecklist)
                PapiroConsole.log(this.caninitialchecklistisrequired)
                PapiroConsole.log(this.state.totalCompletedInitialChecklists)
                PapiroConsole.log(this.state.totalInitialChecklists)
                if (this.caninforminitialchecklist && this.caninitialchecklistisrequired && this.state.totalInitialChecklists != this.state.totalCompletedInitialChecklists) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.activities.checklist" })
                    })
                    return false
                }
                if (this.isrequiredselectteamincalledfinish && (this.state.called.techinicalteam == null || (this.state.called.techinicalteam && (this.state.called.techinicalteam.id == null || (this.state.called.techinicalteam.id && this.state.called.techinicalteam.id == 0))))) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: "O campo 'Time de atendimento' é obrigatório para finalizar o chamado"
                    })
                    return false
                }
            }
        }

        this.setState({
            loading: false,
            loadingTwo: false
        });

        PapiroConsole.log("=== checkAccompaniment -> 2 ===")
        return true;
    }

    checkFinishCalled(itsGoTheCalled = false, closeFinishModal = false, directFinish = false) {
        PapiroConsole.log("=== checkFinishCalled 1 ===")
        this.setState({
            loading: true,
            loadingTwo: true
        });
        const intl = this.props.intl;
        PapiroConsole.log("teste de finalização aqui 1")
        let calledDesc = this.convertHtmlOption(this.state.called.description)
        if(calledDesc != null){
            calledDesc = calledDesc.trim()
        }
        PapiroConsole.log("teste de finalização aqui 1.1.1")
        if (this.state.caneditcalledtitleanddescription && (calledDesc == null || (calledDesc != null && calledDesc.length == 0 && this.state.called.description.indexOf('<img') == -1))) {
            PapiroConsole.log("teste de finalização aqui 1.1")
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.called.description" })
            });
            return false;
        }
        PapiroConsole.log("=== Associar categoria ===")
        PapiroConsole.log(this.state.called.OrganizationCategoryId)
        if ((this.state.called.OrganizationCategoryId == null || typeof this.state.called.OrganizationCategoryId === 'undefined') || (this.state.called.OrganizationCategoryId != null && this.state.called.OrganizationCategoryId.cancreatecalled != null && this.state.called.OrganizationCategoryId.cancreatecalled === false)) {
            PapiroConsole.log("teste de finalização aqui 1.2")
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.associate.category.error" })
            });
            return false;
        }
        if (this.state.called.ParentCalledId !== 0 && this.state.called.ParentCalledId != null && this.state.called.ParentCalledId != undefined) {
            PapiroConsole.log("teste de finalização aqui 1.3")
            if (this.state.called.id === this.state.called.ParentCalledId.id) {
                PapiroConsole.log("teste de finalização aqui 1.4")
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.parent.error" })
                });
                return false;
            }
        }
        PapiroConsole.log("teste de finalização aqui 2")
        PapiroConsole.log(this.state.called.assignedUser)
        PapiroConsole.log("this.state.called.assignedUser")
        if (this.isClient == false && this.state.called.status && this.state.called.status == 2 && !this.state.called.assignedUser && !this.state.directFinishCalled) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.attendance.error.missing.responsible" })
            });
            return false
        }

        if (((this.state.called.status && this.state.called.status == 4) || this.state.directFinishCalled) && this.state.called.calledstatus != null) {
            PapiroConsole.log("=== totalCompletedInitialChecklists -> 2")
            PapiroConsole.log(this.state.totalInitialChecklists)
            PapiroConsole.log(this.state.totalCompletedInitialChecklists)
            if (this.caninforminitialchecklist && this.caninitialchecklistisrequired && this.state.totalInitialChecklists != this.state.totalCompletedInitialChecklists) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.activities.checklist" })
                })
                return false
            }
            /*let calledIsRun = this.state.called.calledstatus.filter(item => item.nextstatusid == 2)
            PapiroConsole.log("=== calledIsRun ===")
            PapiroConsole.log(this.state.called.calledstatus)
            PapiroConsole.log(calledIsRun)
            if(calledIsRun && calledIsRun.length == 0) {
                let runningStatusName = "Em andamento"
                let runningStatusNameFilter = this.state.statusList.filter(item => item.id == 2)
                PapiroConsole.log("=== runningStatusNameFilter ===")
                PapiroConsole.log(runningStatusNameFilter)
                if(runningStatusNameFilter && runningStatusNameFilter.length > 0) {
                    runningStatusName = runningStatusNameFilter[0].name
                }
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.called.finish.error.missing.status"}) + `${runningStatusName}`
                });
                return false
            }*/
        }
        PapiroConsole.log("teste de finalização aqui 3")
        let statusData = this.state.statusList.find(item => item.id == this.state.called.status)
        PapiroConsole.log("=== StatusData ===")
        PapiroConsole.log(statusData)
        PapiroConsole.log(this.state.called.calledstatus)
        PapiroConsole.log(this.isrequiredselectteamincalledfinish)
        PapiroConsole.log(this.state.called.techinicalteam)
        if ((((this.state.called.status && this.state.called.status == 4) || (statusData && statusData.statustypeid == 5)) || this.state.directFinishCalled) && this.state.called.calledstatus != null) {
            PapiroConsole.log("=== StatusData -> 1 ===")
            if (!this.isClient) {
                PapiroConsole.log("=== StatusData -> 2 ===")
                PapiroConsole.log(
                    this.isrequiredselectteamincalledfinish == true && (
                        this.state.called.techinicalteam == null || (
                            this.state.called.techinicalteam != null && this.state.called.techinicalteam.id != null && this.state.called.techinicalteam.id === 0
                        )
                    )
                )
                PapiroConsole.log(
                    this.state.called.techinicalteam == null || (
                        this.state.called.techinicalteam != null && this.state.called.techinicalteam.id != null && this.state.called.techinicalteam.id === 0
                    )
                )
                PapiroConsole.log(this.state.called.techinicalteam != null && this.state.called.techinicalteam.id != null && this.state.called.techinicalteam.id === 0)
                if (this.isrequiredselectteamincalledfinish == true && (this.state.called.techinicalteam == null || (this.state.called.techinicalteam != null && this.state.called.techinicalteam.id != null && this.state.called.techinicalteam.id === 0))) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: "O campo 'Time de atendimento' é obrigatório para finalizar o chamado"
                    })
                    return false
                }
            }
        }

        PapiroConsole.log("teste de finalização aqui 4")

        if (!this.isClient) {
            PapiroConsole.log("teste de finalização aqui 5")
            PapiroConsole.log("=== checkFinishCalled 1.1 ===")
            let status = this.state.called.status;
            let cancelledStatus = false;

            let theStatusIsFinish = false;

            let clientStatus = this.state.clientStatus;
            for (let value in this.state['statusList']) {
                PapiroConsole.log("teste de finalização aqui 6")
                if (this.state['statusList'][value].id == status) {

                    if (this.state['statusList'][value].finished == true || this.state['statusList'][value].statustypeid === 5) {
                        theStatusIsFinish = true;
                        if (this.state['statusList'][value].id == 6) // cancelado
                        {
                            cancelledStatus = true
                        }
                    }
                    if (this.state['statusList'][value].id == 4)
                        clientStatus = this.state['statusList'][value]
                }
            }
            if (directFinish == true || this.state.directFinishCalled == true) {
                PapiroConsole.log("teste de finalização aqui 7")
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        status: 4
                    },
                    clientStatus: clientStatus
                }))
                theStatusIsFinish = true
            }
            PapiroConsole.log("teste de finalização aqui 8")
            PapiroConsole.log("=== checkFinishCalled -> not client ===")
            PapiroConsole.log(theStatusIsFinish)
            PapiroConsole.log(this.state.called.assignedUser)
            PapiroConsole.log(cancelledStatus)
            PapiroConsole.log((theStatusIsFinish == true && (this.state.called.assignedUser == 0 || (this.state.called.assignedUser != null && this.state.called.assignedUser.id != null && this.state.called.assignedUser.id == 0)) && cancelledStatus == false))
            PapiroConsole.log((this.state.called.assignedUser == 0 || (this.state.called.assignedUser && this.state.called.assignedUser.id && this.state.called.assignedUser.id == 0)))
            PapiroConsole.log((this.state.called.assignedUser && this.state.called.assignedUser.id && this.state.called.assignedUser.id == 0))

            let sanitized = this.convertHtmlOption(this.state.action.description)
            if (sanitized != null && sanitized.length > 0)
                sanitized = sanitized.trim()

            let sanitizedCalled = this.convertHtmlOption(this.state.called.description)
            if (sanitizedCalled != null && sanitizedCalled.length > 0)
                sanitizedCalled = sanitizedCalled.trim()

            if ((sanitized == null || (sanitized != null && sanitized.length == 0)) &&
                itsGoTheCalled == true &&
                theStatusIsFinish == true &&
                this.statusDataBase != status) {
                if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.isClient == true && (this.globalAdm == false && this.AdmOrganization == false && this.isCoordenator == false && this.isTechinical == false)) {
                    PapiroConsole.log("=== Caiu -> 1 ===")
                    PapiroConsole.log(this.state.called.finishaccompanimentid)
                    if (this.state.called.finishaccompanimentid == null ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id && this.state.called.finishaccompanimentid.id == 0) ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id == null)) {
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.action" })
                        });
                        return false;
                    }
                }
            } else if (this.state.called.priorityid == null || (this.state.called.priorityid != null && this.state.called.priorityid == 0)) {
                PapiroConsole.log("=== Caiu -> 2 ===")
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.empty.field.priority" })
                });
                return false;
            } else if (this.state.called.addressid == 0 && theStatusIsFinish && cancelledStatus == false) {
                PapiroConsole.log("=== Caiu -> 3 ===")
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.location" })
                })
                return false
            } else if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && theStatusIsFinish) {
                if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.isClient == true && (this.globalAdm == false && this.AdmOrganization == false && this.isCoordenator == false && this.isTechinical == false)) {
                    PapiroConsole.log("=== Caiu -> 4 ===")
                    PapiroConsole.log("=== this.state.called.finishaccompanimentid ===")
                    PapiroConsole.log(this.state.called.finishaccompanimentid)
                    if (this.state.called.finishaccompanimentid == null ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id && this.state.called.finishaccompanimentid.id == 0) ||
                        (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id == null)) {
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.action.existing" })
                        })
                        return false
                    }
                }
            } else if (this.state.caneditcalledtitleanddescription && (sanitizedCalled == null || (sanitizedCalled != null && sanitizedCalled.length == 0 && this.state.called.description.indexOf('<img') == -1))) {
                PapiroConsole.log("=== Caiu -> 5 ===")
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.description" })
                })
                return false
            } else if (theStatusIsFinish == false && this.state.caneditcalledtitleanddescription && (sanitizedCalled == null || (sanitizedCalled != null && sanitizedCalled.length == 0)) && this.state.directSaveCalled == false && directFinish == false) {
                PapiroConsole.log("=== Caiu -> 6 ===")
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.action" }),
                    directSaveCalled: false
                })
                return false
            }
            if (theStatusIsFinish === true && (this.state.called.assignedUser === 0 || (this.state.called.assignedUser != null && this.state.called.assignedUser.id != null && this.state.called.assignedUser.id === 0)) && cancelledStatus === false) {
                PapiroConsole.log("=== Caiu -> 7 ===")
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.responsible" })
                });
                return false;
            }
            if (this.state.directSaveCalled == true) {
                this.setState({ directSaveCalled: false })
            }
            PapiroConsole.log("=== Caiu -> 8 -> description ===")
            PapiroConsole.log(theStatusIsFinish == true && !closeFinishModal && (this.state.oldStatus.id != this.state.called.status || this.state.directFinishCalled))
            PapiroConsole.log(this.state.oldStatus.id != this.state.called.status || this.state.directFinishCalled)
            PapiroConsole.log(this.state.oldStatus.id != this.state.called.status)
            PapiroConsole.log(this.state.directFinishCalled)
            PapiroConsole.log(theStatusIsFinish)
            PapiroConsole.log(closeFinishModal)
            if (theStatusIsFinish == true && !closeFinishModal && (this.state.oldStatus.id != this.state.called.status || this.state.directFinishCalled)) {
                PapiroConsole.log("=== Caiu -> 8 ===")
                this.setState({
                    modalopenFinish: true,
                    loading: false,
                    loadingTwo: false
                });
                return false;
            } else {
                PapiroConsole.log("=== Caiu -> 9 ===")
                this.setState({
                    modalopenFinish: false,
                });
                return true;
            }
        } else {
            PapiroConsole.log("=== checkFinishCalled 1.2 ===")
            let clientClickFinishCalled = this.state.clientClickFinishCalled

            if (this.state.clientClickFinishCalled == true)
                this.setState({ clientClickFinishCalled: false })

            let sanitized = this.convertHtmlOption(this.state.action.description)
            if (sanitized != null && sanitized.length > 0)
                sanitized = sanitized.trim()

            if ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0) {
                PapiroConsole.log("=== Caiu -> 2 ===")
                PapiroConsole.log(this.state.called.finishaccompanimentid)
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: clientClickFinishCalled == true ? intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.action.client" }) : intl.formatMessage({ id: "called.accompaniment.notification.missing.action" })
                });
                return false;
            }

            let status = this.state.called.status;
            let cancelledStatus = false;

            let theStatusIsFinish = false;

            let clientStatus = this.state.clientStatus;
            for (let value in this.state['statusList']) {
                if (this.state['statusList'][value].id == status) {

                    if (this.state['statusList'][value].finished == true) {
                        theStatusIsFinish = true;
                        if (this.state['statusList'][value].id == 6) // cancelado
                        {
                            cancelledStatus = true
                        }
                    }
                    if (this.state['statusList'][value].id == 4)
                        clientStatus = this.state['statusList'][value]
                }
            }

            if (directFinish == true || this.state.directFinishCalled == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        status: 4
                    },
                    clientStatus: clientStatus
                }))
                theStatusIsFinish = true
            }

            if (this.state.directSaveCalled == true)
                this.setState({ directSaveCalled: false })

            this.setState({
                modalopenFinish: false,
            });
            return true;
        }
    }


    finishCalledClient() {
        this.setState({
            loading: true,
        });
        var idCalled = this.state.called.id;
        var status = this.state.FinishCalledId;
        var updatedUser = this.state.called.updatedUser;

        var data = { Id: idCalled, status: status, updatedUser: updatedUser };

        Api.put("/calleds/CalledClientAlter", data)
            .then(result => {
                if (result.data.success) {
                    this.setState({ FinishCalled: false });
                    this.props.addCurrentCalled(result.data.data);
                    this.props.addCalled(result.data.data)
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "edit.called.success" }),
                        files: [],
                        action: {
                            description: "",
                            privacy: ""
                        },
                    });
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
            });

        setTimeout(function () { window.location.reload(); }, 3000);
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    useStyles() {
        return withStyles({
            ImageList: {
                width: "100%",
                height: 100,
            },
            icon: {
                height: 32,
                width: 32
            }
        });
    }

    async getCategories() {
        PapiroConsole.log("entrei no getcategories")
        var result = await CalledService.getAllCategories();

        if (result.success) {
            this.setState({ categoriesListAll: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }

        result = await CalledService.getCategories();
        if (result.success) {
            this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    getParentCalleds = async (organizationid) => {
        if (this.state.parentCalledsList && this.state.parentCalledsList.length > 0)
            return false;

        this.setState({ loading: true })
        if (organizationid == null) {
            //this.setCountLoading("getParentCalleds");
            return false;
        }

        var result = await CalledService.getParentCalled(organizationid);

        if (result.success) {

            let parents = result.data.map(item => {
                return {
                    ...item,
                    name: `${item.id}: ${item.title}`,
                    parentname: `${item.id}: ${item.title}`
                }

            })
            this.setState({ loading: false, parentCalledsList: parents && parents.length > 1 ? parents.sort((a, b) => a.name.localeCompare(b.name)) : parents });
            //this.setCountLoading("getParentCalleds");

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
            //this.setCountLoading("getParentCalleds");

        }
    };

    async getCategoriesByOrganization(organizationId) {
        if (organizationId == null) {
            this.setCountLoading("getCategoriesByOrganization");
            return false;
        }
        PapiroConsole.log("entrei aqui no getCategoriesByOrganization")

        var result = await CalledService.getCategoriesByOrganization(organizationId);

        if (result.success) {
            PapiroConsole.log(result.data)
            PapiroConsole.log("result.data entrei aqui no getCategoriesByOrganization")
            this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.parentname ? a.parentname.localeCompare(b.parentname) : a.name.localeCompare(b.name)) : result.data });
            //this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name && a.parentname == null ? a.name.localeCompare(b.name)  : a.parentname.localeCompare(b.parentname)) : result.data });
            this.setCountLoading("getCategoriesByOrganization");


        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

            this.setCountLoading("getCategoriesByOrganization");

        }
    };

    async migrateResm(calledId) {
        const result = await CalledService.migrateResm(calledId)
        if (result.success) {
            this.setCountLoading("migrateResm");
        } else {
            this.setCountLoading("migrateResm");
        }
    }

    setErrorMessage = (open, variant, message) => {
        this.setState({
            openNotification: open,
            notificationVariant: variant,
            notificationMessage: message
        });
    }

    errorTechnicalMessage = () => {
        const intl = this.props.intl;
        this.setErrorMessage(true, "error", intl.formatMessage({ id: "called.accompaniment.error.technician.not.found" }))
    }

    async getTechnicalUsers() {
        const result = await CalledService.getTechnicalUsers(this.state.called.OrganizationCategoryId.id, this.state.called.organization);

        if (result.success) {
            this.setState({ technicalUsersList: result.data && result.data.lenght > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

            this.setCountLoading("getTechnicalUsers");
            if (!this.isClient) {
                if (result && result.data) {
                    if (Array.isArray(result.data))
                        if (result.data.length == 0) { // porto
                            /*this.setState({
                                openNotification: true, notificationVariant: "error",
                                notificationMessage: intl.formatMessage({id:"called.accompaniment.error.technician.not.found"})
                            });*/
                        }
                }
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getTechnicalUsers");
            Api.kickoff(result);
        }
    }

    cancellCalled() {
        const intl = this.props.intl;
        this.closeCalledModal();
        this.setState({ loading: true });
        Api.put(`/calleds/cancel/${this.state.called.id}/${this.state.userId}`)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "called.accompaniment.called.canceled.success" })
                    });
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            status: result.data.data.statusid,
                        },
                        statusList: [...prevState.statusList, {...result.data.data.status, enable: false}],
                        clientStatus: result.data.data.status,
                        oldStatus: result.data.data.status,
                    }));
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
                this.closeCalledModal()
            });

    }

    async unarchivedCalled() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        Api.put(`/calleds/unarchived/${this.state.called.id}/${this.state.userId}`)
            .then(async result => {
                if (result.data.success) {

                    await this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            archived: result.data.data.archived
                        },
                    }));
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "called.accompaniment.called.unarchived.success" })
                    });
                }
                this.closeUnarchiveModal()

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
                this.closeUnarchiveModal()
            });

    }


    cloneCalled() {
        this.setState({ loading: true });
        const intl = this.props.intl;
        let error = '';
        const { cloneCalledAmount } = this.state;

        if (!cloneCalledAmount) {
            error = intl.formatMessage({ id: "null.amount.notification" });
        } else if (isNaN(cloneCalledAmount)) {
            error = intl.formatMessage({ id: "notANumber.amount.notification" });
        } else if (cloneCalledAmount < 1) {
            error = intl.formatMessage({ id: "negative.amount.notification" });
        }

        if (error !== '') {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: error,
                cloneCalledAmount: null
            });
            return
        }

        const successNotification = `${intl.formatMessage({ id: "clone.called.create.left.notification" })} ${cloneCalledAmount} ${intl.formatMessage({ id: "clone.called.create.right.notification" })}`;
        error = false;

        var cloneFormData = new FormData()
        cloneFormData.append("calledid", this.props.calleds[`called-${this.urlId}`].id)
        cloneFormData.append("amount", cloneCalledAmount)
        cloneFormData.append("calledorganizationmaterial", this.state.calledorganizationmaterial)
        cloneFormData.append("calledusers", this.state.calledusers)
        cloneFormData.append("calledorganizationFields", this.state.calledorganizationFields)
        cloneFormData.append("calledtimeentries", this.state.calledtimeentries)
        cloneFormData.append("calledadditionalservices", this.state.calledadditionalservices)
        cloneFormData.append("initialcalledorganizationchecklists", this.state.initialcalledorganizationchecklists)
        cloneFormData.append("finalcalledorganizationchecklists", this.state.finalcalledorganizationchecklists)
        cloneFormData.append("calledorganizationpatrimonies", this.state.calledorganizationpatrimonies)

        cloneFormData.append("useraction", this.state.userId)




        Api.post(`/calleds/clone`, cloneFormData)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: successNotification,
                        calledorganizationmaterial: false,
                        calledusers: false,
                        calledorganizationFields: false,
                        calledtimeentries: false,
                        calledadditionalservices: false,
                        initialcalledorganizationchecklists: false,
                        finalcalledorganizationchecklists: false,
                        calledorganizationpatrimonies: false,
                    });

                    this.closeCloneCalledModal();
                    // this.cleanCache()
                    this.refreshAccompaniments()

                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    error = true;
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    error = true;
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }

                this.closeCloneCalledModal();
            });

        // 
    }

    async getTypes() {

        const result = await CalledService.getTypes();

        if (result.success) {
            this.setState({ typesList: result.data && result.data.length > 0 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

            this.setCountLoading("getTypes");

        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            this.setCountLoading("getTypes");
            Api.kickoff(result);



        }
    };

    getAvaliableStatus() {
        const intl = this.props.intl;
        var statuses = [
            "novo", "new", "nuevo",
            "em andamento", "in progress", "en proceso",
            "encerrado", "closed", "cerrado",
            "agendado", "scheduled", "programado"
        ]
        return statuses;
    }

    async getStatus(finalizationexternalstatus) {

        if (finalizationexternalstatus == null) {
            this.setCountLoading("getStatus");
            return false;
        }
        Api.get(`/statuses/getstatus/${finalizationexternalstatus}`)
            .then(result => {
                if (result && result.data && result.data.data && result.data.success) {

                    let statuses = result.data.data.map(item => {
                        return {
                            ...item,
                            enable: item.system == false ? true : (this.getAvaliableStatus().indexOf(item.name.toLowerCase().trim()) > -1)
                        }

                    })

                    let validStatuses = statuses.filter(item => item.enable == true)
                    let disabledCurrentStatus = statuses.find(item => item.enable == false && item.id == this.state.called.status)
                    if (disabledCurrentStatus != null && typeof disabledCurrentStatus !== 'undefined')
                        validStatuses = [...validStatuses, disabledCurrentStatus]

                    this.setState({ statusList: validStatuses && validStatuses.length > 1 ? validStatuses.sort((a, b) => a.name.localeCompare(b.name)) : validStatuses })
                    this.setCountLoading("getStatus");


                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.setCountLoading("getStatus");


                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                    this.setCountLoading("getStatus");


                }
            });


    };

    saveCalledReason = async e => {
        e.preventDefault()
        const intl = this.props.intl
        const { userSession } = this.props

        this.setState({ loading: true })

        if (this.state.calledReasonId == null) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.select.reason" }),
            })
            return
        }

        if (this.state.calledReasonId.id == 4) {
            if (this.state.reasonOther.length == 0) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "other.select" }),
                })
                return
            }
        }

        let data = new FormData();
        data.append('calledid', this.state.called.id)
        data.append('reasonid', this.state.calledReasonId.id)
        data.append('description', this.state.reasonOther)
        data.append('createduser', userSession.user.id)

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        //SLA aqui
        await Api.post("/calledreasons", data, config)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        modalopenFinish: true,
                        slaOverflowContentModalOpen: false,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "called.accompaniment.reason.saved" })
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
            })

        this.setState({ loading: false, savedReason: true })
    }

    saveFinishAccompaniment = e => {
        e.preventDefault()
        const intl = this.props.intl;
        const { userSession } = this.props

        this.setState({ loading: true })
        if (this.state.called.finishaccompanimentid == null || this.state.called.finishaccompanimentid == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "select.action" }),
            })
            return
        }

        let finish = this.state.called.finishaccompanimentid;

        this.setState(prevState => ({
            loading: false,
            loadingTwo: false,
            openNotification: true,
            finishAccompanimentModalOpen: false,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "action.selected" }),
            called: {
                ...prevState.called,
                previousfinishaccompanimentid: finish
            }
        }))

    }

    handleSubmitDiskAccept = async (e) => {
        e.preventDefault()
        PapiroConsole.log("=== handleSubmitDiskAccept -> 1 ===")
        await this.setState({ openDialogNewDisk: false, newDiskAccept: true });
        this.submitAction(true)
    }

    openDialogNewDisk = async () => {
        this.setState({ openDialogNewDisk: !this.state.openDialogNewDisk });
    }

    closeDialogNewDisk = _ => this.setState({
        openDialogNewDisk: !this.state.openDialogNewDisk,
        loading: false,
        loadingTwo: false
    });

    handleSubmit = async e => {
        PapiroConsole.log("=== handleSubmit -> 1 ===")
        e.preventDefault();
        await this.setState({ clientClickFinishCalled: false, loading: true, loadingTwo: true, directFinishCalled: false });
        this.submitAction();
    }

    finishCalled = async e => {
        const intl = this.props.intl;
        e.preventDefault()
        if (this.state.called.addressid == 0) {
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.location" })
            })
            return false
        }
        this.setState({ clientClickFinishCalled: this.isClient ? true : false, loading: true, directFinishCalled: true, directSaveCalled: true }, () => this.submitAction())
    }

    startCalledAttendance = async e => {
        this.setState({ loading: true });
        let data = new FormData()
        data.append("calledid", this.state.called.id)
        data.append("userid", this.state.userId)

        await Api.put(`/calleds/startattendance`, data)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        //loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: "Atendimento iniciado com sucesso!!"
                    });
                    this.setState(prevState => ({
                        called: {

                            ...prevState.called,
                            status: result.data.data.status.id,
                            //loading: false,
                            calledstatus: result.data.data.calledstatus
                        },
                        clientStatus: result.data.data.status,
                        oldStatus: result.data.data.status,
                    }));
                    this.LoadBarraNew(this.state.called.id);
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });

        this.setState({ loading: false });
    }

    saveCalled = async e => {
        const intl = this.props.intl;
        e.preventDefault();
        if (this.formRef.current && this.formRef.current.reportValidity()) {
            if (this.state.called.status && this.state.called.status == 2 && !this.state.called.assignedUser) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.called.attendance.error.missing.responsible" })
                });
                return false
            }
            if (this.state.called.addressid == 0) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.location" })
                })
                return false
            }

            this.setState({ loading: true, directFinishCalled: false, directSaveCalled: true }, () => this.submitAction());
        }
        else{
            return;
        }
       
    }
    async postAccompaniment(data, config, closeModalFinish, bSaveCalled = true) {
        const intl = this.props.intl;
        Api.post("/accompaniments", data, config)
            .then(result => {
                if (result.data.success) {
                    if (result && result.data && result.data.data && result.data.data.length > 0) {
                        PapiroConsole.log("entrei aqui no quinto")
                        this.props.addAccompanimentsList(result.data.data);
                        this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id);
                    }

                    this.setState({
                        openDialogNewDisk: false,
                        calledStatusTypeId: this.state.called.status,
                    });

                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            knowledgebaseattachments: []
                        },
                    }))

                    if (this.isClient == false) {
                        if (bSaveCalled) {
                            this.submitCalled(closeModalFinish);
                        }
                    } else {

                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "add.action.success" }),
                            files: [],
                            action: {
                                description: "",
                                privacy: ""
                            },
                            directFinishCalled: false,
                        });
                    }
                } else {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "called.accompaniment.error.finish.called" })
                    })
                }
            })
            .catch(err => {
                if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && !isNaN(err.response.data.errors[0])) {
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openDialogNewDisk: true,
                        valueNewDisk: err.response.data.errors[0],
                    });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        openDialogNewDisk: false,
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }

            });
    }

    validationsOfFlag(team, status, assigned) {
        PapiroConsole.log("=== validationsOfFlag ===")
        PapiroConsole.log("validações da flag aqui")
        PapiroConsole.log(team)
        PapiroConsole.log(status)
        PapiroConsole.log(assigned)
        PapiroConsole.log(this.state.called.techinicalteam)
        PapiroConsole.log(this.state.called.status)
        PapiroConsole.log(this.state.called.assignedUser)

        var checkValidations = true

        // caso dos técnicos
        if (this.state.called.techinicalteam != null && team != null) {
            if (this.state.called.techinicalteam.id != team.id) {
                PapiroConsole.log("entrei na validação 1")
                checkValidations = false

            }
        }
        else if (this.state.called.techinicalteam != team) {
            PapiroConsole.log("entrei na validação 2")
            checkValidations = false

        }

        //caso dos responsáveis
        if (this.state.called.assignedUser != null && assigned != null) {
            if (this.state.called.assignedUser.id != assigned.id) {
                PapiroConsole.log("entrei na validação 3")
                checkValidations = false

            }
        }
        else if (this.state.called.assignedUser != assigned) {
            PapiroConsole.log("entrei na validação 4")
            checkValidations = false

        }

        // caso do status
        if (this.state.called.status != status.id) {
            PapiroConsole.log("variaveis aqui")
            PapiroConsole.log(this.state.called.status)
            PapiroConsole.log(status.id)

            PapiroConsole.log("entrei na validação 5")
            checkValidations = false

        }

        PapiroConsole.log("=== validationsOfFlag -> checkValidations ===")
        PapiroConsole.log(checkValidations)
        if (checkValidations === true) {
            PapiroConsole.log("=== validationsOfFlag -> caiu no if {true} ===")
            return true
        } else {
            PapiroConsole.log("=== validationsOfFlag -> caiu no else {false} ===")
            this.setState({
                loading: false,
                loadingTwo: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "É necessário adicionar uma ação ao mudar time, status ou responsável do chamado ",
            })
            return false
        }
    }
    async submitAction(closeModalFinish = false, directFinish = false, clientReopen = false, fromcalledfinish = false) {
        
        PapiroConsole.log(closeModalFinish)
        PapiroConsole.log(directFinish)
        PapiroConsole.log(clientReopen)
        
        PapiroConsole.log("variaveis aqui no submit action")
        PapiroConsole.log("=== submitAction -> 1 ===")
        PapiroConsole.log("closeModalFinish")
        PapiroConsole.log("entrei aqui teste 1")
        PapiroConsole.log(closeModalFinish)
        const intl = this.props.intl;
        this.setState({ loading: true, loadingTwo: true });

        if (this.state.requestingUser && this.state.called.quickCalledCreated) {
            if (this.state.requestingUser.id == 0) {

                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "cant.update.called.without.requestinguser" }),
                })
                return;

            }
        }
        //alert('aqui');
        if (this.isClient == true && this.state.action.privacy !== "true" && this.state.action.privacy !== "false") {

            this.setState(prevState => ({
                action: {
                    ...prevState.action,
                    privacy: "false"
                }
            }))
        }
        //alert('aqui');
        if (this.state.requireattachedimage) {
            if (this.state.files.length === 0) {
                this.setState({
                    loading: false,
                    loadingTwo: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "files.required" }),
                })
                return;
            }
        }
        PapiroConsole.log("teste de flag aqui")
        PapiroConsole.log(this.state.actionrequiredwhenstatusorteamorassignedchanges)
        PapiroConsole.log(this.state.action.description)

        // checar se a flag de ação ser obrigatoria em caso de mudança de status, time ou responsável 



        let inputValue = ''
        let accompanimentAction = document.getElementById('accompanimentAction')
        PapiroConsole.log("=== accompanimentAction ===")
        PapiroConsole.log(accompanimentAction)
        if (accompanimentAction != null) {
            let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
            if (qlEditor != null) {
                inputValue = `${qlEditor.innerHTML}`
            }
            PapiroConsole.log("=== qlEditor ===")
            PapiroConsole.log(qlEditor)
        }
        PapiroConsole.log("inputValue aqui embaixo editor")
        PapiroConsole.log(inputValue)
        let calledTitleValue = ''
        if (!this.isClient) {
            let calledTitle = document.getElementById('title')
            if (calledTitle != null)
                calledTitleValue = calledTitle.value
        } else {
            calledTitleValue = this.state.called.title
        }

        await this.setState(prevState => ({ called: { ...prevState.called, title: calledTitleValue }, action: { ...prevState.action, description: inputValue } }))


        
        let sanitized = this.convertHtmlOption(this.state.action.description)
        PapiroConsole.log("sanitized here")
        PapiroConsole.log(sanitized)
        PapiroConsole.log(this.state.action.description)
        if (sanitized != null && sanitized.length > 0)
            sanitized = sanitized.trim()



        PapiroConsole.log(inputValue)
        PapiroConsole.log(this.state.action.description)
        PapiroConsole.log(sanitized)

        PapiroConsole.log("print aqui")
        PapiroConsole.log(this.state.startCalledAttendance)
        PapiroConsole.log(this.state.startCalledAttendance)


        if (this.state.actionrequiredwhenstatusorteamorassignedchanges && (sanitized == '' || sanitized == null)) {
            PapiroConsole.log("entrei no primeiro if")
            let validation = this.validationsOfFlag(this.currentTeamOfCalled, this.currentStatusOfCalled, this.currentAssignedOfCalled)
            PapiroConsole.log("validação aqui ")
            if (validation == false) {
                return
            }
        }

        let check = this.checkAccompaniment(false, closeModalFinish, directFinish, sanitized);
        if (check == true) {
            PapiroConsole.log("=== submitAction -> 1.1 ===")
            this.setState({ loading: true });
            const { userSession } = this.props;
            const { action, called } = this.state;

            let statusData = this.state.statusList.filter(item => item.id == this.state.called.status)
            PapiroConsole.log("=== clientStatus ===")
            PapiroConsole.log(this.state.called.finishaccompanimentid)
            PapiroConsole.log(statusData)
            PapiroConsole.log(this.state.oldStatus)
            if (
                ((((
                    ((statusData && statusData.statustypeid == 5) || (statusData && statusData.length > 0 && statusData[0] && statusData[0].statustypeid == 5))
                    && ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0)
                ) || (
                        ((statusData && statusData.statustypeid == 5 && this.state.oldStatus && this.state.oldStatus.id != statusData.id) || (statusData && statusData.length > 0 && statusData[0] && statusData[0].statustypeid == 5 && this.state.oldStatus && this.state.oldStatus.id != statusData[0].id))
                    ))
                    && (
                        this.globalAdm == true || this.AdmOrganization == true || this.isCoordenator == true || this.isTechinical == true
                    )) && !closeModalFinish && !this.state.newDiskAccept)
                ||
                (((
                    ((statusData && statusData.statustypeid == 5) || (statusData && statusData.length > 0 && statusData[0] && statusData[0].statustypeid == 5)) && ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0)
                )
                    && (
                        this.globalAdm == true || this.AdmOrganization == true || this.isCoordenator == true || this.isTechinical == true
                    )) && closeModalFinish && (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id && this.state.called.finishaccompanimentid.id > 0))

                ||
                (((statusData && statusData.statustypeid != 5) || (statusData && statusData.length > 0 && statusData[0] && statusData[0].statustypeid != 5)) && ((sanitized == null || (sanitized != null && sanitized.length == 0)) && this.state.files.length == 0)
                    && (this.globalAdm == true || this.AdmOrganization == true || this.isCoordenator == true || this.isTechinical == true))


            ) {
                PapiroConsole.log("=== submitAction -> 1.1.1 ===")
                PapiroConsole.log(sanitized)
                PapiroConsole.log(inputValue)
                PapiroConsole.log("entrei aqui teste 2")
                this.submitCalled(closeModalFinish)
            } else if ((sanitized && sanitized.length > 0) || this.state.files.length > 0) {
                PapiroConsole.log("=== submitAction -> 1.1.2 ===")
                PapiroConsole.log("checkAccompaniment -> 2 ===")
                let data = new FormData();

                PapiroConsole.log(action.description)
                PapiroConsole.log("action description aqui")

                data.append('description', sanitized && sanitized.length > 0 ? action.description : `#${intl.formatMessage({ id: "attachments" })}`);
                data.append('calledTitle', called.title);
                if (this.isClient && action.privacy == '') {
                    data.append('privacy', false);
                }
                data.append('privacy', action.privacy);
                data.append('called', called.id);
                data.append('userAction', userSession.user.id);

                let status = this.state.called.status;
                var theStatusIsFinish = false
                PapiroConsole.log(this.state['statusList'])
                PapiroConsole.log("this.state['statusList']")
                if (this.state['statusList'] && this.state['statusList'].length > 0) {

                    for (let value in this.state['statusList']) {
                        if (this.state['statusList'][value].id == status) {

                            if (this.state['statusList'][value].finished == true || this.state['statusList'][value].statustypeid === 5) {
                                theStatusIsFinish = true;

                            }

                        }
                    }

                }

                data.append('finish', theStatusIsFinish);
                data.append('originid', 2);

                PapiroConsole.log("this.state.newDiskAccept")
                PapiroConsole.log(this.state.newDiskAccept)
                if (this.state.newDiskAccept) {
                    data.append("acceptNewDisk", true);
                    this.setState({ newDiskAccept: false });
                }

                for (let key in this.state.called) {
                    let name = `called${key}`;

                    if (key === 'dueDate') {
                        data.append(name.toLowerCase(), moment(this.state.called[key]).endOf('day').format("YYYY/MM/DD HH:mm:ss"));
                    }
                    else if (key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) {
                        if (this.state.called[key] && this.state.called[key].id) {
                            data.append("OrganizationCategory", this.state.called[key].id);
                        }
                    }
                    else if (key.toString().toLowerCase().indexOf('requestinguser') > -1) {
                        if (this.state.called[key] != null) {
                            PapiroConsole.log("requestinguser aqui")
                            PapiroConsole.log(this.state.called.requestingUser)
                            data.append("requestinguser", this.state.called[key].id);
                        }

                    }
                    else if (key.toString().toLowerCase().indexOf('calledassigneduser') > -1 || key.toString().toLowerCase().indexOf('assigneduser') > -1) {
                        if (this.state.called[key] && this.state.called[key].id) {
                            data.append("calledassigneduser", this.state.called[key].id);
                        } else {
                            data.append("calledassigneduser", 0);
                        }

                    }
                    else if (key.toString().toLowerCase().indexOf('observantuser') > -1) {
                        if (this.state.called[key] && this.state.called[key].id) {
                            data.append("CalledObservantUser", this.state.called[key].id);
                        }
                        else {
                            data.append("CalledObservantUser", 0);
                        }

                    }
                    else {
                        data.append(name.toLowerCase(), this.state.called[key]);
                    }
                }

                let listKnowledgebaseAttachments = this.state.called.knowledgebaseattachments
                if (listKnowledgebaseAttachments != null && listKnowledgebaseAttachments != "") {
                    let knowledgeBaseAttachmentsIds = ""
                    listKnowledgebaseAttachments.map(item => {
                        knowledgeBaseAttachmentsIds = `${item.id},${knowledgeBaseAttachmentsIds}`
                    })

                    data.append("knowledgeBaseAttachmentsIds", knowledgeBaseAttachmentsIds)
                }

                data.append("displayassignedinfomode", this.state.displayassignedinfomode)
                if (called.techinicalteam != null) {
                    data.append("CalledTechinicalTeamId", called.techinicalteam.id)
                    data.append("techinicalteamname", called.techinicalteam.name)

                }

                if (this.state.defaultActionId != 0 && this.state.defaultActionId) {
                    data.append("DefaultActionId", this.state.defaultActionId.id)
                    data.append("DefaultActionTitle", this.state.defaultActionId.title ? this.state.defaultActionId.title : null)
                }
                data.append('QuickCalledCreated', this.state.called.quickCalledCreated)

                data.append('ClientReopen', clientReopen)



                this.state.files.forEach(file => data.append('filesUpload', file));

                let config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                var aux = 0

                PapiroConsole.log("entrei aqui teste 5")
                //alert(fromcalledfinish);
                if( (userSession.user.email == "lobm@poli.br"  || userSession.user.email == "horaciooliveirajob@gmail.com") && fromcalledfinish /*&& this.state.asynchronousClosingCalled*/){   // ADM GLOBAL
                    //alert(this.state.ClosingCalledModalDataForm);
            

                    PapiroConsole.log("ENTREI AQUI NOS DADOS DA REQUISIÇÃO ASSINCRONA 1 ")
 
                   
                    this.setState({
                        openDialogNewDisk: false,
                    });

                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            knowledgebaseattachments: []
                        },
                    }))
                    let pendingStatus = false
                    if (this.state.called.status != null && this.state.called.status > 0) {
                        this.state.statusList.map(item => {
                            if (item.id == this.state.called.status && item.statustypeid == 7)
                                pendingStatus = true
                        })
                    }
                    //  PORTO
                    if (this.isClient == true && clientReopen == true){
                       
                        const { id } = this.props.match.params
                        let updatedUser = this.state.called.updatedUser;
                
                        data.append("calledid", parseInt(id))
                        data.append("updateduser", updatedUser)                        
                        data.append("clientreopen", true)                        
                        data.append("isclient", true)                        
                    }


                    let newData = new FormData();
                    PapiroConsole.log("entrei aqui no primeiro par")
                    PapiroConsole.log(data)       
                    for (var pair of data.entries()) {
                        //data.append(pair[0], pair[1]);
                        //data.append(pair[0], pair[1]);
                        PapiroConsole.log("pares aqui")
                        PapiroConsole.log(pair[0])
                        PapiroConsole.log(pair[1])
                        newData.append(`Accompaniment.${pair[0]}`, pair[1]);
                      
                    }

                    PapiroConsole.log("entrei aqui no segundo par")
                    PapiroConsole.log(this.state.ClosingCalledModalDataForm)
                    if(this.state.ClosingCalledModalDataForm && typeof this.state.ClosingCalledModalDataForm.entries === 'function'){
                        for (var pair2 of this.state.ClosingCalledModalDataForm.entries()) {
                            
                            newData.append(`CalledTipping.${pair2[0]}`, pair2[1]);
                          
                        }
                    }
                    
                    if(this.state.ClosingCalledModalDataForm2 && typeof this.state.ClosingCalledModalDataForm2.entries === 'function' ){
                        for (var pair3 of this.state.ClosingCalledModalDataForm2.entries()) {
                            PapiroConsole.log("pares aqui 2")
                            PapiroConsole.log(pair2[0])
                            PapiroConsole.log(pair2[1])
                            newData.append(`CalledClosing.${pair3[0]}`, pair3[1]);
                          
                        }
                    }
                    

                    
                    if (this.isClient == false || (((this.state.called.requestingUser && this.state.called.requestingUser.id == userSession.user.id) || this.otherClientInteractsWithCalled == true) && pendingStatus)) {
                        PapiroConsole.log("entrei aqui teste 2")
                        this.submitCalled(closeModalFinish, true, fromcalledfinish, newData);
                    } else if ((this.isClient == true && this.state.called.assignedUser != null && ((this.state.clientStatus && ((this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled == true && this.state.clientClickFinishCalled == true)) || (this.state.clientStatus.statustypeid != 7 && this.canclientautomaticchangestatusaftersendaccompaniment == true)))) {
                        PapiroConsole.log("entrei aqui teste 3")
                        this.submitCalled(closeModalFinish, false,fromcalledfinish,  newData);
                    } else {

                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "add.action.success" }),
                            files: [],
                            // action: {
                            //     // description: "",
                            //     // privacy: ""
                            // },
                            directFinishCalled: false,
                            calledStatusTypeId: this.state.called.status,
                        });
                    }

                }
                else{
                    PapiroConsole.log("entrei no post de acompanhamento")
                    Api.post("/accompaniments", data, config)
                        .then(result => {
                            if (result.data.success) {
                                let accompanimentAction = document.getElementById('accompanimentAction')
                                PapiroConsole.log("=== accompanimentAction 2 ===")
                                PapiroConsole.log(accompanimentAction)
                                if (accompanimentAction != null) {
                                    let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
                                    if (qlEditor != null) {
                                        PapiroConsole.log("=== qlEditor 2 ===")
                                        PapiroConsole.log(qlEditor)
                                        qlEditor.innerHTML = ''
                                    }
                                }
                                PapiroConsole.log(result.data.data)
                                PapiroConsole.log("result aqui")

                                if (result && result.data && result.data.data && result.data.data.length > 0) {
                                    PapiroConsole.log("entrei aqui no primeiro")
                                    PapiroConsole.log(result.data.data)
                                    this.props.addAccompanimentsList(result.data.data);
                                    this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id);
                                }

                                this.setState({
                                    openDialogNewDisk: false,
                                });

                                this.setState(prevState => ({
                                    called: {
                                        ...prevState.called,
                                        knowledgebaseattachments: []
                                    },
                                    calledStatusTypeId: this.state.called.status,
                                }))
                                let pendingStatus = false
                                if (this.state.called.status != null && this.state.called.status > 0) {
                                    this.state.statusList.map(item => {
                                        if (item.id == this.state.called.status && item.statustypeid == 7)
                                            pendingStatus = true
                                    })
                                }
                                if (this.isClient == true && clientReopen == true)
                                    this.reopenCalledClient()

                                PapiroConsole.log("submit accompaniment")
                                PapiroConsole.log((this.isClient == false || (((this.state.called.requestingUser && this.state.called.requestingUser.id == userSession.user.id) || this.otherClientInteractsWithCalled == true) && pendingStatus)))
                                PapiroConsole.log(((this.isClient == true && this.state.called.assignedUser != null && ((this.state.clientStatus && ((this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled == true && this.state.clientClickFinishCalled == true)) || (this.state.clientStatus.statustypeid != 7 && this.canclientautomaticchangestatusaftersendaccompaniment == true)))))
                                
                                if (this.isClient == false || (((this.state.called.requestingUser && this.state.called.requestingUser.id == userSession.user.id) || this.otherClientInteractsWithCalled == true) && pendingStatus)) {
                                    PapiroConsole.log("entrei aqui no segundo")
                                    this.submitCalled(closeModalFinish, true);
                                } else if ((this.isClient == true && this.state.called.assignedUser != null && ((this.state.clientStatus && ((this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled == true && this.state.clientClickFinishCalled == true)) || (this.state.clientStatus.statustypeid != 7 && this.canclientautomaticchangestatusaftersendaccompaniment == true)))) {
                                    PapiroConsole.log("entrei aqui no terceiro")
                                    this.submitCalled(closeModalFinish);
                                } else {

                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false,
                                        loadingTwo: false,
                                        openNotification: true,
                                        notificationVariant: "success",
                                        notificationMessage: intl.formatMessage({ id: "add.action.success" }),
                                        files: [],
                                        action: {
                                            description: "",
                                            privacy: ""
                                        },
                                        directFinishCalled: false,
                                    });
                                }

                                /* PapiroConsole.log("antes de entrar na função")
                                PapiroConsole.log(result.data.data)
                                if(this.state.called.assignedUser){
                                    this.addAccompanimentNotification(result.data.data)
                                }*/



                            } else {
                                this.setState({
                                    loading: false,
                                    loadingTwo: false,
                                    openNotification: true,
                                    notificationVariant: "error",
                                    notificationMessage: intl.formatMessage({ id: "called.accompaniment.error.finish.called" })
                                })
                            }
                        })
                        .catch(err => {
                            if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && !isNaN(err.response.data.errors[0])) {
                                PapiroConsole.log("Caiu no primeiro")
                                this.setState({
                                    loading: false,
                                    loadingTwo: false,
                                    openDialogNewDisk: true,
                                    valueNewDisk: err.response.data.errors[0],
                                });
                            } else {
                                const intl = this.props.intl;
                                PapiroConsole.log("Caiu no segundo")
                                this.setState({
                                    openDialogNewDisk: false,
                                    loading: false,
                                    loadingTwo: false,
                                    openNotification: true,
                                    notificationVariant: "error",
                                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                                });
                            }

                        });
                    }
            } else {
                PapiroConsole.log("=== submitAction -> 1.1.3 ===")
                let sanitizedAction = this.convertHtmlOption(this.state.action.description)
                if (sanitizedAction != null && sanitizedAction.length > 0)
                    sanitizedAction = sanitizedAction.trim()

                if ((sanitizedAction == null || (sanitizedAction != null && sanitizedAction.length == 0)) && this.state.files.length == 0 && (
                    this.state.called.finishaccompanimentid == null || (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id == null) || (this.state.called.finishaccompanimentid && this.state.called.finishaccompanimentid.id && this.state.called.finishaccompanimentid.id == 0)
                )) {
                    PapiroConsole.log("=== Caiu -> 3 ===")
                    PapiroConsole.log(this.state.called.finishaccompanimentid)
                    this.setState({
                        loading: false,
                        loadingTwo: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: clientReopen == true ? intl.formatMessage({ id: "called.accompaniment.notification.called.reopen.error.missing.action.client" }) : this.state.clientClickFinishCalled == true ? intl.formatMessage({ id: "called.accompaniment.notification.called.finish.error.missing.action.client" }) : intl.formatMessage({ id: "called.accompaniment.notification.missing.action" })
                    });
                    return false;
                }
                this.setState({ loading: false, loadingTwo: false })
            }
        } else {
            PapiroConsole.log("=== submitAction -> 1.2 ===")
        }
    }

    createUi = () => {
        return this.state.categoryChildrens.map((el, i) =>
            <Grid key={i} item xs={4} sm={4}>
                {i > 0 ?
                    (<Grid container spacing={4}>
                        <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
                        <Grid item xs={10} sm={10}>
                            <SimpleAutoCompleteByName
                                intl={this.props.intl}
                                label=""
                                options={(this.state.categoryLists[i] || []).sort((a, b) => a.name.localeCompare(b.name))}
                                stateName={`lcat-${i}`}
                                name={`lcat-${i}`}
                                changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
                                selected={el}
                                disabled={(this.isClient && !this.state.called.organizationsettings.roleclientcanselectcategory) ? true : (this.isClient && this.state.requestingUser.id != this.props.userSession.user.id) ? true : false} />
                        </Grid>
                    </Grid>)
                    :
                    (
                        <SimpleAutoCompleteByName
                            intl={this.props.intl}
                            label=""
                            options={(this.state.categoryLists[i] || []).sort((a, b) => a.name.localeCompare(b.name))}
                            stateName={`lcat-${i}`}
                            name={`lcat-${i}`}
                            changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
                            selected={el}
                            disabled={(this.isClient && !this.state.called.organizationsettings.roleclientcanselectcategory) ? true : (this.isClient && this.state.requestingUser.id != this.props.userSession.user.id) ? true : false} />
                    )}
            </Grid>
        )
    }

    handleAddChildrenCategory = (listCategory) => {
        this.setState(prevState => ({
            categoryChildrens: [
                ...prevState.categoryChildrens,
                { id: 0, name: '' }
            ],
            categoryLists: [
                ...prevState.categoryLists,
                listCategory
            ]
        }))
    }

    handleAddReverseChildrenCategory = async (listCategory, first, category = null) => {
        if (first) {
            this.setState({
                categoryChildrens: [
                    category != null ? category : { id: 0, name: '' },
                ],
                categoryLists: [
                    listCategory,
                ]
            })

            if (category && category.cancreatecalled != null && category.cancreatecalled === true) {
                this.OrganizationCategoryId = category
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        OrganizationCategoryId: category
                    }
                }))
            }
        } else {
            this.setState(prevState => ({
                categoryChildrens: [
                    category != null ? category : { id: 0, name: '' },
                    ...prevState.categoryChildrens,
                ],
                categoryLists: [
                    listCategory,
                    ...prevState.categoryLists,
                ]
            }))
        }
    }

    handleRemoveChildrenCategory = async (i) => {
        PapiroConsole.log("=== handleRemoveChildrenCategory ===")
        PapiroConsole.log(i)
        this.setState({ loading: true })

        let categoryChildrens = [...this.state.categoryChildrens]
        let categoryLists = [...this.state.categoryLists]
        categoryChildrens.splice(i + 1)
        categoryLists.splice(i + 1)

        await this.getPreviousCategory(i)

        this.setState({ categoryChildrens, categoryLists })
        this.setState({ loading: false })
    }

    getPreviousCategory = async (i) => {
        PapiroConsole.log("=== getPreviousCategory ===")
        PapiroConsole.log(i)
        if (i > 0) {
            if (this.state.categoryChildrens[i - 1] !== undefined) {
                if (this.state.categoryChildrens[i - 1].cancreatecalled === true) {
                    this.OrganizationCategoryId = this.state.categoryChildrens[i - 1]
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            OrganizationCategoryId: this.state.categoryChildrens[i - 1]
                        }
                    }))
                    await this.categoryCanCreateCalled(this.state.categoryChildrens[i - 1])
                } else {
                    await this.getPreviousCategory(i - 1)
                }
            }
        } else {
            if (this.state.categoryChildrens[i] !== undefined && this.state.categoryChildrens[i].cancreatecalled === true) {
                this.OrganizationCategoryId = this.state.categoryChildrens[i]
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        OrganizationCategoryId: this.state.categoryChildrens[i]
                    }
                }))
            } else {
                this.OrganizationCategoryId = null
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        OrganizationCategoryId: null
                    }
                }))
            }
        }
    }

    async handleChangeCategoryChildrens(i, stateName, value) {
        PapiroConsole.log("=== handleChangeCategoryChildrens ===")
        PapiroConsole.log("OrganizationCategoryId")
        PapiroConsole.log(value)

        this.setState(prevState => ({
            called: {
                ...prevState.called,
                OrganizationCategoryId: value,
            },
            loading: true
        }))

        let categoryChildrens = [...this.state.categoryChildrens]
        if (i < categoryChildrens.length - 1)
            this.handleRemoveChildrenCategory(i)
        else {
            categoryChildrens[i] = value
            this.setState({ categoryChildrens })
        }

        if (value && value.id && value.id > 0) {

            await this.getCategoryChildrenByParentOrganizationCategoryId(value.id, stateName)
            if (value.cancreatecalled === true) {
                await this.categoryCanCreateCalled(value)
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        OrganizationCategoryId: value,
                    }
                }))
            }
        }


        await this.getTechnicalTeams(value.organizationid, value.id)


        this.setState({ loading: false })
    }

    categoryCanCreateCalled = async (value) => {
        this.categoryId = value;
        if (value.caneditcalledtitleanddescription != null) {
            let canEdit = true
            if (this.state.isDisabled == true && value.caneditcalledtitleanddescription == true)
                canEdit = false;
            else
                canEdit = value.caneditcalledtitleanddescription
            this.setState({ caneditcalledtitleanddescription: canEdit });
        }


    }

    getCategoryChildrenByParentOrganizationCategoryId = async (parentId, stateName = '') => {
        const intl = this.props.intl;

        var result = await CalledService.getByParentOrganizationCategoryId(parentId);
        if (result && result.success) {
            if (result.data && result.data.length > 0) {
                this.handleAddChildrenCategory(result.data)
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    async getTechnicalTeams(organizationId, organizationCategoryId) {
        if (organizationCategoryId && organizationCategoryId > 0) {

            const result = await CalledService.getTechinicalTeamsByOrganizationIdAndOrganizationCategoryId(organizationId, organizationCategoryId);
            const intl = this.props.intl;

            if (result.success) {
                let reordernewarrayuserteclist = result.data && result.data.length > 0 ? result.data : new Array();
                this.setState({ techinicalTeamList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
            } else {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(result);

            }
        }
    }

    getCategoryParentByChildrenOrganizationCategoryId = async (childrenId, stateName = '', first = false) => {
        const intl = this.props.intl;

        var result = await CalledService.getCategoryParentByChildrenOrganizationCategoryId(childrenId);
        if (result && result.success) {
            if (result.data && result.data.listitems && result.data.listitems.length > 0) {
                if (result.data && result.data.organizationcategory && result.data.organizationcategory.id) {
                    if (childrenId + '' === result.data.organizationcategory.parentorganizationcategoryid + '') {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "error.same.category.and.parent" })
                        });

                        return false;
                    }
                    await this.handleAddReverseChildrenCategory(result.data.listitems, first, result.data.organizationcategory)
                    await this.getCategoryParentByChildrenOrganizationCategoryId(result.data.organizationcategory.parentorganizationcategoryid)
                } else {
                    let parentIdFilter = result.data.listitems.filter(item => item.id == childrenId)
                    if (parentIdFilter && parentIdFilter.length > 0) {
                        if (childrenId + '' === parentIdFilter[0].parentorganizationcategoryid + '') {
                            this.setState({
                                loading: false,
                                openNotification: true,
                                notificationVariant: "error",
                                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "error.same.category.and.parent" })
                            });

                            return false;
                        }

                        await this.handleAddReverseChildrenCategory(result.data.listitems, first, parentIdFilter[0])
                        await this.getCategoryParentByChildrenOrganizationCategoryId(parentIdFilter[0].parentorganizationcategoryid)
                    } else {
                        await this.handleAddReverseChildrenCategory(result.data.listitems, first)
                    }
                }
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    getRootCategoriesByOrganization = async (organizationId) => {
        const intl = this.props.intl;
        var result = await CalledService.getRootCategoriesByOrganization(organizationId);
        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" }),
                            notificationLinkMove: "organizationcategories"
                        });
                    } else {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" })
                        });
                    }
                }
            }
            this.setState({
                levelCategories: {
                    rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
                },
                categoryLists: [
                    result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
                ]
            });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    getDefaultEmployeeHourValue = async value => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        Api.get(`/users/employeehourvalue/${value}`)
            .then(result => {
                if (result && result.data && result.data.data && result.data.success) {
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            employeehourvalue: !this.caninformzeroincalledtechnicalcost ? result.data.data.employeehourvalue : prevState.called.employeehourvalue,
                            valueforcalled: !this.caninformzeroincalledtechnicalcost ? result.data.data.valueforcalled : prevState.called.valueforcalled,
                            costparameter: result.data.data.costparameter ? result.data.data.costparameter : 1,
                            kmvaluerotated: result.data.data.kmvaluerotated,
                        },
                        loading: false
                    }))
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                } else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });
    }

    async submitCalled(closeModalFinish, clientChangeStatus = false, fromcalledfinish = false, dataformnew = null ) {
        PapiroConsole.log("=== submitCalled 1 ===")
        //console.log(this.props.calleds[`called-${this.urlId}`].submitaction);
        this.setState({
            loading: true,
            loadingTwo: true,
        });

        /*let inputValue = ''
        let calledDescription = document.getElementById('calledDescription')
        PapiroConsole.log("=== calledDescription ===")
        PapiroConsole.log(calledDescription)
        if (calledDescription != null) {
            let qlEditor = calledDescription.querySelector('.ql-container .ql-editor')
            if (qlEditor != null) {
                PapiroConsole.log("=== qlEditor ===")
                PapiroConsole.log(qlEditor)
                inputValue = `${qlEditor.innerHTML}`
            }
        }*/

        let calledTitleValue = ''
        if (!this.isClient) {
            let calledTitle = document.getElementById('title')
            if (calledTitle != null)
                calledTitleValue = calledTitle.value
        } else {
            calledTitleValue = this.state.called.title
        }

        await this.setState(prevState => ({ called: { ...prevState.called, title: calledTitleValue } }))

        let check = this.checkFinishCalled(true, closeModalFinish);
        if (check) {
            /*if (this.state.startCalledAttendance) {
                let clientStatus = this.state.statusList.find(element => element.id == 2)
                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        status: 2
                    },
                    startCalledAttendance: false,
                    clientStatus: clientStatus
                }))
            }*/

            if ((this.state.oldStatus.id != null && this.state.oldStatus.id > 0 && this.state.called.status != null && this.state.called.status > 0 && this.state.oldStatus.id != this.state.called.status) || this.state.directFinishCalled) {
                await this.setState({
                    saveCalledDialogCheck: true,
                    saveBasicInformationsCalledDialogCheck: false
                })
            }

            PapiroConsole.log("=== submitCalled 1.1 ===")
            PapiroConsole.log(this.state.oldStatus)
            PapiroConsole.log(this.state.called.status)
            if (this.state.called.hasdisplacementTrue == true) {
                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        hasdisplacement: true
                    }
                }))
            } else if (this.state.called.hasdisplacementFalse == true) {
                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        hasdisplacement: false
                    }
                }))
            }

            let addressData = null
            if (this.state.called.addresstype == "3") {
                const intl = this.props.intl;

                if (this.state.address.name == null || !this.state.address.name) {
                    this.setState({
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "missing.name.field" })
                    })
                    return false
                }

                let addressConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                let addressData = new FormData()
                for (let key in this.state.address) {
                    if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
                        addressData.append(key, moment(this.state.address[key]).format('YYYY/MM/DD HH:mm:ss'));
                    } else if (key.toString().toLowerCase().indexOf('id') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : 0);
                    } else if (key.toString().toLowerCase().indexOf('name') > -1) {
                        addressData.append(key, this.state.address[key]);
                    } else if (key.toString().toLowerCase().indexOf('street') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('zipcode') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('number') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('complement') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('neighborhood') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('city') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('state') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('country') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('longitude') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('latitude') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
                    } else if (key.toString().toLowerCase().indexOf('iscustomaddress') > -1) {
                        addressData.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : true);
                    } else {
                    }
                }

                addressData.append("organization", this.state.called.organization.id)

                if (this.state.address.id > 0) {
                    const intl = this.props.intl;

                    addressData.append("updateduser", this.state.address.updatedUser);
                    addressData.append("updatedat", this.state.address.updatedAt);

                    let result = await AddressService.editItem(addressData, addressConfig)
                    if (result && result.success) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                addressid: result.data.id,
                            },
                            address: {
                                ...prevState.address,
                                id: result.data.id ? result.data.id : 0
                            },
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "edit.success" })
                        }))
                    } else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        })
                        Api.kickoff(result)
                    }
                } else {
                    const intl = this.props.intl;

                    addressData.append("createduser", this.state.address.createdUser);
                    addressData.append("createdat", this.state.address.createdAt);

                    let result = await AddressService.addItem(addressData, addressConfig)
                    if (result && result.success) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                addressid: result.data.id,
                            },
                            address: {
                                ...prevState.address,
                                id: result.data.id ? result.data.id : 0
                            },
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "add.success" })
                        }))
                    } else {
                        const intl = this.props.intl
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        })
                        Api.kickoff(result)
                    }
                }
            }

            let data = new FormData();

            PapiroConsole.log("requestinguser aqui 2")
            PapiroConsole.log(this.state.called.requestingUser)

            for (let key in this.state.called) {

                if (key === 'dueDate') {
                    data.append(key, moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss"));
                }
                else if (key === "ParentCalledId") {
                    if (this.state.called.ParentCalledId !== 0 && this.state.called.ParentCalledId !== null && this.state.called.ParentCalledId != undefined) {
                        data.append("ParentCalledId", this.state.called.ParentCalledId.id);
                    } else {
                        data.append("ParentCalledId", 0);
                    }

                } else if (key.toString().toLowerCase().indexOf('requestinguser') > -1) {
                    PapiroConsole.log("entrei aqui no papiro")
                    PapiroConsole.log(key)
                    PapiroConsole.log(this.state.called[key])
                    PapiroConsole.log(this.state.called.requestingUser)
                    data.append("requestinguser", this.state.called[key].id);
                } else if (key.toString().toLowerCase().indexOf('assigneduser') > -1) {
                    if (this.state.called[key] && this.state.called[key].id) {
                        data.append("assigneduser", this.state.called[key].id);
                    } else {
                        data.append("assigneduser", 0);
                    }

                } else if (key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) {
                    PapiroConsole.log("=== submitcalled ===")
                    PapiroConsole.log("organizationcategoryid")
                    PapiroConsole.log(this.state.called[key])
                    PapiroConsole.log(this.state.called)
                    data.append("OrganizationCategory", this.state.called[key].id);
                }
                else if (key.toString() === 'techinicalteam') {
                    let techTeamId = this.state.called[key]
                    if (techTeamId == null || typeof techTeamId === 'undefined'
                        || techTeamId.id == null || typeof techTeamId.id === 'undefined') {
                        techTeamId = { id: 0 }
                    }

                    data.append("techinicalTeamId", techTeamId.id)
                    //alert(JSON.stringify(techTeamId));
                    //alert(techTeamId.id);
                }
                else if (key.toString() === 'departamentId') {
                    let deptVal = this.state.called[key].id
                    if (deptVal == null || typeof deptVal === 'undefined')
                        deptVal = 0
                    data.append("OrganizationDepartamentId", deptVal);
                } else if (
                    key
                        .toString()
                        .toLowerCase()
                        .indexOf("employeehourvalue") > -1) {
                    if (this.state.called[key].length > 0) {
                        /* Este código funciona local, ms não em DEMO! Explica essa ateu!
                        let employeehourvalueNotComma = this.state.called[key].replaceAll(",", "")
                        let employeehourvalueFloat = parseFloat(employeehourvalueNotComma) + ""
                        let employeehourvalueFinal = employeehourvalueFloat.replace(".", ",")
                        data.append(key, employeehourvalueFinal)*/
                        data.append(key, this.state.called[key])
                    } else
                        data.append(key, 0)
                } else if (key.toString().toLowerCase().indexOf("valueforcalled") > -1) {
                    if (this.state.called[key].length > 0 || this.state.called[key] > 0) {
                        /* Este código funciona local, ms não em DEMO! Explica essa ateu!
                        let gasvalueperliterNotComma = this.state.called[key].replaceAll(",", "")
                        let gasvalueperliterFloat = parseFloat(gasvalueperliterNotComma) + ""
                        let gasvalueperliterFinal = gasvalueperliterFloat.replace(".", ",")
                        data.append(key, gasvalueperliterFinal)*/
                        data.append(key, this.state.called[key])
                    } else
                        data.append(key, 0)
                } else if (key.toString().toLowerCase().indexOf("finishaccompanimentid") > -1) {
                    if (this.state.called[key] != null && this.state.called[key].id > 0) {
                        data.append(key, this.state.called[key].id)
                    }
                } else if (key.toString().toLowerCase().indexOf("priorityid") > -1) {
                    if (this.state.called[key] && this.state.called[key] > 0) {
                        data.append(key, this.state.called[key])
                    } else if (typeof this.state.called[key] === 'undefined') {
                        if (this.state.called.priority && this.state.called.priority.id) {
                            data.append(key, this.state.called.priority.id)
                        }
                    } else {
                        data.append(key, 0)
                    }
                } else if (key.toString().toLowerCase().indexOf('addressid') > -1) {
                    PapiroConsole.log("=== addressid ===")
                    PapiroConsole.log(this.state.called[key])
                    PapiroConsole.log("this.state.called.addresstype =>")
                    PapiroConsole.log(this.state.called.addresstype)
                    PapiroConsole.log(typeof this.state.called.addresstype)
                    PapiroConsole.log("addressData =>")
                    PapiroConsole.log(addressData)
                    if (this.state.called[key]) {
                        if (this.state.called[key] > 0)
                            data.append("addressid", this.state.called[key]);
                        else if (this.state.called.addresstype == "3" && addressData != null) {
                            data.append("addressid", addressData.id);
                        }
                    } else {
                        if (this.state.called.addresstype == "3" && addressData != null)
                            data.append("addressid", addressData.id);
                        else
                            data.append("addressid", 0);
                    }
                } else if (key.toString().toLowerCase().indexOf('addresstype') > -1) {
                    let addresstypeNumber = parseInt(this.state.called[key])
                    if (!isNaN(addresstypeNumber))
                        data.append("addresstype", addresstypeNumber);
                    else
                        data.append("addresstype", 1);
                }
                else if (key.toString().toLowerCase().indexOf('observantuser') > -1) {
                    if (this.state.called[key] && this.state.called[key].id) {
                        data.append("ObservantUser", this.state.called[key].id);
                    }
                    else {
                        data.append("ObservantUser", 0);
                    }

                }

                else {
                    data.append(key, this.state.called[key]);
                }

            }
            data.append("applicationoriginid", 2)

            let list = this.state.called['patrimonyId'];

            if (list != null && list != "") {

                let result = "";

                for (let value in this.state.called['patrimonyId']) {
                    result = this.state.called['patrimonyId'][value].id + "," + result
                }

                data.append("calledOrganizationPatrimonyId", result);
            }
            data.append("directfinishcalled", this.state.directFinishCalled)
            data.append("clientchangestatus", clientChangeStatus)
            data.append("displayassignedinfomode", this.state.displayassignedinfomode)

            data.append("requestinguserId", this.state.requestingUser ? this.state.requestingUser.id : 0)

            PapiroConsole.log(this.state.called)
            PapiroConsole.log("this.state.called.requestingUser passei por aqui")
            /* if(this.state.called.requestingUser){
                 PapiroConsole.log("this.state.called.requestingUser passei por aqui 2")
                 data.append("RequestingUser", this.state.called.requestingUser.id );
             }*/



            let urlUpdate = "/calleds"
            const { saveCalledDialogCheck, saveBasicInformationsCalledDialogCheck } = this.state
            if (saveBasicInformationsCalledDialogCheck == true && saveCalledDialogCheck == false)
                urlUpdate = "/calleds/basicdata"

            let pendingStatus = false
            if (this.state.called.status != null && this.state.called.status > 0) {
                this.state.statusList.map(item => {
                    if (item.id == this.state.called.status && item.statustypeid == 7)
                        pendingStatus = true
                })
            }



            if ((clientChangeStatus && (((this.state.called.requestingUser && this.state.called.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) && pendingStatus)) ||
                (!clientChangeStatus && (((this.state.called.requestingUser && this.state.called.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) && this.canclientautomaticchangestatusaftersendaccompaniment == true)))
                urlUpdate = "/calleds"

            this.closeSaveCalledDialog()
            // PORTO
            if((this.props.userSession.user.email == "lobm@poli.br"  || this.props.userSession.user.email == "horaciooliveirajob@gmail.com" ) && fromcalledfinish  /*&& this.state.asynchronousClosingCalled*/){   // ADM GLOBAL


                PapiroConsole.log("ENTREI AQUI NOS DADOS DA REQUISIÇÃO ASSINCRONA 2 ")

                const formDataFinal = new FormData();
                if(data != null){
                    for (var pair of data.entries()) {
                        //data.append(pair[0], pair[1]);
                        //data.append(pair[0], pair[1]);
                        PapiroConsole.log("pares aqui")
                        PapiroConsole.log(pair[0])
                        PapiroConsole.log(pair[1])
                        formDataFinal.append(`Called.${pair[0]}`, pair[1]);
                      
                    }
                }
                
                if(dataformnew != null){
                    for (var pair2 of dataformnew.entries()) {
                        PapiroConsole.log("formDataFinal dados")
                        PapiroConsole.log(pair2[0])
                        PapiroConsole.log(pair2[1])
                        formDataFinal.append(pair2[0], pair2[1]);
                        //alert(pair[0]+" = "+ pair[1]);
             
                    }
                }
                if (this.state.organizationFieldList.length > 0) {
                    let dataCallOrgField = new FormData();
                                Object.keys(this.state.called).forEach((key) => {
                                    if (key.includes("ofield-")) {
                                        if (typeof this.state.called[key] == "boolean") {

                                            this.state.called[key] = this.state.called[key].toString()

                                        }
                                        PapiroConsole.log("key")
                                        PapiroConsole.log(key)
                                        let organizationFieldOptionsValuesId = 0
                                        let explode = key.split('-')

                                        if(this.state.called[key] ){

                                            let explode2 = this.state.called[key].split('\n')
                                            
                                            let val = explode2[0]
                                            let organizationFieldId = explode[1];

                                            if (explode2[1] == undefined || explode2[1] == "undefined" || explode2[1] == null || explode2[1] == "null") {
                                                organizationFieldOptionsValuesId = 0;
                                            }
                                            else {
                                                organizationFieldOptionsValuesId = explode2[1];
                                            }
                                            

                                            formDataFinal.append("CalledOrganizationField.OrgFieldIdsList", parseInt(organizationFieldId))
                                            formDataFinal.append("CalledOrganizationField.CalledOrgFieldValuesList", val)
                                            formDataFinal.append("CalledOrganizationField.FieldOptionsValuesList", organizationFieldOptionsValuesId)
                                            formDataFinal.append("CalledOrganizationField.CalledId", this.state.called.id)
                                        }
                                    }
                                })

                }


                


                // for (var pair of dataform.entries()) {
                //     data.append(pair[0], pair[1]);
                //     //alert(pair[0]+" = "+ pair[1]);
                // }
                //alert('FINALIZAR CHAMADO');
                Api.put('/calleds/situationrequestasynchronousclosingcalled', formDataFinal)
                .then(result => {
                    if (result.data.success) {
                        const intl = this.props.intl;

                         this.setState(prevState => ({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "finish.called.redirect" }),
                            
                        }));
                        const { history, match } = this.props;
                        if (history != null && match != null) {
                            setTimeout(function () {
                                history.push({
                                    pathname: `/${match.params.lang}/calleds`,
                                })
                            }, 2000)
                        }

                        // alert(JSON.stringify(result.data));
                        // const { history, match } = this.props;
                        
                        // const finishedNow = this.state.called.status && true;
                        // const originId = this.state.called && this.state.called.originRequest && this.state.called.originRequest.id;
                        // const calledFinish = true;
                        // const intl = this.props.intl;
                        // var showSphereMessage = false
                        // if (originId && (originId == 2 || originId == 6 || originId == 11 || originId == 5 || originId == 4) && (finishedNow || calledFinish)) {
                        //     showSphereMessage = true;
                        // }

                        // this.setState(prevState => ({
                        //     loading: false,
                        //     loadingTwo: false,
                        //     openNotification: true,
                        //     notificationVariant: "success",
                        //     notificationMessage: (this.redirecttheuseraftercompletingthecalled != null && this.redirecttheuseraftercompletingthecalled && (finishedNow || calledFinish)) ? intl.formatMessage({ id: "finish.called.redirect" }) : showSphereMessage ? intl.formatMessage({ id: "finish.called" }) + '. ' + intl.formatMessage({ id: "close.called.sphere" }) : intl.formatMessage({ id: "edit.called.success" }),
                        //     files: [],
                        //     action: {
                        //         description: "",
                        //         privacy: ""
                        //     },
                        //     called: {
                        //         ...prevState.called,
                        //         finishaccompanimentid: null,
                        //     },
                        // }));

                    }
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && !isNaN(err.response.data.errors[0])) {
                        PapiroConsole.log("Caiu no primeiro")
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openDialogNewDisk: true,
                            valueNewDisk: err.response.data.errors[0],
                        });
                    } else {
                        const intl = this.props.intl;
                        PapiroConsole.log("Caiu no segundo")
                        this.setState({
                            openDialogNewDisk: false,
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }

                });
                // .catch(err => {
                //     const intl = this.props.intl;
                //     this.setState({
                //         loading: false,
                //         loadingTwo: false,
                //         openNotification: true,
                //         notificationVariant: "error",
                //         notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                //     });
                // });
            }
            else{
                Api.put(urlUpdate, data)
                    .then(result => {
                        if (result.data.success) {
                            this.setState({
                                saveCalledDialogCheck: false,
                                saveBasicInformationsCalledDialogCheck: true
                            })
                            let serviceStartDate = this.state.called.serviceStartDate
                            if (result.data.data != null && result.data.data.servicestartdate != null)
                                serviceStartDate = result.data.data.servicestartdate

                            const finishedNow = this.state.called.status && this.state.called.status.finished;
                            const calledFinish = result.data.data && result.data.data.status && result.data.data.status.finished
                            const CalledFinishAccompanimentId = result.data.data && result.data.data.calledstatusfinishaccompanimentaccompanimentid
                            const originId = this.state.called && this.state.called.originRequest && this.state.called.originRequest.id;

                            var showSphereMessage = false
                            if (originId && (originId == 2 || originId == 6 || originId == 11 || originId == 5 || originId == 4) && (finishedNow || calledFinish)) {
                                showSphereMessage = true;
                            }

                            if (CalledFinishAccompanimentId != null && CalledFinishAccompanimentId > 0) {
                                this.setState({ finishAccompanimentId: CalledFinishAccompanimentId }, () => this.refreshAccompaniments())
                            }
                            var propCalled = result.data.data
                            propCalled.originRequestName = result.data.data.originrequest.name
                            propCalled.assignedUser = result.data.data.originrequest.name

                            const intl = this.props.intl;
                            this.props.addCurrentCalled(propCalled);
                            this.props.addCalled(propCalled)
                            PapiroConsole.log("entrei aqui no quarto")
                            this.props.addAccompanimentsList(propCalled.accompaniments);
                            this.props.addAccompanimentsListInCalled(propCalled.accompaniments, propCalled.id);
                            this.statusDataBase = result.data.data.status.id;

                            this.setState(prevState => ({
                                loading: false,
                                loadingTwo: false,
                                openNotification: true,
                                notificationVariant: "success",
                                notificationMessage: (this.redirecttheuseraftercompletingthecalled != null && this.redirecttheuseraftercompletingthecalled && (finishedNow || calledFinish)) ? intl.formatMessage({ id: "finish.called.redirect" }) : showSphereMessage ? intl.formatMessage({ id: "finish.called" }) + '. ' + intl.formatMessage({ id: "close.called.sphere" }) : intl.formatMessage({ id: "edit.called.success" }),
                                //notificationMessage: "Finalizado aqui",
                                files: [],
                                action: {
                                    description: "",
                                    privacy: ""
                                },
                                called: {
                                    ...prevState.called,
                                    serviceStartDate: serviceStartDate,
                                    finishaccompanimentid: null,
                                    calledstatus: result.data.data.calledstatus != null ? result.data.data.calledstatus : [],
                                    priority: result.data.data.priority != null ? result.data.data.priority : null,
                                    status: result.data.data.status && result.data.data.status.id
                                },
                                clientStatus: result.data.data.status,
                                oldStatus: result.data.data.status,
                                calledStatusTypeId: prevState.called.status,
                            }));

                            PapiroConsole.log("this.redirecttheuseraftercompletingthecalled")
                            PapiroConsole.log(this.redirecttheuseraftercompletingthecalled)
                            PapiroConsole.log(finishedNow)
                            PapiroConsole.log(calledFinish)
                            if (this.redirecttheuseraftercompletingthecalled != null && this.redirecttheuseraftercompletingthecalled && (finishedNow || calledFinish)) {
                                const { history, match } = this.props;
                                if (history != null && match != null) {
                                    setTimeout(function () {
                                        history.push({
                                            pathname: `/${match.params.lang}/calleds`,
                                        })
                                    }, 2000)
                                }
                            }

                            if (this.state.organizationFieldList &&  this.state.organizationFieldList.length > 0) {
                                PapiroConsole.log("this.state.organizationFieldList")
                                PapiroConsole.log(this.state.organizationFieldList)
                                PapiroConsole.log("this.state.called")
                                PapiroConsole.log(this.state.called)
                                let dataCallOrgField = new FormData();
                                Object.keys(this.state.called).forEach((key) => {
                                    if (key.includes("ofield-")) {
                                        if (typeof this.state.called[key] == "boolean") {

                                            this.state.called[key] = this.state.called[key].toString()

                                        }
                                        PapiroConsole.log("key")
                                        PapiroConsole.log(key)
                                        let organizationFieldOptionsValuesId
                                        let explode = key.split('-')
                                        if(this.state.called[key] ){

                                            let explode2 = this.state.called[key].split('\n')
                                            

                                            let val = explode2[0]
                                            let organizationFieldId = explode[1];
                                            PapiroConsole.log("explode2")
                                            PapiroConsole.log(explode2)
                                            PapiroConsole.log("val")
                                            PapiroConsole.log(val)
                                            PapiroConsole.log("organizationFieldId")
                                            PapiroConsole.log(organizationFieldId)
                                            if (explode2[1] == undefined || explode2[1] == "undefined" || explode2[1] == null || explode2[1] == "null") {
                                                organizationFieldOptionsValuesId = 0;
                                            }
                                            else {
                                                organizationFieldOptionsValuesId = explode2[1];
                                            }


                                            dataCallOrgField.append("OrgFieldIdsList", parseInt(organizationFieldId))
                                            dataCallOrgField.append("CalledOrgFieldValuesList", val)
                                            dataCallOrgField.append("FieldOptionsValuesList", organizationFieldOptionsValuesId)
                                            dataCallOrgField.append("CalledId", this.state.called.id)

                                            /*let val = this.state.called[key]
                                            let explode = key.split('-')
                                            let organizationFieldId = explode[1];
            
                                            
                                            dataCallOrgField.append('Id', organizationFieldId)
                                            dataCallOrgField.append('Value', val);
                                            dataCallOrgField.append('UpdatedUser', this.state.userId);*/
                                        }

                                    }
                                })

                                if (this.state.calledOrganizationFieldsList.length > 0) {
                                    Api.put("/calledorganizationfields", dataCallOrgField)
                                        .then(result => { })
                                        .catch(err => {
                                            const intl = this.props.intl;
                                            this.setState({
                                                loading: false,
                                                openNotification: true,
                                                notificationVariant: "error",
                                                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                                            });
                                        });

                                }
                                else {

                                    Api.post("/calledorganizationfields/v2", dataCallOrgField)
                                        .then(result => { })
                                        .catch(err => {
                                            const intl = this.props.intl;
                                            this.setState({
                                                loading: false,
                                                openNotification: true,
                                                notificationVariant: "error",
                                                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                                            });
                                        });
                                }
                            }

                            this.getCalledCostToCard(this.state.called.id)
                            if (result.data.data.cost != null)
                                this.detailCardsCost(result.data.data.cost)
                        }
                    })
                    .catch(err => {
                        console.log("CATCH BASICA DATA");
                        console.log(err);
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            loadingTwo: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    });
                }
        } else {
            PapiroConsole.log("=== submitCalled 1.2 ===")
            this.setState({
                loading: false,
                loadingTwo: false,
            });
        }
    }
    handleChangeEditor(value) {
        this.setState(prevState => ({
            called: {
                ...prevState.called,
                description: value || ""
            }
        }));
    }

    handleChangeEditorAction(value) {
        PapiroConsole.log("=== handleChangeEditorAction ===")
        PapiroConsole.log(value)
        this.setState(prevState => ({
            action: {
                ...prevState.action,
                description: value || ""
            }
        }));
    }

    handleDynamicInputChange(e, origin) {
        const name = e.target.name;
        const value = e.target.value;
        const formName = origin ? 'action' : 'called'

        if (name.includes("ofield")) {


            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    [name]: value
                }
            }));

        }
    }

    handleInputChange(e, origin) {
        const name = e.target.name;
        const value = e.target.value;
        const formName = origin ? 'action' : 'called';




        //PapiroConsole.log(`=== handleInputChange(e, origin) ===`)
        //PapiroConsole.log(`origin => ${origin}`)
        //PapiroConsole.log(e)
        //PapiroConsole.log(e.target)
        //PapiroConsole.log(e.target.name)
        //PapiroConsole.log(e.target.value)

        if (name === "cloneCalledAmount") {
            this.setState({ cloneCalledAmount: value })
            return
        }
        if (name === "reasonOther") {
            this.setState({ reasonOther: value })
        }

        if (name === "description") {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    finishaccompanimentid: null
                }
            }))
        }

        if (name === 'kmvaluerotated') {
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        [name]: value
                    }
                }));
            } else if (value.length == 0) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        [name]: value
                    }
                }))
            }
        } else if (name === 'handleRequestingUserReason') {
            this.setState({ handleRequestingUserReason: value })
        } else {
            this.setState(prevState => ({
                [formName]: {
                    ...prevState[formName],
                    [name]: value
                }
            }));
        }

        PapiroConsole.log(this.state)
    };

    changeCloneValues = (stateName, value, text = '') => {
        this.setState({
            [stateName]: value
        })
    }
    onOpenAutoCompleteCategory = async () => {

        //alert(JSON.stringify(this.state.called));
        if (this.state.called && this.state.called.organization) {
            this.setState({
                loading: true
            })

            await this.getCategoriesByOrganization(this.state.called.organization);

            this.setState({
                openAutoCompleteCategory: true, loading: false
            })
        }
    }
    changeValues = async (stateName, value, text = '') => {
        PapiroConsole.log("stateName, value, text")
        PapiroConsole.log(stateName)
        PapiroConsole.log(value)
        PapiroConsole.log(text)
        if (stateName === "status") {
            let inputValue = ''
            let qlEditor = null
            let accompanimentAction = document.getElementById('accompanimentAction')
            if (accompanimentAction != null) {
                let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
                if (qlEditor != null) {
                    inputValue = `${qlEditor.innerHTML}`
                }
            }

            setTimeout(function () {
                let accompanimentActionSetter = document.getElementById('accompanimentAction')
                if (accompanimentActionSetter != null) {
                    let qlEditorSetter = accompanimentActionSetter.querySelector('.ql-container .ql-editor')
                    if (qlEditorSetter != null)
                        qlEditorSetter.innerHTML = inputValue
                }
            }, 1500)

            this.setState(prevState => ({
                action: {
                    ...prevState.action,
                    description: inputValue
                }
            }), () => {
                if (qlEditor != null)
                    qlEditor.innerHTML = inputValue
            })
        }

        this.setState(prevState => ({
            called: {
                ...prevState.called,
                [stateName]: value

            }
        }));

        if (stateName.includes("ofield")) {

            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    [stateName]: value.name + '\n' + value.id
                }
            }));


        }

        if (stateName === "status") {
            let getStatusById = this.state.statusList.filter(item => item.id == value)
            if (getStatusById != null) {
                if (getStatusById.length > 0) {
                    this.setState({ clientStatus: getStatusById[0] })
                    if (getStatusById[0].statustypeid == 5 || getStatusById[0].finished == true)
                        this.setState({ finishAccompaniment: true })
                    else
                        this.setState({ finishAccompaniment: false })
                } else
                    this.setState({ finishAccompaniment: false })
            } else
                this.setState({ finishAccompaniment: false })
        }

        if (stateName === "calledReasonId") {
            this.setState({
                "calledReasonId": value
            })
        } else if (stateName === 'requestingUserChange') {
            this.setState({
                'requestingUserChange': value
            })
        }
        if (stateName === "techinicalteam") {
            let { calleds } = this.props
            if (value && value.id && value.id > 0)
                this.setState(prevState => ({ search: { ...prevState.search, technicalUsersList: `/techinicalteamusers/getusers/technicalteam/${value.id}/roletype/${2}` }, technicalUsersList: [] }))
            else
                this.setState(prevState => ({ search: { ...prevState.search, technicalUsersList: `/users/category/${this.state.called && this.state.called.OrganizationCategoryId && this.state.called.OrganizationCategoryId.id ? this.state.called.OrganizationCategoryId.id : 0}/organization/${this.state.called && this.state.called.organization && this.state.called.organization.id ? this.state.called.organization.id : 0}` }, technicalUsersList: [] }))
        }

        if (stateName === "defaultActionId") {
            PapiroConsole.log('=== defaultActionId ===')


            if (value && value.Name && value.Name.length > 0) {
                let accompanimentAction = document.getElementById('accompanimentAction')
                PapiroConsole.log("=== accompanimentAction ===")
                PapiroConsole.log(accompanimentAction)
                if (accompanimentAction != null) {
                    let qlEditor = accompanimentAction.querySelector('.ql-container .ql-editor')
                    if (qlEditor != null) {
                        qlEditor.innerHTML = value.Name || ""
                    }
                    PapiroConsole.log("=== qlEditor ===")
                    PapiroConsole.log(qlEditor)
                }

                this.setState(prevState => ({
                    action: {
                        ...prevState.action,
                        description: this.state.action.description + value.Name || ""
                    },
                    defaultActionId: value
                }))
                this.props.addDefaultAction(value.Name + " " || "")
            } else {
                this.setState({ defaultActionId: 0 })
            }
        }

        if (stateName === 'addressid') {
            PapiroConsole.log('=== addressid ===')
            PapiroConsole.log(stateName)
            PapiroConsole.log(value)
            PapiroConsole.log(this.state.called.addressid)
            //console.log(value)
            /*this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ['addressid']: value.id
                }
            }))*/
        }
        if (stateName === "departamentId") {
            this.setState({ departamentId: value })
        }

        if (stateName === "OrganizationCategoryId") {
            PapiroConsole.log("=== changeValues ===")
            PapiroConsole.log("OrganizationCategoryId")
            PapiroConsole.log(value)
            this.OrganizationCategoryId = value;
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    "OrganizationCategoryText": text
                },
                technicalUsersList: [],
                search: {
                    ...prevState.search,
                    technicalUsersList: `/users/category/${value.id}/organization/${this.state.calledorganizationid}`
                }
            }));
            if (this.OrganizationCategoryId && this.OrganizationCategoryId.id && this.OrganizationCategoryId.id > 0)
                await this.getDepartamentsByOrganizationCategoryId(this.OrganizationCategoryId.id)
            if (value && value.typeid && value.typeid > 0) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        type: value.typeid
                    },
                    typeDisabled: true
                }));
            } else {
                this.setState({ typeDisabled: false });
            }
            if (value && value.priorityid && value.priorityid > 0) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        priorityid: value.priorityid,
                        priority: value.priority && value.priority.id ? value.priority : null
                    },
                    priorityDisabled: true
                }));
            } else {
                this.setState({ priorityDisabled: false });
            }
            if (value && value.caneditcalledtitleanddescription != null) {
                let canEdit = true
                if (this.state.isDisabled == true && value.caneditcalledtitleanddescription == true)
                    canEdit = false;
                else
                    canEdit = value.caneditcalledtitleanddescription
                this.setState({ caneditcalledtitleanddescription: canEdit });
            }
            await this.getTechnicalTeams(value.organizationid, value.id)
        }
        if (stateName === "ParentCalledId") {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    "ParentCalledText": text
                }
            }));
        }
        if (stateName === "assignedUser") {
            if (value > 0)
                await this.getDefaultEmployeeHourValue(value.id)
        }
        if (stateName === "finishaccompanimentid") {

            this.setState({
                action: {
                    description: "",
                    privacy: ""
                }
            })
        }
        if (stateName == 'hasdisplacementTrue') {
            if (value == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasdisplacementTrue': value,
                        'hasdisplacementFalse': false,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasdisplacementTrue': value,
                        'hasdisplacementFalse': true,
                    }
                }))
            }
        }
        if (stateName == 'hasdisplacementFalse') {
            if (value == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasdisplacementFalse': value,
                        'hasdisplacementTrue': false,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasdisplacementFalse': value,
                        'hasdisplacementTrue': true,
                    }
                }))
            }
        }
        if (stateName == 'hasParentCalledTrue') {
            if (value == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasParentCalledTrue': value,
                        'hasParentCalledFalse': false,
                    }
                }));
                await this.getParentCalleds(this.state.called.organization)
            } else {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasParentCalledTrue': value,
                        'hasParentCalledFalse': true,
                    }
                }))
            }
        }
        if (stateName == 'hasParentCalledFalse') {
            if (value == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasParentCalledFalse': value,
                        'hasParentCalledTrue': false,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasParentCalledFalse': value,
                        'hasParentCalledTrue': true,
                    }
                }))
                await this.getParentCalleds(this.state.called.organization)
            }
        }
        if (stateName == 'hasPatrimoniesTrue') {
            if (value == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasPatrimoniesTrue': value,
                        'hasPatrimoniesFalse': false,
                    }
                }));
                await this.getPatrimonios(this.state.called.organization)
            } else {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasPatrimoniesTrue': value,
                        'hasPatrimoniesFalse': true,
                    }
                }))
            }
        }
        if (stateName == 'hasPatrimoniesFalse') {
            if (value == true) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasPatrimoniesFalse': value,
                        'hasPatrimoniesTrue': false,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        'hasPatrimoniesFalse': value,
                        'hasPatrimoniesTrue': true
                    }
                }))
                await this.getPatrimonios(this.state.called.organization)
            }
        }
        if (stateName == 'priorityid') {
            let priorityItem = null
            if (this.state.priorityList && this.state.priorityList.length > 0) {
                let priorityFilter = null
                if (value && value.id && value.id > 0)
                    priorityFilter = this.state.priorityList.filter(item => item.id == value.id)
                else
                    priorityFilter = this.state.priorityList.filter(item => item.id == value)
                if (priorityFilter && priorityFilter.length > 0)
                    priorityItem = priorityFilter[0]
            }


            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    'priorityid': value && value.id ? value.id : value,
                    'priority': priorityItem
                }
            }));
        }

        if (stateName === "requestingUser") {

            this.setState({ requestingUser: value })

        }
        if (stateName == 'selectOrganizations') {
            if (value == true) {

                this.setState({
                    OrganizationFilter: [...this.state.organizationsList]
                })



            } else {
                this.setState({
                    OrganizationFilter: []
                })


            }
        }

        if (stateName == 'OrganizationFilter') {

            this.setState({
                [stateName]: value
            })

        }

        this.setState({
            openAutoCompleteCategory: false
        })
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    async pauseCalled() {

        PapiroConsole.log("entrei nesse pausar chamado")
        this.setState({ loading: true });
        await Api.put(`/calleds/pause/${this.state.called.id}/${this.state.userId}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({ id: "pause.success" })
                    });
                    this.setState(prevState => ({
                        called: {

                            ...prevState.called,
                            status: result.data.data.status.id,
                            loading: false,
                            calledstatus: result.data.data.calledstatus
                        },
                        clientStatus: result.data.data.status,
                        oldStatus: result.data.data.status,
                    }));

                    //this.cleanCache()
                    this.LoadBarraNew(this.state.called.id);
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
            });


        this.setState({ loading: false });
    }

    getCalledByParent() {
        if (this.state.called.ParentCalledId !== 0 && this.state.called.ParentCalledId != null && this.state.called.ParentCalledId != undefined) {
            this.setState({ loading: true });
            Api.get(`/calleds/calledInfo/${this.state.called.ParentCalledId.id}`)
                .then(result => {
                    if (result.data.success) {
                        const intl = this.props.intl;
                        let parent = result.data.data;
                        parent.parentname = `${parent.id}: ${parent.title}`;
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                ParentCalledId: result.data.data
                            },
                            loading: false
                        }));
                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }
                });
        }

    }


    getCalled() {

        Api.get(`/calleds/calledInfo/${this.state.called.id}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            status: result.data.data.statusid
                        },
                        clientStatus: result.data.data.status,
                        oldStatus: result.data.data.status,
                    }));
                }
                this.setCountLoading("getCalled");

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({

                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({

                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }
                this.setCountLoading("getCalled");

            });
    }

    addFileItem = (acceptedFiles) => {
        this.setState({ files: acceptedFiles })
    }

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }
    videoCallClick() {
        //console.log(value);
        this.setState({ modalopen: true });
    };
    openModal() {

    }
    async closeModal() {

        this.setState({ modalopen: false });

    }

    async closeModalFinish() {

        //this.setState({ modalopenFinish: false });

        /*if (this.props.calleds[`called-${this.urlId}`].submitaction == true) {
            this.submitAction(true);
        }*/

        this.setState({ modalopenFinish: false });
    }

    calledFinishOk = async () => {
        // closeModalFinish = false, directFinish = false, clientReopen = false, fromcalledfinish = false)
        this.submitAction(true,false,false,true);
    }

    closeModalCalledFinish = () => {

        this.setState({ modalopenFinish: false });

    }

    async getTechnicalUsersByTechnicalTeam(technicalTeamId) {
        if (technicalTeamId && technicalTeamId > 0) {
            this.setState({ loading: true });

            const result = await CalledService.getTechnicalUsersByTechnicalTeamIdAndRoleType(technicalTeamId, 2);
            const intl = this.props.intl;

            if (result.success) {
                if (result.data.length > 0) {
                    let reordernewarrayuserteclist = new Array();
                    result.data.map(item => {
                        if (item && item.user != null) {
                            let user = item.user
                            user.name = `${user.name} (${user.email})`
                            reordernewarrayuserteclist.push(user)
                        }
                    })
                    this.setState({ loading: false, technicalUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
                } else {
                    this.setState({
                        loading: false
                    })
                }
            } else {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(result);

            }
        }
    }

    async openAccompanimentLocationModal(id, type = 2) {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getByAccompanimentIdAndHistoryCheckType(id, type)
        //console.log(result)
        if (result && result.data && result.data.data) {
            this.setState({
                positionAccompCalled: [result.data.data.latitude ? result.data.data.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : '', result.data.data.longitude ? result.data.data.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : ''],
                addressAccompCalled: result.data.data.addresscomplete ? result.data.data.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : '',
                loading: false,
                accompanimentLocationModalOpen: !this.state.accompanimentLocationModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    async openFinishAccompanimentModal() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getAccompanimentsToFinish(this.state.called.id, this.state.called.requestingUser.id)
        if (result && result.data && result.data.data) {
            let finishAccompaniments = result.data.data.map(item => {
                let body = item.description
                let temp = document.createElement("div")
                temp.innerHTML = body
                let sanitized = temp.textContent || temp.innerText

                return {
                    ...item,
                    name: sanitized,
                    Name: item.description
                }
            })

            this.setState({
                finishAccompanimentList: finishAccompaniments && finishAccompaniments.length > 1 ? finishAccompaniments.sort((a, b) => a.name.localeCompare(b.name)) : finishAccompaniments,
                loading: false,
                finishAccompanimentModalOpen: !this.state.finishAccompanimentModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }

        //console.log(" === this.state.called.finishaccompanimentid === ")
        //console.log(this.state.called.finishaccompanimentid)
    }

    async openLocationModal() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        let result = await getByCalledIdAndHistoryCheckType(this.state.called.id, 1)
        //console.log(result)
        if (result && result.data && result.data.data && result.data.data.latitude && result.data.data.longitude) {
            this.setState({
                positionCalled: [result.data.data.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', ''), result.data.data.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')],
                addressCalled: result.data.data.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', ''),
                loading: false,
                locationModalOpen: !this.state.locationModalOpen
            })
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    openSLAOverflowModal = async _ => {
        const intl = this.props.intl;
        this.setState({ loading: true });
        await Api.get(`/reasons`)
            .then(result => {
                if (result && result.data && result.data.data) {
                    this.setState({
                        slaOverflowContentModalOpen: !this.state.slaOverflowContentModalOpen,
                        reasonsList: result.data.data.length > 1 ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data.data,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    }

    async getCalledFile(fileName, filerepositorymethod) {
        //window.open(`${Api.apiurlbase()}api/calleds/file/${this.state.hirerid}/${this.state.called.id}/${fileName}`, '_blank');
        const intl = this.props.intl;
        this.setState({ loading: true });


        let url = await CalledService.getFile(this.state.hirerid, this.state.called.id, fileName, filerepositorymethod);
        //alert(JSON.stringify(url.data));
        this.setState({ loading: false });
        if (url && url.data && url.data.data && url.data.success) {
            window.open(`${url.data.data}`, '_blank');
        }
        else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "file.not.found" })
            });

        }
        /*
        if (fileName.indexOf("files/") == -1) {
            window.open(`${Api.apiurlbase()}api/calleds/downloadold/${fileName}/${this.state.called.id}/${this.state.hirerid}`, '_blank');
        } else {
            let fName = fileName
            if(fName.startsWith('/wwwroot/', 0))
            fName = fName.substring(9)
            
            let apiUrl = Api.apiurlbase()
            if (apiUrl.endsWith("/"))
            apiUrl = apiUrl.slice(0, -1)

            window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
        }
        */
    };

    async getAccompainmentFile(fileName, accompanimentId, filerepositorymethod) {
        const intl = this.props.intl;
        this.setState({ loading: true });

        let url = await AccompanimentService.getFile(this.state.hirerid, this.state.called.id, accompanimentId, fileName, filerepositorymethod);
        this.setState({ loading: false });

        if (url && url.data && url.data.data && url.data.success) {
            window.open(`${url.data.data}`, '_blank');
        }
        else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "file.not.found" })
            });

        }

        //window.open(`${Api.apiurlbase()}api/accompaniments/file/${this.state.hirerid}/${this.state.called.id}/${accompanimentId}/${fileName}`, '_blank');
        /*
        if (fileName.indexOf("files/") == -1) {
            window.open(`${Api.apiurlbase()}api/accompaniments/downloadold/${fileName}/${this.state.called.id}/${accompanimentId}/${this.state.hirerid}`, '_blank');
        } else {
            window.open(`${Api.apiurlbase()}/${fileName}`, '_blank');
        }
        */
    };

    handleExpandAttendanceHoursCard = () => {
        let expand = !this.state.costBody.attendanceHoursExpanded

        this.setState(prevState => ({
            costBody: {
                ...prevState.costBody,
                attendanceHoursExpanded: expand
            }
        }))
    }

    showCostReport = () => {

    }

    showHoursReport = () => {
        const { classes } = this.props
        const intl = this.props.intl;

        const hoursReportColumns = [
            {
                "name": intl.formatMessage({ id: "call.solution.hours" }),
                "value": this.state.cost.hours.totalHoursRemote,
                "color": "#3f50b5"
            },
            {
                "name": intl.formatMessage({ id: "inloco.attendance.hours" }),
                "value": this.state.cost.hours.totalHoursInLoco,
                "color": "#FC2361"
            },
            {
                "name": intl.formatMessage({ id: "displacement.hours" }),
                "value": this.state.cost.hours.totalHoursDisplacement,
                "color": "#F294AF"
            }
        ]



        let attendanceHours =
            <Card>
                <CardHeader
                    title={intl.formatMessage({ id: "attendance.hours" })}
                    subheader="HH:mm" />
                <CardContent>
                    <div className={classes.chartContainer}>
                        <PieChart width={300} height={250} >
                            <Pie
                                data={hoursReportColumns}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={this.renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="sumallvalue" >
                                {
                                    hoursReportColumns.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))
                                }
                            </Pie>
                        </PieChart>
                    </div>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: this.state.costBody.attendanceHoursExpanded,
                        })}
                        onClick={this.handleExpandAttendanceHoursCard}
                        aria-expanded={this.state.costBody.attendanceHoursExpanded}
                        aria-label="attendance card hours - show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={this.state.costBody.attendanceHoursExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        Teste
                    </CardContent>
                </Collapse>
            </Card>

        this.setState(prevState => ({
            costBody: {
                ...prevState.costBody,
                attendanceHours: attendanceHours
            }
        }))
    }

    showInLocoReport = () => {

    }

    showCalledReport = () => {
        //this.showCostReport()
        //this.showHoursReport()
        //this.showInLocoReport()
    }

    async checkifUserCanAcessCalled(calledId) {

        const { userSession, history, match } = this.props;
        //this.setState({ loading: true })
        PapiroConsole.log("match AQUI")
        PapiroConsole.log(match)
        const result = await CalledService.checkifUserCanAcessCalled(calledId);
        const intl = this.props.intl;

        if (result.success) {

            PapiroConsole.log("resultado nova função aqui")
            PapiroConsole.log(result)
            if (result.data == true) {

                return true
            }
            else {
                this.setState({
                    openNotification: true, notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "user.cannot.access.this.functionality" }),
                    redirectClientWithNoAccess: true,
                });

                setTimeout(function () {

                    history.push({
                        pathname: `/${match.params.lang}/calleds`,

                    })


                }, 5000);

                return false;
            }


        } else {
            const intl = this.props.intl;
            /*
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            */
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "error.user.cannot.access.this.functionality" }),
                redirectClientWithNoAccess: true,
            });

            setTimeout(function () {

                history.push({
                    pathname: `/${match.params.lang}/calleds`,

                })


            }, 5000);


            Api.kickoff(result);
            return false;


        }

    }

    handleChange = async (e) => {
        let value = e.target.value

        this.setState(prevState => ({
            called: {
                ...prevState.called,
                addresstype: value,
            }
        }))
    }
    setClosingCalledModalDataForm(data, data2){

        //alert(data);
        this.setState({ ClosingCalledModalDataForm: data, ClosingCalledModalDataForm2 : data2  })
    }
    async getUserAddresses(userId) {
        this.setState({ loading: true })
        const result = await CalledService.getUserAddresses(userId);
        const intl = this.props.intl;
        if (result.success) {
            if (this.globalAdm || this.AdmOrganization) {
                if (result.data.length < 1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "user.no.registered.addresses" }),
                            notificationLinkMove: "organizations"
                        });
                    } else {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "user.no.registered.addresses" })
                        });
                    }
                }
            }

            if (this.globalAdm || this.AdmOrganization) {
                if (result.data && result.data.length == 1) {
                    PapiroConsole.log("=== addressid ===")
                    PapiroConsole.log(this.state.called)
                    PapiroConsole.log(this.organizationId)

                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            addressid: result.data[0].id
                        }
                    }))
                }
            }

            this.setState({ loading: false, userAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    }

    async getClientsByOrganizationId() {

        PapiroConsole.log("entrei aqui no getClientsByOrganizationId ")
        const { calleds } = this.props
        const intl = this.props.intl;

        this.setState({ loading: true })

        const result = await CalledService.getClientsByOrganizationId(this.state.called.organization.id);
        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && this.state.called.organization.id.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.organization.no.registered.requester" }),
                            notificationLinkMove: "users"
                        });
                    } else {
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.organization.no.registered.requester" })
                        });
                    }
                }
            }
            let reordernewarrayuserteclist = new Array();
            result.data.map(item => {
                item.name = item.name + " (" + item.email + ")";
                reordernewarrayuserteclist.push(item);
            })

            this.setState({ loading: false, clientUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    }

    handleClickActionsMenu = (event) => {
        this.setState({ actionsMenuAnchorEl: event.currentTarget })
    }

    handleCloseActionsMenu = _ => {
        this.setState({ actionsMenuAnchorEl: null })
    }

    validateCpf = value => {
        let sum, rest;

        sum = 0

        if (value == "00000000000" || value == "000.000.000-00") return false;

        value = value.replaceAll(".", "").replaceAll("-", "")

        for (let i = 1; i <= 9; i++) sum += parseInt(value.substring(i - 1, i)) * (11 - i)
        rest = (sum * 10) % 11

        if ((rest == 10) || (rest == 11)) rest = 0
        if (rest != parseInt(value.substring(9, 10))) return false

        sum = 0
        for (let i = 1; i <= 10; i++) sum += parseInt(value.substring(i - 1, i)) * (12 - i)
        rest = (sum * 10) % 11

        if ((rest == 10) || (rest == 11)) rest = 0
        if (rest != parseInt(value.substring(10, 11))) return false
        return true
    }

    handleInputChangeNewUser(e) {
        const name = e.target.name;
        const value = e.target.value;
        var valor = 0;

        if (name === 'cpfOfNewUser') {

            if (value.length <= 14) {
                valor = this.cpfMask(value)
            }
            else {
                valor = this.CNPJMask(value)
            }

            valor.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } })) : ((value.length !== 14 && value.length !== 18) || !this.isCPFOrCNPJValid(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF/CNPJ inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
            this.setState(prevState => ({
                createUserCalled: {
                    ...prevState.createUserCalled,
                    [name]: valor
                }
            }))
        }
        else {

            this.setState(prevState => ({
                createUserCalled: {
                    ...prevState.createUserCalled,
                    [name]: value
                }
            }))
        }
    };

    cpfMask = value => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    CNPJMask = (valor) => {
        return valor
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    };

    isCPFOrCNPJValid(value) {
        // Remove caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.length === 11) {
            // Validação CPF
            let sum = 0;
            let remainder;

            for (let i = 1; i <= 9; i++) {
                sum += parseInt(numericValue.substring(i - 1, i)) * (11 - i);
            }

            remainder = (sum * 10) % 11;

            if (remainder === 10 || remainder === 11) {
                remainder = 0;
            }

            if (remainder !== parseInt(numericValue.substring(9, 10))) {
                return false;
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) {
                sum += parseInt(numericValue.substring(i - 1, i)) * (12 - i);
            }

            remainder = (sum * 10) % 11;

            if (remainder === 10 || remainder === 11) {
                remainder = 0;
            }

            return remainder === parseInt(numericValue.substring(10, 11));
        } else if (numericValue.length === 14) {
            // Validação CNPJ
            let tamanho = numericValue.length - 2;
            let numeros = numericValue.substring(0, tamanho);
            const digitos = numericValue.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
            if (resultado !== parseInt(digitos.charAt(0))) {
                return false;
            }

            tamanho = tamanho + 1;
            numeros = numericValue.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

            return resultado === parseInt(digitos.charAt(1));
        }

        return false;
    }

    closeNewUserBaseDialog = () => this.setState({ newUserBaseDialogOpen: false })
    openNewUserBaseDialog = () => {

        this.getOrganizations();
        this.setState({ newUserBaseDialogOpen: true })

    }
    async getOrganizations() {
        const intl = this.props.intl;
        const result = await CalledService.getOrganizationsSimple();
        PapiroConsole.log(result)
        PapiroConsole.log("result organizações")
        if (result.success) {
            let organizations = result.data
            if (result.data) {
                this.setState({ organizationsList: organizations && organizations.length > 1 ? organizations.sort((a, b) => a.name.localeCompare(b.name)) : organizations });
            }

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }



    insertNewUser = async (e) => {
        e.preventDefault()


        PapiroConsole.log(this.state.createUserCalled)

        if (this.isCPFOrCNPJValid(this.state.createUserCalled.cpfOfNewUser) == false) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo CPF/CNPJ não foi validado"
            });
        }


        else if (this.state.createUserCalled.nameOfNewUser == null || this.state.createUserCalled.nameOfNewUser == "") {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo de nome é obrigatório "
            });
        }


        else if (this.state.createUserCalled.cpfOfNewUser == null || this.state.createUserCalled.cpfOfNewUser == "") {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo de CPF/CNPJ é obrigatório"
            });
        }
        else if (this.state.createUserCalled.emailOfNewUser == null || this.state.createUserCalled.emailOfNewUser == "") {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo de email é obrigatório"
            });
        }
        else if (this.state.createUserCalled.confirmEmailOfNewUser == null || this.state.createUserCalled.confirmEmailOfNewUser == "") {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo de confirmação de email é obrigatório"
            });
        }

        else if (this.state.createUserCalled.confirmEmailOfNewUser !== this.state.createUserCalled.emailOfNewUser) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo de email e o email de confirmação devem ser iguais"
            });
        }
        else if (this.state.called.organization == null) {

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "informe uma organização antes de criar um novo usuário"
            });

        }
        else if (this.state.associateOrganizationOptions == 'associateOtherOrganizations' && this.state.OrganizationFilter.length == 0) {

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "É necessário selecionar ao menos uma organização se a opção Associar solicitante com outras organizações estiver marcada "
            });
        }
        else {

            let data = new FormData();
            for (let key in this.state.createUserCalled) {
                if (key == "nameOfNewUser") {
                    data.append("username", this.state.createUserCalled[key])
                    PapiroConsole.log("entrei")
                }
                else if (key == "cpfOfNewUser") {
                    data.append("userdocument", this.state.createUserCalled[key])
                    PapiroConsole.log("entrei")
                }
                else if (key == "emailOfNewUser") {
                    data.append("useremail", this.state.createUserCalled[key])
                    PapiroConsole.log("entrei")
                }
                else if (key == "confirmEmailOfNewUser") {
                    data.append("userconfirmemail", this.state.createUserCalled[key])
                    PapiroConsole.log("entrei")
                }
            }
            if (this.state.associateOrganizationOptions == 'associateOtherOrganizations') {
                const organizationsIds = this.state.OrganizationFilter.map(item => item.id);
                const organizationsIdsString = organizationsIds.join(',');
                data.append("AssociateOrganizationOption", 2)
                data.append("SelectedOrganizations", organizationsIdsString)


            }
            else {
                data.append("associateOrganizationOption", 1)
                data.append("SelectedOrganizations", '')
            }

            data.append("OrganizationId", this.state.called.organization)
            data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))
            data.append("CreatedUser", this.props.userSession.user.id)

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            this.setState({ loading: true })
            await Api.post("/users/create-user-called", data, config)
                .then(async result => {

                    if (result.data.success) {
                        //await this.getClientsByOrganizationId() // não precisa chamar mais.. combo agora é assincrono
                        var element = result.data.data


                        /*this.setState(prevState => ({
                          called: {
                            ...prevState.called,
                            ["requestingUser"]: element
                          }
                        }));*/


                        const intl = this.props.intl;

                        var message = intl.formatMessage({ id: "user.created.success" });
                        if (result.data.message) {
                            message = result.data.message;
                        }

                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: message
                        });

                    } else {
                        if (result.data && result.data.errors && result.data.errors[0] && result.data.errors[0] === 'user_registered') {
                            this.setState({ loading: false })
                            this.closeNewUserBaseDialog()
                            this.openAssociateOrganizationUserDialog(result.data && result.data.data ? result.data.data : null)
                        }
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }
                });

            this.closeNewUserBaseDialog()

        }




    }

    handleChangeRadioDialog = (event) => {

        PapiroConsole.log(event)
        PapiroConsole.log("entrei no handleChangeRadioDialog ")

        this.setState({ associateOrganizationOptions: event.target.value })


    }

    actionRadio() {
        const intl = this.props.intl
        return (
            <RadioGroup aria-label="privacy" name="privacy" value={this.state.action.privacy} onChange={(e) => this.handleInputChange(e, "action")} row>
                <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: "public.action" })}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={intl.formatMessage({ id: "private.action" })}
                    labelPlacement="end"
                />
            </RadioGroup>
        )
    }

    
    
    render() {
        //PapiroConsole.log("this.state.called campos personalizados observação ofield-")
        PapiroConsole.log("this.state.asynchronousclosingcalled aqui")
        PapiroConsole.log(this.state.asynchronousClosingCalled)
        PapiroConsole.log("this.props.userSession")
        PapiroConsole.log(this.props.userSession)
        // PapiroConsole.log("this.state.action.description")
        // PapiroConsole.log(this.state.action.description)
        if (this.state.optionsBarHeight == null) {
            var options = document.getElementById("options")
            if (options != null) {
                const pos = options.getBoundingClientRect()
                if (pos != null) {
                    PapiroConsole.log(pos)
                    PapiroConsole.log("pos")
                    if (pos.y > 0) {
                    this.setState({ optionsBarHeight: pos.y })
                    } else {
                        window.scrollTo(0,0)
                    }
                }
            }
        }

        function getScrollTop() {
          
            var d = document.documentElement;
            if (typeof d.clientHeight !== "undefined") {
                // IE in standards mode
                return d.scrollTop;
            }
          
            // IE in quirks mode
            return document.body.scrollTop;
        }

        window.onscroll = () => {
            var options = document.getElementById("options")
            if (options != null) {
                var scroll = getScrollTop()
                if (scroll <= (this.state.optionsBarHeight)) {
                    options.style.top = "15px"
                } else {
                    options.style.top = (scroll - (this.state.optionsBarHeight) + (window.screen.availHeight / 20)) + "px"
                }
            }
        }

        PapiroConsole.log(this.state.displayassignedinfomode)
        PapiroConsole.log(this.state.called.requestingUser)
        PapiroConsole.log(this.state.called.requestingUser.isclient)
        PapiroConsole.log("variables")

        const { classes, headerMenu, calleds } = this.props;

        //PapiroConsole.log(calleds[`accompaniments-${this.urlId}`])
        //PapiroConsole.log("calleds aqui ")

        const { id } = this.props.match.params;



        if (id != null && id.lenght > 0 && calleds[`called-${id}`] != null && calleds[`called-${id}`].title != null && calleds[`called-${id}`].title.length > 0) {
            <LastCalledsQueueContext.Consumer>
                {equeuelastCalledsQueue => equeuelastCalledsQueue({ id, title: calleds[`called-${id}`].title })}
            </LastCalledsQueueContext.Consumer>
        }

        const openSaveMenu = Boolean(this.state.saveMenuAnchorEl)

        //console.log("calleds aqui")
        //console.log(calleds)
        const { address, called, action, itemError } = this.state;

        PapiroConsole.log(called.techinicalteam)
        PapiroConsole.log("called.techinicalteam")

        PapiroConsole.log(called.status)
        PapiroConsole.log("called.status aqui")

        PapiroConsole.log(called.techinicalteam)
        PapiroConsole.log("called.techinicalteam")

        const classes2 = this.useStyles();
        const intl = this.props.intl;
        //console.log(this.state.isDisabled)
        //console.log("disabled")
        let modalP = null;
        let modalVideo = null;
        let modalCheck = null;

        //PapiroConsole.log("=== Status ===")
        //PapiroConsole.log(called.status)

        const openActionsMenu = Boolean(this.state.actionsMenuAnchorEl)

        if (this.state.modalPatrimonio === true) {
            modalP = (
                <ModalPatrimonio
                    onClose={() => this.fecharModalPatrimonio()}
                    loadingPatrimony={() =>
                        this.getPatrimonios(this.state.called.organization)
                    }
                    id={this.state.called.organization}
                    notification={() => {
                        this.openNotificacao();
                    }}
                />
            );

        }

        if (this.state.initialChecklistModalOpen === true) {
            modalCheck = (
                <ChecklistModal
                    calledId={this.state.called.id}
                    setCheckConfig={this.setCheckConfig}
                    open={this.state.initialChecklistModalOpen}
                    onClose={this.closeInitialChecklistModal} />
            );
        }

        if (this.state.modalopen === true) {
            modalVideo = (
                <ModalVideoCall
                    onClose={() => this.closeModal()}
                    requestUser={this.infoRequest}
                    orgSettings={this.state.orgSettings}
                    called={this.state.called}
                    organization={this.state.organization}
                    organizationkey={this.state.organizationkey}
                />
            );

        }




        const HtmlTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                minWidth: 'max-content',
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
            },
            [`& .${tooltipClasses.popper}`]: {
                position: 'absolute',
                inset: '0 auto auto 0',
                margin: 0,
                left: '-37%',
                transform: 'translate(1147px, 286px)'
            }
        }));

        const calledBody = () => {
            return (
                <Fragment>
                    {(!this.isClient && this.state.calledStatusTypeId != null && this.state.calledStatusTypeId == 1) && <Box display={this.state.boxDisplay ? 'block' : 'none'}
                        sx={{
                            width: '20%',
                            position: 'fixed',
                            top: '30%',
                            right: '2%',
                            zIndex: '999'
                        }}>
                        <Paper
                            elevation={3} >
                            <Grid container spacing={0} paddingTop={0}>
                                <Grid item xs={1} sm={1} style={{ backgroundColor: '#d23939' }}></Grid>
                                <Grid item xs={11} sm={11} sx={{
                                    padding: '10px 25px 25px 20px',
                                    overflowY: 'auto',
                                    maxHeight: '60vh!important'
                                }}>
                                    <Grid container spacing={1} direction='column'>
                                        <Grid item xs={2} sm={2} padding={0}>
                                            <ClearIcon onClick={_ => this.setState({ boxDisplay: false })} style={{ float: 'right', cursor: 'pointer' }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} marginBottom={1}>
                                            <Typography variant="body1">
                                                O tempo de atendimento do seu chamado não está contando. É preciso iniciar o atendimento!
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                                <Grid item xs={12} sm={7}>
                                                    <Button
                                                        onClick={this.startCalledAttendance}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ borderRadius: '4px 0 0 4px !important' }} >
                                                        Iniciar atendimento
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={intl.formatMessage({ id: "organization" })}
                                name="organizacao"
                                value={this.state.called.orgname}
                                disabled
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography>{intl.formatMessage({ id: "phone" })}: {this.phone}</Typography>
                                                    </React.Fragment>
                                                }
                                            >
                                                <Add />
                                            </HtmlTooltip>
                                        </InputAdornment>
                                    ),
                                    shrink: true
                                }}
                            />
                        </Grid>
                        {this.state.called.quickCalledCreated ?
                            <Grid item xs={12} sm={12} md={6} lg={6}>

                                <SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" }) + " " + intl.formatMessage({ id: "requestuser" }) + " (min 5 caracteres)"} options={this.state.clientUsersList} stateName="requestingUser" changeSelect={this.changeValues} selected={this.state.called.requestingUser} loading={this.state.loadingRequestingUsers} urlapi={Api.apiurlbase() + 'api/users/search/' + (this.state.called && this.state.called.organization ? this.state.called.organization : 0) + '/' + called.addressid} />
                                <Link href="#" underline="none" onClick={this.openNewUserBaseDialog}>Cadastrar/associar novo solicitante?</Link>
                            </Grid>

                            :

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label={intl.formatMessage({ id: "requestuser" })}
                                    name="solicitante"
                                    value={this.state.requestingUserFromBarraNew ? this.state.requestingUserFromBarraNew.name : ""}
                                    disabled
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography>{intl.formatMessage({ id: "email" })}: {this.infoRequest && this.infoRequest.email ? this.infoRequest.email : '---'}</Typography>
                                                            <Typography>{intl.formatMessage({ id: "phone" })}: {this.infoRequest && this.infoRequest.phone ? this.infoRequest.phone : '---'}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Add />
                                                </HtmlTooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <SimpleAutoComplete
                                label={intl.formatMessage({ id: "attendance.team" })}
                                options={this.state.techinicalTeamList}
                                stateName="techinicalteam"
                                changeSelect={this.changeValues}
                                selected={called.techinicalteam}
                                required={this.isrequiredselectteamincalledfinish} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <SimpleAutoCompleteAsynchronousTechnicalUsers
                                label={intl.formatMessage({ id: "assigned" })}
                                linkToSearch={this.state.search.technicalUsersList}
                                toSearchProp={true}
                                nameAndEmail={true}
                                options={this.state.technicalUsersList}
                                stateName="assignedUser"
                                changeSelect={this.changeValues}
                                selected={this.state.called.assignedUser}
                                disabled={(
                                    !(called.organizationsettings.canassingusercalled ||
                                        (
                                            called.organizationsettings.canassingusercalled && (
                                                (!this.isCoordenator && !this.globalAdm && !this.AdmOrganization && !this.isTechinical) ||
                                                (
                                                    !this.isCoordenator && !this.globalAdm && !this.AdmOrganization && this.isTechinical && this.props.userSession.user.id != this.state.called.assignedUser?.id
                                                )
                                            )
                                        ))
                                )}
                                setErrorMessage={this.setErrorMessage}
                                isNotClientErrorMessage={true}
                                isClient={this.isClient}
                            />
                        </Grid>
                        {this.globalAdm &&

                            <Grid item xs={12} sm={6}>
                                <SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" }) + " " + intl.formatMessage({ id: "observer" })} options={this.state.clientUsersList} stateName="observantUser" changeSelect={this.changeValues} loading={this.state.loadingRequestingUsers} selected={this.state.called.observantUser} urlapi={Api.apiurlbase() + 'api/users/search/' + (this.state.called && this.state.called.organization ? this.state.called.organization : 0) + '/' + called.addressid} />
                            </Grid>


                        }

                        {/*(this.isTechinical || this.globalAdm) && (
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <SimpleSelect label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName="addressid" changeSelect={this.changeValues} selected={this.state.called.addressid} />
                            </Grid>
                        )*/}

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} disabled={this.state.typeDisabled} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <SimpleSelectWithColor label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.statusList} stateName="status" changeSelect={this.changeValues} selected={called.status} />
                        </Grid>

                        {(this.isTechinical || this.globalAdm) && (
                            <>
                                <Grid item xs={12} sm={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{intl.formatMessage({ id: "location" })}</FormLabel>
                                        <RadioGroup row aria-label="addresstype" name="addresstype" defaultValue="1" value={called.addresstype} onChange={this.handleChange}>
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: "organization.location" })} />
                                            <FormControlLabel
                                                value="2"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: "requester.location" })} />
                                            <FormControlLabel
                                                value="3"
                                                control={<Radio />}
                                                label={intl.formatMessage({ id: "location.new" })} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {(called.addresstype != null && called.addresstype === "1") && (<Grid item xs={12} sm={12}>
                                    <SimpleSelect
                                        label={intl.formatMessage({ id: "location" })}
                                        options={this.state.organizationAddressesList}
                                        stateName="addressid"
                                        changeSelect={this.changeValues}
                                        selected={called.addressid}
                                        hasLocationIcon={true}
                                        required />
                                </Grid>)}
                                {(called.addresstype != null && called.addresstype === "2") && (<Grid item xs={12} sm={12}>
                                    <SimpleSelect
                                        label={intl.formatMessage({ id: "location" })}
                                        options={this.state.userAddressesList}
                                        stateName="addressid"
                                        changeSelect={this.changeValues}
                                        selected={called.addressid}
                                        hasLocationIcon={true}
                                        required />
                                </Grid>)}
                                {(called.addresstype != null && called.addresstype === "3") && (<Grid item xs={12} sm={12}>
                                    <Accordion style={{ marginTop: "6px", marginBottom: "10px" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>{intl.formatMessage({ id: "location.new" })}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={`${intl.formatMessage({ id: "name" })}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChangeAddress} value={address.name} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField inputProps={{ maxLength: 100 }} fullWidth label={intl.formatMessage({ id: "cep" })} autoComplete='fname' variant='outlined' name='zipcode' onChange={this.handleInputChangeAddress} value={address.zipcode} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "street" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='street' onChange={this.handleInputChangeAddress} value={address.street} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "number" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='number' onChange={this.handleInputChangeAddress} value={address.number} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "complement" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complement' onChange={this.handleInputChangeAddress} value={address.complement} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "neighborhood" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhood' onChange={this.handleInputChangeAddress} value={address.neighborhood} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "city" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='city' onChange={this.handleInputChangeAddress} value={address.city} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "state" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='state' onChange={this.handleInputChangeAddress} value={address.state} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "country" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='country' onChange={this.handleInputChangeAddress} value={address.country} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "longitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitude' onChange={this.handleInputChangeAddress} value={address.longitude} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField fullWidth label={intl.formatMessage({ id: "latitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitude' onChange={this.handleInputChangeAddress} value={address.latitude} />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>)}
                            </>
                        )}
                        {/*this.globalAdm ||this.AdmOrganization || this.isCoordenator || this.isClient
                            ? <LogoutButton onClick={this.handleLogoutClick} />
                            : <LoginButton onClick={this.handleLoginClick} />
                    */}
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="fname"
                                name="title"
                                variant="outlined"
                                fullWidth
                                id="title"
                                InputLabelProps={{ shrink: true }}
                                disabled={this.state.showQuickCalled ? false : this.state.caneditcalledtitleanddescription ? false : true}
                                label={intl.formatMessage({ id: "title" })}
                            //value={called.title}
                            //onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                            <Typography>
                                <FormattedMessage id="description" />
                            </Typography>
                            <div style={{ resize: "vertical", height: "auto", marginBottom: "24px", border: "1px solid #ccc" }}>
                                <ReactQuill name="description"
                                    modules={this.reactQuillModules}
                                    formats={this.reactQuillFormats}
                                    style={{ height: "100%" }} 
                                    fullWidth
                                    value={called.description || ""}
                                    readOnly={this.state.showQuickCalled ? false : this.state.caneditcalledtitleanddescription ? false : true}
                                    required
                                    InputLabelProps={{ shrink: called.description && called.description.length > 0 ? true : false }}
                                    variant="outlined"
                                    id="calledDescription"
                                    onChange={this.handleChangeEditor} />
                                </div>
                        </Grid>
                        {this.state.called.calledattachments && this.state.called.calledattachments.length > 0 && (
                            <Grid item xs={12} style={{ marginBottom: 50 }}>
                                <div className={classes.attachmentsBox}>
                                    <label>{<FormattedMessage id="attachments" />}</label>
                                    <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                        <ul>
                                            {this.state.called.calledattachments.map(file => {
                                                return (<li key={file.attachment.path}>
                                                    <AttachFile /> <Button onClick={() => this.getFile(file.attachment)}>{file.attachment.description}</Button>
                                                </li>)
                                            })}
                                        </ul>
                                    </ImageList>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <SimpleDatePicker label={intl.formatMessage({ id: "solution.forecast" })} stateName="dueDate" changeDate={this.changeValues} currentDate={called.dueDate} format="dd/MM/yyyy HH:mm"/>
                        </Grid>
                        <If test={this.isCoordenator || this.globalAdm || this.AdmOrganization} >
                            <If test={this.state.called.costparameter == 2 && this.canselecthourcostorcalledcostincalled} >
                                <Grid item xs={3} sm={3}>
                                    <TextField
                                        label={intl.formatMessage({ id: "value.per.called" })}
                                        variant="outlined"
                                        value={called.valueforcalled}
                                        onChange={this.handleInputChange}
                                        name="valueforcalled"
                                        id="formatted-numberformat-input"
                                        InputProps={{
                                            inputComponent: this.NumberFormatCustom,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        autoComplete="fname"
                                        required />
                                </Grid>
                                {/*<Grid item xs={2} sm={2} style={{ marginLeft: "-10px" }}>
                                <SimpleHelp message={"Valor Hora Técnico (Usar . como separador decimal)"} />
                            </Grid>*/}
                            </If>
                            <If test={this.state.called.costparameter == 1 && this.canselecthourcostorcalledcostincalled} >
                                <Grid item xs={3} sm={3}>
                                    <TextField
                                        label={intl.formatMessage({ id: "called.employee.hour.value" })}
                                        variant="outlined"
                                        value={called.employeehourvalue}
                                        onChange={this.handleInputChange}
                                        name="employeehourvalue"
                                        id="formatted-numberformat-input"
                                        InputProps={{
                                            inputComponent: this.NumberFormatCustom,
                                        }}
                                        autoComplete="fname"
                                        required
                                        InputLabelProps={{ shrink: true }} />
                                </Grid>
                            </If>
                        </If>
                    </Grid>
                    <If test={(this.isCoordenator || this.globalAdm || this.AdmOrganization) && this.canselectdisplacementincalled} >
                        <Grid xs={12} sm={12} style={{ marginTop: 10, marginLeft: 5, marginBottom: 5 }}>
                            <Typography component='h1' variant='h5'>
                                {intl.formatMessage({ id: "called.accompaniment.will.have.displacement" })}
                            </Typography>
                        </Grid>
                        <Grid container spacing={4} style={{ marginTop: 5, marginLeft: 5, marginBottom: 15 }}>
                            <Grid xs={4} sm={1}>
                                <SimpleCheckBox changeSelect={this.changeValues} stateName='hasdisplacementTrue' fullWidth label={this.props.intl.formatMessage({ id: "yes" })} autoComplete='fname' name="hasdisplacementTrue" selected={this.state.called.hasdisplacementTrue} variant='outlined' />
                            </Grid>
                            <Grid xs={4} sm={1}>
                                <SimpleCheckBox changeSelect={this.changeValues} stateName='hasdisplacementFalse' fullWidth label={this.props.intl.formatMessage({ id: "no" })} autoComplete='fname' name="hasdisplacementFalse" selected={this.state.called.hasdisplacementFalse} variant='outlined' />
                            </Grid>
                        </Grid>
                        {called.hasdisplacementTrue && (
                            <fieldset style={{
                                marginTop: 25,
                                marginBottom: 25,
                                border: '1px solid #c0c0c0',
                                padding: '0 0 15px 0',
                                borderLeft: 'none',
                                borderRight: 'none'
                            }}>
                                <legend style={{
                                    paddingTop: 15,
                                    fontSize: '1.3rem',
                                    border: '1px solid #c0c0c0',
                                    borderBottom: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none'
                                }}>{intl.formatMessage({ id: "called.accompaniment.displacement.config" })}</legend>
                                <Grid container spacing={6}>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            label={intl.formatMessage({ id: "called.distance.value" })}
                                            variant="outlined"
                                            value={called.kmvaluerotated}
                                            onChange={this.handleInputChange}
                                            name="kmvaluerotated"
                                            id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: this.NumberFormatCustom,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            autoComplete="fname"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        )}
                    </If>


                    <If test={this.state.organizationFieldList && this.state.organizationFieldList.length > 0} >
                        <fieldset style={{
                            marginTop: 25,
                            marginBottom: 25,
                            border: '1px solid #c0c0c0',
                            padding: '0 0 15px 0',
                            borderLeft: 'none',
                            borderRight: 'none'
                        }}>
                            <legend style={{
                                paddingTop: 15,
                                fontSize: '1.3rem',
                                border: '1px solid #c0c0c0',
                                borderBottom: 'none',
                                borderLeft: 'none',
                                borderRight: 'none'
                            }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                            <Grid container spacing={4}>
                                {this.state.campoTexto}
                            </Grid>
                        </fieldset>
                    </If>

                    {/*this.state.called.quantityreama4 > 0 && (
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <TextField id='filled-number' disabled name="quantityreama4" label="Qtd Resma" value={this.state.called.quantityreama4} />
                            </Grid>
                        </Grid>
                    )*/}
                </Fragment>
            )
        };

        const actionBody = () => {
            return (
                <Fragment>
                    <If test={this.state.finishAccompaniment == true}>
                        <RTabs selectedIndex={this.state.rTabIndex} onSelect={index => this.setState({ rTabIndex: index })} sx={{ marginTop: '20px' }}>
                            <RTabList style={{ borderColor: '#BB8FCE' }}>
                                <RTab onClick={() => {
                                    this.setState(prevState => ({
                                        called: {
                                            ...prevState.called,
                                            finishaccompanimentid: null
                                        }
                                    }))
                                }}>{intl.formatMessage({ id: "action.new" })}</RTab>
                                <RTab onClick={() => {

                                    this.getTechnicalPublicAccompaniment()
                                    this.setState({
                                        action: {
                                            description: "",
                                            privacy: ""
                                        }
                                    })
                                }}>{intl.formatMessage({ id: "action.existing" })}</RTab>
                            </RTabList>
                            <RTabPanel style={{ padding: 10 }}>
                                <Grid container spacing={0} style={{ marginTop: 15 }}>
                                    {(called.organizationsettings.defaultaccompaniment && !this.isClient) && (
                                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                                            <SimpleAutoComplete
                                                label={intl.formatMessage({ id: "add.default.action" })}
                                                options={this.state.defaultActionList}
                                                stateName='defaultActionId'
                                                changeSelect={this.changeValues}
                                                selected={this.state.defaultActionId}
                                                htmlOptions={true} />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                                        {/*<TextField
                                                name="description"
                                                autoComplete="fname"
                                                label={intl.formatMessage({ id: "action" })}
                                                multiline
                                                rows="8"
                                                fullWidth
                                                value={action.description}
                                                variant="outlined"
                                                onChange={(e) => this.handleInputChange(e, "action")}
                                                inputProps={{ maxLength: 255 }}
                                            // disabled={this.isClient ? true : false}
                                            />*/}
                                        <Typography>
                                            <FormattedMessage id="action" />
                                        </Typography>
                                        {/*<RichTextArea
                                                    id="action"
                                                    name="action"
                                                    modules={this.reactQuillModules}
                                                    formats={this.reactQuillFormats}
													ref={this.quill} 
													//description={action.description || ''}
                                                    style={{ height: 200 }}
                                                    readOnly={this.isClient ? true : false}
                                                    saveChanges={this.handleChangeEditorAction} />*/}
                                        <div style={{ resize: "vertical", height: "auto", marginBottom: "24px", border: "1px solid #ccc" }}>
                                            <ReactQuill
                                                id="accompanimentAction"
                                                name="action"
                                                modules={this.reactQuillModules}
                                                formats={this.reactQuillFormats}
                                                /*value={action.description || ''}*/
                                                style={{ height: "100%" }} 
                                                readOnly={this.isClient ? true : false}
                                                fullWidth
                                                required
                                                //InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                        /*onChange={this.handleChangeEditorAction}*/ />
                                        </div>
                                    </Grid>
                                    {!this.isClient && !this.state.called.archived && <Grid item xs={12} sm={12} justify="flex-end" style={{ marginTop: 40, display: 'flex' }}>
                                        <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
                                            <IconButton aria-label="insert-knowledge-base"
                                                onClick={this.openKnowledgeBaseDialog}
                                                style={{
                                                    marginBottom: '30px',
                                                    backgroundColor: '#277afe',
                                                    color: '#FFF'
                                                }}>
                                                <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                </Grid>
                            </RTabPanel>
                            <RTabPanel style={{ padding: 10 }}>
                                <Grid container spacing={0} style={{ marginTop: 15 }}>
                                    <Grid item xs={12}>
                                        <SimpleAutoComplete
                                            label={intl.formatMessage({ id: "select.action" })}
                                            options={this.state.finishAccompanimentList}
                                            stateName='finishaccompanimentid'
                                            changeSelect={this.changeValues}
                                            selected={this.state.called.finishaccompanimentid}
                                            htmlOptions={true} />
                                    </Grid>
                                </Grid>
                            </RTabPanel>
                        </RTabs>
                    </If>
                    <If test={this.state.finishAccompaniment == false}>
                        <Grid container spacing={0} style={{ marginTop: 15 }}>
                            {(called.organizationsettings.defaultaccompaniment && !this.isClient) && (
                                <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "add.default.action" })}
                                        options={this.state.defaultActionList}
                                        stateName='defaultActionId'
                                        changeSelect={this.changeValues}
                                        selected={this.state.defaultActionId}
                                        htmlOptions={true} />
                                </Grid>
                            )}
                            {(((this.isClient && this.state.requestingUser && this.props.userSession && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (!this.isClient && (this.isTechinical || this.AdmOrganization || this.isCoordenator || this.globalAdm))) && (<Grid item xs={12} style={{ marginBottom: 20 }}>
                                {/*<TextField
                                    name="description"
                                    autoComplete="fname"
                                    label={intl.formatMessage({ id: "action" })}
                                    multiline
                                    rows="8"
                                    fullWidth
                                    value={action.description}
                                    variant="outlined"
                                    onChange={(e) => this.handleInputChange(e, "action")}
                                    inputProps={{ maxLength: 255 }}
                                // disabled={this.isClient ? true : false}
                                />*/}
                                <Typography>
                                    <FormattedMessage id="action" />
                                </Typography>
                                {/*<RichTextArea
                                        id="action"
                                        name="action"
                                        modules={this.reactQuillModules}
                                        formats={this.reactQuillFormats}
										ref={this.quill} 
										//description={action.description || ''}
                                        style={{ height: 200 }}
                                        readOnly={this.isClient ? true : false}
                                        saveChanges={this.handleChangeEditorAction} />*/}
                                        <div style={{ resize: "vertical", height: "auto", marginBottom: "24px", border: "1px solid #ccc" }}>
                                            <ReactQuill
                                                id="accompanimentAction"
                                                className="custom-quill"
                                                name="action"
                                                /*value={action.description || ''}*/
                                                style={{ height: "100%" }} 
                                                fullWidth
                                                modules={this.reactQuillModules}
                                                formats={this.reactQuillFormats}
                                                required
                                                variant="outlined"
                                                    /*onChange={this.handleChangeEditorAction}*/ />
                                     </div>
                            </Grid>)}
                            {!this.isClient && !this.state.called.archived && <Grid item xs={12} sm={12} justify="flex-end" style={{ marginTop: 40, display: 'flex' }}>
                                <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
                                    <IconButton aria-label="insert-knowledge-base"
                                        onClick={this.openKnowledgeBaseDialog}
                                        style={{
                                            marginBottom: '30px',
                                            backgroundColor: '#277afe',
                                            color: '#FFF'
                                        }}>
                                        <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>}
                        </Grid>
                    </If>
                    {(((this.isClient && this.state.requestingUser && this.props.userSession && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (!this.isClient && (this.isTechinical || this.AdmOrganization || this.isCoordenator || this.globalAdm))) && (<Grid mt={3} container spacing={0}>

                        <Typography variant="h6" gutterBottom >
                            <FormattedMessage id="attach.your.files" />
                        </Typography>
                        <section style={{ width: '100%', marginBottom: '30px' }}>
                            <div className={classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}  >
                                <Dropzone onDrop={acceptedFiles => { this.setState({ files: [...this.state.files, ...acceptedFiles] }) }}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()} >
                                            <input {...getInputProps()} />
                                            <AttachFileIcon />
                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                <FormattedMessage id="drag.and.drop.files" />
                                            </Typography>

                                        </div>
                                    )}
                                </Dropzone>
                                {this.state.files.map(file => {
                                    return (
                                        <div className={classes.dropzoneItem} key={file.name} >
                                            {file.name}
                                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    </Grid>)}
                    {called.knowledgebaseattachments.length > 0 && (
                        <Grid container spacing={0} style={{ marginTop: "5px" }}>
                            <Grid item xs={12}>
                                <div className={classes.attachmentsBox}>
                                    <label>{<FormattedMessage id="knowledgebase.attachments" />}</label>
                                    <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                        <ul>
                                            {called.knowledgebaseattachments.map(file => {
                                                return (<li key={file.path}>
                                                    <DeleteForever onClick={() => this.deleteItem(file)} style={{ cursor: "pointer" }} />
                                                    <AttachFile /> <Button onClick={() => this.getCalledFile(file.path, file.filerepositorymethod)}>{file.description}</Button>
                                                </li>)
                                            })}
                                        </ul>
                                    </ImageList>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={8}>
                            {!this.isClient && this.actionRadio()}
                        </Grid>
                        {(((this.state.clientStatus && this.state.clientStatus.statustypeid != 5 && this.isClient && this.state.requestingUser && this.props.userSession && this.state.requestingUser.id == this.props.userSession.user.id)
                            ||
                            (this.state.requestingUser && this.props.userSession && this.props.userSession.user && this.state.requestingUser.id != this.props.userSession.user.id && this.otherClientInteractsWithCalled == true)) || (!this.isClient && (this.isTechinical || this.AdmOrganization || this.isCoordenator || this.globalAdm))) && !this.state.called.archived && (<Grid item xs={12} sm={4}>
                                <div style={!this.isClient ? { float: 'right', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' } : { display: 'flex', justifyContent: 'flex-end' }} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ borderRadius: '4px 0 0 4px !important' }}
                                        disabled={(this.state.disablebutton) ? true : false} >
                                        {<FormattedMessage id="add.action" />}
                                    </Button>
                                    {!this.isClient && (<Button
                                        id="add-action-more-button"
                                        aria-controls={this.state.addActionOpen ? 'add-action-more-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={this.state.addActionOpen ? 'true' : undefined}
                                        onClick={this.addActionHandleClick}
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '5%', minWidth: '20%', paddingLeft: 15, paddingRight: 0, borderRadius: '0 4px 4px 0 !important' }}
                                        disabled={(this.state.disablebutton) ? true : false}
                                        startIcon={<ArrowDropDownIcon />} >
                                        &nbsp;</Button>)}
                                    <Menu
                                        id="add-action-more-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'add-action-more-button',
                                        }}
                                        anchorEl={this.state.addActionAnchorEl}
                                        open={this.state.addActionOpen}
                                        onClose={() => this.addActionHandleClose()}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }} >
                                        {this.state.sectableStatus.map((stts, i) => {
                                            if (stts != null && stts.name != null)
                                                return <MenuItem key={i} onClick={() => this.addActionHandleClose(stts.id)}>{intl.formatMessage({ id: "send.and.set.as" })} {stts.name}</MenuItem>
                                        })}
                                    </Menu>
                                </div>
                            </Grid>)}
                        {(this.isClient && this.state.requestingUser && this.props.userSession && this.state.requestingUser.id == this.props.userSession.user.id) && this.state.clientStatus.statustypeid == 5 &&
                            <Grid item xs={12} sm={4}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '100%', borderRadius: '4px 0 0 4px !important' }}
                                        disabled={!this.state.allowclientinteractiononclosedcalleds} >
                                        {<FormattedMessage id="add.action" />}
                                    </Button>
                                </div>
                            </Grid>

                        }
                    </Grid>
                    <div className={classes.actionItems}>
                        <IconButton color="inherit"
                            aria-label="open drawer"
                            onClick={this.refreshAccompaniments}
                            edge="start"
                        >
                            <CachedIcon />
                        </IconButton>
                        {(calleds[`accompaniments-${this.urlId}`] && calleds[`accompaniments-${this.urlId}`].length > 0) && <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }} >

                            {calleds[`accompaniments-${this.urlId}`].map((action, i) => {
                                if (this.isClient && action.privacy) {

                                } else {
                                    if (action.description.indexOf("https://") > -1 && (action.description.endsWith(".webm") > -1 || action.description.endsWith(".pdf") > -1 || action.description.endsWith(".png") > -1 || action.description.endsWith(".jpeg") > -1 || action.description.endsWith(".jpg") > -1 || action.description.indexOf("@@{") > -1)) {
                                        return <AccompanimentActionVideo
                                            key={i}
                                            caneditaccompanimentinactivecalled={this.state.caneditaccompanimentinactivecalled}
                                            displayassignedinfomode={this.state.displayassignedinfomode}
                                            action={action}
                                            showPrivacy={!this.isClient}
                                            client={this.isClient}
                                            globalAdm={this.globalAdm}
                                            userId={this.props.userSession ? this.props.userSession.user.id : 0}
                                            setLoading={this.setLoading}
                                            setMessage={this.setMessage}
                                            convertHtmlOption={this.convertHtmlOption}
                                            refreshAccompaniments={this.refreshAccompaniments}
                                            calledId={this.state.called.id}
                                            clientStatus={this.state.clientStatus}
                                            knowledgebage={action.knowledgebase && action.knowledgebase.deleted == false}
                                            attachments={action.accompanimentattachments}
                                            addAccompanimentToKnowledgeBase={() => this.addAccompanimentActionToKnowledgeBase(action.id)}
                                            deleteKnowledgeBase={() => this.deleteKnowledgeBase(action.id)}
                                            getFile={this.getAccompainmentFile}
                                            id={action.id}
                                            originId={action.originid}
                                            finishAccompanimentId={this.state.finishAccompanimentId}
                                            openAccompanimentLocationModal={() => {
                                                if (action.calledstatusid == 4 && (action.originid == 6 || action.originid == 11)) {
                                                    if (action.description.search(/realizou Check-in em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 3)
                                                    else if (action.description.search(/realizou Check-out em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 4)
                                                    else if (action.description.search(/realizou a assinatura da OS/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 5)
                                                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 7)
                                                    else if (action.description.search(/realizou Check-out de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                } else {
                                                    if (action.description.search(/realizou Check-in em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 3)
                                                    else if (action.description.search(/realizou Check-out em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 4)
                                                    else if (action.description.search(/realizou a assinatura da OS/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 5)
                                                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 7)
                                                    else if (action.description.search(/realizou Check-..t de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                }
                                            }}
                                        />
                                    } else {
                                        return <AccompanimentAction
                                            caneditaccompanimentinactivecalled={this.state.caneditaccompanimentinactivecalled}
                                            displayassignedinfomode={this.state.displayassignedinfomode}
                                            techinicalteamname={this.state.called.techinicalteam ? this.state.called.techinicalteam?.name : ''}
                                            checkIfCalledIsArchived={this.state.called.archived}
                                            key={i}
                                            action={action}
                                            showPrivacy={!this.isClient}
                                            client={this.isClient}
                                            globalAdm={this.globalAdm}
                                            userId={this.props.userSession ? this.props.userSession.user.id : 0}
                                            setLoading={this.setLoading}
                                            setMessage={this.setMessage}
                                            convertHtmlOption={this.convertHtmlOption}
                                            refreshAccompaniments={this.refreshAccompaniments}
                                            calledId={this.state.called.id}
                                            clientStatus={this.state.clientStatus}
                                            knowledgebage={action.knowledgebase && action.knowledgebase.deleted == false}
                                            attachments={action.accompanimentattachments}
                                            addAccompanimentToKnowledgeBase={() => this.addAccompanimentActionToKnowledgeBase(action.id)}
                                            deleteKnowledgeBase={() => this.deleteKnowledgeBase(action.id)}
                                            getFile={this.getAccompainmentFile}
                                            id={action.id}
                                            originId={action.originid}
                                            finishAccompanimentId={this.state.finishAccompanimentId}
                                            openAccompanimentLocationModal={() => {
                                                if (action.calledstatusid == 4 && (action.originid == 6 || action.originid == 11)) {
                                                    if (action.description.search(/realizou Check-in em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 3)
                                                    else if (action.description.search(/realizou Check-out em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 4)
                                                    else if (action.description.search(/realizou a assinatura da OS/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 5)
                                                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 7)
                                                    else if (action.description.search(/realizou Check-out de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                } else {
                                                    if (action.description.search(/realizou Check-in em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 3)
                                                    else if (action.description.search(/realizou Check-out em campo/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 4)
                                                    else if (action.description.search(/realizou a assinatura da OS/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 5)
                                                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 7)
                                                    else if (action.description.search(/realizou Check-..t de Deslocamento/i) > -1)
                                                        this.openAccompanimentLocationModal(action.id, 8)
                                                    else
                                                        this.openAccompanimentLocationModal(action.id)
                                                }
                                            }}
                                        />
                                    }

                                }
                            })}
                        </div>
                        }
                    </div>
                </Fragment>
            );
        };

        const clientOrganizationField = () => {
            return (
                <If test={this.state.organizationFieldList && this.state.organizationFieldList.length > 0} >
                    <fieldset style={{
                        marginTop: 25,
                        marginBottom: 25,
                        border: '1px solid #c0c0c0',
                        padding: '0 0 15px 0',
                        borderLeft: 'none',
                        borderRight: 'none'
                    }}>
                        <legend style={{
                            paddingTop: 15,
                            fontSize: '1.3rem',
                            border: '1px solid #c0c0c0',
                            borderBottom: 'none',
                            borderLeft: 'none',
                            borderRight: 'none'
                        }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                        <Grid container spacing={4}>
                            {this.state.campoTexto}
                        </Grid>
                    </fieldset>
                </If>
            );
        };

        const headerContent = () => {
            PapiroConsole.log("=== headerContent ===")
            PapiroConsole.log(this.state.called.priority)
            const intl = this.props.intl;
            var slaAtendimento = getSlaAtendimento(this.calledGridData, this.props.userSession);
            var slaSolucao = getSlaSolucao(this.calledGridData, this.props.userSession);
            PapiroConsole.log(slaAtendimento)
            PapiroConsole.log(slaSolucao)
            PapiroConsole.log("slaAtendimento")
            PapiroConsole.log("slaSolucao")


            if (slaAtendimento <= 0) {
                if (this.state.calledcost) {
                    slaAtendimento = this.state.calledcost.slaservice;
                }
                else {
                    slaAtendimento = 0.0;
                }
            }
            if (slaSolucao <= 0) {
                if (this.state.calledcost) {
                    slaSolucao = this.state.calledcost.slasolution;
                }
                else {
                    slaSolucao = 0.0;
                }
            }
            if (slaAtendimento == null || typeof slaAtendimento === 'undefined') {
                slaAtendimento = 0.0;
            }
            if (slaSolucao == null || typeof slaSolucao === 'undefined') {
                slaSolucao = 0.0;
            }
            PapiroConsole.log('slaAtendimento -> ' + slaAtendimento);
            PapiroConsole.log('slaSolucao -> ' + slaSolucao);

            return (
                <Fragment >

                    <Grid container spacing={0} >
                        <Grid item xs={3} > <h3>Nº {(id && id > 0) ? id : this.state.called.id}</h3> </Grid>
                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }} > {!this.isClient ? called.CallDueDate : ""} </Grid>
                        {this.state.called.calledclosingstatusname && (
                            <Grid item xs={1}>
                                <Tooltip
                                    title={<h5>{intl.formatMessage({ id: "closingstatus" })}: {this.state.called.calledclosingstatusname}</h5>}
                                    aria-label="called-closing-status">
                                    <IconButton aria-label="called-closing-status">
                                        <InfoIcon style={{ color: '#3f51b4', fill: '#3f51b4', fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {((!this.isClient) && (this.state.called.priority && this.state.called.priority.colorhex && this.state.called.priority.name)) && (
                            <Grid item xs={6} style={{ marginBottom: 0, fontSize: '0.8em' }}>
                                <Card className={classes.card} style={{ width: 'max-content' }}>
                                    <CardContent style={{ backgroundColor: `${this.state.called.priority.colorhex}`, color: '#fff', fontSize: '1.1em', padding: 15, display: 'flex', justifyContent: 'center', width: 'max-content' }}>
                                        {this.state.called.priority.name}
                                    </CardContent>
                                </Card>
                            </Grid>)}
                        {this.state.called.archived &&
                            <Grid item xs={2} style={{ marginTop: 0, paddingTop: 0 }}  > <h6 style={{ marginTop: 0, paddingTop: 0, fontStyle: 'italic' }}> (arquivado) </h6> </Grid>
                        }


                    </Grid>
                    {this.state.CalledModalOpen && (
                        <DialogConfirmation
                            msgButton={intl.formatMessage({ id: "confirm" })}
                            msg={intl.formatMessage({ id: "called.accompaniment.confirm.finish.called" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            msgAgree={intl.formatMessage({ id: "confirm" })}
                            handleConfirmation={e => this.cancellCalled()}
                            handleClose={e => this.closeCalledModal()}
                            classes={classes}
                            disabled={false}
                            icon={<DeleteIcon />}
                        />
                    )}

                    {this.state.unarchiveModalOpen && (
                        <DialogUnarchivedCalled
                            msgButton={intl.formatMessage({ id: "confirm" })}
                            msg={intl.formatMessage({ id: "do.you.want.to.unarchive.this.call" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            subtitle={intl.formatMessage({ id: "do.you.want.to.unarchive.this.call.info" })}
                            msgAgree={intl.formatMessage({ id: "confirm" })}
                            handleConfirmation={e => this.unarchivedCalled()}
                            handleClose={e => this.closeUnarchiveModal()}
                            classes={classes}
                            disabled={false}
                            icon={<DeleteIcon />}
                        />
                    )}



                    {/*<Dialog open={this.state.CalledModalOpen} onClose={this.closeCalledModal} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            Cancelamento do chamado
                            <ClearIcon onClick={this.closeCalledModal} style={{ marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Você deseja realmente cancelar o chamado ?
                            </DialogContentText>

                        </DialogContent>
                        <Button onClick={this.cancellCalled} color="secondary" type="submit" style={{ textAlign: 'center' }}>
                            Confirmar
                        </Button>
            </Dialog>*/}
                    <Dialog
                        maxWidth="md"
                        open={this.state.knowledgeBaseDetailsDialogOpen}
                        onClose={this.knowledgeBaseDetailsClose}
                        scroll={'body'}
                        aria-labelledby="knowledgebase-dialog-title">
                        <DialogTitle id="knowledgebase-dialog-title">
                            # {this.state.knowledgeBaseItem.id} | {this.state.knowledgeBaseItem.title}
                            <ClearIcon onClick={this.knowledgeBaseDetailsClose} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent className="knowledgebase-dialog-client-details" >
                            <Grid container spacing={0} style={{ marginTop: 15 }}>
                                <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <h5><FormattedMessage id="description" />:</h5>
                                    <div style={{ padding: '5px 10px', border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: 10 }} >{ReactHtmlParser(this.state.knowledgeBaseItem.description)}</div>
                                </Grid>
                                {(this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords && this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.length > 0) && <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <h5><FormattedMessage id="keywords" />:</h5>
                                    <div className='kb-keys' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                        {this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.map(item => {
                                            return <div className='kb-key' style={{ marginTop: 15 }}>{item.knowledgebasekeyword.word}</div>
                                        })}
                                    </div>
                                </Grid>}
                                {this.state.knowledgeBaseItem.knowledgebaseattachments.length > 0 && (
                                    <Grid item xs={12}>
                                        <div className={classes.attachmentsBox}>
                                            <label>{<FormattedMessage id="attachments" />}</label>
                                            <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                                <ul>
                                                    {this.state.knowledgeBaseItem.knowledgebaseattachments.map(file => {
                                                        return (<li key={file.attachment.id}>
                                                            <AttachFile /> <Button onClick={() => this.getFile(file.attachment)}>{file.attachment.description}</Button>
                                                            <Tooltip title={<h5>Copiar link de download</h5>}>
                                                                <IconButton aria-label="textevidence"
                                                                    onClick={() => this.copyFileLink(file.attachment)}>
                                                                    <FileCopyIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </ImageList>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={4} justify="flex-end" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={this.knowledgeBaseDetailsClose}
                                        fullWidth
                                    >
                                        {intl.formatMessage({ id: "close" })}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullWidth={true}
                        maxWidth={"md"}
                        open={this.state.confirmReopenCalledClientDialog}
                        onClose={this.closeConfirmReopenCalledClientDialog}
                        aria-labelledby="confirm-reopen-client-dialog-title" >

                        <DialogTitle id="confirm-reopen-client-dialog-title">
                            <FormattedMessage id="reopen.called" />
                        </DialogTitle>
                        <DialogContent >
                            <DialogContentText  >
                                {intl.formatMessage({ id: "confirm.reopen.called" })}
                            </DialogContentText>
                            <Typography style={{ paddingTop: '15px' }}>
                                <FormattedMessage id="action" />
                            </Typography>
                            <div style={{ resize: "vertical", height: "auto", marginBottom: "24px", border: "1px solid #ccc" }}>
                                <ReactQuill name="action"
                                    /*value={action.description || ''}*/
                                    style={{ height: "100%", marginBottom: '10px', paddingBottom: '20px' }}
                                    fullWidth
                                    modules={this.reactQuillModules}
                                    formats={this.reactQuillFormats}
                                    required
                                    variant="outlined"
                                    id="dialogAccompanimentAction"
                                    /*onChange={this.handleChangeEditorAction}*/ />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeConfirmReopenCalledClientDialog} color="secondary">
                                {intl.formatMessage({ id: "cancel" })}
                            </Button>
                            <Button onClick={this.confirmReopenCalledClient} color="secondary" type="submit">
                                {intl.formatMessage({ id: "confirm" })}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth="md"
                        fullWidth
                        open={this.state.handleChangeRequestingUserDialogOpen}
                        onClose={this.closeHandleChangeRequestingUserDialog}
                        aria-labelledby="handle-change-requesting-user" >
                        <DialogTitle id="handle-change-requesting-user">
                            <FormattedMessage id="change.request.user" />
                            <ClearIcon onClick={this.closeHandleChangeRequestingUserDialog} style={{ float: 'right', cursor: 'pointer' }} />
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        {/*<SimpleAutoComplete
                                            label={intl.formatMessage({ id: "requestuser" })}
                                            options={this.state.requestingUserChangeList}
                                            stateName='requestingUserChange'
                                            changeSelect={this.changeValues}
											selected={this.state.requestingUserChange} />*/}
                                        {/*        let result = this.state.called.addressid > 0 ? await getAllRequestingUsersSelectionModelByOrganizationIdAndLocationId(this.state.called.organization, this.state.called.addressid, this.state.called.requestinguserids) : await getAllRequestingUsersSelectionModelByOrganizationId( this.state.called.organization,this.state.called.requestinguserids);*/}
                                        <SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" }) + " " + intl.formatMessage({ id: "requestuser" })} options={this.state.requestingUserChangeList}
                                            stateName='requestingUserChange' changeSelect={this.changeValues} selected={called.requestingUserChange}
                                            urlapi={this.state.called.addressid > 0 ? Api.apiurlbase() + 'api/users/search/requestinguser/' + (this.state.called && this.state.called.organization ? this.state.called.organization : 0) + '/' + this.state.called.addressid + '/' + this.state.called.requestingUser.id : Api.apiurlbase() + 'api/users/search/requestinguser/' + (this.state.called && this.state.called.organization ? this.state.called.organization : 0) + '/' + this.state.called.requestingUser.id} />





                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            autoComplete="fname"
                                            id="handleRequestingUserReason"
                                            label={intl.formatMessage({ id: "reason" })}
                                            name="handleRequestingUserReason"
                                            fullWidth
                                            value={this.state.handleRequestingUserReason}
                                            variant="outlined"
                                            onChange={this.handleInputChange}
                                            multiline
                                            rows={2}
                                            maxRows={6} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeHandleChangeRequestingUserDialog} variant="contained" color="secondary">
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                                <Button onClick={this.confirmHandleChangeRequestingUserDialog} variant="contained" color="primary" type="submit">
                                    {intl.formatMessage({ id: "confirm" })}
                                </Button>
                            </DialogActions>
                        </DialogTitle>
                    </Dialog>
                    <Dialog
                        maxWidth="md"
                        open={this.state.knowledgeBaseDialogOpen}
                        onClose={this.closeKnowledgeBaseDialog}
                        aria-labelledby="knowledgebase-dialog-title" >
                        <DialogTitle id="knowledgebase-dialog-title">
                            <FormattedMessage id="insert.knowledge.base" />
                            <ClearIcon onClick={this.closeKnowledgeBaseDialog} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent style={{ width: "800px" }} >
                            <Grid container spacing={0} style={{ marginTop: 15 }}>
                                <Grid item xs={10}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "knowledgebase" })}
                                        options={this.state.knowledgeBaseList}
                                        stateName='knowledgebase'
                                        changeSelect={this.changeValuesKnowledgebase}
                                        selected={this.state.knowledgebase} />
                                </Grid>
                                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {(this.state.knowledgebase != null && this.state.knowledgebase.id != null && this.state.knowledgebase.id > 0) && <Tooltip title={<h5>Detalhes</h5>}>
                                        <IconButton aria-label="knowledge-base"
                                            onClick={() => this.knowledgeBaseDetails()}
                                            style={{
                                                marginBottom: '30px',
                                                backgroundColor: '#277afe',
                                                color: '#FFF'
                                            }}>
                                            <DescriptionIcon style={{ fontSize: '2rem' }} />
                                        </IconButton>
                                    </Tooltip>}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeKnowledgeBaseDialog} variant="contained" color="secondary" autoFocus >
                                {intl.formatMessage({ id: "cancel" })}
                            </Button>
                            <Button variant="contained" onClick={this.insertKnowledgebase} color="primary" >
                                <FormattedMessage id="insert" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.locationModalOpen}
                        onClose={this.closeLocationModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth={705}>
                        <DialogTitle id="form-dialog-title">
                            {intl.formatMessage({ id: "called.location" })}
                            <ClearIcon onClick={this.closeLocationModal} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent>
                            <Card style={{
                                width: 705, minWidth: 705, maxWidth: 705,
                                height: 400, minHeight: 400, maxHeight: 400
                            }}>
                                <CardContent>
                                    <MiniMap
                                        style={{
                                            width: 705, minWidth: 705, maxWidth: 705,
                                            height: 400, minHeight: 400, maxHeight: 400
                                        }}
                                        lat={this.state.positionCalled[0]}
                                        long={this.state.positionCalled[1]}
                                        address={this.state.addressCalled}
                                    />
                                </CardContent>
                            </Card>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.accompanimentLocationModalOpen}
                        onClose={this.closeAccompanimentLocationModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth={705}>
                        <DialogTitle id="form-dialog-title">
                            {intl.formatMessage({ id: "called.accompaniment.location" })}
                            <ClearIcon onClick={this.closeAccompanimentLocationModal} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent>
                            <Card style={{
                                width: 705, minWidth: 705, maxWidth: 705,
                                height: 400, minHeight: 400, maxHeight: 400
                            }}>
                                <CardContent>
                                    <MiniMap
                                        style={{
                                            width: 705, minWidth: 705, maxWidth: 705,
                                            height: 400, minHeight: 400, maxHeight: 400
                                        }}
                                        lat={this.state.positionAccompCalled[0]}
                                        long={this.state.positionAccompCalled[1]}
                                        address={this.state.addressAccompCalled}
                                    />
                                </CardContent>
                            </Card>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth="md"
                        fullWidth={true}
                        scroll="paper"
                        style={{ overflow: 'hidden', padding: 15 }}
                        open={this.state.saveCalledDialogOpen}
                        onClose={this.closeSaveCalledDialog}
                        aria-labelledby="form-dialog-save-called-title"
                        aria-describedby="form-dialog-save-called-description"
                        PaperProps={{
                            style: {
                                overflow: 'hidden'
                            }
                        }} >
                        <DialogTitle id="form-dialog-save-called-title">
                            {intl.formatMessage({ id: "save.called" })}
                            <ClearIcon onClick={this.closeSaveCalledDialog} style={{ float: 'right', marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent style={{ overflow: 'hidden' }} id="form-dialog-save-called-description">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    {/*<FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={this.state.saveCalledDialogCheck}
                                                onChange={(event) => this.handleChangeSaveCalledDialogCheck(event, true)} />
                                            }
                                            label="Salvar chamado considerando situação" />
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={this.state.saveBasicInformationsCalledDialogCheck}
                                                onChange={(event) => this.handleChangeSaveCalledDialogCheck(event, false)} />
                                            }
                                            label="Salvar apenas os dados básicos do chamado" />
                                    </FormGroup>*/}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={4} justify="flex-end" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={this.closeSaveCalledDialog}
                                        fullWidth
                                    >
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={(e) => this.handleSaveCalledDialogCheck(e)} >
                                        {intl.formatMessage({ id: "save" })}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        scroll="paper"
                        style={{ overflow: 'hidden', padding: 15 }}
                        open={this.state.slaOverflowContentModalOpen}
                        onClose={this.closeSLAOverflowModal}
                        aria-labelledby="sla-over-dialog-title"
                        aria-describedby="sla-over-dialog-description"
                        PaperProps={{
                            style: {
                                overflow: 'hidden'
                            }
                        }} >
                        <DialogTitle id="sla-over-dialog-title">
                            {intl.formatMessage({ id: "called.accompaniment.sla.overflow" })}
                            <ClearIcon onClick={this.closeSLAOverflowModal} style={{ float: 'right', marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent style={{ overflow: 'hidden' }} id="sla-over-dialog-description">
                            <Grid container spacing={4} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                    <FormattedMessage id="sla.overflow" />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "reason" })}
                                        options={this.state.reasonsList}
                                        stateName='calledReasonId'
                                        changeSelect={this.changeValues}
                                        selected={this.state.calledReasonId} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        autoComplete="fname"
                                        id="outlined-multiline-static"
                                        label={intl.formatMessage({ id: "others" })}
                                        name="reasonOther"
                                        multiline
                                        rows="8"
                                        fullWidth
                                        value={this.state.reasonOther}
                                        variant="outlined"
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={4} justify="flex-end" style={{ paddingRight: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={this.closeSLAOverflowModal}
                                        fullWidth
                                    >
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={this.saveCalledReason} >
                                        {<FormattedMessage id="save" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        scroll="paper"
                        style={{ overflow: 'hidden', padding: 15 }}
                        open={this.state.defaultActionModalOpen}
                        onClose={this.closeDefaultActionDialog}
                        aria-labelledby="defualt-action-dialog-title"
                        aria-describedby="defualt-action-dialog-description"
                        PaperProps={{
                            style: {
                                overflow: 'hidden'
                            }
                        }} >
                        <DialogTitle id="defualt-action-dialog-title">
                            {intl.formatMessage({ id: "default.actions" })}
                            <ClearIcon onClick={this.closeDefaultActionDialog} style={{ float: 'right', marginLeft: '64px', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent style={{ overflow: 'hidden' }} id="defualt-action-dialog-description">
                            <Grid container spacing={4} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                    <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "action" })}
                                        options={this.state.defaultActionList}
                                        stateName='defaultActionId'
                                        changeSelect={this.changeValues}
                                        selected={this.state.defaultActionId} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={4} justify="flex-end" style={{ paddingRight: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={this.closeDefaultActionDialog}
                                        fullWidth
                                    >
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={this.saveDefaultAction} >
                                        {intl.formatMessage({ id: "add.text" })}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Grid container>
                        <Grid item xs={8} >
                            <h2 style={{ textAlign: 'start' }}>{called.title}</h2>
                        </Grid>

                        <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>{this.state.called && this.state.called.originRequestName ? <FormattedMessage id="open.call.via" /> : <FormattedMessage id="open.called" />} {this.state.called && this.state.called.originRequestName ? this.state.called.originRequestName : ""} {this.state.called && this.state.called.providerName && this.state.called.providerName  !== ""  ? `(${this.state.called.providerName})` : null} {<FormattedMessage id="in" />} {moment(this.state.called.openingDate).format("DD/MM/YYYY HH:mm")} {(called.originRequest == 6 || called.originRequest == 11) && (<IconButton style={{ padding: 0 }} aria-label="LocationIcon"><LocationOnIcon onClick={this.openLocationModal} style={{ fontColor: "#CC1717", fill: "#CC1717" }} /></IconButton>)}</p>
                        </Grid>
                        {(!this.state.dataLoading && called.serviceStartDate != null) && <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>Atendimento iniciado em {moment(called.serviceStartDate).format("DD/MM/YYYY HH:mm")}</p>
                        </Grid>}
                        {(!this.state.dataLoading && called.solutionDate != null) && <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>Solucionado em {moment(this.state.called.solutionDate).format("DD/MM/YYYY HH:mm")}</p>
                        </Grid>}
                        {!this.state.dataLoading && <Grid item xs={8} >
                            <p style={{ textAlign: 'start' }}>{<FormattedMessage id="security.token" />} {this.state.called?.securitypassword}</p>
                        </Grid>}
                        {!this.state.dataLoading && <Grid item xs={8} >
                            {
                                called.requestingUser && called.requestingUser.isclient ?
                                    <p
                                        style={{ textAlign: 'start' }}>{called.requestingusername && called.requestingusername.length > 0 ? "Solicitado por: " + called.requestingusername : this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.name ? this.state.requestingUserFromBarraNew.name : ""}
                                        {called.requestinguserdocument && called.requestinguserdocument.length > 0 ? `, ${called.requestinguserdocument}` : this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.document && this.state.requestingUserFromBarraNew.document.lenght > 0 ? `, ${this.state.requestingUserFromBarraNew.document}` : ''}
                                        {called.requestinguseremail && called.requestinguseremail.length > 0 ? `, ${called.requestinguseremail}` : this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.email ? `, ${this.state.requestingUserFromBarraNew.email}` : ''}{this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.phone && this.state.requestingUserFromBarraNew.phone.lenght > 0 ? `, ${this.state.requestingUserFromBarraNew.phone}` : ''}
                                    </p>
                                    :
                                    (this.state.displayassignedinfomode == 4 || (this.state.displayassignedinfomode == 2 && this.isClient)) &&
                                    <p style={{ textAlign: 'start' }}>{called.techinicalteam && called.techinicalteam.name ? "Solicitado por: " + called.techinicalteam.name : "Solicitado por: ***"}</p>
                                    ||
                                    (this.state.displayassignedinfomode == 1 || (this.state.displayassignedinfomode == 2 && !this.isClient)) &&
                                    <p style={{ textAlign: 'start' }}>{called.requestingusername && called.requestingusername.length > 0 ? "Solicitado por: " + called.requestingusername : this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.name ? this.state.requestingUserFromBarraNew.name : ""}
                                        {called.requestinguserdocument && called.requestinguserdocument.length > 0 ? `, ${called.requestinguserdocument}` : this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.document && this.state.requestingUserFromBarraNew.document.lenght > 0 ? `, ${this.state.requestingUserFromBarraNew.document}` : ''}
                                        {called.requestinguseremail && called.requestinguseremail.length > 0 ? `, ${called.requestinguseremail}` : this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.email ? `, ${this.state.requestingUserFromBarraNew.email}` : ''}{this.state.requestingUserFromBarraNew && this.state.requestingUserFromBarraNew.phone && this.state.requestingUserFromBarraNew.phone.lenght > 0 ? `, ${this.state.requestingUserFromBarraNew.phone}` : ''}
                                    </p>
                            }
                        </Grid>}
                        {(!this.state.dataLoading && called.createdusername != null && called.createdusername.length > 0) && <Grid item xs={8} >
                            {
                                called.iscreateduserclient  ?
                                    <p style={{ textAlign: 'start' }}>
                                        {(called.createduseremail != null && called.createduseremail.length > 0 ? "Criado por: " + called.createdusername + `, ${called.createduseremail}` : '')}
                                    </p>
                                    :
                                    (this.state.displayassignedinfomode == 4 || (this.state.displayassignedinfomode == 2 && this.isClient)) &&
                                    <p style={{ textAlign: 'start' }}>
                                        {called.techinicalteam && called.techinicalteam.name ? "Criado por: " + called.techinicalteam.name : "Criado por: ***"}
                                    </p>
                                    ||
                                    (this.state.displayassignedinfomode == 1 || (this.state.displayassignedinfomode == 2 && !this.isClient)) &&
                                    <p style={{ textAlign: 'start' }}>
                                        {(called.createduseremail != null && called.createduseremail.length > 0 ? "Criado por: " + called.createdusername + `, ${called.createduseremail}` : '')}
                                    </p>

                            }
                        </Grid>}
                    </Grid>
                    {!this.state.dataLoading && <Grid container spacing={4}>
                        <Grid item xs={9} sm={5} style={{ paddingTop: 60, paddingBottom: 0 }} >
                            {<FormattedMessage id="sla.service" />}: {(Math.round(slaAtendimento * 100) / 100).toFixed(2)}%
                        </Grid>
                        <Grid item xs={3} sm={1} style={{ paddingTop: 60, paddingBottom: 0 }} >
                            <Tooltip title={<h5>Atualizar SLA e custos do chamado</h5>}>
                                <IconButton color="inherit"
                                    aria-label="refresh-called-cost-and-sla"
                                    onClick={this.handleRefreshCalledCostAndSLA}
                                    edge="start" >
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ paddingTop: 60, paddingBottom: 0 }} />
                        <Grid item xs={12} sm={6} style={{ paddingTop: 15, marginBottom: 5 }}>
                            {/*<LinearProgress variant="determinate" value={slaAtendimento} />*/}
                            <ColorfyLinearProgress progress={slaAtendimento} />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: 30, paddingBottom: 0 }} >
                            {<FormattedMessage id="sla.solution" />}: {(Math.round(slaSolucao * 100) / 100).toFixed(2)}%
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ paddingTop: 15, marginBottom: 30 }} >
                            {/*<LinearProgress variant="determinate" value={slaSolucao} />*/}
                            <ColorfyLinearProgress progress={slaSolucao} />
                        </Grid>
                    </Grid>}
                    {!this.state.dataLoading && <Grid container spacing={4} >

                        <Grid item md={12} sm={12} xs={12} id="options" style={{ position: 'relative', zIndex: 2 }} >

                            <Tooltip title={<h5>{intl.formatMessage({ id: "clean.cache" })}</h5>}>
                                <IconButton aria-label="delete-called"
                                    onClick={this.cleanCache}
                                    style={{
                                        marginRight: 30,
                                        marginTop: 15,
                                        backgroundColor: '#2979FF',
                                        color: '#FFF'
                                    }}>
                                    <CleaningServicesIcon style={{ fontSize: '2rem' }} />
                                </IconButton>
                            </Tooltip>
                            {(((this.isClient && this.state.requestingUser && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (this.globalAdm || this.AdmOrganization || this.isCoordenator)) && this.state.called.archived && (
                                <Tooltip title={<h5>{intl.formatMessage({ id: "unarchive.call" })}</h5>}>
                                    <IconButton aria-label="delete-called"
                                        onClick={this.closeUnarchiveModal}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}>
                                        <InventoryIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(((this.isClient && this.state.requestingUser && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) || (this.globalAdm || this.AdmOrganization || this.isCoordenator)) && !this.state.called.archived && (
                                <Tooltip title={<h5>{intl.formatMessage({ id: "called.cancel" })}</h5>}>
                                    <IconButton aria-label="delete-called"
                                        onClick={this.closeCalledModal}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#CC1717',
                                            color: '#FFF'
                                        }}>
                                        <CloseIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && !this.state.called.archived && (
                                <Tooltip title={<h5><FormattedMessage id="clone.called" /></h5>}>
                                    <IconButton aria-label="clone-called"
                                        onClick={this.closeCloneCalledModal}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}>
                                        <FileCopyIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}


                            <Dialog open={this.state.cloneCalledModalOpen} onClose={this.closeCloneCalledModal} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">
                                    {intl.formatMessage({ id: "clone.called" })}
                                </DialogTitle>

                                <DialogContent>
                                    <DialogContentText>
                                        {intl.formatMessage({ id: "clone.called.description" })}
                                    </DialogContentText>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                name="cloneCalledAmount"
                                                label={intl.formatMessage({ id: "clone.called.amount" })}
                                                fullWidth
                                                onChange={this.handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='calledorganizationmaterial'
                                                fullWidth label={intl.formatMessage({ id: "replaced.materials" })}
                                                autoComplete='fname'
                                                name="calledorganizationmaterial"
                                                selected={this.state.calledorganizationmaterial}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='calledusers'
                                                fullWidth
                                                label={intl.formatMessage({ id: "professionals.involved" })}
                                                autoComplete='fname'
                                                name="calledusers"
                                                selected={this.state.calledusers}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='calledorganizationFields'
                                                fullWidth
                                                label={intl.formatMessage({ id: "organization.custom.fields" })}
                                                autoComplete='fname'
                                                name="calledorganizationFields"
                                                selected={this.state.calledorganizationFields}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='calledtimeentries'
                                                fullWidth
                                                label={intl.formatMessage({ id: "appointment.hours" })}
                                                autoComplete='fname'
                                                name="calledtimeentries"
                                                selected={this.state.calledtimeentries}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='calledadditionalservices'
                                                fullWidth
                                                label='Serviços adicionais'
                                                autoComplete='fname'
                                                name="calledadditionalservices"
                                                selected={this.state.calledadditionalservices}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='initialcalledorganizationchecklists'
                                                fullWidth
                                                label={intl.formatMessage({ id: "checklist.previous.to.attendance" })}
                                                autoComplete='fname'
                                                name="initialcalledorganizationchecklists"
                                                selected={this.state.initialcalledorganizationchecklists}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='finalcalledorganizationchecklists'
                                                fullWidth
                                                label={intl.formatMessage({ id: "checklist.post.attendance" })}
                                                autoComplete='fname'
                                                name="finalcalledorganizationchecklists"
                                                selected={this.state.finalcalledorganizationchecklists}
                                                variant='outlined' />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <SimpleCheckBox
                                                changeSelect={this.changeCloneValues}
                                                stateName='calledorganizationpatrimonies'
                                                fullWidth
                                                label={intl.formatMessage({ id: "menu.organizationpatrimonies" })}
                                                autoComplete='fname'
                                                name="calledorganizationpatrimonies"
                                                selected={this.state.calledorganizationpatrimonies}
                                                variant='outlined' />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.closeCloneCalledModal} color="secondary">
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                    <Button onClick={this.cloneCalled} color="secondary" type="submit">
                                        {intl.formatMessage({ id: "clone" })}
                                    </Button>
                                </DialogActions>
                            </Dialog>



                            {!this.isClient && !this.state.called.archived && (
                                <Tooltip title={<h5><FormattedMessage id="pause.called" /></h5>}>
                                    <IconButton aria-label="pause-called"
                                        onClick={this.pauseCalled}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}>
                                        <PauseIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && this.canstartcallcenter && (
                                <Tooltip title={<h5><FormattedMessage id="video.call" /></h5>}>
                                    <IconButton aria-label="video-call"
                                        onClick={this.videoCallClick}
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}
                                        disabled={this.state.disabledvertex} >
                                        <VideoCallIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && this.canselectpatrimonyincalled && (
                                <Tooltip title={<h5>{intl.formatMessage({ id: "add.patrimony" })}</h5>}>
                                    <IconButton aria-label="add-patrimony"
                                        onClick={this.openPatrimonioModal}
                                        color='secondary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }} >
                                        <BusinessIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!this.isClient && this.caninforminitialchecklist && (
                                <Tooltip title={<h5>{intl.formatMessage({ id: "inform.initial.checklist" })}</h5>}>
                                    <IconButton aria-label="inform-initial-checklist"
                                        onClick={this.openInitialChecklistModal}
                                        color='secondary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }} >
                                        <CheckBoxIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}


                            {!this.isClient && !this.state.called.archived && (
                                <Tooltip title={<h5><FormattedMessage id="save.called" /></h5>}>
                                    <IconButton aria-label="save-called"
                                        onClick={this.saveCalled}
                                        color='primary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#3F51B5',
                                            color: '#FFF'
                                        }}
                                        /*aria-controls={openSaveMenu ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openSaveMenu ? 'true' : undefined}*/
                                        disabled={this.state.disablebutton} >
                                        <SaveIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Menu
                                anchorEl={this.state.saveMenuAnchorEl}
                                id="save-menu"
                                open={openSaveMenu}
                                onClose={this.handleCloseSaveMenu}
                                onClick={this.handleCloseSaveMenu}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Tooltip title={<h5>Segue o fluxo padrão do sistema. Status, responsável e encerramento do chamado serão considerados.</h5>}>
                                    <MenuItem onClick={(event) => this.handleChangeSaveCalledDialogCheck(event, true)}>
                                        Salvar chamado(padrão)
                                    </MenuItem>
                                </Tooltip>
                                <Tooltip title={<h5>Salva todas as informações básicas do chamado, mas sem considerar status, responsável e encerramento do chamado.</h5>}>
                                    <MenuItem onClick={(event) => this.handleChangeSaveCalledDialogCheck(event, false)}>
                                        Salvar apenas os dados básicos do chamado
                                    </MenuItem>
                                </Tooltip>
                            </Menu>

                            {((((this.isClient && this.state.requestingUser != null && this.props.userSession.user != null && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) && this.state.called.assignedUser != null && this.state.clientStatus && (this.state.clientStatus.statustypeid == 2 || this.state.clientStatus.statustypeid == 7) && this.canclientfinishcalled) || (!this.isClient && this.state.called.assignedUser != null)) && !this.state.called.archived && (
                                <Tooltip title={<h5><FormattedMessage id="done.called" /></h5>}>
                                    <IconButton aria-label="done-called"
                                        onClick={this.finishCalled}
                                        color='primary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#3F51B5',
                                            color: '#FFF'
                                        }} >
                                        <DoneAllIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {(((this.isClient && this.state.requestingUser && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true) && this.state.clientStatus && this.state.clientStatus.statustypeid == 5 && this.canclientreopencalled) && (
                                <Tooltip title={<h5><FormattedMessage id="reopen.called" /></h5>}>
                                    <IconButton aria-label="reopen-called"
                                        onClick={this.openConfirmReopenCalledClientDialog}
                                        color='primary'
                                        style={{
                                            marginRight: 30,
                                            marginTop: 15,
                                            backgroundColor: '#3F51B5',
                                            color: '#FFF'
                                        }} >
                                        <FileOpenIcon style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {/* !this.isClient && !this.state.called.archived && <TreeDots labelLeft="" items={this.state.itemsTree} style={{ position: 'absolute', right: 0, top: '50%' }} />*/}
                            {!this.isClient && !this.state.called.archived && <div className={classes.root} style={{ position: 'absolute', right: 0, top: '50%' }} >
                                <div>
                                    <IconButton
                                        style={{
                                            backgroundColor: '#2979FF',
                                            color: '#FFF'
                                        }}
                                        color='primary'
                                        aria-label='actions'
                                        component='span'
                                        id="actions-menu"
                                        aria-controls={openActionsMenu ? 'actions-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openActionsMenu ? 'true' : undefined}
                                        onClick={this.handleClickActionsMenu} >
                                        <ArrowDropDown style={{ fontSize: '2rem' }} />
                                    </IconButton>
                                    <Menu
                                        id="actions-menu"
                                        aria-labelledby="actions-menu"
                                        anchorEl={this.state.actionsMenuAnchorEl}
                                        open={openActionsMenu}
                                        onClose={this.handleCloseActionsMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }} >
                                        <MenuItem>
                                            <Button
                                                color="secondary"
                                                size="large"
                                                style={{ marginBottom: "5px" }}
                                                onClick={this.openHandleChangeRequestingUserDialog}
                                                startIcon={<GroupIcon />}>
                                                {intl.formatMessage({ id: "change.request.user" })}
                                            </Button>
                                        </MenuItem>
                                        {((this.globalAdm == true || this.AdmOrganization == true || this.isCoordenator) && this.state.allowchangeoforganizationofcalledbyadministration != null && this.state.allowchangeoforganizationofcalledbyadministration) && <MenuItem>
                                            <Button
                                                color="secondary"
                                                size="large"
                                                style={{ marginBottom: "5px" }}
                                                onClick={this.redirectToChangeCalledOrganization}
                                                startIcon={<AccountBalance />}>
                                                {intl.formatMessage({ id: "change.called.oraganization" })}
                                            </Button>
                                        </MenuItem>}
                                    </Menu>
                                </div>
                            </div>}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <div>
                                <form name="myForm" className={classes.form} onSubmit={this.handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={4} sm={4} ></Grid>
                                        <Grid item xs={4} sm={4} ></Grid>
                                        <Grid item xs={4} sm={4}>
                                            <Menu
                                                id="video-menu"
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                <MenuItem onClick={this.videoCallClick}><DuoIcon /> <span style={{ marginLeft: '10px' }}>Iniciar Manualmente</span></MenuItem>

                                            </Menu>

                                            {this.state.modalopenFinish && (
                                                <TransitionsModal isopen={this.state.modalopenFinish} handleOpen={this.openModal} handleClose={this.closeModalFinish}>
                                                    <CalledFinish
                                                        called={this.calledGridData}
                                                        calledId={this.urlId}
                                                        calledData={this.state.called}
                                                        actionDescription={this.state.action.description}
                                                        actionFinishId={this.state.called.finishaccompanimentid}
                                                        files={this.state.files}
                                                        addAccompanimentsList={this.props.addAccompanimentsList}
                                                        user={this.props.userSession}
                                                        slaAttend={getSlaAtendimento(this.calledGridData, this.props.userSession)}
                                                        slaSolution={getSlaSolucao(this.calledGridData, this.props.userSession)}
                                                        handleClose={this.closeModalFinish}
                                                        calledFinishOk={this.calledFinishOk}
                                                        closeModalCalledFinish={this.closeModalCalledFinish}
                                                        setFinishAccompanimentId={this.setFinishAccompanimentId}
                                                        setActionDescription={this.setActionDescription}
                                                        setDefaultActionAccompaniment={this.setDefaultActionAccompaniment}
                                                        removeItem={this.removeItem}
                                                        addFileItem={this.addFileItem}
                                                        defaultActionList={this.state.defaultActionList}
                                                        caninformutilizedmaterialsincalledfinish={this.caninformutilizedmaterialsincalledfinish}
                                                        canfinalchecklistisrequired={this.canfinalchecklistisrequired}
                                                        actionRadio={this.actionRadio}
                                                        setClosingCalledModalDataForm={this.setClosingCalledModalDataForm}
                                                        asynchronousClosingCalled={this.state.asynchronousClosingCalled} 
                                                    />
                                                </TransitionsModal>
                                            )}

                                        </Grid>

                                        {/*(this.globalAdm ||this.isCoordenator || this.AdmOrganization) && (<Grid item xs={12} sm={12} style={{ marginBottom: 20, marginTop: 70 }}>
                                            <fieldset style={{
                                                marginTop: 25,
                                                marginBottom: 25,
                                                border: '1px solid #c0c0c0',
                                                padding: '0 0 15px 0',
                                                borderLeft: 'none',
                                                borderRight: 'none'
                                            }} >
                                                <legend style={{
                                                    paddingTop: 15,
                                                    fontSize: '1.3rem',
                                                    border: '1px solid #c0c0c0',
                                                    borderBottom: 'none',
                                                    borderLeft: 'none',Por
                                                    borderRight: 'none'
                                                }} >Relatório Gerencial</legend>
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }} >
                                                    {this.state.costBody.attendanceHours}
                                                    {<Card>
                                                        <CardHeader
                                                          title="Total de {intl.formatMessage({id:"remote.attendance"})}"
                                                          subheader="HH:mm" />
                                                        <CardContent>

                                                        </CardContent>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader
                                                          title="Custo Total de atendimento" />
                                                        <CardContent>

                                                        </CardContent>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader
                                                          title="Informações adicionais" />
                                                        <CardContent>

                                                        </CardContent>
                                                    </Card>}
                                                </div>
                                            </fieldset>
                                        </Grid>)*/}
                                        <Grid item xs={12} sm={12} md={4} lg={4} style={!this.isClient ? { marginBottom: 20, marginTop: 70 } : { display: 'none' }}>
                                            <SimpleSelectWithColor label={intl.formatMessage({ id: "priority" })} options={this.state.priorityList} stateName="priorityid" changeSelect={this.changeValues} selected={this.state.called.priorityid} required disabled={this.state.priorityDisabled} />
                                        </Grid>
                                        {this.isClient &&
                                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                                <SimpleSelectWithColor disabled={true} label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.statusList} stateName="status" changeSelect={this.changeValues} selected={this.state.clientStatus ? this.state.clientStatus?.id : called.status} />
                                            </Grid>
                                        }

                                        {this.state.canselectorganizationcategoriesbylevels === false ?
                                            (<Grid item xs={12} sm={12} md={12} lg={12} style={!this.isClient ? { marginBottom: 20 } : { marginBottom: 20, marginTop: 70 }}>
                                                <SimpleAutoCompleteOpenManual
                                                    label={intl.formatMessage({ id: "category" })}
                                                    options={this.state.categoriesList}
                                                    stateName='OrganizationCategoryId'
                                                    changeSelect={this.changeValues}
                                                    onOpen={this.onOpenAutoCompleteCategory}
                                                    open={this.state.openAutoCompleteCategory}
                                                    selected={called.OrganizationCategoryId}
                                                    disabled={(this.isClient && !called.organizationsettings.roleclientcanselectcategory) ? true : (this.isClient && this.state.requestingUser.id != this.props.userSession.user.id) ? true : false}
                                                    required />
                                            </Grid>)
                                            :
                                            (
                                                <Grid item xs={12} sm={12} md={12} lg={12} style={!this.isClient ? { marginBottom: 20 } : { marginBottom: 20, marginTop: 70 }}>
                                                    <fieldset style={{ padding: '10px 10px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                                        <legend style={{ fontSize: '0.8rem', marginBottom: '0!important' }}><FormattedMessage id="category" />*</legend>
                                                        <Grid container spacing={4} id="categoriesByLevels" className="categoriesByLevels">
                                                            {this.createUi()}
                                                        </Grid>
                                                    </fieldset>
                                                </Grid>
                                            )}
                                        {/*<Grid item xs={12} sm={12} style={!this.isClient ? { marginBottom: 20 } : { marginBottom: 20, marginTop: 70 } }>
                                            <SimpleAutoCompleteAsynchronousOrganizationCategory
                                                label={intl.formatMessage({ id: "category" })}
                                                linkToSearch={this.state.search.categoriesList}
                                                toSearchProp={true}
                                                options={this.state.categoriesList}
                                                stateName="OrganizationCategoryId"
                                                changeSelect={this.changeValues}
                                                selected={called.OrganizationCategoryId}
                                                disabled={(this.isClient && !called.organizationsettings.roleclientcanselectcategory) ? true : false}
                                                setErrorMessage={this.setErrorMessage}
                                            />
                                        </Grid>*/}
                                        {this.canselectdepartamentincalled && <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20 }}>
                                            <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.DepartamentList} stateName='departamentId' changeSelect={this.changeValues} selected={this.state.departamentId}
                                                disabled={this.isClient ? true : false}
                                            />
                                        </Grid>}

                                        {(this.canclientselecttypeincalled && ((this.isClient && this.state.requestingUser.id == this.props.userSession.user.id) || this.otherClientInteractsWithCalled == true)) && <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20 }}>
                                            <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} disabled={true} />
                                        </Grid>}
                                        {this.isClient && <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                                            <Typography>
                                                <FormattedMessage id="description" />
                                            </Typography>
                                            <div style={{ resize: "vertical", height: "auto", marginBottom: "24px", border: "1px solid #ccc" }}>
                                                <ReactQuill name="description" 
                                                    fullWidth
                                                    modules={this.reactQuillModules}
                                                    formats={this.reactQuillFormats}
                                                    style={{ height: "100%" }} 
                                                    readOnly={true}
                                                    required
                                                    value={called.description || ""}
                                                    InputLabelProps={{ shrink: called.description && called.description.length > 0 ? true : false }}
                                                    variant="outlined"
                                                    id="calledDescription"
                                                    onChange={this.handleChangeEditor} />
                                              </div>      
                                        </Grid>}
                                        {(this.isClient && this.state.called.calledattachments && this.state.called.calledattachments.length > 0) && (
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div className={classes.attachmentsBox}>
                                                    <label>{<FormattedMessage id="attachments" />}</label>
                                                    <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                                        <ul>
                                                            {this.state.called.calledattachments.map(file => {
                                                                return (<li key={file.attachment.path}>
                                                                    <AttachFile /> <Button onClick={() => this.getFile(file.attachment)}>{file.attachment.description}</Button>
                                                                </li>)
                                                            })}
                                                        </ul>
                                                    </ImageList>
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid container spacing={4} style={called.hasPatrimoniesFalse && called.hasPatrimoniesFalse ? { marginBottom: 20 } : {}}>
                                            {!this.isClient && this.canselectparentcalledincalled && <Grid item xs={12} sm={6}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} >
                                                        <Typography component='h1' variant='h5'>
                                                            {intl.formatMessage({ id: "parent.calleds" })}?
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <SimpleCheckBox changeSelect={this.changeValues} stateName='hasParentCalledTrue' fullWidth label={this.props.intl.formatMessage({ id: "yes" })} autoComplete='fname' name="hasParentCalledTrue" selected={this.state.called.hasParentCalledTrue} variant='outlined' />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <SimpleCheckBox changeSelect={this.changeValues} stateName='hasParentCalledFalse' fullWidth label={this.props.intl.formatMessage({ id: "no" })} autoComplete='fname' name="hasParentCalledFalse" selected={this.state.called.hasParentCalledFalse} variant='outlined' />
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                            {(!this.isClient || this.canclientselectpatrimonyincalled) && this.canselectpatrimonyincalled && 
                                            <Grid item xs={12} sm={6}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} >
                                                        <Typography component='h1' variant='h5'>
                                                            {intl.formatMessage({ id: "inform.patrimonies" })}?
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <SimpleCheckBox changeSelect={this.changeValues} stateName='hasPatrimoniesTrue' fullWidth label={this.props.intl.formatMessage({ id: "yes" })} autoComplete='fname' name="hasPatrimoniesTrue" selected={this.state.called.hasPatrimoniesTrue} variant='outlined' />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <SimpleCheckBox changeSelect={this.changeValues} stateName='hasPatrimoniesFalse' fullWidth label={this.props.intl.formatMessage({ id: "no" })} autoComplete='fname' name="hasPatrimoniesFalse" selected={this.state.called.hasPatrimoniesFalse} variant='outlined' />
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                        </Grid>
                                        {(this.canselectparentcalledincalled && called.hasParentCalledTrue) && (
                                            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                                                <SimpleAutoComplete label={intl.formatMessage({ id: "parent.called" })} options={this.state.parentCalledsList} stateName='ParentCalledId' changeSelect={this.changeValues} selected={called.ParentCalledId}
                                                    disabled={(this.isClient) ? true : false}
                                                />
                                            </Grid>
                                        )}

                                        {(this.canselectpatrimonyincalled && called.hasPatrimoniesTrue) && (
                                            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                                                <SimpleAutoCompleteMultiple disabled={this.isClient && !this.canclientselectpatrimonyincalled ? true : false} label="Patrimônios Associados ao Chamado:" options={this.state.patrimonyList} stateName='patrimonyId' changeSelect={this.changeValues} selected={called.patrimonyId} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </form>
                            </div>

                        </Grid>

                    </Grid>}
                </Fragment>
            );
        };

        return (

            <Container style={{ position: 'relative' }} component="main" maxWidth="md">

                {/*((this.globalAdm || this.AdmOrganization || this.isCoordenator) && (this.state.called.priority && this.state.called.priority.colorhex && this.state.called.priority.name) ) && (
                <div className="priority-div" >
                    <Card className={classes.card}>
                        <CardContent style={{ backgroundColor: `${this.state.called.priority.colorhex}`, color: '#fff', fontSize: '1.5em', padding: 15 }}>
                            Prioridade: {this.state.called.priority.name}
                        </CardContent>
                    </Card>
                </div>
            )*/}

                {(this.globalAdm || this.AdmOrganization || this.isCoordenator) && (
                    <div className="cardbudget-div" >
                        <Budget
                            className="cardbudget"
                            style={{ marginBottom: 10 }}
                            total={this.state.calledValue}
                            comparation={0}
                            positiveMonth={0}
                            customTitle="CUSTO TOTAL DO CHAMADO"
                            hideComparation={true}
                            icon={<AttachMoneyIcon className={classes2.icon} />}
                            detailsLink
                            detailsLinkFunction={this.valueTimeAttendance} />
                        <Budget
                            className="cardbudget cardbudget-time"
                            total={this.state.calledTime}
                            comparation={0}
                            positiveMonth={0}
                            customTitle="TEMPO DE ATENDIMENTO"
                            hideComparation={true}
                            icon={<WatchLaterIcon className={classes2.icon} />}
                            colorPrimary={true}
                            detailsLink
                            detailsLinkFunction={this.valueCostAttendance} />
                    </div>
                )}

                <Dialog
                    open={this.state.openDialogNewDisk}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: "dialog.title.warning.max.files.reached" })}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {intl.formatMessage({ id: "warning.max.files.reached.first.half" })} {this.state.valueNewDisk} {intl.formatMessage({ id: "warning.max.files.reached.second.half" })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialogNewDisk} variant="contained" color="secondary" autoFocus >
                            {this.props.intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button variant="contained" onClick={this.handleSubmitDiskAccept} color="primary" >
                            Aceitar
                        </Button>
                    </DialogActions>
                </Dialog>



                <div>
                    {<div>{modalP}</div>}
                    {<div>{modalVideo}</div>}
                    {modalCheck}
                    <form name="myForm" className={classes.form} onSubmit={this.handleSubmit} ref={this.formRef} >

                        {!this.isClient && (<div>
                            <Grid container style={{ fontSize: 16 }}>
                                <Grid item xs={12} sm={5}>
                                </Grid>
                                <Grid item xs={12} sm={1} ></Grid>
                                <Grid item xs={12} sm={12}>
                                    {headerContent()}
                                </Grid>
                            </Grid>
                            {!this.state.dataLoading && <Grid container>
                                <Grid item xs={12} sm={12}>
                                    {calledBody()}
                                </Grid>
                                <Grid item xs={12} sm={1} />

                            </Grid>}
                            {!this.state.dataLoading && <Grid item xs={12} sm={12}>
                                {actionBody()}
                            </Grid>}
                        </div>)}
                        {this.isClient && (<div>

                            <Grid container style={{ fontSize: 16 }}>
                                {headerContent()}
                            </Grid>
                            {!this.state.dataLoading &&
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        {clientOrganizationField()}
                                    </Grid>
                                    <Grid item xs={12} sm={1} />

                                </Grid>}
                            {!this.state.dataLoading && <Grid container>
                                {actionBody()}
                            </Grid>}


                        </div>)}


                    </form>
                </div>

                <Dialog
                    maxWidth="md"
                    open={this.state.newUserBaseDialogOpen}
                    onClose={this.closeNewUserBaseDialog}
                    aria-labelledby="newuser-dialog-title" >
                    <DialogTitle id="newuser-dialog-title">
                        <FormattedMessage id="insert.newuser.base" />
                        <ClearIcon onClick={this.closeNewUserBaseDialog} style={{ float: 'right', cursor: 'pointer' }} />
                    </DialogTitle>
                    <DialogContent style={{ width: "800px" }} >
                        <Grid container spacing={1} style={{ marginTop: 15 }}>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label={`${intl.formatMessage({ id: "name" })}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='nameOfNewUser' onChange={this.handleInputChangeNewUser} />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="cpfOfNewUser"
                                    label="CPF/CNPJ*"
                                    fullWidth
                                    value={this.state.createUserCalled.cpfOfNewUser}
                                    onChange={this.handleInputChangeNewUser}
                                    InputLabelProps={{ shrink: true }}
                                    error={itemError.document?.length > 0}
                                    helperText={itemError.document} />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label={`${intl.formatMessage({ id: "email" })}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='emailOfNewUser' onChange={this.handleInputChangeNewUser} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label={`${intl.formatMessage({ id: "confirm.email" })}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='confirmEmailOfNewUser' onChange={this.handleInputChangeNewUser} />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '8px' }}>
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">{intl.formatMessage({ id: "associate.organizations" })}</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={this.state.associateOrganizationOptions}
                                        onChange={this.handleChangeRadioDialog}
                                    >
                                        <FormControlLabel value="associateCalledOrganization" control={<Radio />} label={intl.formatMessage({ id: "associate.requesting.user.to.called.organization" })} />
                                        <FormControlLabel value="associateOtherOrganizations" control={<Radio />} label={intl.formatMessage({ id: "associate.requesting.user.to.others.organizations" })} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {this.state.associateOrganizationOptions == 'associateOtherOrganizations' &&
                                <Grid item xs={12} >
                                    <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "organization" })} options={this.state.organizationsList} stateName='OrganizationFilter' changeSelect={this.changeValues} selected={this.state.OrganizationFilter} />
                                </Grid>
                            }
                            {this.state.associateOrganizationOptions == 'associateOtherOrganizations' &&
                                <Grid item xs={12} >
                                    <SimpleCheckBox label={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })} name='selectOrganizations' stateName='selectOrganizations' changeSelect={this.changeValues} selected={this.state.selectOrganizations} />
                                </Grid>
                            }

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeNewUserBaseDialog} variant="contained" color="secondary" autoFocus >
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button variant="contained" onClick={this.insertNewUser} color="primary" >
                            <FormattedMessage id="insert" />
                        </Button>
                    </DialogActions>
                </Dialog>

                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color="secondary" />
                    </Overlay>
                )}

                {this.state.loadingTwo && (
                    <Overlay>
                        <CircularProgress color="secondary" />
                    </Overlay>
                )}

            </Container>
        );
    }
}

CalledRegistration.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    lastCalledsQueue: state.lastCalledsQueue,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds,
    reload: state.reload
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, calledsActions, headerMenuActions, lastCalledsQueueActions, richTextAreaActions), dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledRegistration))));
//CalledRegistration.contextType = ChatHubContext
