import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import { withRouter } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete-categories';
import * as lastCalledsQueueActions from '../../redux/actions/last-calleds-queue'

import * as componentActions from '../../redux/actions/organizationcategoriesportal';
import { PapiroConsole } from '../../utils/papiroConsole';
import Cardcategories from './components/cardcategories';
import { getLastCalledsQueueByUserId } from '../../services/called.service';
import * as userSessionActions from '../../redux/actions/user-session';
import DialogSystemAlert from "src/components/dialog-system-alert";

class PortalContent extends Component {

    constructor(props) {
        super(props);

        const intl = this.props.intl;


        let fromProps = ""
        if (this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
            fromProps = this.props.from

        let filters = [
            { name: intl.formatMessage({ id: "category" }), id: "category" },
            { name: intl.formatMessage({ id: "organization.category.parent" }), id: "parent" },
            { name: intl.formatMessage({ id: "departament" }), id: "departament" },
            { name: intl.formatMessage({ id: "organization" }), id: "org" },
            { name: intl.formatMessage({ id: "organization.category.create.call" }), id: "createcalled" },
            { name: intl.formatMessage({ id: "organization.category.service.attendance" }), id: "solution" },
            { name: intl.formatMessage({ id: "organization.category.sla" }), id: "sla" },
            { name: intl.formatMessage({ id: "organization.category.service.sla" }), id: "service" },
            { name: intl.formatMessage({ id: "organization.category.time.sla" }), id: "usesla" },
        ];

        const { userSession, organizations } = this.props;
        this.typingTimeout = null;
        if (userSession.user && userSession.user.organizationlistid) {
            this.organizationId = userSession.user.organizationlistid;
        }
        this.state = {
            resultCategories: [],
            resultCategoriesOfPortal : [],
            organizations: organizations,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            organizationList: [],
            organizationsIds: this.organizationId,
            from: fromProps,
            dialogSystemAlert : false,
            dialogSystemAlertMessage : '',
            dialogSystemAlertName : '',

        };
        this.changeValues = this.changeValues.bind(this);
        this.getImage = this.getImage.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.moveToAnotherPage = this.moveToAnotherPage.bind(this);
        this.getAllCategoryOfPortal = this.getAllCategoryOfPortal.bind(this) 
        this.handleCloseDialogSystemAlert= this.handleCloseDialogSystemAlert.bind(this);
        this.handleOpenDialogSystemAlert= this.handleOpenDialogSystemAlert.bind(this);


    }


    getAllCategoryOfPortal = async () => {

        this.setState({ loading: true })
        await Api.get(`/organizationcategories/getallcategoryofportal?org=${this.state.organizationsIds}`)
            .then(async result => {
                if (result.data.success) {

                    this.setState({ loading: false, resultCategoriesOfPortal: result.data.data })
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }


            });
    }



    async getImage(AttachmentId) {

        if (AttachmentId != null && AttachmentId != undefined) {

            await Api.post(`/organizationcategories/attachments`, { AttachmentId })
                .then((result) => {

                    if (result.data) {
                        if (result.data.data && result.data.data.length != undefined && result.data.data.length != 0) {

                            var base = result.data.data;

                            if (base.substring(0, 4).toLowerCase() != "data") {
                                base = "data:image/png;base64," + base;
                            }
                            return base

                        }

                    }
                    else {



                    }
                })
                .catch((err) => {
                    PapiroConsole.log(err);
                    /*this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: "error",
                      notificationMessage:
                        err.response && err.response.data && err.response.data.errors
                          ? err.response.data.errors[0]
                          : this.props.intl.formatMessage({id:"process.error"}),
                    });*/

                    // PapiroConsole.log("entrei aqui");
                });

            return ""

        }

    }

    moveToAnotherPage = async (nextElement) => {


        await this.props.addCurrent(nextElement);
        this.props.history.push({
            pathname: `/${this.props.match.params.lang}/portal-category/${nextElement.id}`,
            state: {
                action: "cardReload",
                parentId: nextElement.parentorganizationcategoryid
            }

        })

    }



    async changeValues(stateName, value, text = '') {



        if (stateName === 'searchCategories') {
            this.moveToAnotherPage(value)
        }
    }



    getOrganizations = async _ => {
        this.setState({ loading: true })
        let data = [];
        const { userSession } = this.props
        if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                })
        }
        this.setState({ organizationList: data, loading: false })
    }

    async componentDidMount() {

        // this.getImage(1059)
        await this.getOrganizations()
        await this.getItens()
        await this.getAllCategoryOfPortal()
        await this.getLastCalledsQueueByUserId(this.props.userSession.user.id)

        if(this.props.userSession && this.props.userSession.user && this.props.userSession.user.startpage && this.props.userSession.user.startpage == "portal" && this.props.userSession.user.alert && this.props.userSession.user.alertalreadydisplayed == false){
        
            PapiroConsole.log("entrei aqui no alerta")
            this.handleOpenDialogSystemAlert(this.props.userSession.user.alert)

        }
    }

    getLastCalledsQueueByUserId = async userId => {
        if (this.props.lastCalledsQueue == null || (this.props.lastCalledsQueue != null && (this.props.lastCalledsQueue.items == null || (this.props.lastCalledsQueue.items != null && Object.keys(this.props.lastCalledsQueue.items).length === 0 && this.props.lastCalledsQueue.items.constructor === Object)))) {
            let response = await getLastCalledsQueueByUserId(userId)
            if (response != null && response.success != null && response.success && response.data != null) {
            if (Array.isArray(response.data)) {
                response.data.forEach(item => {
                if (item.id != null && item.title != null)
                    this.props.equeuelastCalledsQueue({ id: item.id, title: item.title })
                })
            }
            } else {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: false,
                notificationVariant: 'error',
                notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
            }
        }
    }

    getItens(page = 1) {

        this.setState({ loading: true });
        const intl = this.props.intl;

        let url = ''



        //if (this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/portal`) {
        url = `/organizationcategories/list/withoutparents/paginate?org=${this.state.organizationsIds}`;


        /* } else {
             url = `/organizationcategories/organization/list/withoutparents/paginate?org=${this.state.organizationId}`;
                         
         }*/
        Api.get(url)
            .then(result => {
                if (result.data.success) {


                    this.setState({ resultCategories: result.data.data });


                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,
                            OrganizationId: item.organization ? item.organization.id : 0,
                            OrganizationText: item.organization ? item.organization.name : '',
                            parentname: item.parentname,
                            OrganizationSlaId: item.organizationsla ? item.organizationsla.id : 0,
                            OrganizationSlaText: item.organizationsla ? item.organizationsla.name : '',
                            SlaCountTypeId: item.slacounttype ? item.slacounttype.id : 0,
                            CategoryId: item.category ? item.category.id : 0,
                            CategoryText: item.name,
                            ParentOrganizationCategoryId: item.parentorganizationcategory ? item.parentorganizationcategory.id : 0,
                            ParentOrganizationCategoryText: item.parentorganizationcategory ? item.parentorganizationcategory.name : '',
                            CanCreateCalled: item.cancreatecalled == true ? this.props.intl.formatMessage({ id: "yes" }) : this.props.intl.formatMessage({ id: "no" }),

                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }

    handleCloseDialogSystemAlert() {
            this.setState({ dialogSystemAlert: false });
            this.props.updateAlertAlreadyDisplayed(true)
    
    }
    
    handleOpenDialogSystemAlert(alert) {

        PapiroConsole.log("alerta aqui")
        PapiroConsole.log(alert)
        this.setState({ dialogSystemAlertMessage :alert.message, dialogSystemAlertName : alert.title,  dialogSystemAlert: true });

        setTimeout(this.handleCloseDialogSystemAlert, alert.time * 1000);
    }
    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;



        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                <CssBaseline />

                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12}>
                        <Card className="background-title-search">
                            <CardContent>
                                <Grid container >
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                {<FormattedMessage id="portal" />}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: 10 }} >
                                        <Grid item xs={12} >
                                            {/*<TextField type="text"
                                                        fullWidth
                                                        name="searchValue"
                                                        value={this.state.searchValue}
                                                        placeholder={intl.formatMessage({id:"categories"})}
                                                        onChange={this.onFieldChange}/>*/}
                                            <SimpleAutoComplete label={intl.formatMessage({ id: "categories" })} options={this.state.resultCategoriesOfPortal} stateName='searchCategories' changeSelect={this.changeValues} />
                                        </Grid>

                                    </Grid>


                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Can I='List' a='OrganizationCategory'>

                    <Grid container spacing={2} style={{ marginTop: 10 }} >


                        {this.state.resultCategories.map((element) => {
                            return (

                                <Cardcategories properties={this.props} key={element.id} element={element} maxLength={200} />



                            );
                        })}


                    </Grid>



                </Can>



                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}

                <DialogSystemAlert
                    open={this.state.dialogSystemAlert}
                    handleCloseDialogSystemAlert={this.handleCloseDialogSystemAlert}
                    message = {this.state.dialogSystemAlertMessage}
                    title = {this.state.dialogSystemAlertName}
                />


                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />
            </Container>
        );
    }
}
PortalContent.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    lastCalledsQueue: state.lastCalledsQueue,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,

});
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, componentActions, lastCalledsQueueActions,userSessionActions), dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(PortalContent))));
PortalContent.contextType = AbilityContext;
